import { common } from './common'
export const pages = {
  AddCalendarPage: {
    title: common.register,
    calendarSelectionBox: {
      title: '表示するカレンダーを\n登録してください',
      nextStep: common.proceedToRegister,
      description: '選択したカレンダーの予定をSpirに表示し、 日程調整や予定管理をすることができます。',
      securityPageLink:
        "Spirのセキュリティ詳細は<a class='link' rel='noopener' target='_blank' href='https://help.spirinc.com/ja/spir%E3%81%AF%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E5%AF%BE%E7%AD%96%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%81%BE%E3%81%99%E3%81%8B'>こちら</a>",
      previousStep: 'カレンダー連携をやめる'
    },
    modal: {
      common: {
        continueButton: common.proceedToRegister,
        registerButton: common.proceedToRegister,
        reasonTitle: '権限が必要な理由',
        reasonText:
          'Spirでは予定を見つつ候補を設定したり、確定した予定を登録するために、カレンダー情報の表示・編集・アクセスが必要となります。'
      },
      google: {
        title: 'すべてにチェックを入れてください',
        introduction: 'カレンダー登録するためには、すべての権限にチェックを入れる必要があります。'
      },
      microsoft: {
        title: 'アクセスを許可してください',
        introduction: 'カレンダー登録するためには、あなたの情報へのアクセスを許可する必要があります。'
      }
    }
  },
  AuthTeamsCreate: {
    calendarSelectMerit: {
      title: 'チームの予定を登録する\nカレンダーを選択してください',
      description:
        '日程調整をチームで行うにはカレンダー連携が必要です。確定した予定を登録するカレンダーを選択してください。',
      nextStep: 'カレンダー選択に進む'
    },
    title: 'SpirのFreeプランをはじめる',
    pageDescription: 'アカウントを選択 / 新規作成し、以下の項目を入力すると、すぐにSpirをご利用いただけます。',
    teamSettingSection: {
      form: {
        companyName: {
          label: '会社名',
          formName: '会社名',
          placeHolder: '会社名を入力'
        },
        teamName: {
          label: 'チーム名',
          formName: 'チーム名',
          placeHolder: 'チーム名を入力',
          help: 'チーム名は会社名、部署名、プロジェクト名などを設定してください。'
        },
        contactPersonName: {
          label: 'お名前',
          formName: 'お名前',
          placeHolder: 'お名前を入力'
        },
        phoneNumber: {
          label: '電話番号',
          formName: '電話番号'
        },
        updatableInfo: '※チーム名は後で変更できます。'
      }
    },
    registrationCautionSection: {
      others: common.registrationCaution.others,
      othersUrl: common.registrationCaution.othersUrl
    },
    confirmButton: 'この内容で申し込む',
    error: {
      calendar: 'メインカレンダーの登録に失敗し、チームの作成に失敗しました。',
      unknown: 'チームの作成に失敗しました。',
      emailProvider: 'Emailで作成されたアカウントではチームを作成できません。'
    },
    useDefaultBrowser: {
      messageHtml:
        'このブラウザからは<strong>新規チームプランの申し込み</strong>が出来ません。</br>スマホのブラウザ(Safari, Chromeなど)から申し込みを行って下さい。'
    }
  },
  AuthTeamsCreateFree: {
    calendarSelectMerit: {
      title: 'チームの予定を登録する\nカレンダーを選択してください',
      description:
        '日程調整をチームで行うにはカレンダー連携が必要です。確定した予定を登録するカレンダーを選択してください。',
      nextStep: 'カレンダー選択に進む'
    },
    title: 'SpirのFreeプランをはじめる',
    pageDescription:
      'アカウントを選択/新規作成し、以下の項目を入力すると、すぐにSpirのFreeプランをご利用いただけます。',
    teamSettingSection: {
      title: 'チーム設定',
      teamNameHelpText: 'チーム名は会社名、部署名、プロジェクト名などを設定してください。',
      updatableInfo: '※チーム名は後で変更できます。',
      form: {
        companyName: {
          label: '会社名',
          formName: '会社名',
          placeHolder: '会社名を入力'
        },
        teamName: {
          label: 'チーム名',
          formName: 'チーム名',
          placeHolder: 'チーム名を入力'
        },
        contactPersonName: {
          label: 'お名前',
          formName: 'お名前',
          placeHolder: 'お名前を入力'
        },
        phoneNumber: {
          label: '電話番号',
          formName: '電話番号'
        }
      }
    },
    others: common.registrationCaution.others,
    othersUrl: common.registrationCaution.othersUrl,
    confirmButton: 'この内容で申し込む',
    error: {
      unknown: 'チームの作成に失敗しました。'
    }
  },
  AuthTeamIdStart: {
    head: {
      title: 'Spirを利用できるようになりました',
      completion: '「Spirをはじめる」ボタンからメンバーを招待して、日程調整してみましょう。'
    },
    onboardingSteps: {
      head: {
        title: 'Spir活用のステップ',
        desc: 'Spirの使い方をまとめたヘルプページをご用意しました。ぜひご覧ください。'
      },
      steps: {
        '1': {
          title: 'メンバーを招待',
          desc: '予定や日程調整を共有するメンバーをチームに追加'
        },
        '2': {
          title: 'カレンダー共有方法の設定',
          desc: 'チームメンバーからのカレンダーの見え方を確認'
        },
        '3': {
          title: 'チームで日程を調整',
          desc: '空き時間URL、候補を提案からチームで日程調整'
        }
      },
      helpPage: {
        title: 'Spirの使い方',
        desc: 'このページでは、Spirの使い方・活用方法をご紹介しています。',
        detailPage: '記事内容を詳しく見る',
        detailLink: common.links.teamHelpPage,
        imageAlt: 'Spirヘルプページ'
      }
    },
    nextStep: {
      start: 'Spirをはじめる'
    }
  },
  AuthTeamIdStartFree: {
    head: {
      title: 'Freeプランを利用できるようになりました',
      completion: '「Spirをはじめる」ボタンからメンバーを招待して、日程調整してみましょう。'
    },
    onboardingSteps: {
      head: {
        title: 'Spir活用のステップ',
        desc: 'Spirの使い方をまとめたヘルプページをご用意しました。メンバー招待からカレンダーの共有設定などFreeプランを使用する上で必要な情報です。ぜひご覧ください。'
      },
      steps: {
        '1': {
          title: 'メンバーを招待',
          desc: '予定や日程調整を共有するメンバーをチームに追加'
        },
        '2': {
          title: 'カレンダー共有方法の設定',
          desc: 'チームメンバーからのカレンダーの見え方を確認'
        },
        '3': {
          title: 'チームで日程を調整',
          desc: '空き時間URL、候補を提案からチームで日程調整'
        }
      },
      helpPage: {
        title: 'Spirの使い方',
        desc: 'このページでは、Spirの使い方・活用方法をご紹介しています。',
        detailPage: '記事内容を詳しく見る',
        detailLink: common.links.teamHelpPage,
        imageAlt: 'Spirヘルプページ'
      }
    },
    nextStep: {
      start: 'Spirをはじめる'
    }
  },
  PersonalScheduleConfirmGuest: {
    modal: {
      title: common.overlayYourCalendar
    },
    confirmerAction: {
      title: common.noAvailableConfirmationDate
    }
  },
  PersonalAvailabilitySharingConfirmGuest: {
    modal: {
      title: common.overlayYourCalendar
    }
  },
  TeamScheduleConfirmGuest: {
    modal: {
      title: common.overlayYourCalendar
    },
    subscriptionFailure: common.teamRestricted
  },
  TeamAvailabilitySharingConfirmGuest: {
    title: common.pleaseSelectCandidates,
    signUpModalTitle: common.overlayYourCalendar,
    subscriptionErr: common.teamRestricted
  },
  TeamAvailabilitySharingConfirm: {
    subscriptionErr: common.teamRestricted
  },
  PersonalGroupPollVoteGuest: {
    titleInListView: '日程を投票',
    signupGuideBoxTitle: '候補から日程を投票してください',
    signUpModalTitle: common.overlayYourCalendar,
    signUpButton: common.checkCandidatesOnCalendar
  },
  AfterConfirmation: {
    buttons: {
      cancel: common.cancel
    },
    scheduleCancel: {
      title: '予定をキャンセルしますか？',
      description: 'この操作は取り消せません。予定をキャンセルすると、あなたと参加者に通知のメールを配信します。'
    },
    scheduleAlternativeDates: {
      requestAlternative: '別の候補を依頼'
    },
    subscriptionFailure: common.teamRestricted
  },
  PersonalScheduleSuggestNewSlots: {
    footer: {
      confirmButton: common.confirm,
      cancelButton: common.cancel
    }
  },
  AvailabilityList: {
    title: '空き時間URL',
    createButton: common.availabilitySharingCreateButton,
    messages: {
      successes: {
        duplicated: '空き時間URLを複製しました。'
      },
      errors: {
        exceedMaxPublishedCount: '公開可能な空き時間URLの数は{count}件です。',
        notSubscriptionUser: '作成可能な空き時間URLの数は{count}件です。',
        disconnectedZoomIntegration: 'Zoom連携が解除されています。再度連携を行ってください。'
      }
    }
  },
  TeamMemberSetting: {
    title: 'メンバー',
    invite: '招待',
    listTitle: 'メンバー一覧',
    invitationTip: '契約プランを選択してください',
    currentTeamMembersCount: common.currentTeamMembersCount,
    currentTeamMembersCounts: common.currentTeamMembersCount,
    canceledCurrentTeamMembersCount: common.canceledCurrentTeamMembersCount,
    canceledCurrentTeamMembersCounts: common.canceledCurrentTeamMembersCount,
    invitationHelp: '管理者は右上の「招待」ボタンからチームメンバーを追加できます。',
    functionExplanation:
      'メンバー間での予定の共有とメンバーの代わりに日程の調整ができます。\n{planName}では、<span class="member-count">{planMemberLimit}人</span>のメンバーで利用可能です（招待中を含む）',
    shareAndManage: 'メンバー間での予定の共有とメンバーの代わりに日程の調整ができます。',
    reachMemberLimit:
      '現在のプランのご利用可能メンバーの上限に達しました。メンバーを追加する場合は、人数に適したプランへ変更してください。',
    message: {
      errors: {
        EMAIL_ALREADY_INVITED: '招待メールを出した{email}はすでに招待中です。',
        ETC: '不明なエラーが発生しました。',
        INVITATION_ID_NOT_FOUND: '招待URLのコピーに失敗しました'
      },
      successInvite: 'メンバーを招待しました。',
      successCancel: 'キャンセルしました。'
    },
    team: {
      member: common.member
    },
    member: {
      'delete-confirm-left-data':
        'このメンバーが主催者となっている調整があります。<br>・空き時間URLが<strong>{availabilityCount}件</strong></br>・調整中のチーム日程調整が<strong>{scheduleCount}件</strong></br>メンバーを削除するとこれらのデータも削除されます。</br>この操作は取り消せません。',
      'delete-confirm': 'この操作は取り消せません。',
      'delete-label': 'このメンバーを本当に削除しますか？'
    },
    'member-is-managing-resource': {
      deleteMember: {
        header: 'リソース登録者のため、チームから削除できません',
        description: 'チーム設定のリソースから、リソース登録者を変更してください。'
      },
      changeRoleToMember: {
        header: 'リソース登録者のため、メンバーに変更できません',
        description: 'チーム設定のリソースから、リソース登録者を変更してください。'
      },
      button: 'リソース管理画面へ',
      close: '閉じる'
    }
  },
  TeamPlanSetting: {
    title: common.plan,
    selectionDescription: 'チームプランを利用する人数から最適なプランを選択してください',
    confirmationSucceeded: common.planConfirmationSucceeded,
    alertBoxText: {
      trialWithoutSetup:
        '{dueDate}までにプランの申し込みを完了させてください。期日を過ぎるとチームプランが利用できなくなります',
      canceling: 'チームプランが解約されたため、{dueDate}以降利用できなくなります',
      canceled: 'チームの利用にはプランの申し込みが必要です',
      pastDue:
        '登録したクレジットカードでの決済に失敗しました。<span class="payment-info-page">支払い情報</span>からクレジットカードを更新してください',
      paymentFailed:
        '登録したクレジットカードで決済できないため利用が停止しました。チームの利用にはプランの申し込みが必要です'
    },
    planHint: {
      active: '次回の決済日は{nextDate}です。',
      initial:
        '申し込みから1ヶ月間はフリートライアル期間として無料でご利用可能です。次の決済日に選択しているプランの値段で決済されます。'
    },
    cardDeclined: 'このクレジットカードは利用できませんでした。'
  },
  TeamPlanOrderConfirmation: {
    registerTitle: '{planName}プランに申し込む',
    selectedPlan: '選択したプラン',
    fillInCreditCardInfo: 'クレジットカード情報を入力',
    creditCardFormTitle: 'クレジットカード',
    billingSection: '決済情報',
    backButton: 'プランの選択に戻る',
    confirmButton: 'この内容で申し込む',
    confirmationSucceeded: common.planConfirmationSucceeded,
    stripe: {
      cardGenericDecline: 'カードが拒否されました。',
      cardInsufficientFunds: 'カード残高が不足しています。',
      expiredCard: 'カードの有効期限が切れています。',
      incorrectCvc: 'カードのセキュリティコードが間違っています。',
      processingError: 'カードの処理中にエラーが発生しました。しばらくしてからもう一度お試しください。',
      noMatched: 'カードの登録に失敗しました',
      setupIntentAuthenticationFailure:
        '支払い方法を認証できません。別の支払い方法を選択して、もう一度お試しください。',
      formGenerationFailed: 'カード登録フォームの作成に失敗しました。もう一度お試しください。'
    },
    billingNote: {
      freeTrialPeriod: '現在トライアル期間です。初回の決済日は{nextDate}です。',
      nextDate: '初回の決済日は{nextDate}です。'
    },
    billTable: {
      planPrice: 'プラン利用料金',
      salesTax: '消費税',
      sum: '合計金額',
      price: '¥{amount}'
    }
  },
  ServiceUnavailable: {
    title: '日程調整を利用することができません',
    unAvailableDescription:
      'この日程調整は主催者さまのご都合により、現在利用が制限されています。</br>詳しくは主催者さまにお問合せください。',
    goToTopPage: 'トップに戻る'
  },
  TeamMemberInvite: {
    subscriptionErr: common.teamRestricted
  },
  AvailabilitySharingCreate: {
    subscriptionErr: common.teamRestricted
  },
  ConfirmedList: {
    title: common.nav.confirmedList,
    error: {
      confirmedItemNotFound: '詳細情報がみつかりませんでした。'
    },
    pastEventsReloadBtn: '過去の予定を読み込む'
  },
  UnconfirmedListPage: {
    title: common.nav.unconfirmedList
  },
  ListQuestionnaires: {
    newQuestionnaire: {
      title: '無題のフォーム'
    },
    QuestionnaireList: {
      TeamQuestionnairePreviewPanel: {
        cancel: {
          title: 'プレビューを閉じる'
        }
      }
    },
    toasts: {
      success: {
        created: 'フォームが作成されました'
      },
      failed: {
        notMember: common.questionnaires.toasts.failed.notMember,
        teamNotAvailable: common.questionnaires.toasts.failed.teamNotAvailable
      }
    }
  },
  TeamQuestionnaireDetail: {
    deleteQuestionConfirmationModal: {
      title: '質問を削除しますか',
      description:
        '質問項目が、空き時間URLの「カレンダーに登録される予定のタイトル」に設定されている場合、その設定が無効になる場合があります。\n本当に削除しますか。'
    },
    editQuestionToNotRequiredConfirmationModal: {
      title: '任意の質問に変更しますか',
      description:
        '質問項目が、空き時間URLの「カレンダーに登録される予定のタイトル」に設定されている場合、その設定が無効になる場合があります。本当に変更しますか。',
      confirmBtn: '変更'
    },
    toasts: {
      success: {
        deleteQuestion: 'フォーム項目削除が成功しました',
        editQuestion: '質問の更新に成功しました'
      },
      failed: {
        notMember: common.questionnaires.toasts.failed.notMember,
        teamNotAvailable: common.questionnaires.toasts.failed.teamNotAvailable,
        questionnaireNotFound: common.questionnaires.toasts.failed.questionnaireNotFound,
        questionNotFound: '質問がみつかりませんでした',
        failedInChangeOrder: '並び替えに失敗しました',
        questionnaireValidation: '入力内容を確認してください',
        invalidError: '質問の変更に失敗しました。もう一度内容確認してください'
      }
    },
    checkPreview: 'プレビューを確認',
    defaultQuestions: common.questionnaires.defaultQuestions,
    editQuestionnaireOpen: {
      title: '管理用情報の編集'
    },
    changeQuestionsOrderPanelOpen: {
      title: '並び替え'
    },
    newQuestionOpen: {
      title: '質問を追加'
    },
    preview: {
      title: 'プレビュー'
    },
    ReturnButton: {
      title: '一覧へ戻る'
    },
    EditQuestionnairePanel: {
      title: '管理用情報の編集',
      questionnaire: {
        title: '管理用タイトル',
        description: '管理用説明文'
      },
      footerBox: {
        cancel: 'キャンセル',
        save: '保存'
      }
    },
    ChangeQuestionsOrderPanel: {
      title: '質問の並び替え',
      footerBox: {
        cancel: 'キャンセル',
        save: '保存'
      }
    },
    NewQuestionPanel: {
      title: '質問の作成',
      defaultPlaceholder: '回答を記入してください',
      defaultOption: '選択肢1',
      footerBox: {
        cancel: 'キャンセル',
        save: '作成'
      }
    },
    EditCustomQuestionPanel: {
      title: '質問の編集',
      footerBox: {
        danger: '質問を削除',
        cancel: 'キャンセル',
        save: '保存'
      }
    },
    EditDefaultQuestionPanel: {
      title: '質問の編集',
      footerBox: {
        cancel: 'キャンセル',
        save: '保存'
      }
    },
    TeamQuestionnairePreviewPanel: {
      cancel: {
        title: 'プレビューを閉じる'
      }
    }
  },
  TeamWebhookSetting: {
    title: 'Webhook設定',
    description: 'Webhookの利用を希望される場合、下記のフォームからお申し込みください',
    buttonName: 'お申込みフォーム'
  },
  TeamLayout: {
    pageNames: {
      availabilityList: common.nav.availabilityList,
      confirmedList: common.nav.confirmedList,
      unconfirmedList: common.nav.unconfirmedList,
      calendar: common.calendar,
      forms: 'フォーム',
      teamSettings: 'チーム設定'
    }
  },
  PersonalHome: {
    createButton: common.availabilitySharingCreateButton
  },
  TeamHome: {
    createButton: common.availabilitySharingCreateButton
  },
  PersonalUnconfirmedList: {
    createButton: '候補を提案'
  },
  TeamUnconfirmedList: {
    createButton: '候補を提案',
    teamNotAvailable: 'チームを利用できません'
  },
  ProfileView: {
    title: 'プロフィール'
  },
  ProfileShareView: {
    title: 'プロフィール'
  },
  ProfileEdit: {
    title: 'プロフィール'
  },
  Settings: {
    title: 'アカウント設定'
  },
  TeamAvailabilitySharingConfirmedEventEditByMember: {
    cannotEditSlot: '日時の編集はできません'
  }
}
