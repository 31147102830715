import { PollModel } from '@/models/data/poll'
import { ScheduleModel } from '@/models/data/schedule'
import store from '@/store'
import UserModule from '@/store/modules/user'
import { orderBy } from 'lodash'
/**
 * リストPanel表示用
 */
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'
import { ISchedulePollCommon } from '../../types'
import EditScheduleModule from './editSchedule'
import PollListModule from './pollList'
import ScheduleListModule from './scheduleList'
import { invalidatePollListQuery, invalidateScheduleListQuery } from './candidates'
import CandidatesModule from '@/store/modules/candidates'

export enum ListType {
  POLL = 'poll',
  SCHEDULE = 'schedule'
}

export interface IScheduleForUI extends Omit<ISchedulePollCommon, 'createdAt'> {
  backgroundColor: string
  foregroundColor: string
  type: ListType
  createdAt: Date
}

const MODULE_NAME = 'ListPanel'
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class ListPanel extends VuexModule {
  // scheduleとPollをあわせて、 それぞれのnextTokenを保存する
  get listForListPanel(): Array<PollModel | ScheduleModel> {
    const scheduleList = ScheduleListModule.getScheduleModelList
    const pollList = PollListModule.getPollModelList
    const listForUI = [...scheduleList, ...pollList]
    return orderBy(listForUI, 'createdAt', 'desc')
  }
  get isLoading() {
    return ScheduleListModule.isScheduleLoading || PollListModule.getIsPollListLoading
  }
  get isConfirmListLoading() {
    return ScheduleListModule.isConfirmedScheduleLoading || PollListModule.getIsConfirmedPollListLoading
  }
  @Action
  async fetchScheduleList() {
    if (!UserModule.isSignIn) {
      return
    }
    await ScheduleListModule.fetchScheduleList()
    return
  }
  @Action
  async getItemById({ itemId, type }: { itemId: string; type: ListType }) {
    switch (type) {
      case 'schedule': {
        const schedule = await ScheduleListModule.getScheduleByScheduleId(itemId)
        if (!schedule?.fetchCalendarService) {
          return EditScheduleModule.fetchSchedule({ scheduleId: itemId })
        }
        return schedule
      }
      case 'poll':
        return PollListModule.getPollByPollId(itemId)
    }
  }
  @Action
  deleteSchedule(id) {
    const selectedSchedule = this.listForListPanel.find(s => s.id === id)
    if (!selectedSchedule) {
      return
    }
    if (selectedSchedule.type === 'schedule') {
      ScheduleListModule.deleteSchedule(id)
      CandidatesModule.DELETE_SCHEDULE_LIST(id)
      invalidateScheduleListQuery()
      return
    } else {
      PollListModule.deletePoll(id)
      CandidatesModule.DELETE_POLL_LIST(id)
      invalidatePollListQuery()
      return
    }
  }
  @Action
  RESET_STATE() {
    ScheduleListModule.RESET_STATE()
    PollListModule.RESET_STATE()
  }
}

export default getModule(ListPanel)
