export const common = {
  register: '新規登録',
  proceedToRegister: '登録に進む',
  checkCandidatesOnCalendar: '候補と自分の予定を重ねて表示',
  pleaseSelectCandidates: '候補を選択',
  overlayYourCalendar: '候補と自分の予定を重ねて表示',
  confirm: '確定',
  noAvailableCandidate: '参加可能な候補がない',
  pleaseConfirmCandidate: '候補から日程を確定してください',
  cancel: 'キャンセル',
  messageToOrganizer: '主催者へのメッセージ',
  emailAddress: 'メールアドレス',
  name: '名前',
  back: '戻る',
  confirmSchedule: '日程を確定',
  noAvailableConfirmationDate: 'どの日程も都合がつかない場合は',
  requestAlternative: '別の候補を依頼',
  suggestNewSlots: '新しい候補を提案',
  addPossibleDates: '候補を追加',
  personal: '個人',
  team: 'チーム',
  teamSuspended: '利用停止中',
  calendar: 'カレンダー',
  teamCalendars: 'チーム',
  you: 'あなた',
  teamSetting: 'チーム設定',
  member: 'メンバー',
  plan: '契約プラン',
  paymentInfo: '支払い情報',
  attendee: {
    label: '参加者'
  },
  duration: '打合せ時間',
  timeBuffer: '間隔',
  delete: '削除',
  planConfirmationSucceeded: 'プランの申し込みが完了し、利用可能になりました',
  teamRestricted: '現在、こちらのチームの機能は制限されています。',
  registrationCaution: {
    header: '注意事項',
    headerAttention: '※必ずお読みください',
    freeTrial:
      'フリートライアルは、開始後の利用状況に関わらずチームにつき1度だけご利用いただけます。同一と判定されるチームでフリートライアルを繰り返し利用しているとSpir運営者が判定した場合、アカウントを停止させていただくことがございます。',
    paymentMethod: '支払方法：クレジット決済',
    others:
      'その他詳細は<a class="registration-caution-ul__link" href="{link}" target="_blank" rel="noopener noreferrer">特定商取引法に基づく表示</a>をご覧ください。',
    othersUrl: 'https://www.spirinc.com/law'
  },
  links: {
    landingPage: 'https://www.spirinc.com',
    privacyPolicy: 'https://www.spirinc.com/privacy',
    termsOfService: 'https://www.spirinc.com/terms',
    teamHelpPage: 'https://help.spirinc.com/ja'
  },
  requiredAtLeastPurposeCheck: '利用用途を選んでください',
  priceAmount: '<span class="unit">￥</span>{amount} <span class="interval">{interval}</span>',
  recurringInterval: {
    day: '/ 日',
    week: '/ 週',
    month: '/ 月',
    year: '/ 年'
  },
  currentTeamMembersCount: '<span style="color: #6bca7a; font-weight: 600">現在{num}人</span>のメンバーで利用中',
  canceledCurrentTeamMembersCount:
    '<span style="color: #6bca7a; font-weight: 600">現在{num}人</span>のメンバーがいます',
  weekdays: {
    friday: '金',
    monday: '月',
    saturday: '土',
    sunday: '日',
    thursday: '木',
    tuesday: '火',
    wednesday: '水'
  },
  nav: {
    availabilityList: '空き時間URL',
    confirmedList: '確定済の予定',
    unconfirmedList: '未確定の調整'
  },
  questionnaires: {
    toasts: {
      failed: {
        notMember: 'メンバーに登録されていません',
        teamNotAvailable: 'チームを利用できません',
        questionnaireNotFound: 'フォームが見つかりませんでした'
      }
    },
    defaultQuestions: {
      name: {
        title: 'お名前',
        placeholder: 'お名前を入力してください'
      },
      email: {
        title: 'メールアドレス',
        placeholder: 'メールアドレスを入力してください',
        note: 'カレンダー招待と確定通知がメールアドレスに送信されます'
      },
      messageToOrganizer: {
        title: '主催者へのメッセージ',
        placeholder: '主催者へのメッセージを入力してください'
      }
    }
  },
  accountTokenInvalidError: {
    message: '主催者側のカレンダー連携に問題が発生しています。主催者にお問い合わせください。'
  },
  availabilitySharingCreateButton: '空き時間URLを作成'
}
