// for new module
import { PollModel, ScheduleModel } from '@/models/data'
import { AfterConfirmModel, AfterConfirmModelForAvailability } from '@/models/data/afterConfirm'
import store from '@/store'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ScheduleModelTeam } from '../../models/data/schedule'

const MODULE_NAME = 'AfterConfirm'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class AfterConfirm extends VuexModule {
  // @ts-expect-error TS2322
  _model: AfterConfirmModel | AfterConfirmModelForAvailability = null
  _isLoading = false

  get getAfterConfirm() {
    return this._model
  }
  get isLoading() {
    return this._isLoading
  }
  @Action
  setModelFromSchedule(scheduleModel: ScheduleModel) {
    const afterConfirmModel = AfterConfirmModel.createByScheduleModel(scheduleModel)
    this.SET_MODEL(afterConfirmModel)
  }
  @Action
  setModelFromScheduleTeam(scheduleModel: ScheduleModelTeam) {
    const afterConfirmModel = AfterConfirmModel.createByScheduleTeamModel(scheduleModel)
    this.SET_MODEL(afterConfirmModel)
  }
  @Action
  setModelFromPoll(pollModel: PollModel) {
    const afterConfirmModel = AfterConfirmModel.createByPollModel(pollModel)
    this.SET_MODEL(afterConfirmModel)
  }

  @Action
  setAfterConfirmModelForAvailability(model: AfterConfirmModelForAvailability) {
    this.SET_MODEL(model)
  }
  @Mutation
  SET_MODEL(model: AfterConfirmModel | AfterConfirmModelForAvailability) {
    this._model = model
  }
  @Mutation
  SET_LOADING(isLoading: boolean) {
    this._isLoading = isLoading
  }
}

export default getModule(AfterConfirm)
