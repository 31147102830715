import store from '@/store'
import { ApiFetchEvent, GoogleEvent, IAttendee, ProfileOnOverview } from '@/types'
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getProfile } from '../../lib/api/profile'
import CalendarsModule, { encodeCalendarKeyByAccountIdAndCalendarId } from './calendars'
import EventModule from './event'
import EventsModule, { decodeFullCalendarEventToGoogleEvent } from './events'

const MODULE_NAME = 'EventForModal'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class EventForModal extends VuexModule {
  // @ts-expect-error TS2322
  temporaryEvent: GoogleEvent = null
  attendees: ProfileOnOverview[] = []

  get getTemporaryEvent() {
    return this.temporaryEvent
  }
  get getAttendees(): ProfileOnOverview[] {
    return this.attendees.sort(a => (a.isAuthor ? -1 : 1))
  }
  @Action
  async getTeamEventByEventId(payload: ApiFetchEvent) {
    const event = EventsModule.teamEvents.find(
      e =>
        e.id === payload.eventId &&
        // @ts-expect-error TS2532
        e.extendedProps.teamInfo?.teamId === payload.teamInfo?.teamId &&
        // @ts-expect-error TS2532
        e.extendedProps.teamInfo?.memberId === payload.teamInfo?.memberId
    )
    if (!event) {
      return
    }
    const googleEvent = decodeFullCalendarEventToGoogleEvent(event)
    this.SET_EVENT({ ...googleEvent, writable: false })
    // @ts-expect-error TS2345
    this.SET_ATTENDEES(googleEvent.attendees)
    // @ts-expect-error TS2345
    this.fetchAttendeesInfo(googleEvent.attendees)
  }
  @Action
  async getEventByEventId(payload: ApiFetchEvent) {
    let event = EventsModule.allPrivateEvents.find(
      e => e.id === payload.eventId && e.accountId === payload.accountId && e.calendarId === payload.calendarId
    )
    if (!event) {
      event = (await EventModule.getEvent(payload)).event
      if (!event) {
        return null
      }
    }
    // @ts-expect-error TS2345
    const idByAccountAndCalendar = encodeCalendarKeyByAccountIdAndCalendarId(event.accountId, event.calendarId)
    const calendar = CalendarsModule.flattenCalendars[idByAccountAndCalendar]
    this.SET_EVENT({
      ...event,
      writable: event.writable && calendar.writable
    })
    // @ts-expect-error TS2345
    this.SET_ATTENDEES(event.attendees)
    // @ts-expect-error TS2345
    this.fetchAttendeesInfo(event.attendees)
  }
  @Action
  async fetchAttendeesInfo(attendees: IAttendee[]) {
    if (!attendees || attendees.length === 0) {
      return
    }
    const fetchedAttendees = await Promise.all(
      attendees
        .filter(a => a.id && !a.photoURL)
        .map(async a => {
          // @ts-expect-error TS2322
          this.SET_ATTENDEE({ id: a.id, isLoading: true })
          try {
            const profileData = (await getProfile(a.id)).data
            return profileData
          } finally {
            // @ts-expect-error TS2322
            this.SET_ATTENDEE({ id: a.id, isLoading: false })
          }
        })
    )
    fetchedAttendees.forEach(fA => {
      if (fA) {
        this.SET_ATTENDEE({
          id: fA.id,
          isLoading: false,
          fullName: fA.fullName,
          photoURL: fA.photoURL
        })
      }
    })
  }
  @Mutation
  SET_EVENT(event?: GoogleEvent) {
    // @ts-expect-error TS2322
    this.temporaryEvent = event
  }
  @Mutation
  SET_ATTENDEES(attendees: IAttendee[]) {
    if (!attendees || attendees.length === 0) {
      this.attendees = []
      return
    }
    // @ts-expect-error TS2322
    this.attendees = attendees.map(a => {
      return {
        fullName: a.fullName || a.email,
        photoURL: a.photoURL || null,
        email: a.email,
        userId: a.id,
        isAuthor: !!a.organizer,
        attendanceStatus: a.responseStatus,
        isLoading: !!a.isLoading
      }
    })
    if (
      this.attendees.length > 0 &&
      this.temporaryEvent.organizer?.email &&
      this.temporaryEvent.organizer.type === 'normal' &&
      // @ts-expect-error TS2532
      this.attendees.filter(a => a.email === this.temporaryEvent.organizer.email).length === 0
    ) {
      this.attendees.push({
        fullName: this.temporaryEvent.organizer.displayName || this.temporaryEvent.organizer.email,
        email: this.temporaryEvent.organizer.email,
        isAuthor: true
      })
    }
  }
  @Mutation
  SET_ATTENDEE(payload: { id: string; isLoading: boolean; fullName?: string; photoURL?: string }) {
    const findAttendeeIndex = this.attendees.findIndex(a => a.userId === payload.id)
    if (findAttendeeIndex >= 0) {
      const newAttendee = {
        ...this.attendees[findAttendeeIndex],
        isLoading: payload.isLoading
      }
      if (payload.fullName) {
        newAttendee['fullName'] = payload.fullName
      }
      if (payload.photoURL) {
        newAttendee['photoURL'] = payload.photoURL
      }
      Vue.set(this.attendees, findAttendeeIndex, newAttendee) // make reactive
    }
  }
}

export default getModule(EventForModal)
