import { render, staticRenderFns } from "./ProfileImageUploader.vue?vue&type=template&id=0fe97038&scoped=true&"
import script from "./ProfileImageUploader.vue?vue&type=script&lang=ts&"
export * from "./ProfileImageUploader.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileImageUploader.vue?vue&type=style&index=0&id=0fe97038&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css_fghn5se6rw76wmnxy7pxwdglgi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe97038",
  null
  
)

export default component.exports