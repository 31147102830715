import { render, staticRenderFns } from "./_NameWithIcon.vue?vue&type=template&id=7b273040&scoped=true&"
import script from "./_NameWithIcon.vue?vue&type=script&lang=js&"
export * from "./_NameWithIcon.vue?vue&type=script&lang=js&"
import style0 from "./_NameWithIcon.vue?vue&type=style&index=0&id=7b273040&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css_fghn5se6rw76wmnxy7pxwdglgi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b273040",
  null
  
)

export default component.exports