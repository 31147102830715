import TimezoneButton from '@/components/organisms/timezoneButton/TimezoneButton.vue'
import { useTranslation } from '@/composables/useTranslation'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import TimezoneModule, { TimezoneKeyAndDisplayName } from '@/store/modules/timezones'
import { computed, ComputedRef } from '@vue/composition-api'
import Vue from 'vue'

type TimeAxisActions = {
  timezoneForDisplay: ComputedRef<TimezoneKeyAndDisplayName[]>
  timeAxisHeader: (timezoneButtonAsLabel: boolean) => HTMLElement
  timeAxisBody: (eventDate: Date) => HTMLElement
}
export const useTimeAxis = (): TimeAxisActions => {
  const i18n = useTranslation()
  const timezoneForDisplay = computed((): TimezoneKeyAndDisplayName[] => TimezoneModule.timezoneKeysForDisplay)
  function _getAxisDiv(): HTMLDivElement {
    const rootElement = document.createElement('div')
    rootElement.className = 'calendar-time-axis'
    return rootElement
  }
  function timeAxisHeader(timezoneButtonAsLabel: boolean): HTMLElement {
    const root = _getAxisDiv()
    timezoneForDisplay.value.forEach((t, index) => {
      const childElement = document.createElement('div')
      childElement.classList.add('calendar-time-axis__item')
      childElement.classList.add('calendar-time-axis__header')
      childElement.classList.add(`calendar-time-axis__header__i${index}`)
      childElement.classList.add('is-hidden-mobile')
      if (t.canEdit && !timezoneButtonAsLabel) {
        const ComponentClass = Vue.extend(TimezoneButton)
        const instance = new ComponentClass({ i18n })
        instance.$mount()
        childElement.append(instance.$el)
      } else {
        const labelElement = document.createElement('span')
        labelElement.innerText = t.displayName
        childElement.appendChild(labelElement)
      }
      root.appendChild(childElement)
    })
    return root
  }
  function timeAxisBody(eventDate: Date): HTMLElement {
    const rootElement = _getAxisDiv()
    timezoneForDisplay.value.forEach((t, index) => {
      const labelElement = document.createElement('span')
      labelElement.innerText = spirDateFormat(eventDate, spirDateFormatTypes.hourMin, {
        timeZone: t.key
      })
      labelElement.classList.add('calendar-time-axis__item')
      labelElement.classList.add('calendar-time-axis__body')
      labelElement.classList.add(`calendar-time-axis__body__i${index}`)
      if (index > 0) {
        labelElement.classList.add('is-hidden-mobile')
      }
      rootElement.appendChild(labelElement)
    })
    return rootElement
  }

  return {
    timezoneForDisplay,
    timeAxisHeader,
    timeAxisBody
  }
}
