import { addDays, startOfDay } from '@/lib/utils/timezone'
import { Duration } from '@/models'
import { getDefaultOnlineMeetingType } from '@/store/lib/utils'
import {
  TransparencyStatus,
  FullCalendarEvent,
  GoogleEvent,
  ICalendar,
  IOnlineMeeting,
  ITypeCalendarListForUI,
  VISIBILITY
} from '@/types/schedule'
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  isAfter,
  isBefore,
  isEqual,
  startOfHour,
  subMinutes
} from 'date-fns'
import { getDefaultSelectedCalendar } from './functions'

export const isSameEvent = (baseEvent: { start: Date; end: Date }, targetEvent: { start: Date; end: Date }) => {
  return isEqual(targetEvent.start, baseEvent.start) && isEqual(targetEvent.end, baseEvent.end)
}

/**
 *
 * @param baseEvent
 * @param targetEvent
 * targetEventが一部でもbaseEventとかぶっているかどうかをチェックする
 * @return {boolean}
 *  true: かぶっている
 *  false: かぶっていない
 */
export const checkIntersectionEvents = (
  leftEvent: { start: Date; end: Date },
  rightEvent: { start: Date; end: Date }
): boolean => {
  return (
    isSameEvent(leftEvent, rightEvent) ||
    (isAfter(rightEvent.end, leftEvent.start) && isBefore(rightEvent.start, leftEvent.end))
  )
}

const SPLIT_MIN = 30
export const applyDurationToSlots = <T extends { start: Date; end: Date }>(slots: T[], duration: Duration): T[] => {
  const splitMin = duration >= SPLIT_MIN ? SPLIT_MIN : duration
  return slots
    .map(slot => {
      const lastFittingStartTime = subMinutes(slot.end, duration)
      const amountOfSlotsStartTimes = Math.ceil(differenceInMinutes(lastFittingStartTime, slot.start) / splitMin) + 1
      return Array(amountOfSlotsStartTimes)
        .fill(null)
        .map((_, i) => {
          let startTime = addMinutes(slot.start, i * splitMin)
          let endTime = addMinutes(slot.start, i * splitMin + duration)
          if (isAfter(endTime, slot.end)) {
            startTime = subMinutes(slot.end, duration)
            endTime = slot.end
          }
          return {
            ...slot,
            start: startTime,
            end: endTime
          }
        })
    })
    .flat()
}

export const extractAndConstructCandidates = (
  candidates: FullCalendarEvent[],
  model: { duration: number }
): FullCalendarEvent[] => {
  const filteredCandidate = candidates.filter(
    event =>
      event.id !== 'confirmEvent' &&
      event.extendedProps?.source !== 'confirmer' &&
      event.extendedProps?.source !== 'confirmerUnderThirty' &&
      event.extendedProps?.underDuration !== true
  )
  return applyDurationToSlots(filteredCandidate, model.duration as Duration) // FIXME: use type guard
}

export const validateCandidate = (event: FullCalendarEvent): boolean => {
  if (!event.extendedProps || !event.extendedProps.source) {
    return true
  }
  switch (event.extendedProps.source) {
    case 'expired':
    case 'rejected':
    case 'declined':
      return false
  }
  return !event.extendedProps.underDuration
}

type CandidateEventForCheck = {
  id?: string
  start: Date
  end: Date
}
type CurrentEventForCheck = {
  id: string
  start: Date
  end: Date
}
export const hasIntersectedEvents = (candidate: CandidateEventForCheck, events: CurrentEventForCheck[]) => {
  return events.some(event => {
    return (
      (candidate.id ? event.id !== candidate.id : true) &&
      checkIntersectionEvents({ start: event.start, end: event.end }, { start: candidate.start, end: candidate.end })
    )
  })
}

export const getAllDayStartDate = (startDate: Date, allDayFlag: boolean, timeZone?: string): Date => {
  const startOfDayInUserTimezone = startOfDay(startDate, timeZone)
  if (allDayFlag) {
    return startOfDayInUserTimezone
  }
  return startDate
}

export const getAlldayEndDate = (endDate: Date, allDayFlag: boolean, timeZone?: string): Date => {
  const startOfDayInUserTimezone = startOfDay(endDate, timeZone)
  if (!allDayFlag) {
    return endDate
  }
  if (isEqual(startOfDayInUserTimezone, endDate)) {
    return endDate
  }
  return addDays(startOfDayInUserTimezone, 1)
}

export const updateEndDateConsiderAllDay = (
  oldAllDayFlag: boolean,
  newAllDayFlag: boolean,
  end: string,
  timeZone?: string
): string => {
  if (!oldAllDayFlag || oldAllDayFlag === newAllDayFlag) {
    return end
  }
  const endDate = new Date(end)
  const startOfDayEndDate = startOfDay(endDate, timeZone)
  if (!isEqual(startOfDayEndDate, endDate)) {
    return end
  }
  return subMinutes(startOfDayEndDate, 15).toISOString()
}

export function getEventDateWithAllDayConsideration(
  currentEvent: Pick<GoogleEvent, 'start' | 'allDay'>,
  payload: { start: Date; end: Date; allDay?: boolean }
): { start: Date; end: Date; allDay?: boolean } {
  let newStart = payload.start
  let newEnd = payload.end
  if (payload.allDay) {
    if (isEqual(newStart, newEnd) || isAfter(newStart, newEnd)) {
      // startが変わったかEndが変わったか判断し、変わっていない日付の方を修正する
      if (isEqual(newStart, new Date(currentEvent.start))) {
        newStart = addDays(startOfDay(newEnd), -1)
      } else {
        newEnd = addDays(startOfDay(newStart), 1)
      }
    }
  }
  return {
    start: newStart,
    end: newEnd,
    allDay: payload.allDay === undefined ? currentEvent.allDay : payload.allDay
  }
}

export function getEventIsoStringWithAllDayConsideration(
  currentEvent: Pick<GoogleEvent, 'start' | 'allDay'>,
  payload: { start: Date; end: Date; allDay?: boolean }
): { start: string; end: string; allDay?: boolean } {
  const { allDay, start, end } = getEventDateWithAllDayConsideration(currentEvent, payload)
  return {
    allDay,
    start: start.toISOString(),
    end: end.toISOString()
  }
}

export function getDefaultCalendarAndAccountIds(data: {
  tempEvent?: FullCalendarEvent
  defaultSelectedCalendarGetter: () => { accountId: string; calendarId: string }
}): {
  accountId: string
  calendarId: string
} {
  // payload に入ってるのが優先
  if (data.tempEvent && data.tempEvent.extendedProps?.calendarId) {
    return {
      // @ts-expect-error TS2322
      accountId: data.tempEvent.extendedProps.accountId,
      calendarId: data.tempEvent.extendedProps.calendarId
    }
  } else {
    const defaultCalendar = data.defaultSelectedCalendarGetter()
    return {
      accountId: defaultCalendar.accountId,
      calendarId: defaultCalendar.calendarId
    }
  }
}

export function getDefaultEventDate({
  tempEvent,
  tempStart = new Date()
}: {
  tempEvent?: FullCalendarEvent
  tempStart?: Date
}): { start: Date; end: Date } {
  const start = tempEvent?.start ? tempEvent.start : startOfHour(addHours(tempStart, 1))
  const end = tempEvent?.end ? tempEvent.end : addHours(start, 1)
  return { start, end }
}

type GetDefaultEventArgs = {
  writableCalendars: ITypeCalendarListForUI[]
  tempEvent?: FullCalendarEvent
  getSelectedEventValues: () => { calendarId: string; accountId: string; onlineMeeting?: IOnlineMeeting } | null
  getSelectedCalendar: (data: { accountId: string; calendarId: string }) => ICalendar
}

export function getDefaultEvent({
  writableCalendars,
  tempEvent,
  getSelectedEventValues,
  getSelectedCalendar
}: GetDefaultEventArgs): GoogleEvent {
  const selectedEventValues = getSelectedEventValues()
  const { accountId, calendarId } = getDefaultCalendarAndAccountIds({
    tempEvent,
    // @ts-expect-error TS2345
    defaultSelectedCalendarGetter: () => getDefaultSelectedCalendar(writableCalendars, selectedEventValues)
  })
  const selectedCalendar: ICalendar = getSelectedCalendar({ accountId, calendarId })
  const { start, end } = getDefaultEventDate({ tempEvent })
  return {
    accountId,
    calendarId,
    allDay: tempEvent?.allDay || false,
    start: start.toISOString(),
    end: end.toISOString(),
    // @ts-expect-error TS2322
    id: null,
    summary: null,
    location: '',
    description: '',
    attendees: [],
    resources: [],
    // @ts-expect-error TS2322
    attendeeFlag: null,
    writable: true,
    onlineMeeting: getDefaultOnlineMeetingType(selectedCalendar, selectedEventValues?.onlineMeeting),
    visibility: VISIBILITY.DEFAULT,
    transparencyStatus: TransparencyStatus.busy
  }
}

export function encodeEventKey({ parentId, childId }: { parentId: string; childId: string }): string {
  return [parentId, childId].join('$$$')
}
