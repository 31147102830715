import { jsx as e, jsxs as n, Fragment as W } from "react/jsx-runtime";
import sn, { useContext as on, useState as V, useEffect as ne, forwardRef as Mt, useId as lt, useRef as st, useMemo as A, Suspense as Ke, useCallback as ee, Fragment as Ft } from "react";
import { initI18nReact as Kn } from "typesafe-i18n/react";
import "typesafe-i18n";
import "typesafe-i18n/detectors";
import { initExtendDictionary as Jn } from "typesafe-i18n/utils";
import Wn from "indefinite";
import { lowercase as Qn, date as ha, identity as za } from "typesafe-i18n/formatters";
import "@spirinc/spir-ui/style.css";
import { root as Xn } from "@spirinc/spir-ui/styles";
import { QueryClient as Zn, QueryClientProvider as er, useQueryClient as Je, useSuspenseQuery as Ie, useMutation as fe, useQuery as Pt, useInfiniteQuery as tr } from "@tanstack/react-query";
import { Icon as x, Fab as ar, SpirLink as cn, Button as p, Loading as X, Snackbar as v, Dialog as g, Heading as ot, LinkButton as ut, List as $e, DropdownMenu as N, Select as ie, PhoneNumber as Ye, TextField as j, Sheet as De, Checkbox as Yt, Tooltip as _, MultiCombobox as ke, Combobox as B, Switch as va, IconButton as Ne, Textarea as at } from "@spirinc/spir-ui/components";
import * as Pe from "@radix-ui/react-avatar";
import { createRuntimeFn as we } from "@vanilla-extract/recipes/createRuntimeFn";
import { DatePicker as nr, Label as rr, Group as ir, DatePickerStateContext as lr, DateInput as sr, DateSegment as or, Button as ca, Popover as cr, Dialog as dr, Calendar as mr, Heading as ur, CalendarGrid as pr, CalendarGridHeader as hr, CalendarHeaderCell as zr, CalendarGridBody as vr, CalendarCell as yr } from "react-aria-components";
import ct from "dayjs";
import br from "dayjs/plugin/isSameOrAfter";
import gr from "dayjs/plugin/isSameOrBefore";
import fr from "dayjs/plugin/timezone";
import Tr from "dayjs/plugin/utc";
import "dayjs/locale/ja";
import "dayjs/locale/en";
import { mediaQuery as le } from "@spirinc/spir-ui/constants";
import qe, { clsx as ye } from "clsx";
import { DateTime as ya } from "luxon";
import { useMediaQuery as ce } from "react-responsive";
import { ErrorBoundary as dt } from "react-error-boundary";
import { createEditor as Cr, Transforms as it, Editor as kr } from "slate";
import { withReact as Lr, ReactEditor as dn, Slate as Nr, Editable as xr, useSelected as Dr } from "slate-react";
import { zodResolver as Ir } from "@hookform/resolvers/zod";
import { useForm as da, Controller as Fr, useController as Ve, useFormContext as Mr, FormProvider as Pr } from "react-hook-form";
import * as Be from "zod";
import { parseDate as mn, parseZonedDateTime as _e } from "@internationalized/date";
import wr from "@tiptap/extension-link";
import Sr from "@tiptap/extension-placeholder";
import { useEditor as Er, EditorContent as _r } from "@tiptap/react";
import { StarterKit as Rr } from "@tiptap/starter-kit";
import { Node as un } from "@tiptap/core";
import { useVirtualizer as qr } from "@tanstack/react-virtual";
import * as Ge from "@radix-ui/react-tabs";
const ma = {}, pn = {};
Jn();
const { component: Br, context: Ar } = Kn(ma, pn), S = () => on(Ar), Or = {
  en: {
    lowercase: Qn,
    withIndefiniteArticle: Wn,
    dateLong: ha("en", { year: "numeric", month: "short", day: "numeric" })
  },
  ja: {
    lowercase: za,
    withIndefiniteArticle: za,
    dateLong: ha("ja", { year: "numeric", month: "numeric", day: "numeric" })
  }
}, $r = (t) => Or[t], Vr = {
  en: () => import("./index-706e8b9c.mjs"),
  ja: () => import("./index-42254e48.mjs")
}, Hr = (t, a) => ma[t] = { ...ma[t], ...a }, jr = async (t) => (await Vr[t]()).default, hn = async (t) => {
  Hr(t, await jr(t)), Ur(t);
}, Ur = (t) => void (pn[t] = $r(t)), Gr = ({ locale: t, children: a }) => {
  const [r, i] = V({
    en: "notStarted",
    ja: "notStarted"
  });
  return ne(() => {
    hn(t).then(() => i((c) => ({ ...c, [t]: "loaded" })));
  }, [t]), r[t] === "loaded" ? /* @__PURE__ */ e(Br, { locale: t, children: a }, t) : null;
}, Yr = new Zn(), bu = ({ children: t, ...a }) => /* @__PURE__ */ e(er, { client: Yr, children: /* @__PURE__ */ e(Gr, { locale: a.locale, children: /* @__PURE__ */ e("div", { className: Xn, children: t }) }) });
var Kr = "t23tvn1", Jr = "t23tvn5 szap9z6m szap9z12 szap9z20", Wr = "t23tvn3 szap9z2i szap9z2h szap9z34 szap9z33 szap9z3q szap9z3p szap9z4c szap9z4b", Qr = "t23tvn2 szap9z3q szap9z3p szap9z4c szap9z4b szap9z12 szap9z20", Xr = "t23tvn0 qvn8ok9 szap9z12 szap9z1e", Zr = "szap9za szap9zq";
const wt = ({ children: t }) => /* @__PURE__ */ e("div", { className: Xr, children: t }), St = ({ children: t }) => /* @__PURE__ */ e("div", { className: Qr, children: t }), ei = () => /* @__PURE__ */ e(
  "svg",
  {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    children: /* @__PURE__ */ e(
      "path",
      {
        d: "M8.41875 13.5684L14.0188 19.1684L12.5938 20.5684L4.59375 12.5684L12.5938 4.56836L14.0188 5.96836L8.41875 11.5684H20.5938V13.5684H8.41875Z",
        fill: "currentColor"
      }
    )
  }
), Et = ({ onClick: t }) => /* @__PURE__ */ e("button", { type: "button", className: Jr, onClick: t, children: /* @__PURE__ */ e(ei, {}) }), ti = Mt(
  function(a, r) {
    return /* @__PURE__ */ e("div", { ...a, ref: r, className: Kr });
  }
), _t = ({ children: t }) => /* @__PURE__ */ e("h1", { className: Zr, children: t }), Rt = ({ children: t }) => /* @__PURE__ */ e("div", { className: Wr, children: t });
var ai = "_1af23s38 szap9z12 szap9z20 szap9z1o qvn8ok9", ni = "_1af23s37", ri = we({ defaultClassName: "_1af23s30 szap9z18 qvn8oki szap9z2 szap9zq", variantClassNames: { size: { 24: "_1af23s31 szap9zi", 32: "_1af23s32 szap9zg" }, shape: { circle: "szap9z6", square: "szap9z2" }, border: { none: "_1af23s35", default: "_1af23s36" } }, defaultVariants: { size: 32, shape: "circle", border: "none" }, compoundVariants: [] });
const zn = Mt(function({
  src: a,
  fallbackText: r,
  fallbackTextColor: i,
  fallbackBackgroundColor: c,
  size: s = 32,
  shape: o = "circle",
  border: l = "none",
  ...h
}, d) {
  return /* @__PURE__ */ n(
    Pe.Root,
    {
      ref: d,
      ...h,
      className: ri({ size: s, shape: o, border: l }),
      children: [
        /* @__PURE__ */ e(Pe.Image, { className: ni, src: a }),
        /* @__PURE__ */ e(
          Pe.Fallback,
          {
            ...a === void 0 ? {} : { delayMs: 1e3 },
            className: ai,
            style: { color: i, backgroundColor: c },
            children: r
          }
        )
      ]
    }
  );
});
ct.extend(gr);
ct.extend(br);
ct.extend(Tr);
ct.extend(fr);
const J = ct;
var ii = "szap9z2g szap9z32 szap9z3o szap9z4a qvn8ok9 szap9z4 szap9zw", li = "orr5r4j szap9z3i szap9z44 szap9z16 szap9z2 qvn8ok2", si = "orr5r4c", oi = "orr5r42 szap9z3m szap9z48 qvn8ok9 szap9z2 szap9z12 szap9z20 qvn8oki", ci = "szap9z12 szap9z20 szap9z1u szap9z78", di = "szap9z28 szap9z2u", mi = "orr5r4h szap9zk szap9zq qvn8ok0 szap9z16", ui = "orr5r4f qvn8ok2 szap9zg szap9zq", pi = "szap9z12 szap9z20", hi = "orr5r43 szap9z12", zi = "orr5r40 szap9z18 szap9zm szap9z5e", vi = we({ defaultClassName: "orr5r48", variantClassNames: { width: { triggerWidth: "orr5r49", default: "orr5r4a" } }, defaultVariants: { width: "default" }, compoundVariants: [] }), ba = "orr5r4e qvn8ok5 szap9z12 szap9z20 szap9z1o", yi = "orr5r41", bi = "orr5r46 szap9zk szap9z2", gi = "orr5r4i szap9z2a szap9z2w szap9z16", fi = "orr5r47 szap9z12 szap9z20 szap9z1o qvn8ok0 szap9z2", Ti = "szap9zk";
const vn = (t) => /* @__PURE__ */ e(nr, { ...t, className: yi }), yn = (t) => /* @__PURE__ */ e(rr, { ...t, className: zi }), bn = (t) => /* @__PURE__ */ e(ir, { ...t, className: oi }), gn = (t) => {
  const a = on(lr), r = J(`${a.value?.year}-${a.value?.month}-${a.value?.day}`), { locale: i } = S();
  return /* @__PURE__ */ n("div", { className: hi, children: [
    /* @__PURE__ */ e(sr, { ...t, className: pi }),
    r.isValid() ? /* @__PURE__ */ n("div", { className: Ti, children: [
      "(",
      r.locale(i).format("ddd"),
      ")"
    ] }) : null
  ] });
}, fn = ({ segment: t, ...a }) => {
  const i = {
    ...t,
    text: ["month", "day"].includes(t.type) && Number.isInteger(Number(t.text)) ? t.text.padStart(2, "0") : t.text
  };
  return /* @__PURE__ */ e(or, { ...a, segment: i, className: bi });
}, Tn = (t) => /* @__PURE__ */ e(ca, { ...t, className: fi, children: /* @__PURE__ */ e(x.Event, { size: 16 }) }), Cn = ({ width: t = "default" }) => /* @__PURE__ */ e(cr, { className: vi({ width: t }), children: /* @__PURE__ */ e(dr, { children: /* @__PURE__ */ n(mr, { className: ii, children: [
  /* @__PURE__ */ n("header", { className: ci, children: [
    /* @__PURE__ */ e(ca, { slot: "previous", className: ba, children: /* @__PURE__ */ e(x.ArrowLeft, { size: 16 }) }),
    /* @__PURE__ */ e(ur, { className: ui }),
    /* @__PURE__ */ e(ca, { slot: "next", className: ba, children: /* @__PURE__ */ e(x.ArrowRight, { size: 16 }) })
  ] }),
  /* @__PURE__ */ n(pr, { className: si, children: [
    /* @__PURE__ */ e(hr, { children: (a) => /* @__PURE__ */ e(zr, { className: di, children: /* @__PURE__ */ e("div", { className: mi, children: a }) }) }),
    /* @__PURE__ */ e(vr, { children: (a) => /* @__PURE__ */ e(yr, { date: a, className: gi, children: ({ formattedDate: r }) => /* @__PURE__ */ e("div", { className: li, children: r }) }) })
  ] })
] }) }) }), Ci = "pages-fab", kn = (t) => /* @__PURE__ */ e(ar, { ...t, id: Ci, offsetBottom: "64px" }), qt = Mt(function({ onClick: a, ...r }, i) {
  return /* @__PURE__ */ e(cn, { asChild: !0, children: /* @__PURE__ */ e("a", { ref: i, onClick: (s) => {
    s.preventDefault(), a?.(s);
  }, ...r }) });
});
var ki = "_1wq7msi0";
const Li = (t) => {
  let a = t;
  for (; a = a.parentElement; ) {
    const r = getComputedStyle(a, null).getPropertyValue("overflow-y");
    if (a === document.body)
      return document.body;
    if (r === "auto" || r === "scroll" || r === "overlay")
      return a;
  }
  return document.body;
}, Ln = (t) => {
  const a = lt(), r = st(null);
  return ne(() => {
    if (r.current === null)
      return;
    const i = Li(r.current), c = i.getBoundingClientRect(), s = r.current, o = s.getBoundingClientRect(), l = document.createElement("div");
    l.id = `anchor-${a}`, l.classList.add("intersection-anchor"), l.style.position = "absolute";
    const h = o.top - c.top - 8;
    l.style.top = `${h}px`, l.style.left = "0", l.style.right = "0", l.style.height = "2px", i.appendChild(l);
    const d = l, m = new IntersectionObserver(
      (D) => {
        const u = D[0];
        if (u === void 0)
          return;
        const z = u.boundingClientRect, k = u.rootBounds;
        k !== null && (z.top < k.top && s.classList.add("sticky"), z.bottom >= k.top && s.classList.remove("sticky"));
      },
      { threshold: [0], root: i }
    );
    return m.observe(l), () => {
      m.disconnect(), d.remove();
    };
  }, [a]), /* @__PURE__ */ e("div", { ref: r, ...t, className: ki });
};
var Ni = "axzibg9", pt = "axzibg6 szap9z2e szap9z30 szap9z3m szap9z48 szap9z16 szap9z4", ht = "qvn8ok1", zt = "szap9zi qvn8ok2", xi = "szap9z16 szap9z4w", Di = "axzibgg szap9z4s szap9zi", Ii = "axzibgf szap9zi", Ae = "szap9z5k szap9z5j", Kt = "szap9zg", ga = "axzibg5 szap9z2c szap9zi szap9z12 szap9z20 szap9z1c szap9z76", Fi = "szap9zi qvn8ok0";
const Mi = ({ teamPlan: t, gotoTeamPlanPaymentInfoPage: a }) => {
  const { LL: r } = S(), c = ce({ query: le.screen.mobile }) ? { width: "full", size: "medium" } : { width: "fitContent", size: "small" }, s = A(() => {
    if (t.type === "free")
      return null;
    if (t.state.status === "trialing" || t.state.status === "active") {
      const o = t.state.subscription.endDate;
      return r.availablePlanList.nextBillingDateMessage({ billingDate: o });
    } else
      return null;
  }, [t, r.availablePlanList]);
  if (t.type === "free")
    return null;
  switch (t.state.status) {
    case "trialing":
    case "active":
      return /* @__PURE__ */ e("div", { className: Ae, children: /* @__PURE__ */ e("p", { className: Fi, children: s }) });
    case "trialWithoutSetup": {
      const o = t.state.subscription.endDate, l = ya.fromJSDate(o).minus({ day: 1 }).toJSDate();
      return /* @__PURE__ */ n("div", { className: ye(Ae, Kt), children: [
        /* @__PURE__ */ e(
          "p",
          {
            dangerouslySetInnerHTML: {
              __html: r.availablePlanList.planDescription.trialWithoutSetupNotice({ dueDate: l })
            }
          }
        ),
        /* @__PURE__ */ n("p", { className: ga, children: [
          /* @__PURE__ */ e(x.Check, { size: 16 }),
          r.availablePlanList.planDescription.trialWithoutSetupMigrateToFree()
        ] })
      ] });
    }
    case "pastDue":
      return /* @__PURE__ */ n("div", { className: ye(Ae, pt, ht), children: [
        /* @__PURE__ */ e(x.Info, {}),
        /* @__PURE__ */ e("p", { className: zt, children: r.availablePlanList.planDescription.pastDueNotice() }),
        /* @__PURE__ */ e("div", { className: Ni, children: /* @__PURE__ */ e(
          p.Button,
          {
            size: "small",
            buttonType: "secondary",
            ...c,
            onClick: a,
            children: /* @__PURE__ */ e(p.Text, { children: r.availablePlanList.cancelNoteLink() })
          }
        ) })
      ] });
    case "canceled":
      switch (t.state.reason) {
        case "trialExpired": {
          const o = t.state.subscription.endDate, l = ya.fromJSDate(o).minus({ day: 1 }).toJSDate();
          return /* @__PURE__ */ n("div", { className: Ae, children: [
            /* @__PURE__ */ e(
              "p",
              {
                className: Kt,
                dangerouslySetInnerHTML: {
                  __html: r.availablePlanList.planDescription.canceledTrialExpiredNotice({ dueDate: l })
                }
              }
            ),
            /* @__PURE__ */ e(
              "p",
              {
                className: Kt,
                children: r.availablePlanList.planDescription.canceledTrialExpiredPlanSelectPromotion()
              }
            ),
            /* @__PURE__ */ n("p", { className: ga, children: [
              /* @__PURE__ */ e(x.Check, { size: 16 }),
              r.availablePlanList.planDescription.canceledTrialExpiredMigrateToFree()
            ] })
          ] });
        }
        case "paymentFailed":
          return /* @__PURE__ */ n("div", { className: ye(Ae, pt, ht), children: [
            /* @__PURE__ */ e(x.Info, {}),
            /* @__PURE__ */ e("p", { className: zt, children: r.availablePlanList.planDescription.canceledPaymentFailedNotice() })
          ] });
        default:
          return /* @__PURE__ */ n("div", { className: ye(Ae, pt, ht), children: [
            /* @__PURE__ */ e(x.Info, {}),
            /* @__PURE__ */ e("p", { className: zt, children: r.availablePlanList.planDescription.canceledNotice() })
          ] });
      }
    case "canceling":
      return /* @__PURE__ */ n("div", { className: ye(Ae, pt, ht), children: [
        /* @__PURE__ */ e(x.Info, {}),
        /* @__PURE__ */ e("p", { className: zt, children: r.availablePlanList.planDescription.cancelingNotice({
          endDate: t.state.subscription.endDate
        }) })
      ] });
    default:
      return null;
  }
};
var Pi = "ai911i6", wi = "ai911ic", Si = "ai911ib", Bt = "ai911i9", Nn = "ai911id szap9zi szap9zl", Ei = "ai911ih szap9zc szap9zq", _i = "ai911ig szap9zi szap9zq", Ri = "ai911ie szap9zk szap9zq szap9z10", qi = "ai911il szap9zm szap9zl qvn8ok0", Bi = "ai911ik szap9z14 szap9z1q", Ai = "ai911i8 szap9z14 szap9z1q", pa = "ai911i3 ai911i1", xn = "ai911i2 ai911i1", Oi = "ai911i0 szap9z16", He = "ai911i7 szap9z14 szap9zg szap9z20", $i = "ai911i4 szap9zk szap9zq", Dn = "ai911i5", In = "ai911ia szap9zc szap9zq szap9z46", Vi = "ai911if szap9z14 szap9zg szap9z20 szap9z78 szap9z4a", Hi = "ai911ii qvn8ok0 szap9zm", ji = "ai911ij qvn8ok0 szap9zk szap9zn";
const Ui = ({ children: t }) => /* @__PURE__ */ e("div", { className: Oi, role: "table", "aria-label": "Plan list", children: t }), Gi = ({ children: t }) => /* @__PURE__ */ e("div", { role: "rowgroup", className: xn, children: /* @__PURE__ */ e("div", { role: "row", className: pa, children: t }) }), nt = ({ children: t }) => /* @__PURE__ */ e("div", { className: $i, role: "columnheader", children: t }), Yi = ({ children: t }) => /* @__PURE__ */ e("div", { role: "rowgroup", "aria-label": "plan-list-body", className: xn, children: t }), fa = ({ children: t }) => /* @__PURE__ */ e(
  "div",
  {
    role: "row",
    className: ye(pa, Dn),
    children: t
  }
), Ta = ({ name: t }) => /* @__PURE__ */ e("div", { role: "cell", className: He, children: /* @__PURE__ */ e("span", { className: ye(Bt, In), children: t }) }), Ca = ({ children: t }) => /* @__PURE__ */ e("div", { role: "cell", className: ye(He, Vi), children: t }), vt = ({ children: t }) => /* @__PURE__ */ e("span", { className: Bt, children: t }), yt = ({ unit: t }) => /* @__PURE__ */ e("span", { className: _i, children: t }), ka = ({ value: t }) => /* @__PURE__ */ e("span", { className: Ei, children: t }), La = ({ taxIncluded: t }) => /* @__PURE__ */ e("span", { className: Hi, children: t }), Na = ({ value: t }) => /* @__PURE__ */ e("span", { className: ji, children: t }), bt = ({ children: t }) => /* @__PURE__ */ e("div", { role: "cell", className: He, children: t }), gt = ({ title: t }) => /* @__PURE__ */ e("span", { className: Ri, children: t }), ft = ({ value: t }) => /* @__PURE__ */ e("span", { className: ye(Bt, Nn), children: t }), Jt = ({ children: t }) => /* @__PURE__ */ e("div", { role: "cell", className: ye(He, Ai), children: t }), Ki = ({ children: t }) => /* @__PURE__ */ e(
  "div",
  {
    role: "row",
    className: ye(pa, Dn, Pi),
    children: t
  }
), Ji = ({ name: t }) => /* @__PURE__ */ e("div", { role: "cell", className: ye(He, Si), children: /* @__PURE__ */ e("span", { className: ye(Bt, In), children: t }) }), Wi = ({ children: t }) => /* @__PURE__ */ e("div", { role: "cell", className: ye(He, wi, Nn), children: t }), Wt = ({ children: t, ...a }) => {
  const i = ce({ query: le.screen.mobile }) ? { width: "full", size: "medium" } : { width: "fitContent", size: "small" };
  return /* @__PURE__ */ e(p.Button, { ...a, ...i, children: t });
}, Qi = ({ children: t }) => /* @__PURE__ */ e(
  "div",
  {
    role: "cell",
    "aria-describedby": "auto-migration-after-trial",
    className: ye(He, Bi),
    children: t
  }
), Xi = ({ value: t }) => /* @__PURE__ */ e("span", { id: "auto-migration-after-trial", className: qi, children: t });
var Zi = "szap9z14 szap9z20 szap9z7e szap9z79", xa = "szap9zi", Da = "szap9zi", Ia = "szap9zq", el = "_35wql82 szap9z3q szap9z3h szap9z4c szap9z43 szap9z28 szap9z2f szap9z2u szap9z31 qvn8oki szap9z12 szap9z1c szap9z1f szap9z1u szap9z1n szap9z20 szap9z78", tl = "_35wql80 szap9z16 szap9z5k szap9z5j", al = "_35wql81 szap9z16 qvn8oka szap9zi szap9zq";
const nl = ({ teamPlan: t, usage: a }) => {
  const { LL: r } = S();
  if (t.type === "free")
    return null;
  switch (t.state.status) {
    case "trialing":
    case "pastDue":
    case "active":
      return null;
    case "trialWithoutSetup":
    case "canceled":
    case "canceling":
      return /* @__PURE__ */ n("div", { className: tl, role: "region", "aria-label": "usage-table", children: [
        /* @__PURE__ */ e("div", { className: al, children: r.availablePlanList.currentUsage() }),
        /* @__PURE__ */ e("div", { className: el, children: /* @__PURE__ */ n("div", { className: Zi, children: [
          /* @__PURE__ */ n("span", { children: [
            /* @__PURE__ */ e("span", { className: xa, children: r.availablePlanList.usage.memberCountCapability() }),
            /* @__PURE__ */ n("span", { className: Da, children: [
              /* @__PURE__ */ e("span", { className: Ia, children: a.memberCount }),
              r.availablePlanList.usage.memberCountCapabilitySuffix()
            ] })
          ] }),
          /* @__PURE__ */ n("span", { children: [
            /* @__PURE__ */ e("span", { className: xa, children: r.availablePlanList.usage.availabilitySharingCountCapability() }),
            /* @__PURE__ */ n("span", { className: Da, children: [
              /* @__PURE__ */ e("span", { className: Ia, children: a.availabilitySharingCount }),
              r.availablePlanList.usage.availabilitySharingCountCapabilitySuffix()
            ] })
          ] })
        ] }) })
      ] });
    default:
      return null;
  }
};
let Tt = class extends Error {
  constructor(a) {
    super(), this.code = a;
  }
};
const rl = (t) => {
  const { LL: a, locale: r } = S(), i = v.useSnackbar(), [c, s] = V({ type: "none" }), o = Je(), l = ["availablePlans", t.teamId], { data: h } = Ie({
    queryFn: async () => t.fetchTeamAvailablePlans({ teamId: t.teamId }),
    queryKey: l
  }), d = ["teamPlan", t.teamId], { data: m } = Ie({
    queryFn: async () => t.fetchTeamPlan({ teamId: t.teamId }),
    queryKey: d
  }), D = ["availabilitySharingCount", t.teamId], { data: u } = Ie({
    queryFn: async () => t.fetchTeamAvailabilitySharingCount({ teamId: t.teamId }),
    queryKey: D
  }), z = fe({
    mutationFn: async (f) => {
      const P = await t.updateTeamSubscription({
        teamId: t.teamId,
        subscriptionId: f.subscriptionId,
        planId: f.planId,
        priceId: f.priceId
      });
      if (P.type === "error")
        throw new Tt(P.code);
    },
    onSuccess: async () => {
      await o.invalidateQueries({ queryKey: d }), i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: a.availablePlanList.snackbarChangePlanSuccess() }) })
      );
    },
    onError: (f) => {
      f instanceof Tt ? s({ type: "paidPlanCapabilityExceeded" }) : i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: a.availablePlanList.snackbarError() }) })
      );
    }
  }), k = fe({
    mutationFn: async (f) => {
      const P = await t.restartFreePlan({ teamId: t.teamId, planId: f.freePlanId });
      if (P.type === "error")
        throw new Tt(P.code);
    },
    onSuccess: async () => {
      await o.invalidateQueries({ queryKey: d }), i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: a.availablePlanList.snackbarChangePlanSuccess() }) })
      );
    },
    onError: (f) => {
      f instanceof Tt ? s({ type: "freePlanCapabilityExceeded" }) : i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: a.availablePlanList.snackbarError() }) })
      );
    }
  }), L = (f) => r === "en" ? {
    value: f.amount.toLocaleString(r),
    valueIncludingTax: void 0
  } : {
    value: f.amountExcludingTax.toLocaleString(r),
    valueIncludingTax: f.amount.toLocaleString(r)
  }, R = ({ info: f, price: P, capabilities: F, id: O }) => {
    const Q = L(P), te = (() => {
      switch (m.type) {
        case "free":
          return {
            disabled: !0,
            text: a.availablePlanList.buttonCurrentPlan(),
            type: "button"
          };
        case "paid":
          return m.state.status === "canceled" ? {
            disabled: !1,
            text: a.availablePlanList.buttonOrder(),
            onClick: () => s({
              type: "confirmFreePlanApply",
              onConfirm: () => k.mutate({ freePlanId: O })
            }),
            type: "button"
          } : m.state.status === "trialWithoutSetup" ? {
            text: a.availablePlanList.cannotSelectFree(),
            type: "notice"
          } : {
            disabled: !0,
            text: a.availablePlanList.buttonUnavailable(),
            type: "button"
          };
      }
    })();
    return /* @__PURE__ */ n(fa, { children: [
      /* @__PURE__ */ e(Ta, { name: f.name }),
      /* @__PURE__ */ n(Ca, { children: [
        /* @__PURE__ */ n(vt, { children: [
          /* @__PURE__ */ e(yt, { unit: a.availablePlanList.amountPrefix() }),
          /* @__PURE__ */ e(ka, { value: Q.value }),
          /* @__PURE__ */ e(yt, { unit: a.availablePlanList.amountSuffix() })
        ] }),
        /* @__PURE__ */ n(vt, { children: [
          /* @__PURE__ */ e(La, { taxIncluded: a.availablePlanList.taxIncluded() }),
          Q.valueIncludingTax !== void 0 ? /* @__PURE__ */ e(Na, { value: Q.valueIncludingTax }) : null
        ] })
      ] }),
      /* @__PURE__ */ n(bt, { children: [
        /* @__PURE__ */ e(gt, { title: a.availablePlanList.memberCountColumnForMobile() }),
        /* @__PURE__ */ e(
          ft,
          {
            value: `${F.teamMemberCount.value}${a.availablePlanList.maxMemberCountLimitedCapability()}`
          }
        )
      ] }),
      /* @__PURE__ */ n(bt, { children: [
        /* @__PURE__ */ e(gt, { title: a.availablePlanList.availabilitySharingCountColumnForMobile() }),
        /* @__PURE__ */ e(
          ft,
          {
            value: F.teamAvailabilitySharingCount.maxCount.type === "limited" ? `${F.teamAvailabilitySharingCount.maxCount.value}${a.availablePlanList.availabilitySharingCountLimitedCapability()}` : a.availablePlanList.availabilitySharingCountUnlimitedCapability()
          }
        )
      ] }),
      te.type === "button" ? /* @__PURE__ */ e(Jt, { children: /* @__PURE__ */ e(Wt, { disabled: te.disabled, onClick: te.onClick, children: /* @__PURE__ */ e(p.Text, { children: te.text }) }) }) : /* @__PURE__ */ e(Qi, { children: /* @__PURE__ */ e(Xi, { value: te.text }) })
    ] });
  }, M = ({ id: f, info: P, price: F, capabilities: O }) => {
    const Q = L(F), $ = async () => {
      (await t.gotoOrderPage({ teamId: t.teamId, planId: f, priceId: F.id })).type === "error" && s({ type: "paidPlanCapabilityExceeded" });
    }, w = (() => {
      switch (m.type) {
        case "free":
          return {
            disabled: !1,
            text: a.availablePlanList.buttonOrder(),
            onClick: () => void $()
          };
        case "paid":
          return m.state.status === "trialWithoutSetup" || m.state.status === "canceled" ? {
            disabled: !1,
            text: a.availablePlanList.buttonOrder(),
            onClick: () => void $()
          } : m.state.subscription.planId === f && m.state.subscription.priceId === F.id ? {
            disabled: !0,
            text: a.availablePlanList.buttonCurrentPlan()
          } : {
            disabled: m.state.status === "canceling" || m.state.status === "pastDue",
            text: a.availablePlanList.buttonOrder(),
            onClick: () => s({
              type: "confirmPaidPlanChange",
              onConfirm: () => z.mutate({
                subscriptionId: m.state.subscription.id,
                planId: f,
                priceId: F.id
              })
            })
          };
      }
    })();
    return /* @__PURE__ */ n(fa, { children: [
      /* @__PURE__ */ e(Ta, { name: P.name }),
      /* @__PURE__ */ n(Ca, { children: [
        /* @__PURE__ */ n(vt, { children: [
          /* @__PURE__ */ e(yt, { unit: a.availablePlanList.amountPrefix() }),
          /* @__PURE__ */ e(ka, { value: Q.value }),
          /* @__PURE__ */ e(yt, { unit: a.availablePlanList.amountSuffix() })
        ] }),
        /* @__PURE__ */ n(vt, { children: [
          /* @__PURE__ */ e(La, { taxIncluded: a.availablePlanList.taxIncluded() }),
          Q.valueIncludingTax !== void 0 ? /* @__PURE__ */ e(Na, { value: Q.valueIncludingTax }) : null
        ] })
      ] }),
      /* @__PURE__ */ n(bt, { children: [
        /* @__PURE__ */ e(gt, { title: a.availablePlanList.memberCountColumnForMobile() }),
        /* @__PURE__ */ e(
          ft,
          {
            value: `${O.teamMemberCount.value}${a.availablePlanList.maxMemberCountLimitedCapability()}`
          }
        )
      ] }),
      /* @__PURE__ */ n(bt, { children: [
        /* @__PURE__ */ e(gt, { title: a.availablePlanList.availabilitySharingCountColumnForMobile() }),
        /* @__PURE__ */ e(
          ft,
          {
            value: O.teamAvailabilitySharingCount.maxCount.type === "limited" ? `${O.teamAvailabilitySharingCount.maxCount.value}${a.availablePlanList.availabilitySharingCountLimitedCapability()}` : a.availablePlanList.availabilitySharingCountUnlimitedCapability()
          }
        )
      ] }),
      /* @__PURE__ */ e(Jt, { children: /* @__PURE__ */ e(Wt, { disabled: w.disabled, onClick: w.onClick, children: /* @__PURE__ */ e(p.Text, { children: w.text }) }) })
    ] });
  }, H = ({ info: f, capabilities: P }) => {
    const F = m.type === "paid" && (m.state.status === "canceling" || m.state.status === "pastDue");
    return /* @__PURE__ */ n(Ki, { children: [
      /* @__PURE__ */ e(Ji, { name: f.name }),
      /* @__PURE__ */ n(Wi, { children: [
        P.teamMemberCount.value,
        a.availablePlanList.minMemberCount()
      ] }),
      /* @__PURE__ */ e(Jt, { children: /* @__PURE__ */ e(
        Wt,
        {
          buttonType: "secondary",
          disabled: F,
          onClick: () => t.gotoEnterprisePlanAskingPage({ link: f.link, teamId: t.teamId }),
          children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.buttonContact() })
        }
      ) })
    ] });
  };
  return /* @__PURE__ */ n(wt, { children: [
    /* @__PURE__ */ n(St, { children: [
      /* @__PURE__ */ e(Et, { onClick: t.backToMenu }),
      /* @__PURE__ */ e(_t, { children: a.availablePlanList.title() })
    ] }),
    /* @__PURE__ */ n(Rt, { children: [
      /* @__PURE__ */ e(
        Mi,
        {
          teamPlan: m,
          gotoTeamPlanPaymentInfoPage: () => void t.gotoTeamPlanPaymentInfoPage({ teamId: t.teamId })
        }
      ),
      /* @__PURE__ */ e(
        nl,
        {
          teamPlan: m,
          usage: { memberCount: t.teamMemberCount, availabilitySharingCount: u.count }
        }
      ),
      /* @__PURE__ */ n(Ui, { children: [
        /* @__PURE__ */ n(Gi, { children: [
          /* @__PURE__ */ e(nt, { children: a.availablePlanList.planColumn() }),
          /* @__PURE__ */ e(nt, { children: a.availablePlanList.priceColumn() }),
          /* @__PURE__ */ e(nt, { children: a.availablePlanList.memberCountColumn() }),
          /* @__PURE__ */ e(nt, { children: a.availablePlanList.availabilitySharingCountColumn() }),
          /* @__PURE__ */ e(nt, {}),
          " "
        ] }),
        /* @__PURE__ */ e(Yi, { children: h.map((f) => {
          switch (f.type) {
            case "free":
              return /* @__PURE__ */ e(R, { ...f }, `${f.id}-${f.price.id}`);
            case "paid":
              return /* @__PURE__ */ e(M, { ...f }, `${f.id}-${f.price.id}`);
            case "asking":
              return /* @__PURE__ */ e(H, { ...f }, `${f.id}`);
          }
        }) })
      ] }),
      /* @__PURE__ */ n("div", { className: xi, children: [
        /* @__PURE__ */ e("p", { className: Ii, children: a.availablePlanList.cancelNoteMessage() }),
        /* @__PURE__ */ e("div", { className: Di, children: /* @__PURE__ */ e(
          qt,
          {
            href: t.teamPlanPaymentInfoPageLinkHref,
            onClick: () => void t.gotoTeamPlanPaymentInfoPage({ teamId: t.teamId }),
            children: a.availablePlanList.cancelNoteLink()
          }
        ) })
      ] })
    ] }),
    z.status === "pending" || k.status === "pending" ? /* @__PURE__ */ e(X.Spinner, { active: !0 }) : null,
    /* @__PURE__ */ e(g.Root, { open: c.type !== "none", onOpenChange: (f) => s(f ? c : { type: "none" }), children: c.type === "confirmFreePlanApply" ? /* @__PURE__ */ n(g.Content, { children: [
      /* @__PURE__ */ n(g.Header, { children: [
        /* @__PURE__ */ e(g.Title, { children: a.availablePlanList.confirmationDialogTitle() }),
        /* @__PURE__ */ e(g.DefaultClose, {})
      ] }),
      /* @__PURE__ */ n(g.Footer, { children: [
        /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "secondary", onClick: () => s({ type: "none" }), children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.confirmationDialogNo() }) }),
        /* @__PURE__ */ e(
          g.FooterActionButton,
          {
            onClick: () => {
              s({ type: "none" }), c.onConfirm();
            },
            children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.confirmationDialogYes() })
          }
        )
      ] })
    ] }) : c.type === "confirmPaidPlanChange" ? /* @__PURE__ */ n(g.Content, { children: [
      /* @__PURE__ */ n(g.Header, { children: [
        /* @__PURE__ */ e(g.Title, { children: a.availablePlanList.confirmationDialogTitle() }),
        /* @__PURE__ */ e(g.DefaultClose, {})
      ] }),
      /* @__PURE__ */ e(g.Body, { children: a.availablePlanList.confirmationDialogDescription() }),
      /* @__PURE__ */ n(g.Footer, { children: [
        /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "secondary", onClick: () => s({ type: "none" }), children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.confirmationDialogNo() }) }),
        /* @__PURE__ */ e(
          g.FooterActionButton,
          {
            onClick: () => {
              s({ type: "none" }), c.onConfirm();
            },
            children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.confirmationDialogYes() })
          }
        )
      ] })
    ] }) : c.type === "paidPlanCapabilityExceeded" ? /* @__PURE__ */ n(g.Content, { children: [
      /* @__PURE__ */ n(g.Header, { children: [
        /* @__PURE__ */ e(g.Title, { children: a.availablePlanList.memberCountExceededDialogTitle() }),
        /* @__PURE__ */ e(g.DefaultClose, {})
      ] }),
      /* @__PURE__ */ e(g.Body, { children: a.availablePlanList.memberCountExceededDialogDescription() }),
      /* @__PURE__ */ n(g.Footer, { children: [
        /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "secondary", onClick: () => s({ type: "none" }), children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.memberCountExceededDialogBackButton() }) }),
        /* @__PURE__ */ e(
          g.FooterActionButton,
          {
            onClick: () => {
              s({ type: "none" }), t.gotoTeamMemberPage({ teamId: t.teamId });
            },
            children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.memberCountExceededDialogMemberSettingButton() })
          }
        )
      ] })
    ] }) : c.type === "freePlanCapabilityExceeded" ? /* @__PURE__ */ n(g.Content, { children: [
      /* @__PURE__ */ n(g.Header, { children: [
        /* @__PURE__ */ e(g.Title, { children: a.availablePlanList.freePlanCapabilityExceededDialogTitle() }),
        /* @__PURE__ */ e(g.DefaultClose, {})
      ] }),
      /* @__PURE__ */ e(g.Body, { children: a.availablePlanList.freePlanCapabilityExceededDialogDescription() }),
      /* @__PURE__ */ e(g.Footer, { children: /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "secondary", onClick: () => s({ type: "none" }), children: /* @__PURE__ */ e(p.Text, { children: a.availablePlanList.freePlanCapabilityExceededDialogButton() }) }) })
    ] }) : null })
  ] });
}, il = (t) => /* @__PURE__ */ e(Ke, { fallback: /* @__PURE__ */ e(X.Spinner, { active: !0 }), children: /* @__PURE__ */ e(rl, { ...t }) });
var ll = "szap9z12 szap9z1e szap9z7c", sl = "szap9zg szap9zj", ol = "szap9z12 szap9z1c szap9z1f szap9z7c szap9z7b", cl = "szap9zi";
const dl = (t) => {
  const { LL: a } = S(), i = {
    ...ce({ query: le.screen.desktop }) ? {} : { width: "full" }
  };
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ e(ot, { as: "h1", children: a.confirmedEventListEmptyMessages.title() }),
    /* @__PURE__ */ n("div", { className: ll, children: [
      /* @__PURE__ */ n("section", { className: sl, children: [
        /* @__PURE__ */ e("p", { children: a.confirmedEventListEmptyMessages.aboutThisPage() }),
        /* @__PURE__ */ e("p", { children: a.confirmedEventListEmptyMessages.explanation() })
      ] }),
      /* @__PURE__ */ e("p", { className: cl, children: a.confirmedEventListEmptyMessages.promotion() }),
      /* @__PURE__ */ n("section", { className: ol, children: [
        /* @__PURE__ */ e(
          ut.Link,
          {
            ...i,
            href: t.availabilitySharingPageLinkHref,
            onClick: t.onAvailabilitySharingPageClick,
            children: /* @__PURE__ */ e(ut.Text, { children: a.confirmedEventListEmptyMessages.availabilitySharingPage() })
          }
        ),
        /* @__PURE__ */ e(ut.Link, { ...i, href: t.calendarPageLinkHref, onClick: t.onCalendarPageClick, children: /* @__PURE__ */ e(ut.Text, { children: a.confirmedEventListEmptyMessages.calendarPage() }) })
      ] })
    ] })
  ] });
}, ml = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  ConfirmedEventListEmptyMessages: dl
}, Symbol.toStringTag, { value: "Module" }));
var ul = "szap9z12 szap9z1e szap9z7c", pl = "szap9z11", hl = "szap9z10 szap9z1b", Qt = "szap9z12 szap9z1e szap9z7c";
const zl = (t) => {
  const { LL: a } = S();
  return /* @__PURE__ */ n("div", { children: [
    /* @__PURE__ */ e(ot, { as: "h1", children: a.planningEventListEmptyMessages.title() }),
    /* @__PURE__ */ n("div", { className: ul, children: [
      /* @__PURE__ */ n("section", { className: Qt, children: [
        /* @__PURE__ */ e("p", { dangerouslySetInnerHTML: { __html: a.planningEventListEmptyMessages.aboutThisPage() } }),
        /* @__PURE__ */ e("p", { dangerouslySetInnerHTML: { __html: a.planningEventListEmptyMessages.explanation() } })
      ] }),
      /* @__PURE__ */ e("section", { className: Qt, children: /* @__PURE__ */ n("div", { children: [
        /* @__PURE__ */ e(ot, { as: "h2", children: a.planningEventListEmptyMessages.meritTitle() }),
        /* @__PURE__ */ n($e.Ul, { children: [
          /* @__PURE__ */ e($e.Li, { children: a.planningEventListEmptyMessages.meritList.calendar() }),
          /* @__PURE__ */ e($e.Li, { children: a.planningEventListEmptyMessages.meritList.share() }),
          /* @__PURE__ */ e($e.Li, { children: a.planningEventListEmptyMessages.meritList.onlineMeeting() })
        ] })
      ] }) }),
      /* @__PURE__ */ n("section", { className: Qt, children: [
        /* @__PURE__ */ e("p", { className: pl, children: a.planningEventListEmptyMessages.createPromotion() }),
        /* @__PURE__ */ e("p", { className: hl, children: a.planningEventListEmptyMessages.createPromotionMobile() }),
        /* @__PURE__ */ e(qt, { href: t.calendarPageLinkHref, onClick: t.onClickCalendarPageLink, children: a.planningEventListEmptyMessages.calendarPageName() })
      ] })
    ] })
  ] });
}, vl = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  PlanningEventListEmptyMessages: zl
}, Symbol.toStringTag, { value: "Module" })), yl = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  ConfirmedEventList: ml,
  PlanningEventList: vl
}, Symbol.toStringTag, { value: "Module" })), bl = (t) => {
  const { LL: a } = S(), r = v.useSnackbar(), { codeResponse: i, gotoNotFoundPage: c, gotoResourceSettingPage: s, registerAuthcode: o } = t, l = ee(
    (d) => {
      r.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: d.type, children: /* @__PURE__ */ e(v.Description, { children: d.message }) })
      );
    },
    [r]
  ), h = ee(
    async (d) => {
      const m = await o({ code: d });
      m.type === "success" ? l({
        message: a.providerResourceGrantGoogle.success(),
        type: "success"
      }) : l({
        message: m.code === "NOT_ENOUGH_AUTHORITY" ? a.providerResourceGrantGoogle.failed() : a.providerResourceGrantGoogle.unknownError(),
        type: "danger"
      }), s();
    },
    [o, s, l, a.providerResourceGrantGoogle]
  );
  return ne(() => {
    i.code !== null ? h(i.code) : i.error === "access_denied" ? s() : i.error !== null ? (l({ message: a.providerResourceGrantGoogle.failed(), type: "danger" }), s()) : (l({ message: a.providerResourceGrantGoogle.unknownError(), type: "danger" }), c());
  }, [
    i,
    c,
    l,
    h,
    a.providerResourceGrantGoogle,
    s
  ]), /* @__PURE__ */ e(X.Spinner, { active: i.code !== null });
}, gl = (t) => {
  const { LL: a } = S(), r = v.useSnackbar(), { codeResponse: i, gotoNotFoundPage: c, gotoResourceSettingPage: s, registerAuthcode: o } = t, l = ee(
    (d) => {
      r.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: d.type, children: /* @__PURE__ */ e(v.Description, { children: d.message }) })
      );
    },
    [r]
  ), h = ee(
    async (d) => {
      const m = await o({ code: d });
      m.type === "success" ? l({
        message: a.providerResourceGrantMicrosoft.success(),
        type: "success"
      }) : l({
        message: m.code === "NOT_ENOUGH_AUTHORITY" ? a.providerResourceGrantMicrosoft.failed() : a.providerResourceGrantMicrosoft.unknownError(),
        type: "danger"
      }), s();
    },
    [o, s, l, a.providerResourceGrantMicrosoft]
  );
  return ne(() => {
    i.code !== null ? h(i.code) : i.error === "access_denied" ? (l({ message: a.providerResourceGrantMicrosoft.failed(), type: "danger" }), s()) : i.error !== null ? (l({ message: a.providerResourceGrantMicrosoft.failed(), type: "danger" }), s()) : (l({ message: a.providerResourceGrantMicrosoft.unknownError(), type: "danger" }), c());
  }, [
    i,
    c,
    l,
    h,
    a.providerResourceGrantMicrosoft,
    s
  ]), /* @__PURE__ */ e(X.Spinner, { active: i.code !== null });
}, fl = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Google: bl,
  Microsoft: gl
}, Symbol.toStringTag, { value: "Module" }));
var Tl = "szap9z12 szap9z1e szap9z7c", Cl = "szap9z11", kl = "szap9z10 szap9z1b", Ll = "szap9zi", Fa = "szap9z12 szap9z1e szap9z7c";
const Nl = () => {
  const { LL: t } = S();
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ e(ot, { as: "h1", children: t.teamFormListEmptyMessages.title() }),
    /* @__PURE__ */ n("div", { className: Tl, children: [
      /* @__PURE__ */ n("section", { className: Ll, children: [
        /* @__PURE__ */ e("p", { children: t.teamFormListEmptyMessages.featureDescription() }),
        /* @__PURE__ */ e("p", { children: t.teamFormListEmptyMessages.useInTemplate() }),
        /* @__PURE__ */ e("p", { dangerouslySetInnerHTML: { __html: t.teamFormListEmptyMessages.helpLink() } })
      ] }),
      /* @__PURE__ */ e("section", { className: Fa, children: /* @__PURE__ */ n("div", { children: [
        /* @__PURE__ */ e(ot, { as: "h2", children: t.teamFormListEmptyMessages.meritTitle() }),
        /* @__PURE__ */ n($e.Ul, { children: [
          /* @__PURE__ */ e($e.Li, { children: t.teamFormListEmptyMessages.meritList.customizableForm() }),
          /* @__PURE__ */ e($e.Li, { children: t.teamFormListEmptyMessages.meritList.getToKnowBefore() })
        ] })
      ] }) }),
      /* @__PURE__ */ n("section", { className: Fa, children: [
        /* @__PURE__ */ e("p", { className: Cl, children: t.teamFormListEmptyMessages.createPromotion() }),
        /* @__PURE__ */ e("p", { className: kl, children: t.teamFormListEmptyMessages.createPromotionMobile() })
      ] })
    ] })
  ] });
};
var xl = "szap9z4x szap9z4u szap9z12 szap9z1u szap9z1j szap9z1c szap9z78", Dl = "szap9z12 szap9z1e szap9z78", Il = "szap9z3o szap9z4a qvn8oki szap9z4 szap9z2g szap9z30 qvn8ok9", Fl = "szap9z12 szap9z1z szap9z20 szap9z1u szap9z2w", Ml = "qvn8ok1", Pl = "_1e2ra3wa", wl = "szap9z11 szap9z18 szap9z4u", Sl = "_1e2ra3w0 szap9z16 szap9z7c szap9zg", El = "_1e2ra3w4 szap9zj szap9zk", _l = "szap9zq", Ma = "szap9zk szap9zq", Rl = "_1e2ra3w6", ql = "_1e2ra3w5 szap9z12", Pa = "qvn8ok1 szap9z3i";
const Bl = ({ deleteForm: t }) => {
  const { LL: a } = S(), [r, i] = V(!1), c = () => {
    i(!0);
  }, s = () => {
    i(!1), t();
  };
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ n(N.Root, { modal: !1, children: [
      /* @__PURE__ */ e(N.Trigger, { children: /* @__PURE__ */ e(p.Button, { buttonType: "text", size: "small", "aria-label": "optionButton", children: /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.DotsHorizontal, {}) }) }) }),
      /* @__PURE__ */ e(N.Menu, { align: "end", children: /* @__PURE__ */ e(N.MenuItem, { onSelect: c, children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { className: Ml, children: a.teamFormList.deleteFormOption() }) }) }) })
    ] }),
    /* @__PURE__ */ e(g.Root, { open: r, onOpenChange: (o) => i(o), children: /* @__PURE__ */ n(g.Content, { zIndex: "100", children: [
      /* @__PURE__ */ n(g.Header, { children: [
        /* @__PURE__ */ e(g.Title, { children: a.teamFormList.deleteFormDialogTitle() }),
        /* @__PURE__ */ e(g.DefaultClose, {})
      ] }),
      /* @__PURE__ */ e(g.Body, { children: a.teamFormList.deleteFormDialogDescription() }),
      /* @__PURE__ */ n(g.Footer, { children: [
        /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "secondary", onClick: () => i(!1), children: /* @__PURE__ */ e(p.Text, { children: a.teamFormList.deleteFormDialogCancelButton() }) }),
        /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "danger", onClick: s, children: /* @__PURE__ */ e(p.Text, { children: a.teamFormList.deleteFormDialogDeleteButton() }) })
      ] })
    ] }) })
  ] });
}, Al = ({ question: t }) => {
  const { LL: a } = S();
  return t.type === "default" ? /* @__PURE__ */ n("span", { className: Ma, children: [
    /* @__PURE__ */ e("span", { children: t.defaultKey === "name" ? a.teamFormList.defaultQuestionNameTitle() : a.teamFormList.defaultQuestionEmailTitle() }),
    /* @__PURE__ */ e("span", { className: Pa, children: "*" })
  ] }) : /* @__PURE__ */ n("span", { className: Ma, children: [
    /* @__PURE__ */ e("span", { children: t.ui.title }),
    /* @__PURE__ */ e("span", { className: Pa, children: "*" })
  ] });
}, Ol = ({ forms: t, deleteForm: a, openPreview: r, gotoEditPage: i }) => {
  const { LL: c } = S(), o = {
    type: "button",
    ...ce({ query: le.screen.mobile }) ? { width: "full", size: "medium" } : { size: "small" }
  };
  return /* @__PURE__ */ e(W, { children: /* @__PURE__ */ e("ul", { className: Sl, children: t.map((l) => /* @__PURE__ */ e("li", { children: /* @__PURE__ */ n("section", { className: Il, children: [
    /* @__PURE__ */ n("div", { className: Fl, children: [
      /* @__PURE__ */ e("p", { className: _l, children: l.privateTitle }),
      /* @__PURE__ */ e(Bl, { deleteForm: () => a({ formId: l.id }) })
    ] }),
    /* @__PURE__ */ n("div", { className: Dl, children: [
      /* @__PURE__ */ e("p", { className: El, children: l.privateDescription }),
      /* @__PURE__ */ e("p", { className: ql, children: l.questionsOrder.map((h) => {
        const d = h.type === "default" ? l.defaultQuestions[h.defaultKey] : l.customQuestions[h.id];
        return /* @__PURE__ */ e("span", { className: Rl, children: d === void 0 ? null : /* @__PURE__ */ e(Al, { question: d }) }, h.id);
      }) })
    ] }),
    /* @__PURE__ */ e("div", { className: wl, children: /* @__PURE__ */ e("hr", { className: Pl }) }),
    /* @__PURE__ */ n("div", { className: xl, children: [
      /* @__PURE__ */ e(p.Button, { ...o, buttonType: "textMin", onClick: () => r({ form: l }), children: /* @__PURE__ */ e(p.Text, { children: c.teamFormList.previewButton() }) }),
      /* @__PURE__ */ e(
        p.Button,
        {
          ...o,
          buttonType: "secondary",
          onClick: () => i({ formId: l.id }),
          children: /* @__PURE__ */ e(p.Text, { children: c.teamFormList.editButton() })
        }
      )
    ] })
  ] }) }, l.id)) }) });
};
var $l = "_1ewcgjt0 szap9z3q szap9z3p szap9z4c szap9z4b szap9z12 szap9z20 szap9z1e", Vl = "_1ewcgjt1";
class wa extends Error {
  constructor(a) {
    super(), this.code = a;
  }
  get errorMessageKey() {
    switch (this.code) {
      case "NOT_MEMBER_OF_TEAM":
        return "deleteFormDangerToastNotMemberOfTeam";
      case "TEAM_IS_NOT_AVAILABLE":
        return "deleteFormDangerToastTeamIsNotAvailable";
      default:
        return "deleteFormDangerToastFormNotFound";
    }
  }
}
const Hl = ({ teamId: t, fetchTeamForms: a, deleteTeamForm: r, gotoMainPage: i, openPreview: c, gotoEditPage: s, createTeamForm: o }) => {
  const l = ce({ query: le.screen.mobile }), { LL: h } = S(), d = v.useSnackbar(), m = ["teamForms", t], { data: D, isFetching: u } = Ie({
    queryFn: async () => a({ teamId: t }),
    queryKey: m
  }), z = Je(), k = fe({
    mutationFn: async (L) => {
      const R = await r({ teamId: t, formId: L.formId });
      if (R.type === "error")
        throw new wa(R.code);
    },
    onSuccess: async () => {
      await z.invalidateQueries({ queryKey: m }), d.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: h.teamFormList.deleteFormSuccessToast() }) })
      );
    },
    onError: (L) => {
      L instanceof wa ? (d.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: h.teamFormList[L.errorMessageKey]() }) })
      ), i()) : d.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: h.teamFormList.deleteFormDangerToastCannotDelete() }) })
      );
    }
  });
  return /* @__PURE__ */ n("div", { className: $l, children: [
    /* @__PURE__ */ e("div", { className: Vl, children: D.length === 0 ? /* @__PURE__ */ e(Nl, {}) : /* @__PURE__ */ n(W, { children: [
      k.status === "pending" || u ? /* @__PURE__ */ e(X.Spinner, { active: !0 }) : null,
      /* @__PURE__ */ e(
        Ol,
        {
          forms: D,
          deleteForm: (L) => k.mutate(L),
          openPreview: c,
          gotoEditPage: (L) => s({ teamId: t, formId: L.formId })
        }
      )
    ] }) }),
    l ? /* @__PURE__ */ e(kn, { onClick: () => void o(), children: /* @__PURE__ */ e(x.Add, {}) }) : null
  ] });
}, Fn = ({ gotoUnavailablePage: t, ...a }) => {
  const { LL: r } = S(), i = v.useSnackbar();
  return (
    // fallback is empty because Vue app renders a spinner during the redirecting to the unavailable page.
    /* @__PURE__ */ e(dt, { fallback: /* @__PURE__ */ e(W, {}), onError: (s) => {
      const o = s.message === "NOT_MEMBER_OF_TEAM" ? r.teamFormList.notMemberOfTeamFetchError() : s.message === "ILLEGAL_ARGUMENT" ? r.teamFormList.illegalArgumentFetchError() : r.teamFormList.unknownFetchError();
      i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: o }) })
      ), t();
    }, children: /* @__PURE__ */ e(Ke, { fallback: /* @__PURE__ */ e(X.Spinner, { active: !0 }), children: /* @__PURE__ */ e(Hl, { ...a }) }) })
  );
}, jl = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  TeamFormList: Fn
}, Symbol.toStringTag, { value: "Module" })), Ul = ({
  defaultTemplate: t,
  titleForIndividualEvents: a,
  formState: r,
  validateQuestionTemplate: i
}) => {
  const { LL: c } = S(), s = ee(
    (h) => {
      const d = h.reduce((m, D) => {
        if (D.type === "text")
          m.push(D);
        else {
          if (m.length === 0)
            m.push({ type: "text", text: "" });
          else {
            const u = m[m.length - 1];
            u !== void 0 && u.type !== "text" && m.push({ type: "text", text: "" });
          }
          switch (D.type) {
            case "candidateTitle": {
              m.push({
                type: "custom-title-tag",
                attrs: { type: "candidateTitle", label: c.titleForIndividualEventsSlideOut.publicTitle() },
                children: [{ text: "" }]
              });
              break;
            }
            case "inviteeName": {
              m.push({
                type: "custom-title-tag",
                attrs: { type: "inviteeName", label: c.titleForIndividualEventsSlideOut.invitee() },
                children: [{ text: "" }]
              });
              break;
            }
            case "organizerName": {
              m.push({
                type: "custom-title-tag",
                attrs: { type: "organizerName", label: c.titleForIndividualEventsSlideOut.organizer() },
                children: [{ text: "" }]
              });
              break;
            }
            case "question": {
              const u = i({ templateItem: D, formState: r });
              m.push({
                type: "custom-title-tag",
                attrs: { questionId: u.id, type: "question", label: u.ui.title },
                children: [{ text: "" }]
              });
              break;
            }
          }
        }
        return m;
      }, []);
      return d[d.length - 1]?.type === "text" ? [{ children: d }] : [{ children: [...d, { type: "text", text: "" }] }];
    },
    [c.titleForIndividualEventsSlideOut, i, r]
  ), o = A(
    () => s(t),
    [s, t]
  );
  return { initialValue: A(() => {
    if (a.type === "default")
      return o;
    try {
      return s(a.template);
    } catch {
      return o;
    }
  }, [a, o, s]), defaultInitialValue: o };
}, Gl = ({ formState: t, validateCustomQuestion: a }) => {
  const { LL: r } = S();
  return A(() => {
    const c = {
      id: "inviteeName",
      type: "inviteeName",
      label: r.titleForIndividualEventsSlideOut.invitee()
    };
    if (t.type === "unselected")
      return [c];
    {
      const { questionsOrder: s, customQuestions: o } = t.form;
      return s.map((l) => {
        switch (l.type) {
          case "default":
            return l.defaultKey === "name" ? c : void 0;
          case "custom": {
            const h = o[l.id];
            try {
              const d = a({ question: h, questionId: l.id });
              return { id: d.id, type: "question", label: d.ui.title };
            } catch {
              return;
            }
          }
        }
      }).filter((l) => l !== void 0);
    }
  }, [t, a, r.titleForIndividualEventsSlideOut]);
};
var Xt = "_1pm6jmye szap9z3m szap9z48 szap9z12 szap9z20 szap9z1o qvn8ok2 szap9zi szap9zq szap9z2", Yl = we({ defaultClassName: "_1pm6jmy4 szap9z3m szap9z48 szap9z14 szap9z20 szap9z2 qvn8oka", variantClassNames: { state: { normal: "_1pm6jmy5", selected: "_1pm6jmy6" }, defaultVariants: { state: "normal" } }, defaultVariants: {}, compoundVariants: [] }), Sa = "_1pm6jmyc szap9z12 szap9z78", Kl = "_1pm6jmy8 szap9z2g szap9z32 szap9z3o szap9z4a szap9z2 qvn8oka", Jl = "szap9zk szap9zo", Wl = "szap9zq", Ea = "szap9zk szap9zq szap9z5e", Ql = "_1pm6jmyf szap9zk szap9zo", _a = "_1pm6jmyb szap9z3o szap9z4a szap9z2e szap9z30 szap9z2 qvn8ok9", Xl = "_1pm6jmy2 szap9z3o szap9z4a szap9z2e szap9z30 szap9zi szap9z2 qvn8ok9", Zl = "_1pm6jmy1", es = "szap9zk qvn8ok1 szap9z2c", ts = "szap9z5i";
const as = (t) => {
  const { isInline: a, isVoid: r } = t;
  return Object.assign(t, {
    isInline: (c) => c.type === "custom-title-tag" ? !0 : a(c),
    isVoid: (c) => c.type === "custom-title-tag" ? !0 : r(c)
  });
}, ns = (t) => {
  const a = Dr();
  return /* @__PURE__ */ n(
    "span",
    {
      ...t.attributes,
      contentEditable: !1,
      "data-tag-type": t.element.attrs.type,
      className: Yl({ state: a ? "selected" : "normal" }),
      children: [
        t.children,
        t.element.attrs.label
      ]
    }
  );
}, rs = (t) => {
  switch (t.element.type) {
    case "custom-title-tag":
      return /* @__PURE__ */ e(ns, { attributes: t.attributes, element: t.element, children: t.children });
    default:
      return /* @__PURE__ */ e("div", { ...t.attributes, className: Zl, children: t.children });
  }
};
function is(t) {
  return t.anchor.path[1] !== t.focus.path[1] ? !1 : (t.anchor.path[1] ?? 0) % 2 === 1;
}
function ls(t, a) {
  const { selection: r } = t;
  if (r === null)
    return;
  const i = {
    type: "custom-title-tag",
    attrs: a,
    children: [{ text: "" }]
  }, c = r.anchor.path[1] ?? 0, s = r.focus.path[1] ?? 0, o = Math.min(c, s);
  if (is(r)) {
    it.setNodes(t, i, { at: [0, o] });
    const l = { offset: 0, path: [0, o + 1] };
    it.select(t, { anchor: l, focus: l });
  } else {
    it.insertNodes(t, i, { select: !1, voids: !0 });
    const l = { offset: 0, path: [0, o + 2] };
    it.select(t, { anchor: l, focus: l });
  }
  dn.focus(t);
}
const ss = ({
  defaultTemplate: t,
  titleForIndividualEvents: a,
  formState: r,
  validateCustomQuestion: i,
  validateQuestionTemplate: c,
  onChange: s
}) => {
  const { LL: o } = S(), [l, h] = V(!1), [d, m] = V(!1), D = A(() => as(Lr(Cr())), []), { initialValue: u, defaultInitialValue: z } = Ul({
    defaultTemplate: t,
    titleForIndividualEvents: a,
    formState: r,
    validateQuestionTemplate: c
  }), k = Gl({ formState: r, validateCustomQuestion: i }), L = ee(
    (M) => /* @__PURE__ */ e(rs, { ...M }),
    []
  );
  ne(() => {
    it.select(D, kr.end(D, [])), dn.focus(D);
  }, [D]);
  const R = ee(
    (M) => {
      h(!0), ls(D, M);
    },
    [D]
  );
  return /* @__PURE__ */ e(W, { children: /* @__PURE__ */ n(
    Nr,
    {
      editor: D,
      initialValue: u,
      onValueChange: (M) => {
        if (M.length === 0)
          return;
        if (!l && JSON.stringify(M) === JSON.stringify(z))
          return s({
            titleForIndividualEvents: { type: "default" },
            titleIsEmpty: !1
          });
        const H = M[0];
        if (H === void 0 || !("children" in H))
          return;
        const f = H.children.filter((F) => F.type === "custom-title-tag" || F.text !== "").map((F) => {
          if (F.type !== "custom-title-tag")
            return { type: "text", text: F.text };
          switch (F.attrs.type) {
            case "candidateTitle":
            case "inviteeName":
            case "organizerName":
              return { type: F.attrs.type };
            case "question":
              return { type: F.attrs.type, questionId: F.attrs.questionId };
            default:
              throw new Error("Invalid custom title tag type");
          }
        }), P = f.length === 0 || f.length === 1 && f[0]?.type === "text" && f[0]?.text === "";
        m(() => P), s({
          titleForIndividualEvents: { type: "custom", template: f },
          titleIsEmpty: P
        });
      },
      children: [
        /* @__PURE__ */ n("div", { className: ts, children: [
          /* @__PURE__ */ e(
            xr,
            {
              className: Xl,
              "aria-multiline": !1,
              renderElement: L,
              scrollSelectionIntoView: () => {
              },
              onKeyDown: (M) => {
                h(!0), M.key === "Enter" && M.preventDefault();
              }
            }
          ),
          d ? /* @__PURE__ */ e("p", { className: es, children: o.titleForIndividualEventsSlideOut.validationError() }) : null
        ] }),
        /* @__PURE__ */ n("section", { className: Kl, children: [
          /* @__PURE__ */ n("h2", { className: Jl, children: [
            o.titleForIndividualEventsSlideOut.selectedForm(),
            " ",
            /* @__PURE__ */ e("span", { className: Wl, children: r.type === "selected" ? r.form.privateTitle : o.titleForIndividualEventsSlideOut.unselected() })
          ] }),
          /* @__PURE__ */ n("section", { className: _a, children: [
            /* @__PURE__ */ e("h3", { className: Ea, children: o.titleForIndividualEventsSlideOut.informationProvidedByTheGuest() }),
            /* @__PURE__ */ e("ul", { className: Sa, children: k.map((M) => /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e(
              "button",
              {
                type: "button",
                className: Xt,
                onClick: () => R(
                  M.type === "inviteeName" ? { type: M.type, label: M.label } : { type: M.type, questionId: M.id, label: M.label }
                ),
                children: M.label
              }
            ) }, M.id)) })
          ] }),
          /* @__PURE__ */ n("section", { className: _a, children: [
            /* @__PURE__ */ e("h3", { className: Ea, children: o.titleForIndividualEventsSlideOut.otherInformation() }),
            /* @__PURE__ */ n("ul", { className: Sa, children: [
              /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e(
                "button",
                {
                  type: "button",
                  className: Xt,
                  onClick: () => R({
                    type: "organizerName",
                    label: o.titleForIndividualEventsSlideOut.organizer()
                  }),
                  children: o.titleForIndividualEventsSlideOut.organizer()
                }
              ) }),
              /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e(
                "button",
                {
                  type: "button",
                  className: Xt,
                  onClick: () => R({
                    type: "candidateTitle",
                    label: o.titleForIndividualEventsSlideOut.publicTitle()
                  }),
                  children: o.titleForIndividualEventsSlideOut.publicTitle()
                }
              ) })
            ] })
          ] }),
          /* @__PURE__ */ n("ul", { className: Ql, children: [
            /* @__PURE__ */ e("li", { dangerouslySetInnerHTML: { __html: o.titleForIndividualEventsSlideOut.note.selectedForm() } }),
            /* @__PURE__ */ e("li", { children: o.titleForIndividualEventsSlideOut.note.more() })
          ] })
        ] })
      ]
    }
  ) });
}, os = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  TitleForIndividualEventsSlideOut: ss
}, Symbol.toStringTag, { value: "Module" })), fu = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Events: yl,
  ProviderResourceGrant: fl,
  TeamForms: jl,
  TeamTemplates: os
}, Symbol.toStringTag, { value: "Module" }));
var cs = "yv4vt52 szap9z3q szap9z3p szap9z4c szap9z4b", ds = "yv4vt51 szap9z12 szap9z20 szap9z1e", ms = "yv4vt53", us = "yv4vt50";
class Ra extends Error {
  constructor(a) {
    super(), this.code = a;
  }
  get errorMessageKey() {
    switch (this.code) {
      case "NOT_MEMBER_OF_TEAM":
        return "createFormDangerToastNotMemberOfTeam";
      default:
        return "createFormDangerToastTeamIsNotAvailable";
    }
  }
}
const ps = ({ createTeamForm: t, ...a }) => {
  const { LL: r } = S(), i = v.useSnackbar(), { mutate: c, mutateAsync: s, isPending: o } = fe({
    mutationFn: async () => {
      const l = await t({ teamId: a.teamId, privateTitle: r.teamFormList.newFormTitle() });
      if (l.type === "error")
        switch (l.code) {
          case "TEAM_IS_NOT_AVAILABLE":
          case "NOT_MEMBER_OF_TEAM":
          case "TEAM_NOT_FOUND":
            throw new Ra(l.code);
          default:
            throw new Error("Unknown error");
        }
      else
        return l;
    },
    onSuccess: (l) => {
      i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: r.teamFormList.createFormSuccessToast() }) })
      ), a.gotoEditPage({ teamId: a.teamId, formId: l.formId });
    },
    onError: (l) => {
      l instanceof Ra ? (i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: r.teamFormList[l.errorMessageKey]() }) })
      ), a.gotoUnavailablePage()) : i.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: r.teamFormList.unknownFetchError() }) })
      );
    }
  });
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ e(X.Spinner, { active: o }),
    /* @__PURE__ */ n("div", { className: us, children: [
      /* @__PURE__ */ e(Ln, { children: /* @__PURE__ */ e("div", { className: ds, children: /* @__PURE__ */ e("div", { className: cs, children: /* @__PURE__ */ n(p.Button, { size: "small", onClick: () => c(), children: [
        /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Add, {}) }),
        /* @__PURE__ */ e(p.Text, { children: r.teamFormList.createButton() })
      ] }) }) }) }),
      /* @__PURE__ */ e("div", { className: ms, children: /* @__PURE__ */ e(
        Fn,
        {
          ...a,
          createTeamForm: async () => {
            await s();
          }
        }
      ) })
    ] })
  ] });
};
var hs = "dxcen64", zs = "dxcen63", vs = "dxcen60", ys = "dxcen61 szap9z3s szap9z3p szap9z4e szap9z4b szap9z2k szap9z36 qvn8ok9 szap9z12 szap9z1c szap9z1o", bs = "dxcen62";
const We = (t) => /* @__PURE__ */ e("div", { "data-name": "page", className: vs, children: /* @__PURE__ */ e("div", { "data-name": "page-frame", className: ys, children: t.children }) }), Qe = (t) => /* @__PURE__ */ e("div", { "data-name": "settings-frame", className: bs, children: t.children }), Xe = (t) => /* @__PURE__ */ e("div", { "data-name": "illustration-frame", className: zs, children: t.children }), Ze = (t) => /* @__PURE__ */ e("img", { className: hs, ...t }), gs = "addressbook#contacts@group.v.calendar.google.com", fs = (t) => t.writable && (t.type === "normal" || t.type === "group" && t.owner && t.id !== gs);
var Ts = "hzoitk9", Cs = "hzoitka szap9z12 szap9z1e", ks = "hzoitk8 szap9z12 szap9z20 szap9z7a", Ls = "szap9zi qvn8ok0", Ns = "szap9zi szap9zq", xs = "hzoitkf szap9z16 szap9z20", Ds = "szap9zk qvn8ok0", Is = we({ defaultClassName: "hzoitkh szap9zi", variantClassNames: { shareUpdated: { true: "hzoitki", false: "hzoitkj" } }, defaultVariants: { shareUpdated: !0 }, compoundVariants: [] }), Fs = "hzoitkd szap9z16 szap9z20 szap9z7c", qa = "hzoitk5 szap9z4u szap9z5g", Ms = "hzoitkm", Ps = "szap9z30 szap9z3d", ws = "hzoitke szap9z16 qvn8ok0", Ss = "szap9z30", Ba = "szap9zi", Aa = "hzoitk0 szap9z16 szap9z76 szap9z34", Es = "szap9z4y szap9z5k szap9z5j", Oa = "szap9zc szap9zh szap9zq", Zt = "hzoitk3 qvn8okg", Ct = "hzoitkg", _s = "hzoitkl";
const Me = " _ ";
class ea extends Error {
  constructor(a) {
    super(), this.code = a;
  }
}
const Rs = ({
  illustrationPath: t,
  fetchCalendarAccounts: a,
  addNewCalendar: r,
  completeJoin: i,
  gotoNextStep: c,
  gotoSignUp: s,
  gotoTeamMemberTokenExpired: o
}) => {
  const { LL: l } = S(), h = v.useSnackbar(), d = ce({ query: le.screen.mobile }), [m, D] = V(
    void 0
  ), [u, z] = V([]), k = A(a, [a]), L = A(
    () => ({
      ...d ? { width: "full" } : {}
    }),
    [d]
  ), R = A(() => d ? {
    align: "center",
    width: "full"
  } : {
    align: "start"
  }, [d]), M = A(() => d ? {
    align: "center",
    width: "full"
  } : {
    align: "end",
    width: "358px"
  }, [d]), H = A(() => k.map((C) => ({
    ...C,
    calendars: C.calendars.filter((b) => b.primary)
  })), [k]), f = ee((C) => u.find((b) => b.accountId === C.accountId && b.calendarId === C.id), [u]), { mutate: P, isPending: F } = fe({
    mutationFn: async (C) => {
      const b = await i(C);
      if (!b.ok)
        throw new ea(b.error.code);
    },
    onError: (C) => {
      C instanceof ea && C.code === "SUBSCRIPTION_CANCELED" ? (h.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: l.memberJoin.selecting.teamSubscriptionCanceledDangerSnackbar() }) })
      ), s()) : C instanceof ea && C.code === "USER_ALREADY_JOIN_TEAM" ? o("joined-already") : o("etc");
    },
    onSuccess: () => {
      c();
    }
  }), O = A(() => k.map((C) => ({
    ...C,
    calendars: C.calendars.filter((b) => fs(b)).map((b) => {
      const q = f(b), U = q !== void 0;
      return {
        ...b,
        primary: b.accountId === m?.accountId && b.id === m.calendarId,
        visibilityUpdated: U,
        visibility: U ? q.visibility : "false",
        disabled: C.isAccessTokenInvalid || F
      };
    })
  })), [k, m, f, F]), Q = ee(
    (C) => {
      const [b, q] = C.split(Me);
      if (b === void 0 || q === void 0)
        return;
      const U = u.filter(
        (de) => !(de.accountId === m?.accountId && de.calendarId === m?.calendarId)
      );
      if (D({ accountId: b, calendarId: q }), U.find((de) => de.accountId === b && de.calendarId === q)) {
        const de = U.map(
          (K) => K.accountId === b && K.calendarId === q ? { ...K, visibility: K.visibility === "false" ? "readAll" : K.visibility } : K
        );
        z(de);
      } else
        z(
          [...U, { accountId: b, calendarId: q, visibility: "readAll" }]
        );
    },
    [u, m]
  ), $ = ee((C) => {
    const [b, q, U] = C.split(Me);
    if (b === void 0 || q === void 0 || U === void 0)
      return;
    const ge = u.findIndex((me) => me.accountId === b && me.calendarId === q), de = ge >= 0;
    if (U === "false" && de) {
      z((me) => me.filter((G, Fe) => Fe !== ge));
      return;
    }
    const K = U;
    z(
      de ? (me) => me.map((G, Fe) => ({ ...G, visibility: Fe === ge ? K : G.visibility })) : (me) => me.concat({ accountId: b, calendarId: q, visibility: K })
    );
  }, [u]), te = r, w = () => {
    const C = u.filter((b) => b.visibility !== "false").map((b) => ({
      ...b,
      visibility: b.visibility,
      primary: b.accountId === m?.accountId && b.calendarId === m.calendarId
    }));
    P(C);
  }, T = [
    {
      label: l.memberJoin.selecting.teamVisibilityMenu.readAll.label(),
      subLabel: l.memberJoin.selecting.teamVisibilityMenu.readAll.description(),
      value: "readAll"
    },
    {
      label: l.memberJoin.selecting.teamVisibilityMenu.freeBusyOnly.label(),
      subLabel: l.memberJoin.selecting.teamVisibilityMenu.freeBusyOnly.description(),
      value: "freeBusyOnly"
    },
    {
      label: l.memberJoin.selecting.teamVisibilityMenu.notShare.label(),
      subLabel: l.memberJoin.selecting.teamVisibilityMenu.notShare.description(),
      value: "false"
    }
  ];
  return ne(() => {
    if (m !== void 0 || H.length === 0)
      return;
    const C = H[0];
    if (C === void 0 || (C?.calendars ?? []).length === 0)
      return;
    const b = C.calendars.find((q) => q.primary);
    b !== void 0 && Q(
      `${b.accountId}${Me}${b.id}`
    );
  }, [H, m, Q]), /* @__PURE__ */ n(We, { children: [
    /* @__PURE__ */ n(Qe, { children: [
      /* @__PURE__ */ e(X.Spinner, { active: F }),
      /* @__PURE__ */ n("section", { "data-name": "primary-calendar-settings", role: "region", "aria-labelledby": "primary-calendar", children: [
        /* @__PURE__ */ n("div", { className: Aa, children: [
          /* @__PURE__ */ e("div", { id: "primary-calendar", className: Oa, role: "heading", "aria-level": 1, children: l.memberJoin.selecting.title() }),
          /* @__PURE__ */ e("p", { className: Ba, children: l.memberJoin.selecting.description() })
        ] }),
        /* @__PURE__ */ e("div", { className: Ss, children: /* @__PURE__ */ e(ie.Frame, { children: /* @__PURE__ */ n(
          ie.Root,
          {
            ...m !== void 0 ? {
              value: `${m.accountId}${Me}${m.calendarId}`
            } : void 0,
            onValueChange: Q,
            children: [
              /* @__PURE__ */ e(ie.Trigger, { children: /* @__PURE__ */ e(ie.Value, {}) }),
              /* @__PURE__ */ e(ie.Menu, { ...R, children: H.map((C) => /* @__PURE__ */ n(Ft, { children: [
                /* @__PURE__ */ e(ie.MenuGroupHeading, { children: C.userName }),
                /* @__PURE__ */ e(ie.MenuGroup, { children: C.calendars.map((b) => /* @__PURE__ */ n(
                  ie.MenuItem,
                  {
                    value: `${b.accountId}${Me}${b.id}`,
                    children: [
                      /* @__PURE__ */ e(ie.IconsFrame, { children: /* @__PURE__ */ e(x.CalendarColor, { color: b.backgroundColor }) }),
                      /* @__PURE__ */ n(ie.TextFrame, { children: [
                        /* @__PURE__ */ e(ie.ItemText, { children: b.title }),
                        /* @__PURE__ */ e(ie.DescriptionText, { children: b.invitationAddress })
                      ] })
                    ]
                  },
                  b.id
                )) }, C.accountId)
              ] }, C.accountId)) })
            ]
          }
        ) }) }),
        /* @__PURE__ */ n(p.Button, { buttonType: "text", onClick: te, children: [
          /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Add, {}) }),
          /* @__PURE__ */ e(p.Text, { children: l.memberJoin.selecting.calendarAddButton() })
        ] })
      ] }),
      /* @__PURE__ */ e("hr", { className: qe(Zt, Es) }),
      /* @__PURE__ */ n("section", { className: Ps, role: "region", "aria-labelledby": "my-calendar-share", children: [
        /* @__PURE__ */ n("div", { className: Aa, children: [
          /* @__PURE__ */ e("div", { id: "my-calendar-share", className: Oa, role: "heading", "aria-level": 2, children: l.memberJoin.selecting.myCalendarShareTitle() }),
          /* @__PURE__ */ e("p", { className: Ba, children: l.memberJoin.selecting.myCalendarShareDescription() })
        ] }),
        /* @__PURE__ */ e("div", { "data-name": "my-calendar-share-settings", children: O.map((C) => /* @__PURE__ */ n(Ft, { children: [
          /* @__PURE__ */ n("div", { "data-name": "account-line", className: ks, children: [
            /* @__PURE__ */ e("div", { className: Ts, children: /* @__PURE__ */ e(
              zn,
              {
                src: C.photoUrl,
                fallbackBackgroundColor: C.fallbackBackgroundColor,
                fallbackText: C.fallbackText,
                fallbackTextColor: C.fallbackTextColor
              }
            ) }),
            /* @__PURE__ */ n("div", { className: Cs, children: [
              /* @__PURE__ */ e("span", { className: qe(Ns, Ct), children: C.userName }),
              /* @__PURE__ */ e("span", { className: qe(Ls, Ct), children: C.email })
            ] })
          ] }),
          /* @__PURE__ */ e("hr", { className: qe(Zt, qa) }),
          C.calendars.map((b) => /* @__PURE__ */ n(Ft, { children: [
            /* @__PURE__ */ n(
              "div",
              {
                ...b.primary ? { "aria-label": "primary-calendar", role: "region" } : null,
                className: Fs,
                children: [
                  /* @__PURE__ */ e("div", { className: ws, children: b.primary ? /* @__PURE__ */ e(x.Flag, { role: "img", "aria-label": "Indicator of primary calendar", size: 20 }) : null }),
                  /* @__PURE__ */ n("div", { className: xs, children: [
                    /* @__PURE__ */ e(
                      "span",
                      {
                        className: qe(
                          Is({ shareUpdated: b.visibilityUpdated }),
                          Ct
                        ),
                        children: b.title
                      }
                    ),
                    /* @__PURE__ */ e(x.CalendarColor, { color: b.backgroundColor }),
                    /* @__PURE__ */ e("span", { className: qe(Ds, Ct), children: b.invitationAddress })
                  ] }),
                  /* @__PURE__ */ e("div", { className: _s, children: /* @__PURE__ */ e(ie.Frame, { children: /* @__PURE__ */ n(
                    ie.Root,
                    {
                      disabled: b.disabled,
                      value: `${b.accountId}${Me}${b.id}${Me}${b.visibility}`,
                      onValueChange: $,
                      children: [
                        /* @__PURE__ */ e(ie.Trigger, { children: /* @__PURE__ */ e(ie.Value, {}) }),
                        /* @__PURE__ */ e(ie.Menu, { ...M, children: T.map((q) => /* @__PURE__ */ e(
                          ie.MenuItem,
                          {
                            disabled: b.primary && q.value === "false",
                            value: `${b.accountId}${Me}${b.id}${Me}${q.value}`,
                            children: /* @__PURE__ */ n(ie.TextFrame, { children: [
                              /* @__PURE__ */ e(ie.ItemText, { children: q.label }),
                              /* @__PURE__ */ e(ie.DescriptionText, { children: q.subLabel })
                            ] })
                          },
                          q.value
                        )) })
                      ]
                    }
                  ) }) })
                ]
              }
            ),
            /* @__PURE__ */ e("hr", { className: qe(Zt, qa) })
          ] }, b.id))
        ] }, C.accountId)) })
      ] }),
      /* @__PURE__ */ e("div", { className: Ms, children: /* @__PURE__ */ e(
        p.Button,
        {
          ...L,
          disabled: m === void 0 || F,
          onClick: w,
          "aria-labelledby": "complete-setup",
          children: /* @__PURE__ */ e(p.Text, { id: "complete-setup", children: l.memberJoin.selecting.completeButton() })
        }
      ) })
    ] }),
    /* @__PURE__ */ e(Xe, { children: /* @__PURE__ */ e(Ze, { src: t, alt: "Schedule Illustration" }) })
  ] });
};
var qs = "szap9z4y", Bs = "t957v81 szap9zi", As = "szap9zc szap9zq";
const Os = ({ illustrationPath: t, startSpir: a }) => {
  const { LL: r } = S(), i = ce({ query: le.screen.mobile }), c = A(
    () => ({
      ...i ? { width: "full" } : { width: "fitContent" }
    }),
    [i]
  );
  return /* @__PURE__ */ n(We, { children: [
    /* @__PURE__ */ n(Qe, { children: [
      /* @__PURE__ */ e("div", { className: As, role: "heading", "aria-level": 1, children: r.memberJoin.complete.title() }),
      /* @__PURE__ */ e("p", { className: Bs, children: r.memberJoin.complete.description() }),
      /* @__PURE__ */ e("div", { className: qs, children: /* @__PURE__ */ e(p.Button, { ...c, onClick: a, children: /* @__PURE__ */ e(p.Text, { children: r.memberJoin.complete.next() }) }) })
    ] }),
    /* @__PURE__ */ e(Xe, { children: /* @__PURE__ */ e(Ze, { src: t, alt: "Calendar Illustration" }) })
  ] });
};
var $s = "szap9z4y", Vs = "_1et6gq81 szap9zi", Hs = "szap9zc szap9zq";
const js = ({ illustrationPath: t, addCalendar: a }) => {
  const { LL: r } = S(), i = ce({ query: le.screen.mobile }), c = A(
    () => ({
      ...i ? { width: "full" } : { width: "fitContent" }
    }),
    [i]
  );
  return /* @__PURE__ */ n(We, { children: [
    /* @__PURE__ */ n(Qe, { children: [
      /* @__PURE__ */ e("div", { className: Hs, role: "heading", "aria-level": 1, children: r.memberJoin.noCalendar.title() }),
      /* @__PURE__ */ e("p", { className: Vs, children: r.memberJoin.noCalendar.description() }),
      /* @__PURE__ */ e("div", { className: $s, children: /* @__PURE__ */ e(p.Button, { ...c, onClick: a, children: /* @__PURE__ */ e(p.Text, { children: r.memberJoin.noCalendar.next() }) }) })
    ] }),
    /* @__PURE__ */ e(Xe, { children: /* @__PURE__ */ e(Ze, { src: t, alt: "Schedule Illustration" }) })
  ] });
};
var Us = "szap9z12 szap9z20 szap9z7a szap9z1f szap9z1c szap9z4y", Gs = "_1kryuds3 szap9z4y", Ys = "_1kryuds4 szap9zi", Ks = "_1kryuds1 szap9zi", Js = "szap9zc szap9zq";
const Ws = ({ illustrationPath: t, signUpHref: a = "", signInWithGoogle: r, signInWithMicrosoft: i, gotoSignUp: c }) => {
  const { LL: s } = S(), o = ce({ query: le.screen.mobile }), l = A(
    () => ({
      ...o ? { width: "full", size: "medium" } : { width: "fitContent", size: "small" }
    }),
    [o]
  );
  return /* @__PURE__ */ n(We, { children: [
    /* @__PURE__ */ n(Qe, { children: [
      /* @__PURE__ */ e("div", { className: Js, role: "heading", "aria-level": 1, children: s.memberJoin.signIn.title() }),
      /* @__PURE__ */ e("p", { className: Ks, children: s.memberJoin.signIn.description() }),
      /* @__PURE__ */ n("div", { className: Us, children: [
        /* @__PURE__ */ n(p.Button, { buttonType: "secondary", ...l, onClick: r, children: [
          /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Google, {}) }),
          /* @__PURE__ */ e(p.Text, { children: s.memberJoin.signIn.google() })
        ] }),
        /* @__PURE__ */ n(p.Button, { buttonType: "secondary", ...l, onClick: i, children: [
          /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Microsoft, {}) }),
          /* @__PURE__ */ e(p.Text, { children: s.memberJoin.signIn.microsoft() })
        ] })
      ] }),
      /* @__PURE__ */ e("div", { className: Gs, children: /* @__PURE__ */ n("p", { className: Ys, children: [
        s.memberJoin.signIn.descriptionToSignUp(),
        /* @__PURE__ */ e(qt, { href: a, onClick: c, children: s.memberJoin.signIn.signUpLink() })
      ] }) })
    ] }),
    /* @__PURE__ */ e(Xe, { children: /* @__PURE__ */ e(Ze, { src: t, alt: "Spir Illustration" }) })
  ] });
};
var Qs = "szap9z12 szap9z20 szap9z7a szap9z1f szap9z1c szap9z4y", Xs = "_1713xb83 szap9z4y", Zs = "_1713xb84 szap9zi", eo = "_1713xb81 szap9zi", to = "szap9zc szap9zq";
const ao = ({ illustrationPath: t, signInHref: a = "", signUpWithGoogle: r, signUpWithMicrosoft: i, gotoSignIn: c }) => {
  const { LL: s } = S(), o = ce({ query: le.screen.mobile }), l = A(
    () => ({
      ...o ? { width: "full", size: "medium" } : { width: "fitContent", size: "small" }
    }),
    [o]
  );
  return /* @__PURE__ */ n(We, { children: [
    /* @__PURE__ */ n(Qe, { children: [
      /* @__PURE__ */ e("div", { className: to, role: "heading", "aria-level": 1, children: s.memberJoin.signUp.title() }),
      /* @__PURE__ */ e("p", { className: eo, children: s.memberJoin.signUp.description() }),
      /* @__PURE__ */ n("div", { className: Qs, children: [
        /* @__PURE__ */ n(p.Button, { buttonType: "secondary", ...l, onClick: r, children: [
          /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Google, {}) }),
          /* @__PURE__ */ e(p.Text, { children: s.memberJoin.signUp.google() })
        ] }),
        /* @__PURE__ */ n(p.Button, { buttonType: "secondary", ...l, onClick: i, children: [
          /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Microsoft, {}) }),
          /* @__PURE__ */ e(p.Text, { children: s.memberJoin.signUp.microsoft() })
        ] })
      ] }),
      /* @__PURE__ */ e("div", { className: Xs, children: /* @__PURE__ */ n("p", { className: Zs, children: [
        s.memberJoin.signUp.descriptionToSignIn(),
        /* @__PURE__ */ e(qt, { href: a, onClick: c, children: s.memberJoin.signUp.signInLink() })
      ] }) })
    ] }),
    /* @__PURE__ */ e(Xe, { children: /* @__PURE__ */ e(Ze, { src: t, alt: "Spir Illustration" }) })
  ] });
};
var no = "szap9z4y", $a = "nnzlqh1 szap9zi", ro = "szap9zc szap9zq", io = "nnzlqh2 szap9z2e szap9z30 szap9z4y", lo = "szap9z3o szap9z4a szap9z2c szap9z2y szap9z12 szap9z78 szap9z20", so = "szap9zi";
const oo = ({ illustrationPath: t, team: a, gotoNextStep: r }) => {
  const { LL: i } = S(), c = ce({ query: le.screen.mobile }), s = A(
    () => ({
      ...c ? { width: "full", size: "medium" } : { width: "fitContent", size: "small" }
    }),
    [c]
  );
  return /* @__PURE__ */ n(We, { children: [
    /* @__PURE__ */ n(Qe, { children: [
      /* @__PURE__ */ e("div", { className: ro, role: "heading", "aria-level": 1, children: i.memberJoin.welcome.title() }),
      /* @__PURE__ */ e("p", { className: $a, children: i.memberJoin.welcome.description.firstLine({ name: a.name }) }),
      /* @__PURE__ */ e("p", { className: $a, children: i.memberJoin.welcome.description.secondLine() }),
      /* @__PURE__ */ e("div", { className: io, children: /* @__PURE__ */ n("div", { className: lo, children: [
        /* @__PURE__ */ e(
          zn,
          {
            "aria-describedby": "team-name",
            src: a.photoUrl,
            size: 24,
            shape: "square",
            fallbackText: a.fallbackText,
            fallbackTextColor: a.fallbackTextColor,
            fallbackBackgroundColor: a.fallbackBackgroundColor
          }
        ),
        /* @__PURE__ */ e("span", { id: "team-name", className: so, children: a.name })
      ] }) }),
      /* @__PURE__ */ e("div", { className: no, children: /* @__PURE__ */ e(p.Button, { ...s, onClick: r, children: /* @__PURE__ */ e(p.Text, { children: i.memberJoin.welcome.next() }) }) })
    ] }),
    /* @__PURE__ */ e(Xe, { children: /* @__PURE__ */ e(Ze, { src: t, alt: "Spir Illustration" }) })
  ] });
};
class co extends Error {
}
class mo extends Error {
}
const uo = ({
  localChannelName: t,
  isSignedIn: a,
  teamId: r,
  invitationId: i,
  token: c,
  step: s,
  spirIllustrationPath: o,
  scheduleIllustrationPath: l,
  calendarIllustrationPath: h,
  updateQueryParams: d,
  gotoSignUp: m,
  gotoTeamMemberTokenExpired: D,
  fetchCalendarAccounts: u,
  addNewCalendar: z,
  completeJoin: k,
  validateToken: L,
  fetchTeam: R,
  signUpWithGoogle: M,
  signUpWithMicrosoft: H,
  signInWithGoogle: f,
  signInWithMicrosoft: P,
  startSpir: F
}) => {
  const { setLocale: O } = S(), [Q, $] = V("welcome");
  Ie({
    queryFn: async () => {
      const b = await L({ teamId: r, invitationId: i, token: c });
      if (b)
        return b;
      throw new co();
    },
    queryKey: ["validateToken", r, i, c],
    retry: !1
  });
  const T = Ie({
    queryFn: async () => {
      const b = await R(r);
      if (!b.ok)
        throw new mo();
      return b.team;
    },
    queryKey: ["fetchTeam", r, i, c],
    retry: !1
  }), C = A(() => u(), [u]);
  switch (ne(() => {
    if (s === void 0 || !a || 3 < s) {
      d({ step: void 0, mode: void 0 });
      return;
    }
    if (1 <= s || s <= 3) {
      const b = C.length > 0 ? "calendar-settings" : "no-calendar";
      $(b), d({ step: b === "calendar-settings" ? 3 : 2, mode: void 0 });
    }
  }, [s, a, C, d]), ne(() => {
    const b = new BroadcastChannel(t);
    return b.onmessage = async (q) => {
      const U = q.data;
      "locale" in U && (await hn(U.locale), O(U.locale));
    }, () => {
      b.close();
    };
  }, [t, O]), Q) {
    case "sign-up":
      return /* @__PURE__ */ e(
        ao,
        {
          illustrationPath: o,
          signUpWithGoogle: M,
          signUpWithMicrosoft: H,
          gotoSignIn: () => {
            $("sign-in"), d({ step: 1, mode: "signIn" });
          }
        }
      );
    case "sign-in":
      return /* @__PURE__ */ e(
        Ws,
        {
          illustrationPath: o,
          signInWithGoogle: f,
          signInWithMicrosoft: P,
          gotoSignUp: () => {
            $("sign-up"), d({ step: 1, mode: "signUp" });
          }
        }
      );
    case "no-calendar":
      return /* @__PURE__ */ e(js, { illustrationPath: l, addCalendar: z });
    case "calendar-settings":
      return /* @__PURE__ */ e(
        Rs,
        {
          illustrationPath: l,
          fetchCalendarAccounts: u,
          addNewCalendar: z,
          completeJoin: k,
          gotoNextStep: () => {
            $("complete"), d({ step: 4, mode: void 0 });
          },
          gotoSignUp: m,
          gotoTeamMemberTokenExpired: D
        }
      );
    case "complete":
      return /* @__PURE__ */ e(Os, { illustrationPath: h, startSpir: F });
    case "welcome":
    default:
      return /* @__PURE__ */ e(
        oo,
        {
          illustrationPath: o,
          team: T.data,
          gotoNextStep: () => {
            if (!a) {
              $("sign-up"), d({ step: 1, mode: "signUp" });
              return;
            }
            C.length > 0 ? ($("calendar-settings"), d({ step: 3, mode: void 0 })) : ($("no-calendar"), d({ step: 2, mode: void 0 }));
          }
        }
      );
  }
}, po = ({ gotoTeamMemberTokenExpired: t, ...a }) => /* @__PURE__ */ e(dt, { fallback: /* @__PURE__ */ e(W, {}), onError: () => {
  t();
}, children: /* @__PURE__ */ e(Ke, { fallback: /* @__PURE__ */ e(X.Spinner, { active: !0 }), children: /* @__PURE__ */ e(uo, { ...a, gotoTeamMemberTokenExpired: t }) }) }), Le = {
  companyName: "companyName",
  teamName: "teamName",
  adminName: "adminName",
  phoneNumber: "phoneNumber"
}, ho = (t) => Be.object(
  {
    companyName: Be.string().max(150, { message: t.formControls.errors.maxLength({ length: 150 }) }),
    teamName: Be.string().max(30, { message: t.formControls.errors.maxLength({ length: 30 }) }).nonempty({
      message: t.formControls.errors.empty({ fieldName: t.organizationProfile.teamNameLabel() })
    }),
    adminName: Be.string().max(30, { message: t.formControls.errors.maxLength({ length: 30 }) }).nonempty({
      message: t.formControls.errors.empty({ fieldName: t.organizationProfile.adminNameLabel() })
    }),
    phoneNumber: Be.object({
      number: Be.string(),
      validationResult: Be.enum(Ye.validationResults)
    }).refine(
      (a) => a.validationResult === "indeterminate" ? a.number !== "" : a.validationResult === "valid",
      (a) => ({
        message: a.validationResult === "empty" ? t.formControls.errors.empty({ fieldName: t.organizationProfile.phoneNumberLabel() }) : t.formControls.errors.invalidPhoneNumber()
      })
    )
  }
);
var zo = "d7jspf0 szap9z16", vo = "d7jspf1";
const yo = ({ initialValues: t, ...a }) => {
  const { LL: r } = S(), {
    register: i,
    handleSubmit: c,
    control: s,
    formState: o
  } = da({
    mode: "onChange",
    resolver: Ir(ho(r))
  }), { errors: l, isValid: h } = o, d = (z) => {
    const k = { ...z, phoneNumber: z.phoneNumber.number };
    a.onSubmit(k);
  }, m = (z) => l[z] !== void 0, u = {
    type: "submit",
    ...ce({ query: le.screen.desktop }) ? {} : { width: "full" }
  };
  return /* @__PURE__ */ n("form", { className: zo, onSubmit: (z) => void c(d)(z), children: [
    /* @__PURE__ */ n(j.Frame, { children: [
      /* @__PURE__ */ n(j.LabelFrame, { children: [
        /* @__PURE__ */ e(j.Label, { children: r.organizationProfile.companyNameLabel() }),
        /* @__PURE__ */ e(j.HelperText, { children: r.organizationProfile.companyNameHelperText() })
      ] }),
      /* @__PURE__ */ e(j.TextFieldRoot, { error: m(Le.companyName), children: /* @__PURE__ */ e(
        j.Input,
        {
          placeholder: r.organizationProfile.companyNamePlaceholder(),
          defaultValue: t.companyName,
          ...i(Le.companyName)
        }
      ) }),
      m(Le.companyName) ? /* @__PURE__ */ e(j.ErrorText, { children: l.companyName?.message }) : null
    ] }),
    /* @__PURE__ */ n(j.Frame, { children: [
      /* @__PURE__ */ n(j.LabelFrame, { children: [
        /* @__PURE__ */ n(j.Label, { children: [
          r.organizationProfile.teamNameLabel(),
          /* @__PURE__ */ e(j.AttentionText, { children: "*" })
        ] }),
        /* @__PURE__ */ e(j.HelperText, { children: r.organizationProfile.teamNameHelperText() })
      ] }),
      /* @__PURE__ */ e(j.TextFieldRoot, { error: m(Le.teamName), children: /* @__PURE__ */ e(
        j.Input,
        {
          placeholder: r.organizationProfile.teamNamePlaceholder(),
          defaultValue: t.teamName,
          ...i(Le.teamName)
        }
      ) }),
      m(Le.teamName) ? /* @__PURE__ */ e(j.ErrorText, { children: l.teamName?.message }) : null
    ] }),
    /* @__PURE__ */ n(j.Frame, { children: [
      /* @__PURE__ */ e(j.LabelFrame, { children: /* @__PURE__ */ n(j.Label, { children: [
        r.organizationProfile.adminNameLabel(),
        /* @__PURE__ */ e(j.AttentionText, { children: "*" })
      ] }) }),
      /* @__PURE__ */ e(j.TextFieldRoot, { error: m(Le.adminName), children: /* @__PURE__ */ e(
        j.Input,
        {
          placeholder: r.organizationProfile.adminNamePlaceholder(),
          defaultValue: t.adminName,
          ...i(Le.adminName)
        }
      ) }),
      m(Le.adminName) ? /* @__PURE__ */ e(j.ErrorText, { children: l.adminName?.message }) : null
    ] }),
    /* @__PURE__ */ e(
      Fr,
      {
        name: Le.phoneNumber,
        defaultValue: { number: t.phoneNumber, validationResult: "indeterminate" },
        control: s,
        render: ({ field: z }) => /* @__PURE__ */ n(Ye.Frame, { children: [
          /* @__PURE__ */ e(Ye.LabelFrame, { children: /* @__PURE__ */ n(Ye.Label, { children: [
            r.organizationProfile.phoneNumberLabel(),
            /* @__PURE__ */ e(j.AttentionText, { children: "*" })
          ] }) }),
          /* @__PURE__ */ e(
            Ye.Input,
            {
              error: m(Le.phoneNumber),
              value: z.value,
              onChange: z.onChange
            }
          ),
          m(Le.phoneNumber) ? /* @__PURE__ */ e(Ye.ErrorText, { children: l.phoneNumber?.message }) : null
        ] })
      }
    ),
    /* @__PURE__ */ e("div", { className: vo, children: /* @__PURE__ */ e(p.Button, { ...u, disabled: !h, children: /* @__PURE__ */ e(p.Text, { children: r.organizationProfile.submitButton() }) }) })
  ] });
};
var bo = "szap9z5i";
const go = (t) => {
  const { LL: a } = S(), r = v.useSnackbar(), i = Je(), c = ["teams", t.teamId], { status: s, data: o } = Pt({
    queryFn: async () => t.fetchTeamOrganizationProfile(t.teamId),
    queryKey: c
  }), { mutate: l, status: h } = fe({
    mutationFn: async (d) => {
      await t.updateTeamOrganizationProfile(t.teamId, d), i.setQueryData(c, d);
    },
    onSuccess: () => {
      r.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: a.organizationProfile.snackbarMessageOnSaved() }) })
      );
    },
    onError: () => {
      r.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: a.organizationProfile.snackbarMessageOnError() }) })
      );
    }
  });
  switch (s) {
    case "pending":
      return /* @__PURE__ */ e(X.Spinner, { active: !0 });
    case "error":
      return null;
    case "success":
      return /* @__PURE__ */ n(wt, { children: [
        /* @__PURE__ */ n(St, { children: [
          /* @__PURE__ */ e(Et, { onClick: t.backToMenu }),
          /* @__PURE__ */ e(_t, { children: a.organizationProfile.title() })
        ] }),
        /* @__PURE__ */ n(Rt, { children: [
          /* @__PURE__ */ e("p", { className: bo, children: a.organizationProfile.description() }),
          /* @__PURE__ */ e(
            yo,
            {
              initialValues: o,
              onSubmit: l
            }
          )
        ] }),
        h === "pending" ? /* @__PURE__ */ e(X.Spinner, { active: !0 }) : null
      ] });
  }
};
var fo = "_12eriwf0";
const To = ({ hasPaymentInfo: t, teamId: a, createStripeCustomerPortalLink: r, sendSignal: i, backToMenu: c }) => {
  const s = v.useSnackbar(), { LL: o } = S(), h = {
    type: "submit",
    ...ce({ query: le.screen.desktop }) ? {} : { width: "full" }
  }, d = fe({
    mutationFn: async () => (i("TEAM_PAYMENT_REACH_STRIPE_CUSTOMER_PORTAL", { teamId: a }), await r({ teamId: a })),
    onSuccess: (m) => window.open(m, "_blank"),
    onError: () => {
      s.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: o.paymentInfo.error() }) })
      );
    }
  });
  return /* @__PURE__ */ n(wt, { children: [
    /* @__PURE__ */ n(St, { children: [
      /* @__PURE__ */ e(Et, { onClick: c }),
      /* @__PURE__ */ e(_t, { children: o.paymentInfo.title() })
    ] }),
    /* @__PURE__ */ n(Rt, { children: [
      /* @__PURE__ */ e(X.Spinner, { active: d.status === "pending" }),
      t ? /* @__PURE__ */ n(W, { children: [
        /* @__PURE__ */ e("p", { className: fo, children: o.paymentInfo.description() }),
        /* @__PURE__ */ e(
          p.Button,
          {
            ...h,
            disabled: d.status === "pending",
            onClick: () => d.mutate(),
            children: /* @__PURE__ */ e(p.Text, { children: o.paymentInfo.checkButton() })
          }
        )
      ] }) : /* @__PURE__ */ e("p", { children: o.paymentInfo.noBillingDescription() })
    ] })
  ] });
}, ua = 100;
var Co = "_1tic7qa3", ko = "_1tic7qa4 szap9z2e szap9z30", Lo = "qvn8ok0", No = "qvn8ok1", xo = "_1tic7qa8 szap9z2e szap9z30 szap9z3m szap9z48 szap9z4s szap9z4 szap9z14 szap9z20 szap9z78 qvn8ok1", Do = "_1tic7qab szap9z14 szap9z20 szap9z74 qvn8ok1 szap9zk", Io = "_1tic7qac", Fo = "_1tic7qa9", Mo = "qvn8ok2 szap9zi", Po = "_1tic7qa0 qvn8okm szap9z12 szap9z20 szap9zq szap9zk", wo = "_1tic7qa7 qvn8ok0 szap9zk", So = "_1tic7qa5 szap9z14 szap9z20 szap9z1u szap9z7c", Eo = "_1tic7qa6";
const kt = 10, _o = (t) => {
  const { LL: a } = S(), r = ce({ query: le.screen.mobile }), { providerResources: i, registeredResources: c } = t, [s, o] = V(/* @__PURE__ */ new Set()), l = i.map((u) => {
    const z = u.type === "google" ? `google_${u.resourceId}` : `microsoft_${u.id}`;
    return {
      ...u,
      providerResourceKey: z,
      checked: s.has(z),
      failedToCreate: t.failedToCreateResources.some((k) => {
        const L = u.type === "google" ? u.resourceId : u.id;
        return k.type === u.type && k.originResourceId === L;
      }),
      disabled: c.some((k) => {
        const L = u.type === "google" ? u.resourceId : u.id;
        return k.originResource.type === u.type && k.originResource.id === L;
      })
    };
  }), h = s.size + c.length > ua ? a.resourceList.overMaxRegisterValidationError({ max: ua }) : s.size > kt ? a.resourceList.overMaxSelectingValidationError({ max: kt }) : null, d = s.size > 0 && h === null && l.filter((u) => u.checked && u.failedToCreate).length === 0, m = (u) => {
    u.disabled || o((z) => (u.checked ? z.delete(u.providerResourceKey) : z.add(u.providerResourceKey), new Set(z)));
  }, D = () => {
    const u = l.filter((z) => s.has(z.providerResourceKey));
    t.onSave(u);
  };
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ n(De.Header, { children: [
      /* @__PURE__ */ e(De.Title, { children: a.resourceList.providerResourceListSheetTitle() }),
      /* @__PURE__ */ e(De.DefaultClose, {}),
      /* @__PURE__ */ e(De.Description, { children: i.length === 0 ? a.resourceList.emptyResourceList() : c.length === i.length ? a.resourceList.fullSelectedHelperText() : a.resourceList.waitingSelectHelperText({ max: kt }) }),
      t.failedToCreateResources.length > 0 ? /* @__PURE__ */ n("div", { className: xo, "aria-label": "failed-to-create error helper", children: [
        /* @__PURE__ */ e(x.Info, { size: 24, className: Fo }),
        /* @__PURE__ */ e("p", { className: Mo, children: a.resourceList.failedToCreateHelperText() })
      ] }) : null
    ] }),
    /* @__PURE__ */ e(De.Body, { children: i.length === 0 ? null : /* @__PURE__ */ n(W, { children: [
      /* @__PURE__ */ e("div", { className: Po, children: h !== null ? /* @__PURE__ */ e("span", { className: No, children: h }) : /* @__PURE__ */ e("span", { className: Lo, children: a.resourceList.counter({ count: s.size, max: kt }) }) }),
      /* @__PURE__ */ e("ul", { className: Co, children: l.map((u) => /* @__PURE__ */ e(
        "li",
        {
          className: ko,
          children: /* @__PURE__ */ n(Yt.Frame, { children: [
            /* @__PURE__ */ e(
              Yt.Checkbox,
              {
                disabled: u.disabled,
                checked: u.disabled ? !0 : u.checked,
                onCheckedChange: () => m(u)
              }
            ),
            /* @__PURE__ */ e(Yt.Label, { "data-component-name": "provider-resource-checkbox-label", children: /* @__PURE__ */ n("span", { className: So, children: [
              /* @__PURE__ */ e("span", { className: Eo, children: u.type === "google" ? u.resourceName : u.displayName }),
              u.failedToCreate ? /* @__PURE__ */ e(_.Provider, { children: /* @__PURE__ */ n(_.Root, { children: [
                /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ n("span", { className: Do, children: [
                  /* @__PURE__ */ e(x.Info, { size: 16 }),
                  a.resourceList.failedToCreateResourceText()
                ] }) }),
                /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(
                  _.Content,
                  {
                    "data-component-name": "provider-resource-tooltip",
                    side: "bottom",
                    align: "end",
                    children: /* @__PURE__ */ e("span", { className: Io, children: a.resourceList.failedToCreateResourceToolTip() })
                  }
                ) })
              ] }) }) : u.disabled ? /* @__PURE__ */ e("span", { className: wo, children: a.resourceList.registeredResourceText() }) : null
            ] }) })
          ] })
        },
        u.providerResourceKey
      )) })
    ] }) }),
    /* @__PURE__ */ n(De.Footer, { sticky: !r, children: [
      /* @__PURE__ */ e(De.Close, { children: /* @__PURE__ */ e(De.FooterActionButton, { buttonType: "secondary", children: /* @__PURE__ */ e(p.Text, { children: a.resourceList.cancelButton() }) }) }),
      /* @__PURE__ */ e(De.FooterActionButton, { disabled: !d, onClick: D, children: /* @__PURE__ */ e(p.Text, { children: a.resourceList.saveButton() }) })
    ] })
  ] });
}, Ro = (t) => {
  const { state: a, registeredResources: r, failedToCreateResources: i, onSave: c } = t, s = (o) => {
    o || t.onClose();
  };
  return /* @__PURE__ */ e(De.Root, { open: a.state === "open", onOpenChange: s, children: /* @__PURE__ */ e(De.Content, { "data-component-name": "provider-resource-list-sheet", children: a.state === "open" ? /* @__PURE__ */ e(
    _o,
    {
      registeredResources: r,
      failedToCreateResources: i,
      providerResources: a.providerResources,
      onSave: c
    }
  ) : null }) });
};
var qo = "x2dsuwf szap9z12 szap9z20 szap9z1o qvn8ok3 szap9zg", Bo = "x2dsuwe", Ao = "x2dsuwd szap9z14 szap9z20 szap9z1o qvn8ok8", Va = "x2dsuw9 szap9zg szap9zj", Oo = "qvn8ok1", $o = "szap9z3o szap9z4a szap9z32 szap9z16 szap9z7a szap9z1e", Vo = "x2dsuwk szap9z32 szap9z12 szap9z20 szap9z1q", Ho = "szap9zi szap9z14 szap9z20 szap9z76", jo = "szap9z12 szap9z1e szap9z7e szap9z7d szap9z1u szap9z34", Uo = "x2dsuw2 szap9zj szap9zg", Go = "x2dsuw0", Yo = "szap9z5g szap9zi", Ha = "x2dsuwh szap9z66 szap9zi", Ko = "x2dsuwc szap9z14 szap9z1c szap9z20 szap9z78", Jo = "szap9z12 szap9z20 szap9z76", Wo = "szap9z14 szap9z11 szap9z20 szap9z76", ja = "x2dsuw6 x2dsuw4", Ua = "x2dsuw5 x2dsuw4", Qo = "x2dsuw3 szap9z16", ta = "x2dsuw8 szap9z14 szap9zg szap9z20", aa = "x2dsuw7 szap9zk szap9zq", Xo = "x2dsuwi szap9zi";
const Zo = ({ name: t, url: a }) => /* @__PURE__ */ n(Pe.Root, { className: Ao, children: [
  /* @__PURE__ */ e(Pe.Image, { className: Bo, src: a, alt: t }),
  /* @__PURE__ */ e(Pe.Fallback, { className: qo, delayMs: 600, children: t === void 0 ? null : t[0] })
] }), ec = ({ updateRegisterMember: t, deleteRoom: a }) => {
  const { LL: r } = S();
  return /* @__PURE__ */ n(N.Root, { children: [
    /* @__PURE__ */ e(N.Trigger, { children: /* @__PURE__ */ e(p.Button, { buttonType: "text", size: "small", "aria-label": "optionButton", children: /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.DotsHorizontal, {}) }) }) }),
    /* @__PURE__ */ n(N.Menu, { align: "end", children: [
      /* @__PURE__ */ e(N.MenuItem, { onSelect: t, children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { children: r.resourceList.listOptionChangeRegisterMember() }) }) }),
      /* @__PURE__ */ e(N.MenuItem, { onSelect: a, children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { className: Oo, children: r.resourceList.listOptionDeleteRoom() }) }) })
    ] })
  ] });
}, tc = (t) => {
  const { LL: a } = S(), r = t.rooms.map((i) => {
    const c = t.teamMembers.find((s) => s.id === i.registerMemberId);
    return {
      room: i,
      registerMember: c
    };
  });
  return /* @__PURE__ */ e(W, { children: /* @__PURE__ */ e("section", { children: t.rooms.length === 0 ? /* @__PURE__ */ e("p", {}) : /* @__PURE__ */ e(_.Provider, { children: /* @__PURE__ */ n("div", { className: Qo, role: "table", "aria-label": "Registered resource list", children: [
    /* @__PURE__ */ e("div", { role: "rowgroup", className: Ua, children: /* @__PURE__ */ n("div", { role: "row", className: ja, children: [
      /* @__PURE__ */ e("div", { className: aa, role: "columnheader", children: a.resourceList.listHeaderResourceName() }),
      /* @__PURE__ */ e("div", { className: aa, role: "columnheader", children: /* @__PURE__ */ n("div", { className: Jo, children: [
        a.resourceList.listHeaderRegisterMember(),
        /* @__PURE__ */ n(_.Root, { children: [
          /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ e("span", { className: Wo, children: /* @__PURE__ */ e(x.Help, { size: 16 }) }) }),
          /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(_.Content, { collisionPadding: 24, children: a.resourceList.listHeaderRegisterMemberHelp() }) })
        ] })
      ] }) }),
      /* @__PURE__ */ e("div", { className: aa, role: "columnheader" })
    ] }) }),
    /* @__PURE__ */ e("div", { role: "rowgroup", "aria-label": "resource-list-body", className: Ua, children: r.map((i) => /* @__PURE__ */ n("div", { role: "row", className: ja, children: [
      /* @__PURE__ */ e("div", { role: "cell", className: ta, children: /* @__PURE__ */ e("span", { className: Va, children: i.room.name }) }),
      /* @__PURE__ */ e("div", { role: "cell", className: ta, children: i.registerMember === void 0 ? null : /* @__PURE__ */ n("div", { className: Ko, children: [
        /* @__PURE__ */ e(Zo, { name: i.registerMember.name, url: i.registerMember.photoUrl }),
        /* @__PURE__ */ e("span", { className: Va, children: i.registerMember.name })
      ] }) }),
      /* @__PURE__ */ e("div", { role: "cell", className: ta, children: /* @__PURE__ */ e(
        ec,
        {
          updateRegisterMember: () => t.onUpdateRegisterMember({ roomId: i.room.id }),
          deleteRoom: () => t.onDeleteRoom({ roomId: i.room.id })
        }
      ) })
    ] }, i.room.id)) })
  ] }) }) }) });
};
class Lt extends Error {
}
class Ga extends Error {
  constructor(a) {
    super(), this.failedToCreateResources = a;
  }
}
const ac = (t) => {
  const a = ce({ query: le.screen.mobile }), { LL: r } = S(), i = Je(), c = v.useSnackbar(), [s, o] = V({ type: "none" }), [l, h] = V({
    state: "closed"
  }), [d, m] = V([]), D = ["providerResourceList", t.teamId, t.accountId], { isFetching: u, refetch: z } = Pt({
    queryKey: D,
    enabled: !1,
    retry: !1,
    queryFn: async () => {
      const T = await t.fetchProviderResources({ accountId: t.accountId });
      if (T.type === "error")
        throw T.code === "authorityInsufficient" ? new Lt() : new Error("unexpected");
      return T.providerResources;
    }
  }), k = async () => {
    try {
      const { data: T, isSuccess: C } = await z({ cancelRefetch: !0, throwOnError: !0 });
      if (!C)
        throw new Error("error");
      h({ state: "open", providerResources: T });
    } catch (T) {
      if (T instanceof Lt) {
        o({ type: "authorityInsufficient" });
        return;
      }
      c.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: r.resourceList.unknownError() }) })
      );
    }
  }, L = ["registeredResourceList", t.teamId], { data: R } = Ie({
    queryFn: async () => {
      const T = await t.fetchRooms({ teamId: t.teamId });
      if (T.type === "error")
        throw new Error(T.code);
      return {
        rooms: T.rooms
      };
    },
    queryKey: L,
    retry: !1
  }), { mutate: M, isPending: H } = fe({
    mutationFn: async (T) => {
      const C = T.providerResources.map((q) => q.type === "google" ? { originResourceId: q.resourceId, type: "google" } : { originResourceId: q.id, type: "microsoft" }), b = await t.saveRooms({ teamId: t.teamId, rooms: C });
      if (b.type === "error")
        switch (b.error.code) {
          case "failedToCreate":
            throw new Ga(b.error.details);
          default:
            throw new Error("error");
        }
      return { rooms: b.rooms, providerResources: T.providerResources };
    },
    onError: (T) => {
      if (T instanceof Ga) {
        m(T.failedToCreateResources), c.create(
          /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: r.resourceList.failedToCreateError() }) })
        );
        return;
      }
      c.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: r.resourceList.unknownError() }) })
      ), h({ state: "closed" });
    },
    onSuccess: (T) => {
      i.setQueryData(L, {
        rooms: R.rooms.concat(T.rooms)
      }), h({ state: "closed" });
    }
  }), { mutate: f, isPending: P } = fe({
    mutationFn: async (T) => {
      if ((await t.deleteRoom({ teamId: t.teamId, roomId: T.roomId })).type === "error")
        throw new Error("error");
      return { deletedRoomId: T.roomId };
    },
    onError: () => {
      c.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: r.resourceList.failedToDeleteRoom() }) })
      );
    },
    onSuccess: (T) => {
      i.setQueryData(L, {
        rooms: R.rooms.filter((C) => C.id !== T.deletedRoomId)
      }), c.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: r.resourceList.successToDeleteRoom() }) })
      );
    }
  }), { mutate: F, isPending: O } = fe({
    mutationFn: async (T) => {
      const C = await t.updateRegisterMember({
        teamId: t.teamId,
        room: { id: T.roomId, registerMemberId: t.myTeamMember.id }
      });
      if (C.type === "error")
        throw C.code === "authorityInsufficient" ? new Lt() : new Error("unexpected");
      return C.room;
    },
    onError: (T) => {
      T instanceof Lt ? o({ type: "authorityInsufficientWhenUpdating" }) : c.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: r.resourceList.unknownError() }) })
      );
    },
    onSuccess: (T) => {
      i.setQueryData(L, {
        rooms: R.rooms.map((C) => C.id === T.id ? T : C)
      }), c.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: r.resourceList.successToChangeRegisterMember() }) })
      );
    }
  }), Q = (T) => {
    m([]), M({ providerResources: T });
  }, $ = (T) => {
    o({ type: "roomDelete", onDelete: () => f({ roomId: T.roomId }) });
  }, te = (T) => {
    F({ roomId: T.roomId });
  }, w = a ? { width: "full", size: "medium" } : { width: "fitContent", size: "medium" };
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ e(
      X.Spinner,
      {
        active: H || u || O || P,
        className: Go
      }
    ),
    /* @__PURE__ */ n(wt, { children: [
      /* @__PURE__ */ n(St, { children: [
        /* @__PURE__ */ e(Et, { onClick: t.backToMenu }),
        /* @__PURE__ */ e(_t, { children: r.resourceList.title() }),
        a ? null : /* @__PURE__ */ e(ti, { children: /* @__PURE__ */ e(p.Button, { ...w, onClick: () => void k(), children: /* @__PURE__ */ e(p.Text, { children: r.resourceList.addResourceButton() }) }) })
      ] }),
      /* @__PURE__ */ n(Rt, { children: [
        /* @__PURE__ */ n("div", { className: jo, children: [
          /* @__PURE__ */ e("div", { className: Uo, children: /* @__PURE__ */ e("p", { children: r.resourceList.aboutThisPage({ max: ua }) }) }),
          a ? /* @__PURE__ */ e(p.Button, { ...w, onClick: () => void k(), children: /* @__PURE__ */ e(p.Text, { children: r.resourceList.addResourceButton() }) }) : null
        ] }),
        /* @__PURE__ */ e(
          tc,
          {
            rooms: R.rooms,
            teamMembers: t.teamMembers,
            onUpdateRegisterMember: te,
            onDeleteRoom: $
          }
        )
      ] }),
      /* @__PURE__ */ e(
        Ro,
        {
          registeredResources: R.rooms,
          failedToCreateResources: d,
          state: l,
          onSave: Q,
          onClose: () => {
            m([]), h({ state: "closed" });
          }
        }
      ),
      /* @__PURE__ */ e(g.Root, { open: s.type !== "none", onOpenChange: (T) => o(T ? s : { type: "none" }), children: s.type === "authorityInsufficient" || s.type === "authorityInsufficientWhenUpdating" ? /* @__PURE__ */ n(g.Content, { children: [
        /* @__PURE__ */ n(g.Header, { children: [
          /* @__PURE__ */ e(g.Title, { children: s.type === "authorityInsufficient" ? r.resourceList.authorityInsufficientHeaderForRegistering() : r.resourceList.authorityInsufficientHeaderForUpdating() }),
          /* @__PURE__ */ e(g.DefaultClose, {})
        ] }),
        /* @__PURE__ */ n(g.Body, { children: [
          /* @__PURE__ */ e("p", { className: Yo, children: r.resourceList.providerResourceGrantDescription() }),
          /* @__PURE__ */ n("ol", { children: [
            /* @__PURE__ */ e("li", { className: Ha, children: r.resourceList.providerResourceGrantDescriptionListItem1() }),
            /* @__PURE__ */ e("li", { className: Ha, children: r.resourceList.providerResourceGrantDescriptionListItem2() })
          ] }),
          /* @__PURE__ */ e("div", { className: Vo, children: r.resourceList.helpLinkText() !== "" && r.resourceList.helpLinkUrl() !== "" ? /* @__PURE__ */ e(cn, { href: r.resourceList.helpLinkUrl(), target: "_blank", children: /* @__PURE__ */ n("span", { className: Ho, children: [
            r.resourceList.helpLinkText(),
            /* @__PURE__ */ e(x.OpenInNew, { size: 16 })
          ] }) }) : null })
        ] }),
        /* @__PURE__ */ n("div", { className: $o, children: [
          /* @__PURE__ */ n(
            p.Button,
            {
              buttonType: "secondary",
              width: "full",
              onClick: t.openGoogleProviderResourceGrantPage,
              children: [
                /* @__PURE__ */ e(x.Google, {}),
                /* @__PURE__ */ e(p.Text, { children: r.resourceList.openGoogleProviderResourceGrantPageButton() })
              ]
            }
          ),
          /* @__PURE__ */ n(
            p.Button,
            {
              buttonType: "secondary",
              width: "full",
              onClick: t.openMicrosoftProviderResourceGrantPage,
              children: [
                /* @__PURE__ */ e(x.Microsoft, {}),
                /* @__PURE__ */ e(p.Text, { children: r.resourceList.openMicrosoftProviderResourceGrantPageButton() })
              ]
            }
          )
        ] })
      ] }) : s.type === "roomDelete" ? /* @__PURE__ */ n(g.Content, { children: [
        /* @__PURE__ */ n(g.Header, { children: [
          /* @__PURE__ */ e(g.Title, { children: r.resourceList.roomDeleteHeader() }),
          /* @__PURE__ */ e(g.DefaultClose, {})
        ] }),
        /* @__PURE__ */ e(g.Body, { children: /* @__PURE__ */ e("p", { className: Xo, children: r.resourceList.roomDeleteDescription() }) }),
        /* @__PURE__ */ n(g.Footer, { children: [
          /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "secondary", onClick: () => o({ type: "none" }), children: /* @__PURE__ */ e(p.Text, { children: r.resourceList.roomDeleteCancelButton() }) }),
          /* @__PURE__ */ e(
            g.FooterActionButton,
            {
              buttonType: "danger",
              onClick: () => {
                o({ type: "none" }), s.onDelete();
              },
              children: /* @__PURE__ */ e(p.Text, { children: r.resourceList.roomDeleteConfirmButton() })
            }
          )
        ] })
      ] }) : null })
    ] })
  ] });
}, nc = (t) => {
  const { LL: a } = S(), r = v.useSnackbar();
  return /* @__PURE__ */ e(dt, { fallback: /* @__PURE__ */ e(W, {}), onError: () => {
    r.create(
      /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: a.resourceList.unknownError() }) })
    ), t.gotoUnavailablePage();
  }, children: /* @__PURE__ */ e(Ke, { fallback: /* @__PURE__ */ e(X.Spinner, { active: !0 }), children: /* @__PURE__ */ e(ac, { ...t }) }) });
}, rc = ({ teamMemberEmails: t, ...a }) => {
  const { LL: r } = S(), { field: i, formState: c } = Ve(a), [s, o] = V(!1), [l, h] = V(""), d = A(
    () => (c.defaultValues?.attendees ?? []).filter((z) => z !== void 0),
    [c.defaultValues?.attendees]
  ), m = A(() => {
    const z = new RegExp(l, "i");
    return Array.from(/* @__PURE__ */ new Set([...d, ...t])).filter((L) => z.test(L));
  }, [t, l, d]), D = (z) => {
    i.onChange(z);
  }, u = new RegExp(/^\S+@\S+\.\S+$/);
  return /* @__PURE__ */ e(
    ke.ComboboxRoot,
    {
      items: m,
      closeOnSelect: !0,
      name: i.name,
      value: i.value,
      onValueChange: ({ value: z }) => D(z),
      open: s,
      onOpenChange: (z) => o(z.open),
      openOnClick: !0,
      inputValue: l,
      onInputValueChange: (z) => h(z.inputValue),
      children: /* @__PURE__ */ n(
        ke.TagsInputRoot,
        {
          editable: !1,
          value: i.value,
          validate: ({ inputValue: z }) => u.test(z) && !i.value.includes(z),
          onValueChange: ({ value: z }) => D(z),
          children: [
            /* @__PURE__ */ n(ke.Frame, { children: [
              /* @__PURE__ */ e(ke.LabelFrame, { children: /* @__PURE__ */ e(ke.Label, { children: r.teamTemplateConfirmedEventEditByMember.attendee.label() }) }),
              /* @__PURE__ */ e(ke.TagsInputContext, { children: ({ value: z }) => /* @__PURE__ */ n(ke.TagsInputControl, { children: [
                z.map((k, L) => /* @__PURE__ */ e(ke.TagsInputItem, { index: L, value: k, children: /* @__PURE__ */ n(ke.TagsInputItemPreview, { children: [
                  /* @__PURE__ */ e(ke.TagsInputItemText, { children: k }),
                  /* @__PURE__ */ e(ke.TagsInputItemDeleteTrigger, {})
                ] }) }, k)),
                /* @__PURE__ */ e(
                  ke.Input,
                  {
                    style: { maxWidth: "100%", minWidth: "auto" },
                    placeholder: r.teamTemplateConfirmedEventEditByMember.attendee.placeholder(),
                    onKeyDown: (k) => {
                      k.key === "Enter" && m.length === 0 && u.test(l) && !i.value.includes(l) && (D([...i.value, l]), h(""));
                    }
                  }
                )
              ] }) })
            ] }),
            /* @__PURE__ */ e(B.Portal, { children: /* @__PURE__ */ e(B.Positioner, { children: /* @__PURE__ */ e(B.Content, { width: "triggerWidth", children: m.length === 0 ? /* @__PURE__ */ e(
              B.Item,
              {
                item: { value: r.teamTemplateConfirmedEventEditByMember.attendee.noResults(), disabled: !0 },
                children: /* @__PURE__ */ e(B.TextFrame, { children: /* @__PURE__ */ n(B.ItemText, { children: [
                  r.teamTemplateConfirmedEventEditByMember.attendee.noResults(),
                  /* @__PURE__ */ e("br", {}),
                  r.teamTemplateConfirmedEventEditByMember.attendee.attendeeNoResultInfo()
                ] }) })
              }
            ) : m.map((z) => /* @__PURE__ */ e(B.Item, { item: z, children: /* @__PURE__ */ e(B.TextFrame, { children: /* @__PURE__ */ e(B.ItemText, { children: z }) }) }, z)) }) }) })
          ]
        }
      )
    }
  );
};
var ic = "_1aql8n62", lc = "_1aql8n64 szap9z16 szap9z78", sc = "_1aql8n60 qvn8okh", oc = "szap9zk szap9zq szap9z5e", cc = "szap9z18 szap9zm szap9z5c szap9z4s";
const dc = ({ children: t }) => {
  const a = lt(), { LL: r } = S();
  return /* @__PURE__ */ n("fieldset", { "aria-labelledby": a, className: sc, children: [
    /* @__PURE__ */ e("legend", { id: a, className: oc, children: r.teamTemplateConfirmedEventEditByMember.duration.label() }),
    t
  ] });
}, mc = ({ label: t, onChange: a, ...r }) => {
  const { field: i } = Ve({ ...r }), c = (s) => {
    s !== null && a(s.toString());
  };
  return /* @__PURE__ */ n(
    vn,
    {
      value: mn(i.value),
      name: i.name,
      onChange: (s) => c(s),
      children: [
        /* @__PURE__ */ e(yn, { children: t }),
        /* @__PURE__ */ n(bn, { children: [
          /* @__PURE__ */ e(gn, { children: (s) => /* @__PURE__ */ e(fn, { segment: s }) }),
          /* @__PURE__ */ e(Tn, {})
        ] }),
        /* @__PURE__ */ e(Cn, {})
      ]
    }
  );
}, uc = ({ label: t, displayDate: a, onChange: r, ...i }) => {
  const { field: c } = Ve({ ...i }), s = mn(a), o = (l) => {
    l !== null && r(l.toString());
  };
  return /* @__PURE__ */ n(
    vn,
    {
      value: s,
      name: c.name,
      onChange: (l) => o(l),
      children: [
        /* @__PURE__ */ e(yn, { children: t }),
        /* @__PURE__ */ n(bn, { children: [
          /* @__PURE__ */ e(gn, { children: (l) => /* @__PURE__ */ e(fn, { segment: l }) }),
          /* @__PURE__ */ e(Tn, {})
        ] }),
        /* @__PURE__ */ e(Cn, {})
      ]
    }
  );
}, Ya = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45"
], Ka = ({ label: t, onChange: a, ...r }) => {
  const { field: i } = Ve(r), [c, s] = V(i.value);
  ne(() => {
    s(i.value);
  }, [i.value]);
  const o = A(() => {
    const d = new RegExp(c ?? "");
    return c === i.value ? Ya : Ya.filter((m) => d.test(m));
  }, [c, i.value]), l = (d) => {
    const m = d[0];
    m !== void 0 && (s(m), a(m));
  }, h = (d) => {
    s(d);
  };
  return /* @__PURE__ */ n(
    B.Root,
    {
      selectionBehavior: "preserve",
      name: i.name,
      inputValue: c,
      value: [i.value],
      disabled: i.disabled ?? !1,
      openOnClick: !0,
      items: o,
      onFocusOutside: () => {
        s(i.value);
      },
      onValueChange: ({ value: d }) => l(d),
      onInputValueChange: (d) => {
        h(d.inputValue);
      },
      children: [
        /* @__PURE__ */ n(B.Frame, { children: [
          /* @__PURE__ */ e(B.LabelFrame, { children: /* @__PURE__ */ e(B.Label, { size: "small", children: t }) }),
          /* @__PURE__ */ e(B.Control, { size: "small", children: /* @__PURE__ */ e(B.Input, {}) })
        ] }),
        /* @__PURE__ */ e(B.Portal, { children: /* @__PURE__ */ e(B.Positioner, { children: /* @__PURE__ */ e(B.Content, { className: ic, children: o.map((d) => /* @__PURE__ */ e(B.Item, { item: d, size: "small", children: /* @__PURE__ */ e(B.TextFrame, { children: /* @__PURE__ */ e(B.ItemText, { children: d }) }) }, d)) }) }) })
      ]
    }
  );
}, pc = ({ onChange: t, ...a }) => {
  const { field: r } = Ve(a), { LL: i } = S();
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ e("label", { htmlFor: "default", className: cc, children: i.teamTemplateConfirmedEventEditByMember.duration.allDay() }),
    /* @__PURE__ */ e(va.Frame, { children: /* @__PURE__ */ e(va.Switch, { id: "default", checked: r.value, onCheckedChange: () => t(!r.value) }) })
  ] });
}, hc = ({ initialDuration: t, timezone: a, displayEndDate: r, updateDisplayEndDate: i }) => {
  const { LL: c } = S(), { control: s, setValue: o, getValues: l } = Mr(), h = (z) => {
    o("startDate", z, { shouldDirty: !0 });
    const k = l("startTime"), L = _e(`${z}T${k}[${a}]`), R = l("endDate"), M = l("endTime"), H = _e(`${R}T${M}[${a}]`), f = L.compare(H);
    if (f === 0 || f < 0)
      return;
    const P = L.add({ minutes: t }), F = J.tz(P.toDate(), a);
    o("endDate", F.format("YYYY-MM-DD"), { shouldDirty: !0 }), o("endTime", F.format("HH:mm"), { shouldDirty: !0 }), i({
      startDateTime: J.tz(L.toDate(), a),
      endDateTime: F,
      allDay: l("allDay")
    });
  }, d = (z) => {
    o("startTime", z, { shouldDirty: !0 });
    const k = l("startDate"), L = _e(`${k}T${z}[${a}]`), R = l("endDate"), M = l("endTime"), H = _e(`${R}T${M}[${a}]`), f = L.compare(H);
    if (f === 0 || f < 0)
      return;
    const P = L.add({ minutes: t }), F = J.tz(P.toDate(), a);
    o("endDate", F.format("YYYY-MM-DD"), { shouldDirty: !0 }), o("endTime", F.format("HH:mm"), { shouldDirty: !0 }), i({
      startDateTime: J.tz(L.toDate(), a),
      endDateTime: F,
      allDay: l("allDay")
    });
  }, m = (z) => {
    const k = l("startTime"), L = l("startDate"), R = _e(`${L}T${k}[${a}]`), M = l("allDay"), H = l("endTime"), f = _e(`${z}T${H}[${a}]`), P = M && H === "00:00" ? f.add({ days: 1 }) : f;
    o("endDate", J.tz(P.toDate(), a).format("YYYY-MM-DD"), { shouldDirty: !0 });
    const F = P.compare(R);
    if (F === 0 || F > 0)
      i({
        startDateTime: J.tz(`${L} ${k}`, a),
        endDateTime: J.tz(P.toDate(), a),
        allDay: M
      });
    else {
      const O = P.subtract({ minutes: t }), Q = J.tz(O.toDate(), a);
      o("startDate", Q.format("YYYY-MM-DD"), { shouldDirty: !0 }), o("startTime", Q.format("HH:mm"), { shouldDirty: !0 }), i({
        startDateTime: Q,
        endDateTime: J.tz(P.toDate(), a),
        allDay: M
      });
    }
  }, D = (z) => {
    o("endTime", z, { shouldDirty: !0 });
    const k = l("startDate"), L = l("startTime"), R = _e(`${k}T${L}[${a}]`), M = l("endDate");
    i({
      startDateTime: J(R.toDate(), a),
      endDateTime: J(`${M} ${z}`, a),
      allDay: l("allDay")
    });
    const H = _e(`${M}T${z}[${a}]`), f = H.compare(R);
    if (f === 0 || f > 0)
      return;
    const P = H.subtract({ minutes: t }), F = J.tz(P.toDate(), a);
    o("startDate", F.format("YYYY-MM-DD"), { shouldDirty: !0 }), o("startTime", F.format("HH:mm"), { shouldDirty: !0 });
  }, u = (z) => {
    o("allDay", z, { shouldDirty: !0 });
    const k = l("startDate"), L = l("startTime"), R = l("endDate"), M = l("endTime");
    i({
      startDateTime: J(`${k} ${L}`, a),
      endDateTime: J(`${R} ${M}`, a),
      allDay: z
    });
  };
  return /* @__PURE__ */ n(dc, { children: [
    /* @__PURE__ */ n("div", { className: lc, children: [
      /* @__PURE__ */ e(
        mc,
        {
          label: c.teamTemplateConfirmedEventEditByMember.duration.startDate(),
          name: "startDate",
          control: s,
          onChange: h
        }
      ),
      l("allDay") === !0 ? /* @__PURE__ */ e("div", {}) : /* @__PURE__ */ e(
        Ka,
        {
          label: c.teamTemplateConfirmedEventEditByMember.duration.startTime(),
          name: "startTime",
          control: s,
          onChange: d
        }
      ),
      /* @__PURE__ */ e(
        uc,
        {
          displayDate: r,
          label: c.teamTemplateConfirmedEventEditByMember.duration.endDate(),
          name: "endDate",
          control: s,
          onChange: m
        }
      ),
      l("allDay") === !0 ? /* @__PURE__ */ e("div", {}) : /* @__PURE__ */ e(
        Ka,
        {
          label: c.teamTemplateConfirmedEventEditByMember.duration.endTime(),
          name: "endTime",
          control: s,
          onChange: D
        }
      )
    ] }),
    /* @__PURE__ */ e(pc, { control: s, name: "allDay", onChange: u })
  ] });
}, Ja = (t) => t.endDateTime.format("HH:mm") === "00:00" && !t.startDateTime.isSame(t.endDateTime, "day") && t.allDay ? t.endDateTime.subtract(1, "day").format("YYYY-MM-DD") : t.endDateTime.format("YYYY-MM-DD");
var zc = "i89ofz0 szap9z2e szap9z30 szap9z3m szap9z48 szap9z4", vc = "i89ofz2", Nt = "i89ofz3 szap9z28 szap9z2u szap9zk szap9z12 szap9z20 szap9z78", xt = "i89ofz4 szap9z16 qvn8ok0", yc = "szap9zk szap9zq szap9z5e";
const bc = ({ isDatePickerEnabled: t, summary: a, start: r, end: i, allDay: c, location: s, visibility: o, organizer: l, getDateTimeRange: h }) => {
  const { LL: d } = S(), m = A(() => {
    if (o === "confidential")
      return null;
    switch (o) {
      case "public":
        return d.teamTemplateConfirmedEventEditByMember.visibility.public();
      case "private":
        return d.teamTemplateConfirmedEventEditByMember.visibility.private();
      default:
        return d.teamTemplateConfirmedEventEditByMember.visibility.default();
    }
  }, [o, d.teamTemplateConfirmedEventEditByMember.visibility]);
  return /* @__PURE__ */ n("div", { className: zc, role: "region", "aria-label": "Event info", children: [
    a === void 0 ? null : /* @__PURE__ */ e("h2", { className: yc, children: a }),
    /* @__PURE__ */ e(_.Provider, { children: /* @__PURE__ */ n("ul", { className: vc, children: [
      t ? null : /* @__PURE__ */ n("li", { className: Nt, children: [
        /* @__PURE__ */ n(_.Root, { children: [
          /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ e("div", { className: xt, children: /* @__PURE__ */ e(x.Schedule, { size: 16 }) }) }),
          /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(_.Content, { side: "top", collisionPadding: 16, children: d.teamTemplateConfirmedEventEditByMember.eventDateTooltip() }) })
        ] }),
        h({ start: r, end: i, allDay: c })
      ] }),
      s === void 0 || s === "" ? null : /* @__PURE__ */ n("li", { className: Nt, children: [
        /* @__PURE__ */ n(_.Root, { children: [
          /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ e("div", { className: xt, children: /* @__PURE__ */ e(x.LocationOn, { size: 16 }) }) }),
          /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(_.Content, { side: "top", collisionPadding: 16, children: d.teamTemplateConfirmedEventEditByMember.locationTooltip() }) })
        ] }),
        s
      ] }),
      m === null ? null : /* @__PURE__ */ n("li", { className: Nt, children: [
        /* @__PURE__ */ n(_.Root, { children: [
          /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ e("div", { className: xt, children: /* @__PURE__ */ e(x.Visibility, { size: 16 }) }) }),
          /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(_.Content, { side: "top", collisionPadding: 16, children: d.teamTemplateConfirmedEventEditByMember.visibilityTooltip() }) })
        ] }),
        m
      ] }),
      l === void 0 ? null : /* @__PURE__ */ n("li", { className: Nt, children: [
        /* @__PURE__ */ n(_.Root, { children: [
          /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ e("div", { className: xt, children: /* @__PURE__ */ e(x.Person, { size: 16 }) }) }),
          /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(_.Content, { side: "top", collisionPadding: 16, children: d.teamTemplateConfirmedEventEditByMember.organizerTooltip() }) })
        ] }),
        l.displayName ?? l.email
      ] })
    ] }) })
  ] });
};
var gc = "_12xm0no1 szap9z3o szap9z4a szap9z2e szap9z30 qvn8oki szap9z2 szap9z4s szap9zi qvn8ok2 qvn8ok9", fc = "szap9zk szap9zq", Tc = "_12xm0no2";
const Cc = un.create({
  name: "microsoftTeams",
  content: "block*",
  group: "block",
  addAttributes() {
    return {
      id: {
        parseHTML: (t) => t.getAttribute("id")
      },
      class: {
        parseHTML: (t) => t.getAttribute("class")
      },
      style: {
        parseHTML: (t) => t.getAttribute("style")
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "div.me-email-text"
      }
    ];
  },
  renderHTML({ HTMLAttributes: t }) {
    return ["div", t, 0];
  }
}), kc = un.create({
  name: "spirInfo",
  content: "block+",
  group: "block",
  addAttributes() {
    return {
      spirInfo: {
        default: null
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "div[data-spir-info]",
        getAttrs: (t) => ({ spirInfo: t.getAttribute("data-spir-info") })
      }
    ];
  },
  renderHTML({ node: t }) {
    const a = t.attrs.spirInfo;
    return ["div", { "data-spir-info": typeof a == "string" ? a : "" }, 0];
  }
}), Lc = (t) => {
  const a = lt(), { LL: r } = S(), { field: i } = Ve(t), c = Er({
    editorProps: {
      attributes: {
        class: gc,
        id: a,
        role: "textbox"
      }
    },
    extensions: [
      Rr,
      Sr.configure({
        emptyEditorClass: Tc,
        placeholder: r.teamTemplateConfirmedEventEditByMember.memo.placeholder(),
        showOnlyCurrent: !0,
        showOnlyWhenEditable: !0
      }),
      wr,
      kc,
      Cc
    ],
    content: i.value,
    parseOptions: {
      preserveWhitespace: !1
    },
    onUpdate: ({ editor: s }) => {
      const o = s.isEmpty ? "" : s.getHTML();
      i.onChange(o);
    }
  });
  return /* @__PURE__ */ n("div", { children: [
    /* @__PURE__ */ e("label", { className: fc, htmlFor: a, onClick: () => c?.commands?.focus(), children: r.teamTemplateConfirmedEventEditByMember.memo.label() }),
    /* @__PURE__ */ e(_r, { editor: c })
  ] });
};
var Nc = "b5rfma4 szap9z2c szap9z2y szap9z16 szap9z20 szap9z1u", xc = "szap9z2a szap9z2w szap9zk qvn8ok1", Dc = "b5rfmaf szap9zk qvn8ok0 qvn8okh", Ic = "b5rfma0 qvn8okh", Fc = "b5rfmae", Mc = "szap9z14 szap9z76 szap9z20", Pc = "szap9zk szap9zq", wc = "b5rfma8", Sc = we({ defaultClassName: "b5rfma5 szap9zi", variantClassNames: { checked: { true: "qvn8ok2", false: "qvn8ok6" } }, defaultVariants: { checked: !0 }, compoundVariants: [] }), Ec = "szap9z14 szap9z20 szap9z1o qvn8ok0", _c = we({ defaultClassName: "b5rfmab szap9zk qvn8ok0", variantClassNames: { expanded: { true: "b5rfmac", false: "b5rfmad" } }, defaultVariants: { expanded: !1 }, compoundVariants: [] }), Rc = "b5rfmaa";
const qc = ({ url: t, ...a }) => {
  const { LL: r } = S(), { field: i, formState: c } = Ve(a), s = lt(), o = lt(), [l, h] = V(!1), [d, m] = V(!1), D = st(null), u = c.defaultValues?.onlineMeeting, z = () => {
    const L = i.value !== "none" ? "none" : u;
    i.onChange(L);
  }, k = A(() => {
    switch (u) {
      case "googleMeet":
        return r.teamTemplateConfirmedEventEditByMember.onlineMeeting.type.googleMeet();
      case "zoom":
        return r.teamTemplateConfirmedEventEditByMember.onlineMeeting.type.zoom();
      case "microsoftTeams":
        return r.teamTemplateConfirmedEventEditByMember.onlineMeeting.type.microsoftTeams();
      default:
        return "";
    }
  }, [r.teamTemplateConfirmedEventEditByMember.onlineMeeting.type, u]);
  return ne(() => {
    D.current !== null && (t === void 0 && m(!1), i.value === "none" && m(!1), m(D.current.scrollHeight > D.current.clientHeight));
  }, [i.value, t]), /* @__PURE__ */ e(_.Provider, { children: /* @__PURE__ */ n("fieldset", { className: Ic, children: [
    /* @__PURE__ */ n("legend", { className: Mc, children: [
      /* @__PURE__ */ e("span", { className: Pc, children: r.teamTemplateConfirmedEventEditByMember.onlineMeeting.label() }),
      /* @__PURE__ */ n(_.Root, { children: [
        /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ e("span", { className: Ec, children: /* @__PURE__ */ e(x.Help, { size: 16 }) }) }),
        /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(_.Content, { side: "top", collisionPadding: 16, children: /* @__PURE__ */ e("span", { children: r.teamTemplateConfirmedEventEditByMember.onlineMeeting.tooltip() }) }) })
      ] })
    ] }),
    /* @__PURE__ */ n("div", { className: Nc, children: [
      /* @__PURE__ */ e(
        "span",
        {
          className: Sc({ checked: i.value !== "none" }),
          children: k
        }
      ),
      /* @__PURE__ */ e("div", { className: Fc, children: /* @__PURE__ */ e(
        Ne.Button,
        {
          id: s,
          "aria-labelledby": o,
          type: "button",
          buttonType: "text",
          size: "large",
          role: "checkbox",
          "aria-checked": i.value !== "none",
          value: i.value,
          onClick: z,
          children: /* @__PURE__ */ e(Ne.Icon, { children: i.value === "none" ? /* @__PURE__ */ e(x.Undo, {}) : /* @__PURE__ */ e(x.Delete, {}) })
        }
      ) }),
      t === void 0 || i.value === "none" ? null : /* @__PURE__ */ n("div", { "data-part": "url", className: Rc, children: [
        /* @__PURE__ */ e("p", { ref: D, className: _c({ expanded: l }), children: t }),
        d ? /* @__PURE__ */ e("button", { type: "button", className: Dc, onClick: () => h((L) => !L), children: l ? r.teamTemplateConfirmedEventEditByMember.onlineMeeting.url.long() : r.teamTemplateConfirmedEventEditByMember.onlineMeeting.url.short() }) : null
      ] }),
      /* @__PURE__ */ e(
        "label",
        {
          id: o,
          htmlFor: s,
          className: wc,
          children: k
        }
      )
    ] }),
    i.value === "none" ? /* @__PURE__ */ e("span", { className: xc, children: r.teamTemplateConfirmedEventEditByMember.onlineMeeting.deleteWarning() }) : null
  ] }) });
};
var Mn = "_1xv3zog4", Pn = "_1xv3zog0 szap9z12 szap9z1e", wn = "_1xv3zog7 szap9z3o szap9z4a szap9z12 szap9z20 szap9z1q qvn8okk", Bc = "_1xv3zog6 szap9z2g szap9z32 szap9z3o szap9z4a szap9z12 szap9z1e szap9z7c", Sn = "_1xv3zog2 szap9z3o szap9z4a szap9z12 szap9z20 szap9z1u qvn8okm", Ac = "szap9z5i szap9zi", Oc = "_1xv3zog9 szap9z12 szap9z1e", $c = "szap9z2g szap9z32 szap9z3o szap9z4a szap9z12 szap9z1e szap9z7c", Vc = "szap9zc", Hc = "szap9zq";
const jc = ({
  isDatePickerEnabled: t,
  teamId: a,
  memberId: r,
  templateId: i,
  confirmedEventId: c,
  accountId: s,
  calendarId: o,
  eventId: l,
  timezone: h,
  eventDateChannelName: d,
  getTeamEvent: m,
  updateTeamEvent: D,
  goBack: u,
  getCandidateMembers: z,
  refreshTeamEvent: k,
  getDateTimeRange: L,
  onTeamEventFetched: R,
  onDirtyUpdate: M,
  sendConfirmationDialogOpenSignal: H
}) => {
  const { LL: f } = S(), P = v.useSnackbar(), F = Je(), [O, Q] = V(h), [$, te] = V({ open: !1 }), w = [
    "teams",
    a,
    "members",
    r,
    "accounts",
    s,
    "calendars",
    o,
    "events",
    l
  ], { data: T } = Ie({
    queryKey: w,
    queryFn: async () => {
      const I = await m({
        teamId: a,
        memberId: r,
        accountId: s,
        calendarId: o,
        eventId: l
      });
      if (I.ok)
        return I.data;
      throw new Error(I.error);
    }
  });
  ne(() => {
    R(T);
  }, [T, R]);
  const { data: C } = Ie({
    queryKey: ["candidateMembers", a],
    queryFn: async () => {
      const I = await z();
      if (I === void 0)
        throw new Error();
      return I;
    }
  }), b = A(() => J(T.start).tz(O), [T.start, O]), q = A(() => J(T.end).tz(O), [T.end, O]), U = A(() => q.diff(b, "minute"), [b, q]), ge = (T.attendees ?? []).map((I) => I.email), { handleSubmit: de, formState: K, control: me, ...G } = da({
    defaultValues: {
      attendees: ge,
      onlineMeeting: T.onlineMeeting.type,
      memo: T.description ?? "",
      startDate: b.format("YYYY-MM-DD"),
      startTime: b.format("HH:mm"),
      endDate: q.format("YYYY-MM-DD"),
      endTime: q.format("HH:mm"),
      allDay: T.allDay
    },
    reValidateMode: "onBlur"
  }), Fe = ee(() => {
    const I = G.getValues("startDate"), Te = G.getValues("startTime"), ue = J.tz(`${I} ${Te}`, O), ae = G.getValues("endDate"), Ce = G.getValues("endTime"), pe = J.tz(`${ae} ${Ce}`, O);
    return { startDateTime: ue, endDateTime: pe, allDay: G.getValues("allDay") };
  }, [G, O]), [E, Z] = V(() => Ja(Fe())), se = ee((I) => {
    Z(Ja(I));
  }, []), Se = G.watch(["startDate", "startTime", "endDate", "endTime", "allDay"]);
  ne(() => {
    const [I, Te, ue, ae, Ce] = Se, pe = J.tz(`${I} ${Te}`, O).toISOString(), he = J.tz(`${ue} ${ae}`, O).toISOString();
    R({
      ...T,
      start: pe,
      end: he,
      allDay: Ce
    });
  }, [Se, R, O, T]);
  const {
    register: xe,
    handleSubmit: At,
    formState: je
  } = da({
    defaultValues: {
      message: ""
    }
  });
  ne(() => {
    const I = new BroadcastChannel(d);
    return I.onmessage = (Te) => {
      const ue = Te.data;
      if (ue.type === "dateTimeChanged") {
        const { start: ae, end: Ce, allDay: pe } = ue.data, he = J(ae).tz(O), oe = J(Ce).tz(O);
        G.setValue("startDate", he.format("YYYY-MM-DD"), { shouldDirty: !0 }), G.setValue("startTime", he.format("HH:mm"), { shouldDirty: !0 }), G.setValue("endDate", oe.format("YYYY-MM-DD"), { shouldDirty: !0 }), G.setValue("endTime", oe.format("HH:mm"), { shouldDirty: !0 }), G.setValue("allDay", pe, { shouldDirty: !0 }), se({ startDateTime: he, endDateTime: oe, allDay: pe });
      }
      if (ue.type === "timezoneChanged") {
        const ae = G.getValues("startDate"), Ce = G.getValues("startTime"), pe = G.getValues("endDate"), he = G.getValues("endTime"), oe = J(`${ae} ${Ce}`).tz(ue.data.timezone), ze = J(`${pe} ${he}`).tz(ue.data.timezone);
        se({
          startDateTime: oe,
          endDateTime: ze,
          allDay: G.getValues("allDay")
        }), G.setValue("startDate", oe.format("YYYY-MM-DD"), { shouldDirty: !0 }), G.setValue("startTime", oe.format("HH:mm"), { shouldDirty: !0 }), G.setValue("endDate", ze.format("YYYY-MM-DD"), { shouldDirty: !0 }), G.setValue("endTime", ze.format("HH:mm"), { shouldDirty: !0 }), Q(ue.data.timezone);
      }
    }, () => {
      I.close();
    };
  }, [d, G, O, se]), ne(() => {
    M(K.isDirty);
  }, [K, M]);
  const Ue = fe({
    mutationFn: async ({ message: I, description: Te, attendees: ue, onlineMeeting: ae, start: Ce, end: pe, allDay: he }) => {
      const oe = await D({
        teamId: a,
        templateId: i,
        confirmedEventId: c,
        description: Te,
        start: Ce,
        end: pe,
        allDay: he,
        attendees: ue,
        onlineMeeting: ae,
        message: I
      });
      if (!oe.ok)
        throw new Error(oe.error);
      if (!(await k({
        teamId: a,
        memberId: r,
        start: Ce ?? T.start,
        end: pe ?? T.end
      })).ok)
        throw new Error();
    },
    onSuccess: async () => {
      await F.invalidateQueries({ queryKey: w }), M(!1), P.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: f.teamTemplateConfirmedEventEditByMember.success() }) })
      ), u(a);
    },
    onError: () => {
      P.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: f.teamTemplateConfirmedEventEditByMember.unknownError() }) })
      );
    }
  }), mt = ee((I) => {
    const ue = K.dirtyFields.memo ?? !1 ? I.memo === "" ? { action: "delete" } : { action: "update", value: I.memo } : void 0, ae = K.dirtyFields.attendees, pe = (ae === void 0 ? !1 : [ae].flatMap((Re) => Re).some((Re) => Re)) ? I.attendees.length === 0 ? { action: "delete" } : { action: "update", value: I.attendees.map((Re) => ({ email: Re })) } : void 0, oe = (K.dirtyFields.onlineMeeting ?? !1) && I.onlineMeeting === "none" ? { type: "none" } : void 0, ze = (K.dirtyFields.startDate ?? !1) || (K.dirtyFields.startTime ?? !1), $t = (K.dirtyFields.endDate ?? !1) || (K.dirtyFields.endTime ?? !1), et = K.dirtyFields.allDay ?? !1, { start: Ee, end: Vt, allDay: tt } = ze || $t || et ? {
      start: J.tz(`${I.startDate} ${I.startTime}`, O).toISOString(),
      end: J.tz(`${I.endDate} ${I.endTime}`, O).toISOString(),
      allDay: I.allDay
    } : {
      start: void 0,
      end: void 0,
      allDay: void 0
    };
    H({ teamId: a, templateId: i }), te({ open: !0, formValues: { description: ue, attendees: pe, onlineMeeting: oe, start: Ee, end: Vt, allDay: tt } });
  }, [K.dirtyFields, H, a, i, O]), Ot = ee((I) => async (Te) => {
    te({ open: !1 }), await Ue.mutateAsync({
      message: Te.message === "" ? void 0 : Te.message,
      description: I.description,
      attendees: I.attendees,
      onlineMeeting: I.onlineMeeting,
      start: I.start,
      end: I.end,
      allDay: I.allDay
    });
  }, [Ue]);
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ e(X.Spinner, { active: K.isSubmitting || je.isSubmitting }),
    /* @__PURE__ */ e(Pr, { ...G, handleSubmit: de, control: me, formState: K, children: /* @__PURE__ */ n("form", { className: Pn, onSubmit: (I) => void de(mt)(I), children: [
      /* @__PURE__ */ n("div", { className: Sn, children: [
        /* @__PURE__ */ e("div", { role: "heading", "aria-level": 1, className: Vc, children: f.teamTemplateConfirmedEventEditByMember.title() }),
        /* @__PURE__ */ e(Ne.Button, { buttonType: "text", type: "button", onClick: () => u(a), "aria-label": "Close", children: /* @__PURE__ */ e(Ne.Icon, { children: /* @__PURE__ */ e(x.Close, {}) }) })
      ] }),
      /* @__PURE__ */ e("div", { className: Mn, children: /* @__PURE__ */ n("div", { className: Bc, children: [
        /* @__PURE__ */ e(
          bc,
          {
            isDatePickerEnabled: t,
            summary: T.summary,
            start: T.start,
            end: T.end,
            allDay: T.allDay,
            location: T.location,
            visibility: T.visibility,
            organizer: T.organizer,
            getDateTimeRange: L
          }
        ),
        t ? /* @__PURE__ */ e(
          hc,
          {
            initialDuration: U,
            timezone: O,
            displayEndDate: E,
            updateDisplayEndDate: se
          }
        ) : null,
        /* @__PURE__ */ e(
          rc,
          {
            control: me,
            name: "attendees",
            teamMemberEmails: C.map((I) => I.email)
          }
        ),
        T.onlineMeeting.type === "none" ? null : /* @__PURE__ */ e(qc, { control: me, name: "onlineMeeting", url: T.onlineMeeting.info?.url }),
        /* @__PURE__ */ e(Lc, { control: me, name: "memo" })
      ] }) }),
      /* @__PURE__ */ e("div", { className: wn, children: /* @__PURE__ */ e(
        p.Button,
        {
          type: "submit",
          disabled: !K.isDirty || $.open || je.isSubmitting,
          size: "small",
          "aria-label": "Update event",
          children: /* @__PURE__ */ e(p.Text, { children: f.teamTemplateConfirmedEventEditByMember.update() })
        }
      ) })
    ] }) }),
    /* @__PURE__ */ e(
      g.Root,
      {
        open: $.open,
        onOpenChange: (I) => {
          I || te({ open: !1 });
        },
        children: /* @__PURE__ */ e(g.Content, { zIndex: "100", children: $.open && /* @__PURE__ */ n(
          "form",
          {
            className: Oc,
            onSubmit: (I) => void At(Ot($.formValues))(I),
            children: [
              /* @__PURE__ */ n(g.Header, { children: [
                /* @__PURE__ */ e(g.Title, { children: f.teamTemplateConfirmedEventEditByMember.modal.title() }),
                /* @__PURE__ */ e(g.DefaultClose, {})
              ] }),
              /* @__PURE__ */ n(g.Body, { children: [
                /* @__PURE__ */ n("p", { className: Ac, children: [
                  /* @__PURE__ */ e("span", { className: Hc, children: f.teamTemplateConfirmedEventEditByMember.modal.body.warning() }),
                  /* @__PURE__ */ e("span", { children: f.teamTemplateConfirmedEventEditByMember.modal.body.notification() })
                ] }),
                /* @__PURE__ */ n(at.Frame, { children: [
                  /* @__PURE__ */ n(at.LabelFrame, { children: [
                    /* @__PURE__ */ e(at.Label, { children: f.teamTemplateConfirmedEventEditByMember.modal.messageLabel() }),
                    /* @__PURE__ */ e(at.HelperText, { children: f.teamTemplateConfirmedEventEditByMember.modal.messageHelp() })
                  ] }),
                  /* @__PURE__ */ e(
                    at.Textarea,
                    {
                      defaultValue: "",
                      ...xe("message"),
                      rows: 3
                    }
                  )
                ] })
              ] }),
              /* @__PURE__ */ n(g.Footer, { children: [
                /* @__PURE__ */ e(
                  g.FooterActionButton,
                  {
                    buttonType: "secondary",
                    onClick: () => te({ open: !1 }),
                    "aria-label": "modal close",
                    children: /* @__PURE__ */ e(p.Text, { children: f.teamTemplateConfirmedEventEditByMember.modal.backToEdit() })
                  }
                ),
                /* @__PURE__ */ e(
                  g.FooterActionButton,
                  {
                    type: "submit",
                    buttonType: "danger",
                    disabled: je.isSubmitting,
                    "aria-label": "confirm change",
                    children: /* @__PURE__ */ e(p.Text, { children: f.teamTemplateConfirmedEventEditByMember.modal.confirmChange() })
                  }
                )
              ] })
            ]
          }
        ) })
      }
    )
  ] });
}, Uc = () => {
  const { LL: t } = S();
  return /* @__PURE__ */ n("div", { className: Pn, children: [
    /* @__PURE__ */ n("div", { className: Sn, children: [
      /* @__PURE__ */ e(X.SkeltonText, { fontSize: "text-xl", width: "25%" }),
      /* @__PURE__ */ e(Ne.Button, { buttonType: "text", type: "button", disabled: !0, children: /* @__PURE__ */ e(Ne.Icon, { children: /* @__PURE__ */ e(x.Close, {}) }) })
    ] }),
    /* @__PURE__ */ e("div", { className: Mn, children: /* @__PURE__ */ n("div", { className: $c, children: [
      /* @__PURE__ */ e(X.SkeltonText, { fontSize: "text-base", width: "25%" }),
      /* @__PURE__ */ e(X.SkeltonText, { fontSize: "text-lg" }),
      /* @__PURE__ */ e(X.SkeltonText, { fontSize: "text-lg" })
    ] }) }),
    /* @__PURE__ */ e("div", { className: wn, children: /* @__PURE__ */ e(
      p.Button,
      {
        disabled: !0,
        size: "small",
        type: "submit",
        children: /* @__PURE__ */ e(p.Text, { children: t.teamTemplateConfirmedEventEditByMember.update() })
      }
    ) })
  ] });
}, Gc = (t) => {
  const { LL: a } = S(), r = v.useSnackbar();
  return /* @__PURE__ */ e(dt, { fallback: /* @__PURE__ */ e(W, {}), onError: () => {
    r.create(
      /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: a.teamTemplateConfirmedEventEditByMember.unknownError() }) })
    ), t.goBack(t.teamId);
  }, children: /* @__PURE__ */ e(Ke, { fallback: /* @__PURE__ */ e(Uc, {}), children: /* @__PURE__ */ e(jc, { ...t }) }) });
};
var Yc = "cjlmai2 szap9z12 szap9z20 szap9z1o qvn8ok9 szap9zm szap9zo", Kc = "cjlmai1", Jc = "cjlmai0 szap9z18 qvn8oki szap9z6";
const En = Mt(
  function({ src: a, fallbackText: r, fallbackTextColor: i, fallbackBackgroundColor: c, ...s }, o) {
    return /* @__PURE__ */ n(Pe.Root, { ref: o, ...s, className: Jc, children: [
      /* @__PURE__ */ e(Pe.Image, { className: Kc, src: a }),
      /* @__PURE__ */ e(
        Pe.Fallback,
        {
          delayMs: a === void 0 ? 0 : 1e3,
          className: Yc,
          style: { color: i, backgroundColor: c },
          children: r
        }
      )
    ] });
  }
);
var Wc = "_5sffyu3 szap9z2a szap9z2w szap9z3i szap9z44 szap9z12 szap9z20 szap9z2 qvn8ok0", Qc = "szap9z2a szap9z2w szap9z14 szap9z20 qvn8oki szap9z2 szap9z3k szap9z44 qvn8ok9", Xc = "szap9z2a szap9z2w szap9z3i szap9z44 szap9z12 szap9z20 qvn8ok0", Zc = "_5sffyu2 szap9zi szap9zq szap9z46";
const na = (t) => /* @__PURE__ */ e("div", { className: Qc, children: t.children }), ra = (t) => /* @__PURE__ */ e("div", { className: Xc, children: t.children }), ia = (t) => /* @__PURE__ */ e("div", { className: Zc, children: t.children }), la = (t) => /* @__PURE__ */ e("button", { ...t, className: Wc, children: /* @__PURE__ */ e(x.Close, { size: 16 }) });
var ed = "_3wf9vrs", td = "szap9z12", ad = "_3wf9vrr szap9z60 szap9z6m", nd = "_3wf9vr4 szap9z12 szap9z1e szap9z7a", rd = "_3wf9vr5", id = we({ defaultClassName: "_3wf9vr0 szap9z3o szap9z4a qvn8oki szap9z4 szap9z2g szap9z30", variantClassNames: { status: { active: "qvn8ok9", disabled: "qvn8okc" }, defaultVariants: { status: "active" } }, defaultVariants: {}, compoundVariants: [] }), sa = { mobile: 260, tablet: 254, desktop: 254 }, ld = "szap9z12 szap9z20 szap9zk qvn8ok0", sd = "_3wf9vrt szap9z12 szap9z1u szap9z20 szap9z7c", od = "_3wf9vrp szap9z12", cd = "szap9z12 szap9z1e szap9z76", dd = "szap9zm szap9zq qvn8ok0", md = "szap9z12 szap9z20 szap9z78", ud = "szap9z12 szap9z1e szap9z76", pd = "szap9zm szap9zq qvn8ok0", hd = "szap9zm szap9zo szap9z3i", zd = "szap9zg szap9zq", vd = "_3wf9vr6 szap9z16", yd = we({ defaultClassName: "_3wf9vr9", variantClassNames: { type: { regular: "_3wf9vra", caution: "qvn8ok1" }, defaultVariants: { type: "regular" } }, defaultVariants: {}, compoundVariants: [] }), bd = "_3wf9vr7 szap9z12 szap9z1e", gd = "_3wf9vr8 szap9zg szap9zj szap9zq", fd = "_3wf9vru szap9z12 szap9z20 szap9z7c szap9z79", Td = "szap9z12 szap9z76", Cd = we({ defaultClassName: "szap9zk szap9zq", variantClassNames: { availability: { available: "qvn8ok2", unavailable: "qvn8ok6" }, defaultVariants: { availability: "unavailable" } }, defaultVariants: {}, compoundVariants: [] });
const kd = (t) => /* @__PURE__ */ e("div", { className: id({ status: t.disabled ? "disabled" : "active" }), "aria-label": "template-card", children: t.children }), Ld = (t) => /* @__PURE__ */ e("div", { className: nd, children: t.children }), Nd = (t) => /* @__PURE__ */ e("div", { className: rd, children: t.children }), xd = (t) => /* @__PURE__ */ e("div", { className: vd, children: t.children }), Dd = (t) => /* @__PURE__ */ e("div", { className: bd, children: /* @__PURE__ */ e("div", { className: gd, children: t.children }) }), Id = (t) => /* @__PURE__ */ n(N.Root, { children: [
  /* @__PURE__ */ e(N.Trigger, { children: /* @__PURE__ */ e(p.Button, { buttonType: "text", size: "small", "aria-label": "template-card-operation-menu", children: /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.DotsHorizontal, {}) }) }) }),
  /* @__PURE__ */ e(N.Menu, { align: "end", children: t.children })
] }), rt = (t) => /* @__PURE__ */ e(N.MenuItem, { ...t, children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { className: yd({ type: t.type ?? "regular" }), children: t.children }) }) }), Fd = N.RadioGroup, Wa = ({ children: t, ...a }) => /* @__PURE__ */ e(N.RadioMenuItem, { ...a, children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { children: t }) }) }), Md = N.MenuGroup, Pd = N.MenuGroupHeading, wd = N.MenuSeparator, Sd = (t) => /* @__PURE__ */ e("div", { className: ud, children: t.children }), Ed = (t) => /* @__PURE__ */ e("div", { className: pd, children: t.children }), _d = (t) => /* @__PURE__ */ e("div", { className: md, children: t.children }), Rd = (t) => /* @__PURE__ */ e("span", { className: hd, children: t.children }), qd = (t) => /* @__PURE__ */ e("div", { className: zd, children: t.children }), Bd = (t) => /* @__PURE__ */ e("div", { className: Td, children: t.children }), Oe = (t) => /* @__PURE__ */ e(
  "span",
  {
    className: Cd({ availability: t.available ? "available" : "unavailable" }),
    children: t.children
  }
), Ad = (t) => /* @__PURE__ */ e("div", { className: cd, children: t.children }), Od = (t) => /* @__PURE__ */ e("div", { className: dd, children: t.children }), $d = (t) => /* @__PURE__ */ e("div", { className: od, children: t.children }), Vd = (t) => /* @__PURE__ */ e("div", { className: td, children: t.children }), Hd = (t) => /* @__PURE__ */ e("div", { className: ad, children: t.children }), jd = (t) => /* @__PURE__ */ e("div", { className: ed, children: /* @__PURE__ */ e(Yd, { ...t }) }), Ud = (t) => /* @__PURE__ */ e("div", { className: sd, children: t.children }), Gd = (t) => /* @__PURE__ */ e("div", { className: fd, children: t.children }), oa = (t) => /* @__PURE__ */ e(p.Button, { ...t, size: "small", buttonType: "textMin", children: /* @__PURE__ */ e(p.Text, { children: t.children }) }), Qa = (t) => /* @__PURE__ */ e("div", { className: ld, children: t.children }), Xa = (t) => {
  const a = ce({ query: le.screen.mobile }), r = a ? { width: "fitContent", size: "medium" } : { width: "fitContent", size: "small" }, i = a ? {} : { flexShrink: 0 };
  return /* @__PURE__ */ e(p.Button, { ...t, ...r, style: i, buttonType: "secondary", children: /* @__PURE__ */ e(p.Text, { children: t.children }) });
}, Yd = ({ name: t, ...a }) => /* @__PURE__ */ e(_.Provider, { children: /* @__PURE__ */ n(_.Root, { children: [
  /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ e(En, { ...a }) }),
  /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(_.Content, { side: "top", children: t }) })
] }) }), Kd = (t) => {
  const a = st(null), r = ee(() => {
    const D = window.matchMedia(le.screen.column.mobile).matches, u = window.matchMedia(le.screen.column.tablet).matches, z = document.querySelector(`.${t.listFrameClassName}`), { width: k } = z?.getBoundingClientRect() ?? { width: 0 };
    return D ? {
      grid: 1,
      width: k,
      margin: {
        x: 0,
        y: 16
      },
      height: sa.mobile
    } : u ? {
      grid: 1,
      width: k,
      margin: {
        x: 0,
        y: 16
      },
      height: sa.tablet
    } : {
      grid: 2,
      width: (k - 16) / 2,
      margin: {
        x: 16,
        y: 16
      },
      height: sa.desktop
    };
  }, [t.listFrameClassName]), [i, c] = V(() => r()), s = ee(() => {
    const u = document.querySelector(`.${t.pageFrameClassName}`)?.getBoundingClientRect(), z = { height: window.innerHeight, width: window.innerWidth };
    return u === void 0 ? z : {
      height: u.height > 0 ? u?.height : z.height,
      width: u.width > 0 ? u?.width : z.width
    };
  }, [t.pageFrameClassName]), [o, l] = V(() => s()), h = st(null), d = qr({
    overscan: 10,
    count: t.templates.length,
    estimateSize: () => i.height,
    getScrollElement: () => a.current,
    // scrollRefが設定されたタイミングで呼び出されるVirtualScrollの初期化処理
    observeElementRect: (D, u) => {
      const z = s();
      l((L) => z ?? L);
      const k = r();
      c((L) => k ?? L), u({ height: 1e3, width: 500 }), h.current = u;
    },
    lanes: i.grid,
    gap: i.margin.y
  }), m = ee(() => {
    l(() => s()), c(() => r()), d.measure();
  }, [s, r, l, c, d]);
  return ne(() => {
    h.current?.(o);
  }, [o]), ne(() => {
    window.addEventListener("resize", m);
    const D = new ResizeObserver(m), u = document.getElementById("home-layout-wrapper");
    return u !== null && D.observe(u), () => {
      window.removeEventListener("resize", m), u !== null && D.disconnect();
    };
  }, [m]), {
    scrollRef: a,
    gridSize: i,
    pageSize: o,
    virtualizer: d
  };
};
var Jd = "_8c2ljm2 qvn8okg qvn8okh", Wd = "_8c2ljme szap9z3k szap9z46", _n = "_8c2ljma", Qd = "_8c2ljm9", Za = "_8c2ljmd", en = "szap9z12 szap9z20 szap9z76", Xd = "szap9z2c qvn8ok5 szap9z12 szap9z20 szap9z7a", Zd = "szap9z3m szap9z48 szap9z4s szap9z4x szap9z2a szap9z2y qvn8oki szap9z2 szap9z5i", Dt = "_8c2ljmh", em = "szap9z12 szap9z1e szap9z7a", tn = "_8c2ljm8 szap9z2g", tm = "_8c2ljm6 qvn8okm", an = "_8c2ljm7 szap9z2c szap9z2y szap9z3k szap9z46 szap9zi szap9zq qvn8ok0", am = "szap9z4w szap9z12 szap9z1u szap9z20 szap9z7a", nm = "szap9zi szap9zq", rm = "_8c2ljmf", im = "_8c2ljm0 szap9z2g szap9z32 szap9z16 szap9z20 szap9z7a", lm = "_8c2ljm1";
function sm({ value: t, changePlainText: a }) {
  const r = st(null);
  return ne(() => {
    setTimeout(() => {
      r.current !== null && (r.current.focus(), r.current.setSelectionRange(0, 0), r.current.scrollTo(0, 0));
    }, 10);
  }, []), /* @__PURE__ */ e("textarea", { ref: r, className: Qd, value: t, onChange: a });
}
const om = ({
  teamTemplateListItem: t,
  timezoneGroups: a,
  groupedTimezones: r,
  copyText: i,
  getTimezoneByKey: c,
  fetchCandidates: s,
  getHtmlText: o,
  getPlainText: l
}) => {
  const h = ce({ query: le.screen.mobile }), { LL: d, locale: m } = S(), [D, u] = V("plainText"), z = v.useSnackbar(), [k, L] = V(""), [R, M] = V(""), [H, f] = V(m), [P, F] = V(t.timeZone), [O, Q] = V(!1), [$, te] = V(void 0), { data: w, isSuccess: T, isFetching: C } = Pt({
    queryKey: ["public"],
    initialData: [],
    queryFn: async () => {
      const E = await s({
        teamId: t.teamId,
        availabilitySharingId: t.id
      });
      return E.ok ? E.candidates : [];
    }
  }), b = A(() => a.flatMap((E) => {
    const Z = r[E];
    return Z === void 0 ? [] : Z.map((se) => ({
      value: se.key,
      group: E,
      label: se.fullLabel,
      description: se.subLabel
    }));
  }), [r, a]), q = A(() => b.filter((E) => {
    if (P === E.value)
      return !1;
    if ($ === void 0)
      return !0;
    const Z = new RegExp($, "i");
    return Z.test(E.label) || Z.test(E.description) || Z.test(E.value);
  }), [P, b, $]), U = A(() => {
    const E = c({ key: P, locale: m });
    return {
      value: P,
      group: E.group,
      label: E.fullLabel,
      shortLabel: E.label,
      description: E.subLabel
    };
  }, [c, m, P]), ge = `${window.location.origin}/t/${t.teamId}/as/${t.id}/confirm`;
  ne(() => {
    if (!T)
      return;
    const E = {
      activeCandidates: w,
      title: t.candidateTitle,
      duration: t.duration,
      locale: H,
      url: ge,
      timezone: P
    }, Z = l(E);
    L(Z), (async () => {
      const Se = await o(E);
      M(Se);
    })();
  }, [w, T, H, P, ge, o, l, t]);
  const de = () => {
    i({ text: ge, type: "url", templateId: t.id }), z.create(
      /* @__PURE__ */ e(v.Root, { children: /* @__PURE__ */ e(v.Description, { children: d.teamTemplateList.shareUrl.copyUrlSuccessSnackbar() }) })
    );
  }, K = (E) => {
    L(E.target.value);
  }, me = () => {
    i({ text: k, type: "plain", templateId: t.id });
  }, G = () => {
    i({ text: R, type: "html", templateId: t.id });
  }, Fe = () => {
    D === "plainText" ? me() : G(), z.create(
      /* @__PURE__ */ e(v.Root, { children: /* @__PURE__ */ e(v.Description, { children: d.teamTemplateList.shareUrl.copySuccessSnackbar() }) })
    );
  };
  return /* @__PURE__ */ n(g.Content, { zIndex: "1000", width: "wide", children: [
    /* @__PURE__ */ n(g.Header, { children: [
      /* @__PURE__ */ e(g.Title, { children: d.teamTemplateList.shareUrl.title() }),
      /* @__PURE__ */ e(g.DefaultClose, {})
    ] }),
    /* @__PURE__ */ n(g.Body, { children: [
      /* @__PURE__ */ n("div", { className: im, children: [
        /* @__PURE__ */ e("div", { className: lm, children: /* @__PURE__ */ e(j.TextFieldRoot, { size: "small", readOnly: !0, children: /* @__PURE__ */ e(j.Input, { defaultValue: ge }) }) }),
        /* @__PURE__ */ n(p.Button, { buttonType: "primary", size: "small", width: h ? "full" : "fitContent", onClick: de, children: [
          /* @__PURE__ */ e(p.Text, { children: d.teamTemplateList.shareUrl.urlCopyButton() }),
          /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.LinkVertical, {}) })
        ] })
      ] }),
      /* @__PURE__ */ e("hr", { className: Jd }),
      /* @__PURE__ */ n("div", { className: am, children: [
        /* @__PURE__ */ e("h3", { className: nm, children: d.teamTemplateList.shareUrl.textCopySectionTitle() }),
        h ? /* @__PURE__ */ e(Ne.Button, { buttonType: "secondary", onClick: Fe, disabled: C, children: /* @__PURE__ */ e(Ne.Icon, { children: /* @__PURE__ */ e(x.CopyContent, {}) }) }) : /* @__PURE__ */ n(p.Button, { buttonType: "text", size: "small", onClick: Fe, disabled: C, children: [
          /* @__PURE__ */ e(p.Text, { children: D === "plainText" ? d.teamTemplateList.shareUrl.plainTextCopy() : d.teamTemplateList.shareUrl.htmlTextCopy() }),
          /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.CopyContent, {}) })
        ] })
      ] }),
      /* @__PURE__ */ n("div", { className: Zd, children: [
        /* @__PURE__ */ n(Ge.Root, { defaultValue: D, onValueChange: u, children: [
          /* @__PURE__ */ n(Ge.List, { className: tm, children: [
            /* @__PURE__ */ e(Ge.Trigger, { className: an, value: "plainText", children: d.teamTemplateList.shareUrl.plainTextTab() }),
            /* @__PURE__ */ e(Ge.Trigger, { className: an, value: "htmlText", children: d.teamTemplateList.shareUrl.htmlTextTab() })
          ] }),
          /* @__PURE__ */ e(Ge.Content, { className: tn, value: "plainText", children: C ? /* @__PURE__ */ e(nn, {}) : /* @__PURE__ */ e(sm, { value: k, changePlainText: K }) }),
          /* @__PURE__ */ e(Ge.Content, { className: tn, value: "htmlText", children: C ? /* @__PURE__ */ e(nn, {}) : /* @__PURE__ */ e(
            "div",
            {
              className: _n,
              dangerouslySetInnerHTML: { __html: R }
            }
          ) })
        ] }),
        /* @__PURE__ */ n("div", { className: Xd, children: [
          /* @__PURE__ */ n("div", { className: en, children: [
            h ? null : /* @__PURE__ */ e(x.Translate, { size: 16 }),
            /* @__PURE__ */ n(N.Root, { children: [
              /* @__PURE__ */ e(N.Trigger, { children: h ? /* @__PURE__ */ e(Ne.Button, { buttonType: "secondary", children: /* @__PURE__ */ e(Ne.Icon, { children: /* @__PURE__ */ e(x.Translate, {}) }) }) : /* @__PURE__ */ n(p.Button, { buttonType: "textMin", size: "small", children: [
                /* @__PURE__ */ e(p.Text, { children: d.teamTemplateList.shareUrl.localeSelect[H]() }),
                /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.ArrowDown, {}) })
              ] }) }),
              /* @__PURE__ */ n(N.Menu, { collisionPadding: 16, align: "start", side: "top", className: Za, children: [
                /* @__PURE__ */ e(N.CheckboxMenuItem, { checked: H === "ja", onCheckedChange: () => f("ja"), children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { children: d.teamTemplateList.shareUrl.localeSelect.ja() }) }) }),
                /* @__PURE__ */ e(N.CheckboxMenuItem, { checked: H === "en", onCheckedChange: () => f("en"), children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { children: d.teamTemplateList.shareUrl.localeSelect.en() }) }) })
              ] })
            ] })
          ] }),
          /* @__PURE__ */ n("div", { className: en, children: [
            h ? null : /* @__PURE__ */ e(x.Timezone, { size: 16 }),
            /* @__PURE__ */ n(
              N.Root,
              {
                open: O,
                onOpenChange: (E) => Q(E),
                children: [
                  /* @__PURE__ */ e(N.Trigger, { children: h ? /* @__PURE__ */ e(Ne.Button, { buttonType: "secondary", children: /* @__PURE__ */ e(Ne.Icon, { children: /* @__PURE__ */ e(x.Timezone, {}) }) }) : /* @__PURE__ */ n(p.Button, { buttonType: "textMin", size: "small", "aria-label": "Timezone selector", children: [
                    /* @__PURE__ */ e(p.Text, { children: U.shortLabel }),
                    /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.ArrowDown, {}) })
                  ] }) }),
                  /* @__PURE__ */ e(
                    N.Menu,
                    {
                      collisionPadding: 16,
                      align: "start",
                      side: "top",
                      className: Za,
                      onCloseAutoFocus: () => te(void 0),
                      children: /* @__PURE__ */ n(
                        B.Root,
                        {
                          selectionBehavior: "clear",
                          items: [U, ...q],
                          defaultValue: [P],
                          defaultOpen: !0,
                          onInputValueChange: ({ inputValue: E }) => {
                            te(E);
                          },
                          onValueChange: ({ value: E }) => {
                            const Z = E[0];
                            typeof Z == "string" && F(Z);
                          },
                          onOpenChange: (E) => {
                            E.open || Q(!1);
                          },
                          children: [
                            /* @__PURE__ */ e(B.Control, { className: Wd, children: /* @__PURE__ */ e(B.Input, {}) }),
                            /* @__PURE__ */ n(B.MenuContent, { className: rm, children: [
                              /* @__PURE__ */ n(B.ItemGroup, { children: [
                                /* @__PURE__ */ e(B.ItemGroupHeading, { children: d.teamTemplateList.shareUrl.timezoneSelect.currentLocation() }),
                                /* @__PURE__ */ e(
                                  B.Item,
                                  {
                                    item: U,
                                    children: /* @__PURE__ */ n(B.TextFrame, { children: [
                                      /* @__PURE__ */ e(B.ItemText, { children: U.label }),
                                      /* @__PURE__ */ e(B.DescriptionText, { children: U.description })
                                    ] })
                                  }
                                )
                              ] }),
                              a.map((E) => {
                                const Z = q.filter((se) => se.group === E);
                                return Z.length === 0 ? /* @__PURE__ */ e(Ft, {}, E) : /* @__PURE__ */ n(B.ItemGroup, { children: [
                                  /* @__PURE__ */ e(B.ItemGroupHeading, { children: E }),
                                  Z.map((se) => /* @__PURE__ */ e(
                                    B.Item,
                                    {
                                      item: se,
                                      children: /* @__PURE__ */ n(B.TextFrame, { children: [
                                        /* @__PURE__ */ e(B.ItemText, { children: se.label }),
                                        /* @__PURE__ */ e(B.DescriptionText, { children: se.description })
                                      ] })
                                    },
                                    se.value
                                  ))
                                ] }, E);
                              })
                            ] })
                          ]
                        }
                      )
                    }
                  )
                ]
              }
            )
          ] })
        ] })
      ] })
    ] })
  ] });
}, nn = () => /* @__PURE__ */ n("div", { className: ye(em, _n), children: [
  /* @__PURE__ */ e("div", { className: Dt, children: /* @__PURE__ */ e(X.SkeltonText, {}) }),
  /* @__PURE__ */ e("div", { className: Dt, children: /* @__PURE__ */ e(X.SkeltonText, {}) }),
  /* @__PURE__ */ e("div", { className: Dt, children: /* @__PURE__ */ e(X.SkeltonText, {}) }),
  /* @__PURE__ */ e("div", { className: Dt, children: /* @__PURE__ */ e(X.SkeltonText, { width: "75%" }) })
] });
var cm = "_1b12f2j5 szap9z11", dm = "szap9z3m szap9z48 szap9z2a szap9z2w", Rn = "_1b12f2j1", mm = "szap9z3q szap9z3p szap9z4c szap9z4b szap9z12 szap9z20 szap9z1e", um = "_1b12f2ji szap9zg szap9zj szap9zo szap9z32", pm = "szap9ze szap9zh szap9zq szap9z2w", It = "_1b12f2jh szap9zg szap9zj szap9zo szap9z32", rn = "szap9za szap9zd szap9zq szap9z2w", hm = "szap9z12 szap9z7a", zm = "szap9z12 szap9z78 szap9zi szap9zq", vm = "_1b12f2jd szap9z12 szap9z1q szap9z1n szap9z7c szap9z2c", ym = "_1b12f2jc szap9z3q szap9z4c szap9z12 szap9z20 szap9z1e", bm = "szap9z12 szap9z20 szap9z78", gm = "_1b12f2j2", fm = "_1b12f2j3 szap9z2i szap9z34", Tm = "szap9z3m szap9z48 szap9z2a szap9z2w szap9z12 szap9z78", Cm = "_1b12f2ja", km = "szap9z12 szap9z1u", Lm = "szap9z2g";
const Nm = [15, 30, 45, 60, 90, 120, 180, 240, 300, 360, 420, 480], ln = ({ children: t }) => /* @__PURE__ */ e("div", { className: mm, children: /* @__PURE__ */ e("div", { className: Rn, children: t }) }), xm = (t) => {
  const a = ce({ query: le.screen.mobile }), { LL: r } = S(), i = t.template.attendeesGroups.flat().map(
    (l) => [l.organizerMemberId, ...l.attendeeMemberIds].map((h) => t.teamMembers.find((d) => d.id === h)).filter((h) => h !== void 0).map((h) => ({
      id: h.id,
      name: h.name,
      src: h.photoUrl,
      fallbackText: h.fallbackText,
      fallbackTextColor: h.fallbackTextColor,
      fallbackBackgroundColor: h.fallbackBackgroundColor
    }))
  ).filter((l) => l.length > 0), c = A(() => ({
    monday: (t.template.dayOfTheWeekRules.monday?.rules.length ?? 0) > 0,
    tuesday: (t.template.dayOfTheWeekRules.tuesday?.rules.length ?? 0) > 0,
    wednesday: (t.template.dayOfTheWeekRules.wednesday?.rules.length ?? 0) > 0,
    thursday: (t.template.dayOfTheWeekRules.thursday?.rules.length ?? 0) > 0,
    friday: (t.template.dayOfTheWeekRules.friday?.rules.length ?? 0) > 0,
    saturday: (t.template.dayOfTheWeekRules.saturday?.rules.length ?? 0) > 0,
    sunday: (t.template.dayOfTheWeekRules.sunday?.rules.length ?? 0) > 0
  }), [t.template.dayOfTheWeekRules]), s = (l) => {
    l !== "public" && l !== "private" || (t.template.isPublished ? "public" : "private") === l || t.onPublishToggle(t.template.id, t.template.isPublished);
  }, o = A(() => t.isPlanCanceled ? "planCanceled" : a ? t.template.isPublished ? "publicMobile" : "privateMobile" : t.template.isPublished ? "publicDesktop" : "privateDesktop", [t.isPlanCanceled, t.template.isPublished, a]);
  return /* @__PURE__ */ e(W, { children: /* @__PURE__ */ e(kd, { disabled: t.isPlanCanceled || !t.template.isPublished, children: /* @__PURE__ */ n(Ld, { children: [
    /* @__PURE__ */ n(xd, { children: [
      /* @__PURE__ */ e(Dd, { children: t.template.privateTitle }),
      /* @__PURE__ */ n(Id, { children: [
        o === "privateMobile" || o === "privateDesktop" ? /* @__PURE__ */ e(
          rt,
          {
            onClick: () => t.onTemplateEdit(t.template.id),
            children: r.teamTemplateList.actions.viewOrEdit()
          }
        ) : null,
        o === "publicMobile" ? /* @__PURE__ */ e(
          rt,
          {
            onClick: () => t.onShareModalOpen(t.template),
            children: r.teamTemplateList.actions.shareByText()
          }
        ) : null,
        o === "publicMobile" ? /* @__PURE__ */ e(
          rt,
          {
            onClick: () => t.onEmbedModalOpen(t.template.id),
            children: r.teamTemplateList.actions.embed()
          }
        ) : null,
        t.canCreateTemplate ? /* @__PURE__ */ e(
          rt,
          {
            onClick: () => void t.onTemplateDuplicate(t.template.id),
            children: r.teamTemplateList.actions.duplicate()
          }
        ) : null,
        /* @__PURE__ */ e(
          rt,
          {
            type: "caution",
            onClick: () => void t.onTemplateDelete(t.template.id),
            children: r.teamTemplateList.actions.delete()
          }
        ),
        o !== "planCanceled" ? /* @__PURE__ */ n(W, { children: [
          /* @__PURE__ */ e(wd, {}),
          /* @__PURE__ */ n(Md, { children: [
            /* @__PURE__ */ e(Pd, { children: r.teamTemplateList.publishSettings() }),
            /* @__PURE__ */ n(
              Fd,
              {
                value: t.template.isPublished ? "public" : "private",
                onValueChange: (l) => s(l),
                children: [
                  /* @__PURE__ */ e(Wa, { value: "public", children: r.teamTemplateList.publishSettingsPublic() }),
                  /* @__PURE__ */ e(Wa, { value: "private", children: r.teamTemplateList.publishSettingsPrivate() })
                ]
              }
            )
          ] })
        ] }) : null
      ] })
    ] }),
    /* @__PURE__ */ n(Sd, { children: [
      /* @__PURE__ */ n(Ed, { children: [
        r.teamTemplateList.durationLabel(),
        /* @__PURE__ */ e(Rd, { children: r.teamTemplateList.buffer({ buffer: t.template.timeBuffer }) })
      ] }),
      /* @__PURE__ */ n(_d, { children: [
        /* @__PURE__ */ e(qd, { children: r.teamTemplateList.durationValue({ duration: t.template.duration }) }),
        /* @__PURE__ */ n(Bd, { children: [
          /* @__PURE__ */ e(Oe, { available: c.sunday, children: r.teamTemplateList.weeklyAvailability.sunday() }),
          /* @__PURE__ */ e(Oe, { available: c.monday, children: r.teamTemplateList.weeklyAvailability.monday() }),
          /* @__PURE__ */ e(Oe, { available: c.tuesday, children: r.teamTemplateList.weeklyAvailability.tuesday() }),
          /* @__PURE__ */ e(Oe, { available: c.wednesday, children: r.teamTemplateList.weeklyAvailability.wednesday() }),
          /* @__PURE__ */ e(Oe, { available: c.thursday, children: r.teamTemplateList.weeklyAvailability.thursday() }),
          /* @__PURE__ */ e(Oe, { available: c.friday, children: r.teamTemplateList.weeklyAvailability.friday() }),
          /* @__PURE__ */ e(Oe, { available: c.saturday, children: r.teamTemplateList.weeklyAvailability.saturday() })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ n(Ad, { children: [
      /* @__PURE__ */ e(Od, { children: r.teamTemplateList.attendees() }),
      /* @__PURE__ */ e($d, { children: i.map((l, h) => /* @__PURE__ */ n(sn.Fragment, { children: [
        /* @__PURE__ */ e(Vd, { children: l.map((d) => /* @__PURE__ */ e(jd, { ...d }, d.id)) }),
        h < i.length - 1 ? /* @__PURE__ */ e(Hd, {}) : null
      ] }, h)) })
    ] }),
    /* @__PURE__ */ e(Nd, {}),
    /* @__PURE__ */ e(Ud, { children: t.isPlanCanceled ? /* @__PURE__ */ e(Qa, { children: r.teamTemplateList.planUnavailableDescription() }) : t.template.isPublished ? /* @__PURE__ */ n(W, { children: [
      /* @__PURE__ */ n(Gd, { children: [
        /* @__PURE__ */ e(
          oa,
          {
            disabled: !t.template.isPublished,
            onClick: () => t.onLinkCopy(t.template.id),
            children: r.teamTemplateList.actions.copyLink()
          }
        ),
        a ? null : /* @__PURE__ */ e(
          oa,
          {
            disabled: !t.template.isPublished,
            onClick: () => t.onShareModalOpen(t.template),
            children: r.teamTemplateList.actions.shareByText()
          }
        ),
        a ? null : /* @__PURE__ */ e(
          oa,
          {
            disabled: !t.template.isPublished,
            onClick: () => t.onEmbedModalOpen(t.template.id),
            children: r.teamTemplateList.actions.embed()
          }
        )
      ] }),
      /* @__PURE__ */ e(Xa, { onClick: () => t.onTemplateEdit(t.template.id), children: r.teamTemplateList.actions.viewOrEdit() })
    ] }) : /* @__PURE__ */ n(W, { children: [
      /* @__PURE__ */ e(Qa, { children: r.teamTemplateList.publishDescription() }),
      a ? null : /* @__PURE__ */ e(
        Xa,
        {
          onClick: () => t.onPublishToggle(t.template.id, t.template.isPublished),
          children: r.teamTemplateList.actions.publish()
        }
      )
    ] }) })
  ] }) }) });
}, Dm = sn.memo(xm), Im = ({
  teamId: t,
  scrollToTemplateId: a,
  teamTemplatesKey: r,
  templatesAreRefetching: i,
  templatesAreLoading: c,
  templatesAreCompleted: s,
  planState: o,
  planCapability: l,
  pages: h,
  pageParams: d,
  deleteScrollToTemplateId: m,
  gotoTeamTemplateCreatePage: D,
  copyTeamTemplateLink: u,
  openEmbedModal: z,
  gotoTeamTemplateEditPage: k,
  onTeamTemplateCountChange: L,
  fetchTeamMembers: R,
  fetchCandidates: M,
  copyText: H,
  getHtmlText: f,
  getPlainText: P,
  timezoneGroups: F,
  groupedTimezones: O,
  getTimezoneByKey: Q,
  ...$
}) => {
  const te = ce({ query: le.screen.mobile }), { LL: w } = S(), T = Je(), C = v.useSnackbar(), { data: b } = Pt({
    queryKey: ["teamMembers", t],
    queryFn: async () => {
      const y = await R();
      if (y === void 0)
        throw new Error();
      return y;
    },
    initialData: $.teamMembers
  });
  function q(y) {
    const be = y >= 120 ? { type: "hour", duration: y / 60 } : { type: "min", duration: y };
    return w.teamTemplateList.displayDuration[be.type]({ duration: be.duration });
  }
  const U = A(() => h.reduce((y, Y) => ({
    items: [...y.items, ...Y.items],
    ...Y.nextToken === void 0 ? {} : { nextToken: Y.nextToken }
  }), { items: [] }), [h]);
  ne(() => {
    s && L(U.items.length);
  }, [s, L, U.items.length]);
  const { mutate: ge, isPending: de } = fe({
    mutationFn: async (y) => {
      const Y = await $.toggleTeamTemplatePublish(y);
      if (Y.type === "error")
        throw new Error();
      return { updatedTemplate: Y.updatedTemplate };
    },
    onSuccess: (y) => {
      const Y = h.map((ve) => ({
        items: ve.items.map((re) => re.id === y.updatedTemplate.id ? y.updatedTemplate : re),
        nextToken: ve.nextToken
      })), be = d.filter((ve) => ve !== y.updatedTemplate.id);
      T.setQueryData(r, {
        pages: Y,
        pageParams: be
      });
    },
    onError: () => {
      C.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: w.teamTemplateList.unknownError() }) })
      );
    }
  }), { mutate: K, isPending: me } = fe({
    mutationFn: async (y) => {
      const Y = await $.duplicateTeamTemplate(y);
      if (Y.type === "error")
        throw new Error();
      return { newTemplate: Y.newTemplate };
    },
    onSuccess: (y) => {
      const Y = h.map((be, ve) => ve !== 0 ? be : {
        items: [y.newTemplate, ...be.items],
        nextToken: be.nextToken
      });
      T.setQueryData(r, {
        pages: Y,
        pageParams: d
      }), C.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: w.teamTemplateList.duplicateTeamTemplateSuccessToast() }) })
      );
    },
    onError: () => {
      C.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: w.teamTemplateList.unknownError() }) })
      );
    }
  }), { mutate: G, isPending: Fe } = fe({
    mutationFn: async (y) => (await $.deleteTeamTemplate(y), { deletedTemplateId: y.teamTemplateId }),
    onSuccess: (y) => {
      const Y = h.map((ve) => ({
        items: ve.items.filter((re) => re.id !== y.deletedTemplateId),
        nextToken: ve.nextToken
      })), be = d.filter((ve) => ve !== y.deletedTemplateId);
      T.setQueryData(r, {
        pages: Y,
        pageParams: be
      }), C.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "success", children: /* @__PURE__ */ e(v.Description, { children: w.teamTemplateList.deleteTeamTemplateSuccessToast() }) })
      );
    },
    onError: () => {
      C.create(
        /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: w.teamTemplateList.unknownError() }) })
      );
    }
  }), [E, Z] = V({ type: "none" }), [se, Se] = V(""), [xe, At] = V(
    $.initialFilterQuery.privateTitle
  ), je = (y) => {
    At(y), $.updateFilterQuery({
      teamId: t,
      query: {
        privateTitle: y,
        attendee: I?.id ?? null,
        duration: ae
      }
    });
  }, [Ue, mt] = V(""), Ot = A(() => b.filter(
    (y) => [y.name, y.email].join(" ").toLowerCase().indexOf(Ue.toLowerCase()) >= 0
  ), [b, Ue]), [I, Te] = V(
    b.find((y) => y.id === $.initialFilterQuery.attendee) ?? null
  ), ue = (y) => {
    Te(y), $.updateFilterQuery({
      teamId: t,
      query: {
        privateTitle: xe,
        attendee: y?.id ?? null,
        duration: ae
      }
    });
  }, [ae, Ce] = V($.initialFilterQuery.duration), pe = (y) => {
    Ce(y), $.updateFilterQuery({
      teamId: t,
      query: {
        privateTitle: xe,
        attendee: I?.id ?? null,
        duration: y
      }
    });
  }, he = A(() => U.items.filter(
    (y) => xe === null || y.privateTitle.toLowerCase().includes(xe.toLowerCase())
  ).filter(
    (y) => I === null || y.attendeesGroups.flat().flatMap((Y) => [Y.organizerMemberId, ...Y.attendeeMemberIds]).some(
      (Y) => Y === I.id
    )
  ).filter((y) => ae === null || y.duration === ae), [U.items, xe, I, ae]), { scrollRef: oe, gridSize: ze, pageSize: $t, virtualizer: et } = Kd({
    templates: he,
    listFrameClassName: Rn,
    pageFrameClassName: $.pageFrameClassName
  }), [Ee, Vt] = V(a);
  ne(() => {
    if (Ee === void 0)
      return;
    const y = U.items.find((Y) => Y.id === Ee);
    y !== void 0 && Z({ type: "shareUrl", teamTemplate: y });
  }, [t, Ee, U]), ne(() => {
    const y = async () => {
      if (i || Ee === void 0 || oe.current === null)
        return;
      const Y = oe.current, be = he.findIndex((Gt) => Gt.id === Ee);
      if (be === -1)
        return;
      const ve = document.querySelector('[data-name="list-actual"]');
      if (ve === null)
        return;
      const re = document.querySelector('[data-name="template-list-sticky"]');
      if (re === null)
        return;
      const Ut = Math.floor(be / ze.grid) * (ze.height + ze.margin.y), jn = Y.scrollTop, Un = Y.getBoundingClientRect().top, Gn = ve.getBoundingClientRect().top - Un, Yn = ze.grid === 1 && ze.height === 246 ? 0 : re.clientHeight;
      await new Promise((Gt) => {
        setTimeout(() => {
          Y.scrollTo({
            top: Ut + jn + Gn - Yn,
            behavior: "smooth"
          }), m(), Vt(void 0), Gt(void 0);
        });
      });
    };
    try {
      y();
    } catch {
    }
  }, [
    Ee,
    m,
    he,
    et,
    oe,
    i,
    ze
  ]);
  const tt = A(() => o.type === "paid" && o.state.status === "canceled" || U.items.length === 0 && c ? !1 : l.template.type === "unlimited" ? !0 : l.template.count > U.items.length, [U, l, c, o]), Re = A(() => o.type === "paid" && o.state.status === "canceled", [o]), Ht = ee(() => {
    D({ teamId: t });
  }, [t, D]), qn = ee((y, Y) => {
    ge({ teamId: t, teamTemplateId: y, currentIsPublished: Y });
  }, [t, ge]), Bn = ee((y) => {
    K({ teamId: t, teamTemplateId: y });
  }, [t, K]), An = ee((y) => {
    G({ teamId: t, teamTemplateId: y });
  }, [t, G]), On = ee((y) => {
    u({ teamId: t, teamTemplateId: y }), Z({ type: "none" }), C.create(
      /* @__PURE__ */ e(v.Root, { snackbarType: "default", children: /* @__PURE__ */ e(v.Description, { children: w.teamTemplateList.copyTeamTemplateLinkSuccessToast() }) })
    );
  }, [t, u, C, w.teamTemplateList]), $n = ee((y) => {
    Z({ type: "shareUrl", teamTemplate: y });
  }, []), Vn = ee((y) => {
    z({ teamId: t, teamTemplateId: y }), Z({ type: "none" });
  }, [t, z]), Hn = ee((y) => {
    k({ teamId: t, teamTemplateId: y });
  }, [t, k]), jt = A(
    () => te ? {
      align: "center",
      width: "full"
    } : {
      align: "end",
      width: "358px"
    },
    [te]
  );
  return /* @__PURE__ */ n(W, { children: [
    /* @__PURE__ */ e(
      X.Spinner,
      {
        active: !s && he.length === 0 || i && E.type !== "shareUrl" || de || me || Fe
      }
    ),
    /* @__PURE__ */ n(
      "div",
      {
        ref: oe,
        "data-name": "page-container",
        className: gm,
        style: { height: `${$t.height}px`, overflowY: "auto" },
        children: [
          /* @__PURE__ */ n("div", { className: fm, "data-name": "page-inner", children: [
            /* @__PURE__ */ e(Ln, { "data-name": "template-list-sticky", children: /* @__PURE__ */ n(W, { children: [
              /* @__PURE__ */ e(ln, { children: /* @__PURE__ */ n("div", { className: km, children: [
                /* @__PURE__ */ e("div", { className: cm, children: tt ? /* @__PURE__ */ n(p.Button, { onClick: () => Ht(), children: [
                  /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Add, {}) }),
                  /* @__PURE__ */ e(p.Text, { children: w.teamTemplateList.createButton() })
                ] }) : /* @__PURE__ */ e(_.Provider, { children: /* @__PURE__ */ n(_.Root, { children: [
                  /* @__PURE__ */ e(_.Trigger, { children: /* @__PURE__ */ n(p.Button, { disabled: !0, onClick: () => Ht(), children: [
                    /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Add, {}) }),
                    /* @__PURE__ */ e(p.Text, { children: w.teamTemplateList.createButton() })
                  ] }) }),
                  /* @__PURE__ */ e(_.Portal, { children: /* @__PURE__ */ e(
                    _.Content,
                    {
                      "data-component-name": "provider-resource-tooltip",
                      side: "bottom",
                      align: "end",
                      children: w.teamTemplateList.choosePlan()
                    }
                  ) })
                ] }) }) }),
                /* @__PURE__ */ n("div", { className: bm, children: [
                  /* @__PURE__ */ n("div", { className: zm, children: [
                    /* @__PURE__ */ e("span", { children: w.teamTemplateList.filter() }),
                    /* @__PURE__ */ e("span", { children: ":" })
                  ] }),
                  /* @__PURE__ */ n("div", { className: hm, children: [
                    /* @__PURE__ */ n(N.Root, { children: [
                      /* @__PURE__ */ e(N.Trigger, { disabled: xe !== null, children: /* @__PURE__ */ e(p.Button, { buttonType: "secondary", "aria-label": "private-title-filter", children: /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Search, {}) }) }) }),
                      /* @__PURE__ */ e(
                        N.Menu,
                        {
                          ...jt,
                          collisionBoundary: oe.current,
                          collisionPadding: 16,
                          "data-component-name": "filter-dropdown-menu",
                          children: /* @__PURE__ */ n("form", { className: Tm, children: [
                            /* @__PURE__ */ e("div", { className: Cm, children: /* @__PURE__ */ e(j.Frame, { children: /* @__PURE__ */ n(j.TextFieldRoot, { children: [
                              /* @__PURE__ */ e(j.IconFrame, { children: /* @__PURE__ */ e(x.Search, {}) }),
                              /* @__PURE__ */ e(
                                j.Input,
                                {
                                  value: se,
                                  placeholder: w.teamTemplateList.filterPrivateTitlePlaceholder(),
                                  onChange: (y) => Se(y.target.value)
                                }
                              ),
                              /* @__PURE__ */ e(
                                j.IconButton,
                                {
                                  type: "button",
                                  onClick: () => Se(""),
                                  children: /* @__PURE__ */ e(x.Close, {})
                                }
                              )
                            ] }) }) }),
                            /* @__PURE__ */ e(N.MenuItem, { "data-component-name": "private-title-filter-dropdown-menu-item", children: /* @__PURE__ */ e(
                              p.Button,
                              {
                                disabled: se === "",
                                type: "submit",
                                onClick: () => {
                                  je(se), Se("");
                                },
                                children: /* @__PURE__ */ e(p.Text, { children: w.teamTemplateList.filterPrivateTitleSearchButton() })
                              }
                            ) })
                          ] })
                        }
                      )
                    ] }),
                    /* @__PURE__ */ n(N.Root, { children: [
                      /* @__PURE__ */ e(N.Trigger, { disabled: I !== null, children: /* @__PURE__ */ e(p.Button, { buttonType: "secondary", "aria-label": "attendee-filter", children: /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Avatar, {}) }) }) }),
                      /* @__PURE__ */ n(
                        N.Menu,
                        {
                          ...jt,
                          collisionBoundary: oe.current,
                          collisionPadding: 16,
                          "data-component-name": "filter-dropdown-menu",
                          children: [
                            /* @__PURE__ */ e("div", { className: dm, children: /* @__PURE__ */ e(j.Frame, { children: /* @__PURE__ */ n(j.TextFieldRoot, { children: [
                              /* @__PURE__ */ e(j.IconFrame, { children: /* @__PURE__ */ e(x.Search, {}) }),
                              /* @__PURE__ */ e(
                                j.Input,
                                {
                                  value: Ue,
                                  placeholder: w.teamTemplateList.filterAttendeesPlaceholder(),
                                  onChange: (y) => mt(y.target.value),
                                  onKeyDown: (y) => y.stopPropagation()
                                }
                              )
                            ] }) }) }),
                            Ot.map((y) => /* @__PURE__ */ n(
                              N.MenuItem,
                              {
                                onClick: () => {
                                  ue(y), mt("");
                                },
                                children: [
                                  /* @__PURE__ */ e(N.IconsFrame, { children: /* @__PURE__ */ e(
                                    En,
                                    {
                                      src: y.photoUrl,
                                      fallbackText: y.fallbackText,
                                      fallbackTextColor: y.fallbackTextColor,
                                      fallbackBackgroundColor: y.fallbackBackgroundColor
                                    }
                                  ) }),
                                  /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { children: y.name }) })
                                ]
                              },
                              y.id
                            ))
                          ]
                        }
                      )
                    ] }),
                    /* @__PURE__ */ n(N.Root, { children: [
                      /* @__PURE__ */ e(N.Trigger, { disabled: ae !== null, children: /* @__PURE__ */ e(p.Button, { buttonType: "secondary", "aria-label": "duration-filter", children: /* @__PURE__ */ e(p.IconFrame, { children: /* @__PURE__ */ e(x.Schedule, {}) }) }) }),
                      /* @__PURE__ */ n(
                        N.Menu,
                        {
                          ...jt,
                          collisionBoundary: oe.current,
                          collisionPadding: 16,
                          "data-component-name": "filter-dropdown-menu",
                          children: [
                            /* @__PURE__ */ e(
                              N.CheckboxMenuItem,
                              {
                                checked: ae === null,
                                onCheckedChange: () => pe(null),
                                children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { children: w.teamTemplateList.filterAllDuration() }) })
                              }
                            ),
                            Nm.map((y) => /* @__PURE__ */ e(
                              N.CheckboxMenuItem,
                              {
                                checked: ae === y,
                                onCheckedChange: () => pe(y),
                                children: /* @__PURE__ */ e(N.TextFrame, { children: /* @__PURE__ */ e(N.ItemText, { children: q(y) }) })
                              },
                              y
                            ))
                          ]
                        }
                      )
                    ] })
                  ] })
                ] })
              ] }) }),
              xe !== null || I !== null || ae !== null ? /* @__PURE__ */ e("div", { className: ym, children: /* @__PURE__ */ n("div", { className: vm, children: [
                xe !== null ? /* @__PURE__ */ n(na, { children: [
                  /* @__PURE__ */ e(ra, { children: /* @__PURE__ */ e(x.Search, { size: 16 }) }),
                  /* @__PURE__ */ e(ia, { children: xe }),
                  /* @__PURE__ */ e(la, { onClick: () => je(null) })
                ] }) : null,
                I !== null ? /* @__PURE__ */ n(na, { children: [
                  /* @__PURE__ */ e(ra, { children: /* @__PURE__ */ e(x.Avatar, { size: 16 }) }),
                  /* @__PURE__ */ e(ia, { children: I.name }),
                  /* @__PURE__ */ e(la, { onClick: () => ue(null) })
                ] }) : null,
                ae !== null ? /* @__PURE__ */ n(na, { children: [
                  /* @__PURE__ */ e(ra, { children: /* @__PURE__ */ e(x.Schedule, { size: 16 }) }),
                  /* @__PURE__ */ e(ia, { children: q(ae) }),
                  /* @__PURE__ */ e(la, { onClick: () => pe(null) })
                ] }) : null
              ] }) }) : null
            ] }) }),
            /* @__PURE__ */ e(ln, { "data-name": "list-content-frame", children: /* @__PURE__ */ e("div", { "data-name": "list-frame", className: Lm, children: s && U.items.length === 0 ? /* @__PURE__ */ n(W, { children: [
              /* @__PURE__ */ e("div", { className: rn, children: w.teamTemplateList.emptyListTitle() }),
              /* @__PURE__ */ e(
                "p",
                {
                  className: It,
                  dangerouslySetInnerHTML: { __html: w.teamTemplateList.emptyListDescription() }
                }
              ),
              /* @__PURE__ */ e("div", { className: pm, children: w.teamTemplateList.emptyListMeritTitle() }),
              /* @__PURE__ */ n("ul", { className: um, children: [
                /* @__PURE__ */ e("li", { children: w.teamTemplateList.emptyListMeritList1() }),
                /* @__PURE__ */ e("li", { children: w.teamTemplateList.emptyListMeritList2() }),
                /* @__PURE__ */ e("li", { children: w.teamTemplateList.emptyListMeritList3() })
              ] }),
              te ? /* @__PURE__ */ e("p", { className: It, children: w.teamTemplateList.emptyListCreatePromotionMobile() }) : /* @__PURE__ */ e("p", { className: It, children: w.teamTemplateList.emptyListCreatePromotionDesktop() })
            ] }) : s && he.length === 0 ? /* @__PURE__ */ n(W, { children: [
              /* @__PURE__ */ e("div", { className: rn, children: w.teamTemplateList.emptyFilteredListTitle() }),
              /* @__PURE__ */ e("p", { className: It, children: w.teamTemplateList.emptyFilteredListDescription() })
            ] }) : /* @__PURE__ */ e(
              "div",
              {
                "data-name": "list-actual",
                style: { height: `${et.getTotalSize()}px`, position: "relative" },
                children: et.getVirtualItems().map((y) => {
                  const Y = y.start, be = ze.margin.x > 0 ? y.lane * (ze.width + ze.margin.x) : 0, ve = he[y.index];
                  return ve !== void 0 ? /* @__PURE__ */ e(
                    "div",
                    {
                      "data-name": "list-item",
                      style: {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: ze.width,
                        height: y.size,
                        transform: `translateX(${be}px) translateY(${Y}px)`
                      },
                      children: /* @__PURE__ */ e(
                        Dm,
                        {
                          template: ve,
                          teamMembers: b,
                          onPublishToggle: (re, Ut) => void qn(re, Ut),
                          onTemplateDuplicate: (re) => void Bn(re),
                          onTemplateDelete: (re) => Z({ type: "confirmDelete", teamTemplateId: re }),
                          onLinkCopy: (re) => On(re),
                          onShareModalOpen: (re) => $n(re),
                          onEmbedModalOpen: (re) => Vn(re),
                          onTemplateEdit: (re) => Hn(re),
                          canCreateTemplate: tt,
                          isPlanCanceled: Re
                        }
                      )
                    },
                    y.key
                  ) : null;
                })
              }
            ) }) })
          ] }),
          te && tt ? /* @__PURE__ */ e(kn, { onClick: () => Ht(), children: /* @__PURE__ */ e(x.Add, {}) }) : null
        ]
      }
    ),
    /* @__PURE__ */ e(g.Root, { open: E.type !== "none", onOpenChange: (y) => Z(y ? E : { type: "none" }), children: E.type === "confirmDelete" ? /* @__PURE__ */ n(g.Content, { zIndex: "100", children: [
      /* @__PURE__ */ n(g.Header, { children: [
        /* @__PURE__ */ e(g.Title, { children: w.teamTemplateList.confirmDeleteDialogTitle() }),
        /* @__PURE__ */ e(g.DefaultClose, {})
      ] }),
      /* @__PURE__ */ e(g.Body, { children: /* @__PURE__ */ e(g.Description, { children: w.teamTemplateList.confirmDeleteDialogDescription() }) }),
      /* @__PURE__ */ n(g.Footer, { children: [
        /* @__PURE__ */ e(g.FooterActionButton, { buttonType: "danger", onClick: () => Z({ type: "none" }), children: /* @__PURE__ */ e(p.Text, { children: w.teamTemplateList.confirmDeleteDialogNo() }) }),
        /* @__PURE__ */ e(
          g.FooterActionButton,
          {
            onClick: () => {
              An(E.teamTemplateId), Z({ type: "none" });
            },
            children: /* @__PURE__ */ e(p.Text, { children: w.teamTemplateList.confirmDeleteDialogYes() })
          }
        )
      ] })
    ] }) : E.type === "shareUrl" ? /* @__PURE__ */ e(
      om,
      {
        teamTemplateListItem: E.teamTemplate,
        timezoneGroups: F,
        groupedTimezones: O,
        getTimezoneByKey: Q,
        copyText: H,
        fetchCandidates: M,
        getHtmlText: f,
        getPlainText: P
      }
    ) : null })
  ] });
}, Fm = ({ teamId: t, fetchTeamTemplates: a, ...r }) => {
  const i = ["teamTemplates", t], {
    data: c,
    status: s,
    isLoading: o,
    isRefetching: l,
    isFetchingNextPage: h,
    hasNextPage: d,
    fetchNextPage: m
  } = tr({
    queryKey: i,
    queryFn: async ({ pageParam: u }) => await a({ teamId: t, nextToken: u === "initial" || typeof u != "string" ? void 0 : u }),
    initialPageParam: "initial",
    getPreviousPageParam: (u) => u.nextToken ?? void 0,
    getNextPageParam: (u) => u.nextToken ?? void 0,
    refetchOnWindowFocus: !1,
    staleTime: 5e3
  }), D = A(
    () => !o && !l && !h && !d,
    [
      o,
      l,
      h,
      d
    ]
  );
  return ne(() => {
    h || d && m();
  }, [
    h,
    d,
    m
  ]), s === "success" ? /* @__PURE__ */ e(
    Im,
    {
      pages: c.pages,
      pageParams: c.pageParams,
      templatesAreRefetching: l,
      templatesAreLoading: o,
      templatesAreCompleted: D,
      teamId: t,
      teamTemplatesKey: i,
      ...r
    }
  ) : /* @__PURE__ */ e(X.Spinner, { active: !0 });
}, Mm = ({ fetchPlanCapability: t, fetchTeamPlanState: a, ...r }) => {
  const i = ["teamPlanCapability", r.teamId], { data: c } = Ie({
    queryFn: async () => t({ teamId: r.teamId }),
    queryKey: i
  }), s = ["teamPlanState", r.teamId], { data: o } = Ie({
    queryFn: () => a({ teamId: r.teamId }),
    queryKey: s
  });
  return /* @__PURE__ */ e(
    Fm,
    {
      ...r,
      planCapability: c,
      planState: o
    }
  );
}, Pm = (t) => {
  const a = v.useSnackbar();
  return /* @__PURE__ */ e(dt, { fallback: /* @__PURE__ */ e(W, {}), onError: () => {
    a.create(
      /* @__PURE__ */ e(v.Root, { snackbarType: "danger", children: /* @__PURE__ */ e(v.Description, { children: "Unknown Error" }) })
    );
  }, children: /* @__PURE__ */ e(Ke, { fallback: /* @__PURE__ */ e(X.Spinner, { active: !0 }), children: /* @__PURE__ */ e(Mm, { ...t }) }) });
}, Tu = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  TeamAvailablePlanList: il,
  TeamFormList: ps,
  TeamMemberJoin: po,
  TeamOrganizationProfile: go,
  TeamPaymentInfo: To,
  TeamResource: nc,
  TeamTemplateConfirmedEventEditByMember: Gc,
  TeamTemplateList: Pm
}, Symbol.toStringTag, { value: "Module" })), wm = () => /* @__PURE__ */ e(v.Portal, { zIndex: "1050" }), Cu = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  SnackbarPortal: wm
}, Symbol.toStringTag, { value: "Module" }));
export {
  bu as ReactPagesEntryPoint,
  fu as features,
  Tu as pages,
  Cu as ui
};
