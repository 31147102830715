import { WeekDay } from '@/models/data/userInfo'
import CalendarControlModule from '@/store/modules/calendarControl'
import CalendarsModule, { PrivateCalendar } from '@/store/modules/calendars'
import ProfileModule from '@/store/modules/profile'
import { activeLayerNames, CalendarBodyMode, UserCalendarStatus, WorkDayKey } from '@/types'

export const getStartWeekDayForVueCalendar = () => {
  const startWeek = CalendarControlModule.startWeek
  if (startWeek < 7 && startWeek > 1) {
    return startWeek + 1
  } else if (startWeek === 7) {
    return 1
  } else {
    return 2
  }
}
/**
 * 曜日のリストを週の始まりの設定に合わせてリターンする。
 * @param startOfWeekday
 * @returns
 * i.e) ['sunday', 'monday' .....]
 */
export const weekDays = (startOfWeekday: WeekDay = WeekDay.sunday): WorkDayKey[] => {
  return Array.from(Array(7)).map((_, i) => {
    const addDay = (startOfWeekday + i) % 7
    return WeekDay[addDay === 0 ? 7 : addDay] as WorkDayKey // fixme: WeekDay自体を変える必要がある
  })
}

export const filterByPrimaryCalendar = (privateCalendars: PrivateCalendar[]) => {
  return privateCalendars
    .map(pc => ({ ...pc, calendars: pc.calendars.filter(c => c.primary) }))
    .filter(pc => pc.calendars.length > 0)
}

export const CalendarBodyModeUtil = {
  shouldSaveRegisteredEventStyle(mode: CalendarBodyMode, eventSource: string | undefined): boolean {
    return mode.type === 'layer' && mode.activeLayerName === activeLayerNames.none && eventSource !== 'candidate'
  },
  shouldUpdateLayeredStyle(mode: CalendarBodyMode): boolean {
    return (
      mode.type === 'layer' &&
      !(mode.activeLayerName === activeLayerNames.none || mode.activeLayerName === activeLayerNames.temp)
    )
  },
  isActiveLayerEvent(mode: CalendarBodyMode, eventSource: string | undefined): boolean {
    if (mode.type === 'normal') return true
    else {
      if (mode.activeLayerName === activeLayerNames.candidate) {
        return eventSource === 'candidate'
      }
      if (mode.activeLayerName === 'registered') return eventSource !== 'candidate'
      return true
    }
  },
  classForLayer(mode: CalendarBodyMode, eventSource: string | undefined): 'active-layer' | 'noactive-layer' {
    return this.isActiveLayerEvent(mode, eventSource) ? 'active-layer' : 'noactive-layer'
  },
  getLayerStyles(
    mode: CalendarBodyMode,
    eventStyleId: string,
    eventSource: string | undefined,
    getInset: (id: string) => string
  ): { prop: string; value: string }[] {
    const styles: { prop: string; value: string }[] = []
    if (eventSource === 'candidate') {
      styles.push({ prop: 'left', value: '0%' })
      styles.push({ prop: 'right', value: '0%' })
      if (this.isActiveLayerEvent(mode, eventSource)) {
        styles.push({ prop: 'z-index', value: '100' })
      } else {
        styles.push({ prop: 'z-index', value: '0' })
      }
    } else {
      const originalInset = getInset(eventStyleId)
      if (originalInset) styles.push({ prop: 'inset', value: originalInset })
    }
    return styles
  }
}

export function hasNoRegisteredCalendars(): boolean {
  return (
    !!ProfileModule.myProfile &&
    !CalendarsModule.getIsCalendarLoading &&
    CalendarsModule.userStatus !== UserCalendarStatus.VALID
  )
}
