import Timezones, { getTZInfoByKey, localTimezone, TimezoneDetail } from '@/lib/timezone'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { TimeZones } from '@/models/data/userInfo'
import TimezoneStorage from '@/models/localStorage/Timezone'
import store from '@/store'
import ProfileModule from '@/store/modules/profile'
import { concat, groupBy, uniq } from 'lodash'
import MiniSearch from 'minisearch'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import CalendarControlModule from './calendarControl'

const MODULE_NAME = 'TimeZoneMaster'

const timezoneLocalStorage = new TimezoneStorage()

type Timezones = 'ja' | 'en'

export type TimezoneDetailWithTimeLabel = TimezoneDetail & {
  fullLabelWithTime: string
}

export type TimezoneKeyAndDisplayName = {
  key: string
  displayName: string
  canEdit?: boolean
}
type AllTimezones = {
  [key in Timezones]?: TimezoneDetail[]
}

const searchTimezone = (searchArray: TimezoneDetail[], searchWord: string) => {
  const searcher = new MiniSearch({
    fields: ['key', 'abbreviation', 'label', 'subLabel'],
    storeFields: ['key'],
    idField: 'key'
  })
  searcher.addAll(searchArray)
  return searcher.search(searchWord)
}
export interface IModuleTimezone {
  _timezones: AllTimezones
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class TimezoneMaster extends VuexModule {
  _timezones: AllTimezones = Timezones
  timezoneForNonLoginUser = null
  _timezoneKeyForInterrupt = null // 公開URLなど、ユーザーのTimezoneではなく独自のTimezoneで表示する必要がある場合使用

  get allTimezones(): ({ locale }: { locale: string }) => TimezoneDetailWithTimeLabel[] {
    return ({ locale = ProfileModule.getLanguage }: { locale: string }) => {
      const tzByLocal: TimezoneDetail[] = this._timezones[locale]
      return tzByLocal.map(tz => {
        const time = spirDateFormat(new Date(), spirDateFormatTypes.hourMin, { timeZone: tz.key }) //'HH:mm'
        return {
          ...tz,
          fullLabelWithTime: `${tz.fullLabel}(${time})`
        }
      })
    }
  }
  get timezonesByGroup() {
    return groupBy(this.allTimezones({ locale: ProfileModule.getLanguage }), 'group')
  }
  get searchTimezoneByString() {
    return (searchStr: string) => {
      // @ts-expect-error TS2345
      const searcherJp = searchTimezone(this._timezones['ja'], searchStr)
      // @ts-expect-error TS2345
      const searcherEn = searchTimezone(this._timezones['en'], searchStr)
      const allResultKeys = concat(searcherJp, searcherEn).map(tz => tz.key)
      return uniq(allResultKeys)
    }
  }
  get timezoneByKey() {
    return ({ key, locale = ProfileModule.getLanguage }: { key: string; locale?: string }) => {
      const findByKey = this.allTimezones({ locale }).find(a => a.key === key)
      return findByKey || getTZInfoByKey(key)
    }
  }
  get userTimezoneKey() {
    return ProfileModule.primaryTimezoneKey || this.timezoneForNonLoginUser || localTimezone()
  }
  get userTimezoneInfo() {
    const timezoneInfoByUserTimezoneKey = this.timezoneByKey({ key: this.userTimezoneKey })
    return {
      ...timezoneInfoByUserTimezoneKey,
      abbreviation: ProfileModule.myProfile?.timeZones.primary.displayName || timezoneInfoByUserTimezoneKey.abbreviation
    }
  }
  get localTimezoneInfo() {
    return this.timezoneByKey({ key: localTimezone() })
  }
  get timezoneKeysForDisplay(): TimezoneKeyAndDisplayName[] {
    const keyAndDisplayName = (key: string, displayName?: string) => {
      const timezoneInfo = this.timezoneByKey({ key })
      return {
        key,
        displayName: displayName || timezoneInfo.abbreviation
      }
    }
    const returnValue = []
    // Timezoneに割り込みが存在する場合は、割り込みTimezoneとPrimaryTimezone
    if (this._timezoneKeyForInterrupt) {
      // @ts-expect-error TS2345
      returnValue.push(keyAndDisplayName(this._timezoneKeyForInterrupt))
      if (this._timezoneKeyForInterrupt !== this.userTimezoneKey) {
        returnValue.push(
          // @ts-expect-error TS2345
          keyAndDisplayName(this.userTimezoneKey, ProfileModule.myProfile?.timeZones.primary.displayName)
        )
      }
    } else {
      const primary = keyAndDisplayName(this.userTimezoneKey, ProfileModule.myProfile?.timeZones.primary.displayName)
      primary['canEdit'] = true
      // @ts-expect-error TS2345
      returnValue.push(primary)
      if (ProfileModule.myProfile?.timeZones.secondary) {
        returnValue.push(
          // @ts-expect-error TS2345
          keyAndDisplayName(
            ProfileModule.myProfile?.timeZones.secondary.key,
            ProfileModule.myProfile?.timeZones.secondary.displayName
          )
        )
      }
      if (ProfileModule.myProfile?.timeZones.tertiary) {
        returnValue.push(
          // @ts-expect-error TS2345
          keyAndDisplayName(
            ProfileModule.myProfile?.timeZones.tertiary.key,
            ProfileModule.myProfile?.timeZones.tertiary.displayName
          )
        )
      }
    }
    return returnValue
  }
  get hasInterrupt(): boolean {
    return !!this._timezoneKeyForInterrupt
  }
  @Action
  updatePrimaryTimezone(primaryTimezoneKey: string) {
    const userProfile = ProfileModule.myProfile
    if (!userProfile) {
      return this.updateTimezone({ primary: { key: primaryTimezoneKey } })
    }
    const timezoneClone = {
      ...userProfile.timeZones
    }
    timezoneClone.primary.key = primaryTimezoneKey
    timezoneClone.primary.displayName = ''
    return this.updateTimezone(timezoneClone)
  }
  @Action
  async updateTimezone(payload: TimeZones) {
    const currentTimezone = this.userTimezoneKey
    if (!ProfileModule.myProfile) {
      this.SET_TIMEZONE(payload.primary.key)
    } else {
      this.saveCurrentTimezoneToLocalStorage()
      await ProfileModule.updateTimezone(payload)
    }
    CalendarControlModule.UpdateEventStartByChangingTimezone({
      currentTimezone,
      newTimezone: payload.primary.key,
      isInit: true
    })
  }
  @Action
  saveCurrentTimezoneToLocalStorage() {
    timezoneLocalStorage.saveTimezone(localTimezone(), this.userTimezoneKey)
  }
  @Action
  needToChangeTimezone() {
    if (!ProfileModule.myProfile) {
      return false
    }
    const localTZ = localTimezone()
    if (this.userTimezoneKey === localTZ) {
      return false
    }
    const savedLocalTimezoneKey = timezoneLocalStorage.loadTimezone()?.localTimezone
    if (savedLocalTimezoneKey === localTZ) {
      return false
    }
    return true
  }
  @Action
  UpdateTimezoneForInterrupt(newTimezone?: string) {
    const currentTimezone = this.timezoneKeysForDisplay[0].key
    this.SET_TIMEZONE_FOR_INTERRUPT(newTimezone)
    const nextTimezone = newTimezone || this.userTimezoneKey
    if (currentTimezone !== newTimezone) {
      CalendarControlModule.UpdateEventStartByChangingTimezone({
        currentTimezone,
        newTimezone: nextTimezone,
        isInit: !newTimezone
      })
    }
  }
  @Mutation
  SET_TIMEZONE(timezone: string) {
    // @ts-expect-error TS2322
    return (this.timezoneForNonLoginUser = timezone)
  }
  @Mutation
  SET_TIMEZONE_FOR_INTERRUPT(timezone?: string) {
    // @ts-expect-error TS2322
    this._timezoneKeyForInterrupt = timezone
  }
}

export default getModule(TimezoneMaster)
