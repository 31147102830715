import store from '@/store'
import { AbTestingTogglableFeatures, TogglableFeature, TogglableFeatures } from '@/types/feature'
import { getAll, getRemoteConfig, isSupported } from 'firebase/remote-config'
import { setEnabledFeatures } from 'vue-feature-flipping'
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'

@Module({
  dynamic: true,
  name: 'FeatureToggler',
  namespaced: true,
  store
})
export class FeatureToggler extends VuexModule {
  @Action
  async initialize() {
    const values = (await isSupported()) ? getAll(getRemoteConfig()) : {}
    const filterEnabledFeatures = (features: { [key: string]: TogglableFeature }) => {
      return Object.values(features)
        .filter(feature => !!values[feature.remoteConfigKeyName] && values[feature.remoteConfigKeyName].asBoolean())
        .map(feature => feature.featureName)
    }
    const enabledTogglableFeatures = filterEnabledFeatures(TogglableFeatures)
    const enabledAbTestingTogglableFeatures = filterEnabledFeatures(AbTestingTogglableFeatures)
    setEnabledFeatures([...enabledTogglableFeatures, ...enabledAbTestingTogglableFeatures])
  }
}
export default getModule(FeatureToggler)
