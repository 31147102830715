import axiosInstance from '@/lib/axios'
import {
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostResponse,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse,
  TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostRequest,
  TeamsTeamIdAvailabilitySharingsPreviewSlotsPostRequest,
  TeamsTeamIdAvailabilitySharingsPreviewSlotsPostResponse
} from '@spirinc/contracts'

export const previewSlots = async (
  teamId: string,
  payload: TeamsTeamIdAvailabilitySharingsPreviewSlotsPostRequest
): Promise<TeamsTeamIdAvailabilitySharingsPreviewSlotsPostResponse> => {
  return (await axiosInstance.post(`teams/${teamId}/availability-sharings/slots-preview`, payload)).data
}

export const getOverview = async (
  teamId: string,
  id: string
): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse> => {
  return (await axiosInstance.get(`public/teams/${teamId}/availability-sharings/${id}/overview`)).data
}

export const getCandidatesById = async (
  teamId: string,
  id: string
): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse> => {
  return (await axiosInstance.get(`public/teams/${teamId}/availability-sharings/${id}/candidates`)).data
}

export const confirmAsSpirUser = async (
  teamId: string,
  id: string,
  payload: TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostRequest
): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostResponse> => {
  return (await axiosInstance.post(`teams/${teamId}/availability-sharings/${id}/confirm`, payload)).data
}

export const confirmAsGuest = async (
  teamId: string,
  id: string,
  payload: TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostRequest
): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostResponse> => {
  return (await axiosInstance.post(`public/teams/${teamId}/availability-sharings/${id}/confirm`, payload)).data
}

export const getConfirmedInfo = async (data: {
  teamId: string
  availabilitySharingId: string
  availabilitySharingConfirmId: string
}): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse> => {
  return (
    await axiosInstance.get(
      `public/teams/${data.teamId}/availability-sharings/${data.availabilitySharingId}/confirmed/${data.availabilitySharingConfirmId}`
    )
  ).data
}
