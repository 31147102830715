// Dragした時のEventを一時的に保存するStore
import store from '@/store'
import { FullCalendarEvent } from '@/types'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
const MODULE_NAME = 'TempEvent'

type TempEventPayload = {
  start: Date
  end: Date
  allDay?: boolean
  accountId?: string
  calendarId?: string
}
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class TempEvent extends VuexModule {
  // @ts-expect-error TS2322
  private tempEvent: FullCalendarEvent = null

  get getTempEvent(): FullCalendarEvent | null {
    if (!this.tempEvent) {
      return null
    }
    return this.tempEvent
  }

  @Action
  setTempEvent(payload: TempEventPayload) {
    this.SET_TEMP_EVENTS(payload)
  }

  @Mutation
  SET_TEMP_EVENTS(event: TempEventPayload) {
    const newEvent: FullCalendarEvent = {
      id: 'tempEvent',
      start: event.start,
      end: event.end,
      editable: false,
      clickable: false,
      title: '',
      allDay: event.allDay,
      extendedProps: {
        source: 'candidate',
        accountId: event.accountId || undefined,
        calendarId: event.calendarId || undefined
      }
    }
    this.tempEvent = newEvent
  }
  @Mutation
  RESET() {
    // @ts-expect-error TS2322
    this.tempEvent = null
  }
}

export default getModule(TempEvent)
