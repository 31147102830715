import i18nGlobal from '@/i18n'
import { QueryParams } from '@/lib/queryParams'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import TimezoneModule from '@/store/modules/timezones'
import { CandidateForShare, ITypeCalendarListForUI } from '@/types'
import { QueryParameter } from '@/types/queryParameter'
import anchorme from 'anchorme'
import copy from 'clipboard-copy'
import {
  addMinutes,
  differenceInMinutes,
  getUnixTime,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  subMinutes
} from 'date-fns'
import { groupBy, isArray, sortBy } from 'lodash'
import { DateTime } from 'luxon'
import MobileDetect from 'mobile-detect'
import queryString from 'query-string'
import { UAParser } from 'ua-parser-js'
import { IVueI18n } from 'vue-i18n'

export const isMobile = (): boolean => {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile()
}

export const isIOS = (): boolean => {
  return /iP(hone|(o|a)d)/.test(navigator.userAgent)
}

export const isSafari = (): boolean => {
  return /Safari/.test(navigator.userAgent)
}

export const isAndroid = (): boolean => {
  return /Android/.test(navigator.userAgent)
}

export const isNotSupportedForLogin = (userAgent: string = navigator.userAgent): boolean => {
  const notSupportedBrowsers = ['Facebook']
  const browserName = new UAParser(userAgent).getBrowser().name
  // @ts-expect-error TS2345
  return notSupportedBrowsers.includes(browserName)
}
export const isNotSupportedForSignUp = (
  params: {
    userAgent?: string
    queryParameter?: QueryParameter
  } = {
    userAgent: navigator.userAgent
  }
): boolean => {
  const notSupportedBrowsers = ['Facebook', 'Line']
  const uaParser = new UAParser(params.userAgent)
  const browserName = uaParser.getBrowser().name
  // @ts-expect-error TS2345
  if (notSupportedBrowsers.includes(browserName)) {
    return true
  }
  const notSupportedUserAgent = ['YJApp-ANDROID', 'YJApp-IOS']
  for (const uaPart of notSupportedUserAgent) {
    if (uaParser.getUA().includes(uaPart)) {
      return true
    }
  }
  if (!params.queryParameter) {
    return false
  }
  // SmartNewsの広告からの申込みはutmパラメータで弾くようにとサポートから回答があったため
  const notSupportedSource = ['smartnews']
  const utmSource = params.queryParameter.utm_source
  if (!utmSource) {
    return false
  }
  if (isArray(utmSource)) {
    utmSource.forEach(source => {
      if (source && notSupportedSource.includes(source)) {
        return true
      }
    })
    return false
  }
  return notSupportedSource.includes(utmSource)
}

const DURATION_UNIT_THRESHOLD = 120
export function adjustDurationUnit(duration: number): { type: 'hour' | 'min'; duration: number } {
  return duration >= DURATION_UNIT_THRESHOLD
    ? { type: 'hour', duration: duration / 60 }
    : { type: 'min', duration: duration }
}

export const useNativeShare = (userAgent: string): boolean => {
  const uaParser = new UAParser(userAgent)
  // fixme: 本来なら、Desktop Safariのみ対象外にするが、モバイルSafariでIssueがあるため対応が終わるまでモバイルSafariもClipBoardに直接コピー
  // https://developer.apple.com/forums/thread/662629
  const customFilter =
    // @ts-expect-error TS2532
    !uaParser.getBrowser().name.includes('Safari') &&
    uaParser.getOS().name !== 'Windows' &&
    uaParser.getOS().name !== 'Chromium OS'
  return !!navigator.share && customFilter
}

export const copyClipboard = (url: string): 'mobile' | 'clipboard' => {
  copy(url)
  return 'clipboard'
}

type CandidateGroupsArgs = {
  activeCandidates: CandidateForShare[]
  duration: number
  maxStartTimeInterval: number
  i18n?: IVueI18n
  timezone?: string
}

export const mergeCandidates = ({
  candidates,
  duration
}: {
  candidates: CandidateForShare[]
  duration: number
}): CandidateForShare[] => {
  const sortedCandidates = sortBy(candidates, 'start')
  const startTimeIncrements = duration === 15 ? 15 : 30
  const mergedCandidates: CandidateForShare[] = sortedCandidates.reduce(
    (acc: CandidateForShare[], candidate: CandidateForShare): CandidateForShare[] => {
      if (acc.length === 0) {
        return [candidate]
      }
      const lastCandidate = acc[acc.length - 1]
      const lastCandidateStart = DateTime.fromISO(lastCandidate.start)
      const lastCandidateEnd = DateTime.fromISO(lastCandidate.end)
      const start = DateTime.fromISO(candidate.start)
      const end = DateTime.fromISO(candidate.end)
      // 候補が重なっている、またはstart time incrementsと一致（繋がった候補）はmergeする
      if (
        lastCandidateEnd > start ||
        end.diff(lastCandidateEnd.plus({ minutes: startTimeIncrements })).as('minutes') <= 0
      ) {
        const result = [
          ...acc.slice(0, acc.length - 1),
          { start: lastCandidateStart.toJSDate().toISOString(), end: end.toJSDate().toISOString() }
        ]
        return result
      } else {
        return [...acc, candidate]
      }
    },
    []
  )
  return mergedCandidates
}

export const getCandidateGroups = ({
  activeCandidates,
  duration,
  maxStartTimeInterval,
  i18n = i18nGlobal,
  timezone
}: CandidateGroupsArgs): {
  candidateGroups: {
    label: string
    candidates: CandidateForShare[]
    buttonsData: {
      startTime: Date
      endTime: Date
    }[]
  }[]
  periodLabel: string
} => {
  const candidateGroupRecords: { [key: string]: CandidateForShare[] } = groupBy(
    sortBy(activeCandidates, (candidate: CandidateForShare) => candidate.start),
    (candidate: CandidateForShare) =>
      spirDateFormat(parseISO(candidate.start), spirDateFormatTypes.yyyymmddweekday, { timeZone: timezone })
  )

  const candidateGroupList = Object.entries(candidateGroupRecords).sort(
    (a, b) => getUnixTime(parseISO(a[1][0].start)) - getUnixTime(parseISO(b[1][0].start))
  )

  const startTimeInterval = duration > maxStartTimeInterval ? maxStartTimeInterval : duration

  const candidateGroups = candidateGroupList.map(group => {
    const label = group[0]
    const candidates = mergeCandidates({ candidates: group[1], duration })

    let buttonsData: { startTime: Date; endTime: Date }[] = []
    group[1].forEach(candidate => {
      const start = parseISO(candidate.start)
      const end = parseISO(candidate.end)
      const lastFittingStartTime = subMinutes(end, duration)
      const amountOfStartTimes = Math.ceil(differenceInMinutes(lastFittingStartTime, start) / startTimeInterval) + 1

      Array(amountOfStartTimes)
        .fill(null)
        .forEach((_, i) => {
          const start = parseISO(candidate.start)
          const end = parseISO(candidate.end)
          let startTime = addMinutes(start, i * startTimeInterval)
          let endTime = addMinutes(start, i * startTimeInterval + duration)
          if (isAfter(endTime, end)) {
            startTime = subMinutes(end, duration)
            endTime = end
          }

          const buttonData = { startTime, endTime }

          // 前に追加したボタンと同じ時間帯だったら追加しない。
          if (
            buttonsData.length === 0 ||
            !isEqual(buttonsData[buttonsData.length - 1].startTime, buttonData.startTime) ||
            !isEqual(buttonsData[buttonsData.length - 1].endTime, buttonData.endTime)
          ) {
            buttonsData = [...buttonsData, buttonData]
          }
        })
    })

    return { label, candidates, buttonsData }
  })

  let periodLabel = i18n.t('scheduleSharingText.emptyCandidateMessage').toString()
  if (candidateGroups.length > 0) {
    const candidateGroupStart = candidateGroups[0].candidates
    const candidateGroupEnd = candidateGroups[candidateGroups.length - 1].candidates
    const periodStart = candidateGroupStart[0].start
    const periodEnd = candidateGroupEnd[candidateGroupEnd.length - 1].end

    const option = {
      timeZone: timezone,
      locale: i18n.locale
    }
    const periodStartText = spirDateFormat(parseISO(periodStart), spirDateFormatTypes.mdWeekday, option)
    const periodEndText = spirDateFormat(parseISO(periodEnd), spirDateFormatTypes.mdWeekday, option)

    periodLabel = periodStartText === periodEndText ? periodStartText : `${periodStartText}〜${periodEndText}`
  }

  return { candidateGroups, periodLabel }
}

export type ShareSchedulePlainTextArgs = {
  activeCandidates: CandidateForShare[]
  title: string
  duration: number
  url: string
  i18n?: IVueI18n
  timezone?: string
}
export const getShareSchedulePlainText = ({
  activeCandidates,
  title,
  duration,
  url,
  i18n = i18nGlobal,
  timezone
}: ShareSchedulePlainTextArgs) => {
  const maxStartTimeInterval = 30
  const timezoneLabel =
    // @ts-expect-error TS2322
    TimezoneModule.timezoneByKey({ key: timezone, locale: i18n.locale })?.fullLabel ||
    TimezoneModule.userTimezoneInfo.fullLabel

  const { candidateGroups, periodLabel } = getCandidateGroups({
    activeCandidates,
    duration,
    maxStartTimeInterval,
    i18n,
    timezone
  })

  const durationText =
    duration >= 120
      ? `${duration / 60}${i18n.t('scheduleSharingText.time.hour').toString()}`
      : `${duration}${i18n.t('scheduleSharingText.time.minute').toString()}`
  let plainText =
    `${title}\n` +
    `${i18n.t('scheduleSharingText.candidatePeriod').toString()}: ${periodLabel}\n` +
    `${i18n.t('scheduleSharingText.meetingTime').toString()}: ` +
    durationText +
    `\n` +
    `${i18n.t('timezone.displayTime').toString()}: ${timezoneLabel}\n\n`

  const dateDisplayOption = { timeZone: timezone, locale: i18n.locale }

  candidateGroups.forEach(({ candidates }) => {
    const start = parseISO(candidates[0].start)
    plainText += `${spirDateFormat(start, spirDateFormatTypes.mdWeekday, dateDisplayOption)}`
    candidates.forEach((candidate, index) => {
      const start = parseISO(candidate.start)
      const end = parseISO(candidate.end)
      if (index === 0) {
        plainText += ` ${spirDateFormat(start, spirDateFormatTypes.hourMin, dateDisplayOption)} - ${spirDateFormat(
          end,
          spirDateFormatTypes.hourMin,
          dateDisplayOption
        )}`
      } else {
        plainText += `、${spirDateFormat(start, spirDateFormatTypes.hourMin, dateDisplayOption)} - ${spirDateFormat(
          end,
          spirDateFormatTypes.hourMin,
          dateDisplayOption
        )}`
      }
    })

    plainText += `\n\n`
  })

  plainText += `${i18n.t('scheduleSharingText.plainIntroMessage').toString()}\n\n` + `${url}\n\n` + `Powered by Spir\n`

  return plainText
}

export const getSharePlainText = ({
  activeCandidates,
  duration,
  url,
  i18n = i18nGlobal,
  timezone
}: ShareSchedulePlainTextArgs) => {
  const maxStartTimeInterval = 30
  const timezoneLabel =
    // @ts-expect-error TS2322
    TimezoneModule.timezoneByKey({ key: timezone, locale: i18n.locale })?.fullLabel ||
    TimezoneModule.userTimezoneInfo.fullLabel

  const { candidateGroups, periodLabel } = getCandidateGroups({
    activeCandidates,
    duration,
    maxStartTimeInterval,
    i18n,
    timezone
  })

  const durationText =
    duration >= 120
      ? `${duration / 60}${i18n.t('scheduleSharingText.time.hour').toString()}`
      : `${duration}${i18n.t('scheduleSharingText.time.minute').toString()}`

  let plainText = `${i18n.t('scheduleSharingText.intro', { duration: durationText }).toString()}\n\n`

  const dateDisplayOption = { timeZone: timezone, locale: i18n.locale }

  candidateGroups.forEach(({ candidates }) => {
    const start = parseISO(candidates[0].start)
    plainText += `${spirDateFormat(start, spirDateFormatTypes.mdWeekday, dateDisplayOption)}`
    candidates.forEach((candidate, index) => {
      const start = parseISO(candidate.start)
      const end = parseISO(candidate.end)
      if (index === 0) {
        plainText += ` ${spirDateFormat(start, spirDateFormatTypes.hourMin, dateDisplayOption)} - ${spirDateFormat(
          end,
          spirDateFormatTypes.hourMin,
          dateDisplayOption
        )}`
      } else {
        plainText += `、${spirDateFormat(start, spirDateFormatTypes.hourMin, dateDisplayOption)} - ${spirDateFormat(
          end,
          spirDateFormatTypes.hourMin,
          dateDisplayOption
        )}`
      }
    })

    plainText += `\n`
  })

  plainText += '\n'
  plainText += `${i18n.t('scheduleSharingText.linkInvitation').toString()}\n` + `${url}\n\n` + `Powered by Spir\n`

  return plainText
}

export type ShareScheduleHtmlOption = {
  maxShowCandidateCount?: number
  maxShowSelectableItemCount?: number
  maxShowLineSelectableItemCount?: number
  maxStartTimeInterval?: number
}

const defaultOptionOfScheduleHtmlText: ShareScheduleHtmlOption = {
  maxShowCandidateCount: 4,
  maxShowSelectableItemCount: 8,
  maxShowLineSelectableItemCount: 3,
  maxStartTimeInterval: 30
}

type ShareScheduleHtmlTextArgs = ShareSchedulePlainTextArgs & {
  option?: ShareScheduleHtmlOption
}
export const getShareScheduleHtmlText = ({
  activeCandidates,
  title,
  duration,
  url,
  i18n = i18nGlobal,
  timezone,
  option
}: ShareScheduleHtmlTextArgs) => {
  const maxShowCandidateCount =
    option?.maxShowCandidateCount || (defaultOptionOfScheduleHtmlText.maxShowCandidateCount as number)
  const maxShowSelectableItemCount =
    option?.maxShowSelectableItemCount || defaultOptionOfScheduleHtmlText.maxShowSelectableItemCount
  const maxShowLineSelectableItemCount =
    option?.maxShowLineSelectableItemCount || defaultOptionOfScheduleHtmlText.maxShowLineSelectableItemCount
  const maxStartTimeInterval = option?.maxStartTimeInterval || defaultOptionOfScheduleHtmlText.maxStartTimeInterval

  const primaryTitleText = (text: string) => {
    const mjText = `
      <mj-text font-family="-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif" color="#1E366A" font-size="14px" font-weight="bold" line-height="1.3">
        ${text}
      </mj-text>
    `
    return mjText
  }

  const primaryBodyText = (text: string) => {
    const mjText = `
      <mj-text font-family="-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif" color="#1E366A" font-size="14px" font-weight="400" line-height="1.3">
        ${text}
      </mj-text>
    `
    return mjText
  }

  const secondaryBodyText = (text: string) => {
    const mjText = `
      <mj-text font-family="-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif" color="#536999" font-size="14px" font-weight="400" line-height="1.3">
        ${text}
      </mj-text>
    `
    return mjText
  }

  const signatureTextStyle = 'font: 12px; font-weight: bold; color: #1E366A; line-height: 1.3; margin: 0px;'

  const signatureText = (text: string) => {
    const mjText = `
      <mj-text font-family="-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif" color="#1E366A" font-size="12px" font-weight="bold" line-height="1.3">
        ${text}
      </mj-text>
    `
    return mjText
  }

  const emptyCandidateTextStyle = 'font-size: 14px; font-weight=400; color: #c21627; line-height: 1.3; margin: 0px;'

  const divider = `
    <mj-spacer height="6px" />
    <mj-divider border-width="1px" border-color="#d9dee9" width="48px" align="left" />
    <mj-spacer height="6px" />
  `

  const br = (size: 's' | 'm' | 'l') => {
    let height = '14px'
    if (size === 's') {
      height = '6px'
    } else if (size === 'm') {
      height = '14px'
    } else if (size === 'l') {
      height = '20px'
    }

    const mjText = `<mj-spacer height="${height}" />`
    return mjText
  }

  // vertical-align="middle"が効かないのでpaddingで中央表示の制御をしている。
  const scheduleButton = (text: string, href: string) => {
    const mjText = `
      <mj-button css-class="scheduleButton" font-family="-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif" width="100px" height="30px" font-size="12px" font-weight="400" text-align="center" color="#0074b3" border="1px solid #0074b3" border-radius="6px" padding="0px 0px" inner-padding="6px 0px" background-color="white" href="${href}">
        ${text}
      </mj-button>
    `
    return mjText
  }

  // vertical-align="middle"が効かないのでpaddingで中央表示の制御をしている。
  const retainTextButton = (text: string) => {
    const mjText = `
      <mj-button css-class="retainTextButton" font-family="-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif" width="100px" height="30px" font-size="12px" font-weight="400" text-align="center" color="#1e366a" border="1px solid transparent" border-radius="6px" padding="0px 0px" inner-padding="6px 0px" background-color="transparent">
        ${text}
      </mj-button>
    `
    return mjText
  }

  const confirmButton = (text: string, href: string) => {
    const mjText = `
      <mj-button font-family="-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif" font-size="14px" font-weight="400" text-align="center" color="white" border-radius="6px" padding="0px 0px" inner-padding="6px 12px" background-color="#1E366A" href="${href}">
        ${text}
      </mj-button>
    `
    return mjText
  }

  const timezoneLabel =
    // @ts-expect-error TS2322
    TimezoneModule.timezoneByKey({ key: timezone, locale: i18n.locale })?.fullLabel ||
    TimezoneModule.userTimezoneInfo.fullLabel

  const { candidateGroups, periodLabel } = getCandidateGroups({
    activeCandidates,
    duration,
    // @ts-expect-error TS2322
    maxStartTimeInterval,
    i18n,
    timezone
  })
  const dateDisplayOption = {
    timeZone: timezone,
    locale: i18n.locale
  }
  const mjmlContentTitle = `
    ${br('l')}
    ${divider}
    ${primaryBodyText(i18n.t('scheduleSharingText.htmlIntroMessage').toString())}
  `
  const displayDuration = adjustDurationUnit(duration)
  const durationText =
    displayDuration.type === 'hour'
      ? `${displayDuration.duration}${i18n.t('scheduleSharingText.time.hour').toString()}`
      : `${displayDuration.duration}${i18n.t('scheduleSharingText.time.minute').toString()}`
  let mjmlContentText = `
    ${br('m')}
    ${primaryTitleText(title)}
    ${br('s')}
    ${
      candidateGroups.length === 0
        ? secondaryBodyText(
            // eslint-disable-next-line prettier/prettier
            `${i18n
              .t('scheduleSharingText.candidatePeriod')
              .toString()}: <span style="${emptyCandidateTextStyle}">${periodLabel}</span>`
          )
        : secondaryBodyText(
            // eslint-disable-next-line prettier/prettier
            `${i18n.t('scheduleSharingText.candidatePeriod').toString()}: ${periodLabel}`
          )
    }
    ${br('s')}
    ${secondaryBodyText(`${i18n.t('timezone.displayTime').toString()}: ${timezoneLabel}`)}
    ${br('s')}
    ${secondaryBodyText(
      /* eslint-disable-next-line prettier/prettier */
      `${i18n.t('scheduleSharingText.meetingTime').toString()}: ` + durationText
    )}
    ${br('l')}
  `

  candidateGroups.slice(0, maxShowCandidateCount).forEach(({ candidates, buttonsData }) => {
    mjmlContentText += `
      ${br('l')}
      ${primaryTitleText(
        `${spirDateFormat(parseISO(candidates[0].start), spirDateFormatTypes.mdWeekday, dateDisplayOption)}`
      )}
      ${br('s')}
    `

    // @ts-expect-error TS2532
    let numberOfLines = Math.ceil(buttonsData.length / maxShowLineSelectableItemCount)
    // @ts-expect-error TS2532
    const maxNumberOfLines = Math.ceil(maxShowSelectableItemCount / maxShowLineSelectableItemCount)
    if (maxNumberOfLines < numberOfLines) {
      numberOfLines = maxNumberOfLines
    }

    const showEndTime = i18n.locale === 'ja'
    Array(numberOfLines)
      .fill(null)
      .forEach((_, i) => {
        mjmlContentText += `<mj-group width="100%">`

        Array(maxShowLineSelectableItemCount)
          .fill(null)
          .forEach((_, j) => {
            // @ts-expect-error TS2532
            const totalIndex = i * maxShowLineSelectableItemCount + j

            if (totalIndex + 1 <= buttonsData.length) {
              const { startTime, endTime } = buttonsData[totalIndex]
              const queryParameters = {}
              queryParameters[QueryParams.QUERY_PARAM_CONFIRM_START] = startTime.toISOString()
              queryParameters[QueryParams.QUERY_PARAM_TIMEZONE] = timezone || ''

              // @ts-expect-error TS2532
              if (totalIndex < maxShowSelectableItemCount - 1) {
                const dateFormat = showEndTime
                  ? `${spirDateFormat(startTime, spirDateFormatTypes.hourMin, dateDisplayOption)}
                - ${spirDateFormat(endTime, spirDateFormatTypes.hourMin, {
                  timeZone: timezone
                })}`
                  : `${spirDateFormat(startTime, spirDateFormatTypes.hourMin, dateDisplayOption)}`
                mjmlContentText += `
                  <mj-column>
                    ${scheduleButton(dateFormat, `${url}?${queryString.stringify(queryParameters)}`)}
                  </mj-column>
                `
                // @ts-expect-error TS2532
              } else if (totalIndex === maxShowSelectableItemCount - 1) {
                if (buttonsData.length === maxShowSelectableItemCount) {
                  const dateFormat = showEndTime
                    ? `${spirDateFormat(startTime, spirDateFormatTypes.hourMin, dateDisplayOption)}
                - ${spirDateFormat(endTime, spirDateFormatTypes.hourMin, {
                  timeZone: timezone
                })}`
                    : `${spirDateFormat(startTime, spirDateFormatTypes.hourMin, dateDisplayOption)}`
                  mjmlContentText += `
                  <mj-column>
                    ${scheduleButton(dateFormat, `${url}?${queryString.stringify(queryParameters)}`)}
                  </mj-column>
                `
                } else {
                  mjmlContentText += `
                    <mj-column>
                      ${retainTextButton(
                        // eslint-disable-next-line prettier/prettier
                        `${i18n
                          .t('scheduleSharingText.remainItemCountMessage', {
                            // @ts-expect-error TS2532
                            count: buttonsData.length - (maxShowSelectableItemCount - 1)
                          })
                          .toString()}`
                      )}
                    </mj-column>
                  `
                }
              } else {
                mjmlContentText += `<mj-column></mj-column>`
              }
            } else {
              mjmlContentText += `<mj-column></mj-column>`
            }
          })

        mjmlContentText += `
          </mj-group>
          ${br('s')}
        `
      })
  })
  const renderPrimaryBodyText = (candidateGroupsSize: number, showCandidateCountMax: number): string => {
    const remaining = candidateGroupsSize - showCandidateCountMax
    return remaining > 0
      ? `${primaryBodyText(
          // eslint-disable-next-line prettier/prettier
          `${i18n.tc('scheduleSharingText.remainCandidateCountMessage', remaining, { count: remaining }).toString()}`
        )}
      ${br('s')}
        `
      : ''
  }

  mjmlContentText += `
      ${br('m')}
      ${br('m')}
      // @ts-expect-error TS2345
      ${renderPrimaryBodyText(candidateGroups.length, maxShowCandidateCount)}
      ${confirmButton(i18n.t('scheduleSharingText.confirmButton').toString(), url)}
      ${br('m')}
      ${br('m')}
      ${signatureText(
        `Powered by <a style="${signatureTextStyle}" href='${i18n.t('links.landingPage').toString()}'>Spir</a>`
      )}
      ${br('m')}
      ${divider}
      ${br('l')}
    `
  const mjmlText = `
    <mjml>
      <mj-head>
        <mj-style inline="inline">
          .spir-html-title {
            max-width: 340px !important;
            padding: 0 12px;
            margin: 0 !important;
          }
          .spir-html-title td {
            padding: 0 !important;
          }
          .spir-html-body {
            max-width: 320px !important;
            padding: 12px;
            margin: 0 !important;
          }
          .spir-html-body td {
            padding: 0 !important;
          }
        </mj-style>
      </mj-head>
      <mj-body>
        <mj-section css-class="spir-html-title">
          <mj-column>
            ${mjmlContentTitle}
          </mj-column>
        </mj-section>
        <mj-section css-class="spir-html-body">
          <mj-column>
            ${mjmlContentText}
          </mj-column>
        </mj-section>
      </mj-body>
    </mjml>
  `

  return import('mjml-browser').then(({ default: mjml2html }) => mjml2html(mjmlText).html)
}

export const copyOrShareHTMLAndText = (htmlText: string, text: string): 'mobile' | 'clipboard' => {
  if (isIOS() || isSafari()) {
    // Hack. IOSの場合、clipboardに直接コピーができない。
    // コピー用のNodeを作成してCopyする。その後削除する。
    const TEMP_NODE_ID = 'temporaryNodeForCopy'
    const newNode = document.createElement('div')
    newNode.id = TEMP_NODE_ID
    document.body.appendChild(newNode)
    newNode.innerHTML = htmlText
    const selected = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(newNode)

    // @ts-expect-error TS2531
    selected.removeAllRanges()
    // @ts-expect-error TS2531
    selected.addRange(range)
    const listener = (e: ClipboardEvent) => {
      // @ts-expect-error TS2531
      e.clipboardData.setData('text/html', htmlText)
      // @ts-expect-error TS2531
      e.clipboardData.setData('text/plain', text)
      e.preventDefault()
    }
    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)
    document.body.removeChild(newNode)
    return 'clipboard'
  } else {
    const listener = (e: ClipboardEvent) => {
      // @ts-expect-error TS2531
      e.clipboardData.setData('text/html', htmlText)
      // @ts-expect-error TS2531
      e.clipboardData.setData('text/plain', text)
      e.preventDefault()
    }
    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)
    return 'clipboard'
  }
}

export const firstDateOfCandidates = (dates: Array<{ start: string; end: string }>) => {
  let firstDate
  dates.forEach(date => {
    if (!firstDate) {
      firstDate = new Date(date.start)
    }
    const currentStartDate = new Date(date.start)
    if (isBefore(currentStartDate, firstDate)) {
      firstDate = currentStartDate
    }
  })
  return firstDate
}

export const checkOverlap = (targetEvents, start: Date, end: Date, updatedEventId?: string): boolean => {
  for (const event of targetEvents) {
    if (event.id === updatedEventId) {
      continue
    }
    if (isEqual(start, event.start) || isEqual(end, event.end)) {
      return false
    }
    if (isAfter(end, event.start) && isBefore(start, event.end)) {
      return false
    }
  }
  return true
}

export const getStartAndEndDateFromClickedPosition = (
  duration: number,
  eventStart: Date,
  eventEnd: Date,
  slotDuration: number,
  jsEvent: MouseEvent,
  el: HTMLElement
): { start: Date; end: Date } => {
  const endStartDuration = differenceInMinutes(eventEnd, eventStart)
  const oneSlotHeight = el.offsetHeight / (endStartDuration / slotDuration)
  const offsetY = jsEvent.offsetY >= 0 ? jsEvent.offsetY : 0
  let timeHeight = 0
  if (
    (jsEvent.target as Element).classList.contains('fc-event-title-container') ||
    (jsEvent.target as Element).classList.contains('fc-event-title')
  ) {
    timeHeight = 15.16
  }
  const clickedSlot = Math.floor((offsetY + timeHeight) / oneSlotHeight)
  let startTime = addMinutes(eventStart, clickedSlot * slotDuration)
  let endTime = addMinutes(startTime, Number(duration))
  if (isAfter(endTime, eventEnd)) {
    endTime = eventEnd
    startTime = addMinutes(endTime, -duration)
  }
  return {
    start: startTime,
    end: endTime
  }
}

/**
 * 日程調整の作成などで最初に選択されるカレンダー
 * 優先順位
 * 1. 前回選択(payload) && Visible
 * 2. Visible Primary
 * 3. Visible Writable
 * 4. Primary
 */
export const getDefaultSelectedCalendar = (
  targetCalendars: ITypeCalendarListForUI[],
  payload?: { accountId: string; calendarId: string }
): ITypeCalendarListForUI => {
  if (targetCalendars.length === 0) {
    throw Error('no calendar')
  }
  const primayCalendars = targetCalendars.filter(c => c.primary)
  const wriableCalendars = targetCalendars.filter(c => c.writable)
  const visiblePrimaryCalendars = primayCalendars.filter(c => c.visible)
  const visibleWritableCalendars = wriableCalendars.filter(c => c.visible)
  if (payload) {
    const visiblePrimary = visiblePrimaryCalendars.find(
      c => c.accountId === payload.accountId && c.calendarId === payload.calendarId
    )
    if (visiblePrimary) {
      return visiblePrimary
    }
    const visibleWritable = visibleWritableCalendars.find(
      c => c.accountId === payload.accountId && c.calendarId === payload.calendarId
    )
    if (visibleWritable) {
      return visibleWritable
    }
  }
  if (visiblePrimaryCalendars.length > 0) {
    return visiblePrimaryCalendars[0]
  }
  if (visibleWritableCalendars.length > 0) {
    return visibleWritableCalendars[0]
  }
  if (payload) {
    const savedIdFromPrimaryCalendar = targetCalendars.find(
      c => c.accountId === payload.accountId && c.id === payload.calendarId
    )
    if (savedIdFromPrimaryCalendar) {
      return savedIdFromPrimaryCalendar
    }
  }
  return primayCalendars[0]
}

export const text2link = inputText => {
  return anchorme({
    input: inputText,
    options: {
      attributes: {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    }
  })
}
