import i18n from '@/i18n'
import * as pollAPI from '@/lib/api/poll'
import * as scheduleAPI from '@/lib/api/schedule'
import { addDays } from '@/lib/utils/timezone'
import { PollModel } from '@/models/data/poll'
import { ScheduleModel } from '@/models/data/schedule'
import store from '@/store'
import { PollStatus } from '@/types'
import { addMinutes, isAfter, isBefore, parseISO } from 'date-fns'
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Candidate, CandidateStatus, FullCalendarEvent, ScheduleSource } from '../../types'
import CalendarControlModule from './calendarControl'
import CalendarModule from './calendars'
import { CACHE_PERIOD } from './events'
import { IScheduleForUI, ListType } from './listPanel'
import PollAndScheduleModule from './pollAndSchedule'
import teamScheduleListModule from './teamScheduleList'
import { EVENTS, EventBus } from '@/lib/eventBus'
import { ScheduleStatus } from '@/models'

export const getScheduleTitleByStatus = (title: string, status: CandidateStatus, type: ListType) => {
  let titlePrefix = ''
  switch (type) {
    case 'poll':
      titlePrefix = i18n.t('groupPoll.candidateTitlePrefix').toString()
      break
    case 'schedule':
      titlePrefix = i18n.t('scheduleTabs.calendarEvents.summaryPrefix.planning').toString()
      break
  }
  switch (status) {
    case 'expired':
      titlePrefix = i18n.t('scheduleTabs.calendarEvents.summaryPrefix.expired').toString()
      break
    case 'confirmed':
      titlePrefix = ''
      break
  }
  return `${titlePrefix}${title}`
}
// @ts-expect-error TS2366
export const getStatusByDate = (endDate: Date, duration: number, type: ListType): ScheduleSource => {
  const now = new Date()
  if (isBefore(endDate, addMinutes(now, duration))) {
    if (type === 'poll') {
      return 'expiredPoll'
    }
    return 'expired'
  }
  if (isAfter(endDate, addMinutes(now, duration))) return type
}
const getScheduleCandidates = (
  scheduleId: IScheduleForUI['id'],
  scheduleTitle: IScheduleForUI['title'],
  accountId: IScheduleForUI['accountId'],
  calendarId: IScheduleForUI['calendarId'],
  candidates: Candidate[],
  eventsDate: {
    start: Date
    end: Date
  },
  backgroundColor: IScheduleForUI['backgroundColor'],
  type: ListType,
  duration: IScheduleForUI['duration']
): FullCalendarEvent[] => {
  return candidates
    .filter(({ start, end, status }) => {
      return (
        status !== 'deleted' &&
        status !== 'rejected' &&
        isAfter(parseISO(end), eventsDate.start) &&
        isBefore(parseISO(start), eventsDate.end)
      )
    })
    .map(({ id, start, end, status, underDuration }): FullCalendarEvent => {
      return {
        // @ts-expect-error TS2322
        id,
        // @ts-expect-error TS2345
        title: getScheduleTitleByStatus(scheduleTitle, status, type),
        start: parseISO(start),
        end: parseISO(end),
        startEditable: false,
        durationEditable: false,
        backgroundColor: backgroundColor,
        borderColor: 'white',
        extendedProps: {
          source: getStatusByDate(parseISO(end), duration, type),
          scheduleId: scheduleId,
          candidateId: id,
          accountId,
          calendarId,
          underDuration
        }
      }
    })
}

export const CACHE_KEY_PREFIX = 'candidates-poll-list'
export const invalidatePollListQuery = () => {
  // 全ての週の候補日のキャッシュをクリアしたいため、exact: falseでクリアする
  EventBus.emit(EVENTS.INVALIDATE_QUERIES, { queryKey: [CACHE_KEY_PREFIX], exact: false })
}
export const TARGET_GROUP_POLL_LIST_STATUSES: PollStatus[] = ['open', 'expired']

export const CACHE_KEY_PREFIX_FOR_SCHEDULE = 'candidates-schedule-list'
export const TARGET_SCHEDULE_LIST_STATUSES: ScheduleStatus[] = [
  'suggestedByOrganizer',
  'requestedByConfirmer',
  'requestedByOrganizer',
  'suggestedByConfirmer',
  'expired'
]
export const invalidateScheduleListQuery = () => {
  //週の候補日のキャッシュをクリアしたいため、exact: falseでクリアする
  EventBus.emit(EVENTS.INVALIDATE_QUERIES, { queryKey: [CACHE_KEY_PREFIX_FOR_SCHEDULE], exact: false })
}

const MODULE_NAME = 'Candidates'
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Candidates extends VuexModule {
  private pollList: PollModel[] = []
  private scheduleList: ScheduleModel[] = []
  private _isLoading = false

  get isLoading() {
    return this._isLoading
  }
  get groupPoll() {
    return (id: string) => {
      return this.pollList.find(poll => poll.id === id)
    }
  }
  get schedule() {
    return (id: string) => {
      return this.scheduleList.find(schedule => schedule.id === id)
    }
  }
  get candidates() {
    const scheduleList = [...this.pollList, ...this.scheduleList]
    const editingScheduleId = PollAndScheduleModule.editingForm?.id
    const eventDate = CalendarControlModule.getEventsDate
    return scheduleList
      .filter(({ status, id, calendarKey }) => {
        return (
          CalendarModule.visibleCalendarsKeys.some(vc => vc === calendarKey) &&
          status !== 'confirmed' &&
          id !== editingScheduleId
        )
      })
      .reduce((acc, schedule) => {
        return acc.concat(
          ...getScheduleCandidates(
            schedule.id,
            schedule.title,
            schedule.accountId,
            schedule.calendarId,
            schedule.candidates,
            // @ts-expect-error TS2345
            eventDate,
            schedule.calendarInfo?.backgroundColor,
            schedule.type,
            schedule.duration
          )
        )
      }, teamScheduleListModule.allCandidates)
  }

  @Action({ commit: 'SET_SCHEDULE_LIST' })
  async fetchScheduleList(payload: { start: string; end: string }) {
    const { start, end } = payload
    const schedules = await scheduleAPI.getList({ start, end, status: TARGET_SCHEDULE_LIST_STATUSES })
    return schedules
  }
  @Action({ commit: 'SET_POLL_LIST' })
  async fetchPollList(payload: { start: string; end: string }) {
    const { start, end } = payload
    const polls = (await pollAPI.getList({ start, end, status: TARGET_GROUP_POLL_LIST_STATUSES })).groupPolls
    return polls
  }
  @Action
  async updateScheduleCandidates(newSchedule: ScheduleModel) {
    // @ts-expect-error TS2531
    const start = CalendarControlModule.getEventsDate.start
    const startDate = addDays(start, -CACHE_PERIOD)
    const endDate = addDays(start, CACHE_PERIOD)
    this.fetchScheduleList({ start: startDate.toISOString(), end: endDate.toISOString() })
    this.UPDATE_SCHEDULE_LIST(newSchedule)
    invalidateScheduleListQuery()
  }

  @Action
  async deleteScheduleCandidates(payload: { scheduleId: string; candidateId: string }) {
    this.SET_LOADING_STATUS(true)
    try {
      const schedule = this.scheduleList.find(schedule => schedule.id === payload.scheduleId)
      if (!schedule) {
        return
      }
      await scheduleAPI.deleteCandidate(payload.scheduleId, payload.candidateId)
      const newCandidates = schedule.candidates.filter(cn => cn.id !== payload.candidateId)
      schedule.candidates = newCandidates
      this.UPDATE_SCHEDULE_LIST(schedule)
      invalidateScheduleListQuery()
    } finally {
      this.SET_LOADING_STATUS(false)
    }
  }

  @Action
  async deleteSchedule(scheduleId) {
    this.SET_LOADING_STATUS(true)
    try {
      await scheduleAPI.deleteSchedule(scheduleId)
      this.DELETE_SCHEDULE_LIST(scheduleId)
      invalidateScheduleListQuery()
    } finally {
      this.SET_LOADING_STATUS(false)
    }
  }
  @Action
  async deletePoll(pollId) {
    this.SET_LOADING_STATUS(true)
    try {
      await pollAPI.deletePoll(pollId)
      this.DELETE_POLL_LIST(pollId)
      invalidatePollListQuery()
    } finally {
      this.SET_LOADING_STATUS(false)
    }
  }

  @Action
  async deletePollCandidate(payload: { pollId: string; candidateId: string }) {
    const { pollId, candidateId } = payload
    this.SET_LOADING_STATUS(true)
    const groupPoll = this.pollList.find(poll => poll.id === pollId)
    if (!groupPoll) {
      return
    }
    try {
      await pollAPI.removeCandidates(pollId, [candidateId])
      groupPoll.deleteCandidate(candidateId)
      this.UPDATE_POLL_LIST(groupPoll)
      invalidatePollListQuery()
    } finally {
      this.SET_LOADING_STATUS(false)
    }
  }

  @Action
  async updatePollCandidates(newSPoll: PollModel) {
    // @ts-expect-error TS2531
    const start = CalendarControlModule.getEventsDate.start
    const startDate = addDays(start, -CACHE_PERIOD)
    const endDate = addDays(start, CACHE_PERIOD)
    this.fetchPollList({ start: startDate.toISOString(), end: endDate.toISOString() })
    this.UPDATE_POLL_LIST(newSPoll)
    invalidatePollListQuery()
  }
  @Mutation
  SET_SCHEDULE_LIST(scheduleList: Array<any>) {
    this.scheduleList = scheduleList.map(s => new ScheduleModel(s))
  }
  @Mutation
  SET_POLL_LIST(pollList: Array<any>) {
    this.pollList = pollList.map(s => new PollModel(s))
  }
  @Mutation
  UPDATE_SCHEDULE_LIST(newSchedule: ScheduleModel) {
    const findIndex = this.scheduleList.findIndex(s => s.id === newSchedule.id)
    if (findIndex >= 0) {
      Vue.set(this.scheduleList, findIndex, newSchedule) // make reactive
    } else {
      this.scheduleList.push(newSchedule)
    }
  }
  @Mutation
  UPDATE_POLL_LIST(newPoll: PollModel) {
    const findIndex = this.pollList.findIndex(s => s.id === newPoll.id)
    if (findIndex >= 0) {
      Vue.set(this.pollList, findIndex, newPoll) // make reactive
    } else {
      this.pollList.push(newPoll)
    }
  }
  @Mutation
  DELETE_SCHEDULE_LIST(scheduleId) {
    const findIndex = this.scheduleList.findIndex(s => s.id === scheduleId)
    if (findIndex >= 0) {
      this.scheduleList.splice(findIndex, 1)
    }
  }
  @Mutation
  DELETE_POLL_LIST(pollId) {
    const findIndex = this.pollList.findIndex(s => s.id === pollId)
    if (findIndex >= 0) {
      this.pollList.splice(findIndex, 1)
    }
  }
  @Mutation
  SET_LOADING_STATUS(isLoading: boolean) {
    this._isLoading = isLoading
  }
}

export default getModule(Candidates)
