import { SpirAttendeeStatus } from '@/types'
import {
  EventAttendee,
  OnlineMeeting,
  PublicAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse
} from '@spirinc/contracts'
import { PollModel } from './poll'
import { ScheduleModel, ScheduleModelTeam } from './schedule'

export type Attendee = Omit<EventAttendee, 'isSpirUser' | 'email' | 'permitted'> & {
  photoURL?: string
  email?: string
}
// todo: contractが定義されたら変える
export type AfterConfirmResponse = {
  type: 'schedule' | 'group-poll' | 'availability-sharing'
  teamOrPrivate: 'team' | 'private'
  id?: string
  confirmedId?: string
  teamId?: string
  date: {
    start: Date
    end: Date
  }
  title: string
  onlineMeeting?: OnlineMeeting
  attendees?: Array<Attendee>
  description?: string
  candidateDescription?: string
  location?: string
  confirmUrl?: string
}

export class AfterConfirmModel {
  type: 'schedule' | 'group-poll' | 'availability-sharing'
  teamOrPrivate: 'team' | 'private'
  id?: string
  confirmedId?: string
  teamId?: string
  title: string
  date: {
    start: Date
    end: Date
  }
  onlineMeeting?: OnlineMeeting
  attendees: Array<Attendee> // todo
  description?: string
  candidateDescription?: string
  location?: string
  confirmUrl?: string
  constructor(data?: AfterConfirmResponse) {
    // @ts-expect-error TS2532
    this.type = data.type
    // @ts-expect-error TS2532
    this.teamOrPrivate = data.teamOrPrivate
    // @ts-expect-error TS2532
    this.id = data.id
    // @ts-expect-error TS2532
    this.confirmedId = data.confirmedId
    // @ts-expect-error TS2532
    this.teamId = data.teamId
    // @ts-expect-error TS2532
    this.date = data.date
    // @ts-expect-error TS2532
    this.onlineMeeting = data.onlineMeeting
    // @ts-expect-error TS2322
    this.attendees = data.attendees
    // @ts-expect-error TS2532
    this.description = data.description
    // @ts-expect-error TS2532
    this.candidateDescription = data.candidateDescription
    // @ts-expect-error TS2532
    this.location = data.location
    // @ts-expect-error TS2532
    this.title = data.title
    // @ts-expect-error TS2532
    this.confirmUrl = data.confirmUrl
  }
  static createByPollModel(pollModel: PollModel): AfterConfirmModel {
    if (!pollModel.isConfirmed) {
      throw Error('not confirmed')
    }
    const confirmedCandidate = pollModel.candidates.find(c => c.status === 'confirmed')
    if (!confirmedCandidate) {
      throw Error('no conirm info')
    }
    const attendees: Attendee[] = [
      {
        // @ts-expect-error TS2532
        fullName: pollModel.author.name,
        // @ts-expect-error TS2532
        photoURL: pollModel.author.photoURL,
        // @ts-expect-error TS2532
        id: pollModel.author.id,
        responseStatus: SpirAttendeeStatus.ACCEPTED,
        organizer: true
      }
    ]
    if (confirmedCandidate.votes && confirmedCandidate.votes.length > 0) {
      confirmedCandidate.votes.forEach(v => {
        attendees.push({
          // @ts-expect-error TS2322
          fullName: v.attendee.fullName,
          photoURL: v.attendee.photoURL,
          email: v.attendee.email,
          id: v.attendee.id,
          organizer: false,
          responseStatus: v.answer === 'yes' ? SpirAttendeeStatus.ACCEPTED : SpirAttendeeStatus.DECLINED
        })
      })
    }
    return new AfterConfirmModel({
      type: 'group-poll',
      teamOrPrivate: 'private',
      id: pollModel.id,
      date: {
        start: new Date(confirmedCandidate.start),
        end: new Date(confirmedCandidate.end)
      },
      onlineMeeting: pollModel.onlineMeeting,
      description: pollModel.description,
      title: pollModel.title,
      location: pollModel.location,
      attendees: attendees
    })
  }
  static createByScheduleModel(scheduleModel: ScheduleModel): AfterConfirmModel {
    if (!scheduleModel.confirmedInfo?.start || !scheduleModel.confirmedInfo?.end) {
      throw Error('no conirm info')
    }
    const attendees: Attendee[] = [
      {
        // @ts-expect-error TS2532
        fullName: scheduleModel.author.name,
        // @ts-expect-error TS2532
        photoURL: scheduleModel.author.photoURL,
        // @ts-expect-error TS2532
        id: scheduleModel.author.id,
        organizer: true,
        responseStatus: SpirAttendeeStatus.ACCEPTED
      }
    ]
    if (scheduleModel.attendees && scheduleModel.attendees.length > 0) {
      scheduleModel.attendees.forEach(a => {
        attendees.push({
          // @ts-expect-error TS2322
          fullName: a.fullName,
          photoURL: a.photoURL,
          email: a.email,
          id: a.id,
          organizer: false,
          // @ts-expect-error TS2322
          responseStatus: a.responseStatus
        })
      })
    }
    return new AfterConfirmModel({
      type: 'schedule',
      teamOrPrivate: 'private',
      id: scheduleModel.id,
      date: {
        start: new Date(scheduleModel.confirmedInfo?.start),
        end: new Date(scheduleModel.confirmedInfo?.end)
      },
      onlineMeeting: scheduleModel.onlineMeeting,
      description: scheduleModel.description,
      title: scheduleModel.title,
      location: scheduleModel.location,
      attendees: attendees
    })
  }
  static createByScheduleTeamModel(scheduleModel: ScheduleModelTeam): AfterConfirmModel {
    if (!scheduleModel.confirmedInfo?.start || !scheduleModel.confirmedInfo?.end) {
      throw Error('no conirm info')
    }
    const attendees: Attendee[] = [
      {
        // @ts-expect-error TS2322
        fullName: scheduleModel.organizerInfo.fullName,
        photoURL: scheduleModel.organizerInfo.photoURL,
        organizer: true,
        id: scheduleModel.organizerInfo.userId,
        responseStatus: SpirAttendeeStatus.ACCEPTED
      },
      // @ts-expect-error TS2322
      ...scheduleModel.attendees.map(sa => {
        return {
          fullName: sa.fullName,
          photoURL: sa.photoURL,
          organizer: false,
          id: sa.id,
          email: sa.email,
          responseStatus: sa.responseStatus
        }
      })
    ]
    return new AfterConfirmModel({
      type: 'schedule',
      teamOrPrivate: 'team',
      teamId: scheduleModel.teamId,
      id: scheduleModel.id,
      date: {
        start: new Date(scheduleModel.confirmedInfo?.start),
        end: new Date(scheduleModel.confirmedInfo?.end)
      },
      onlineMeeting: scheduleModel.onlineMeeting,
      description: scheduleModel.description,
      title: scheduleModel.title,
      location: scheduleModel.location,
      attendees: attendees
    })
  }
}

export class AfterConfirmModelForAvailability extends AfterConfirmModel {
  messageToOrganizer?: string // todo: アンケートフィームに変わるときに変更が必要
  eventTitle?: string // チーム空き時間URLの場合のみ使用
  publicTitle?: string // チーム空き時間URLの場合のみ使用
  constructor(data: {
    teamId?: string
    payload:
      | PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse
      | PublicAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse
  }) {
    const attendees = data.payload.attendees.map(at => {
      return { ...at, responseStatus: at.responseStatus as SpirAttendeeStatus, organizer: at.isOrganizer }
    })
    const afterConfirmParam: AfterConfirmResponse = {
      type: 'availability-sharing',
      teamOrPrivate: data.teamId ? 'team' : 'private',
      // @ts-expect-error TS2532
      confirmedId: data.payload.confirmInfo.id,
      teamId: data.teamId,
      onlineMeeting: data.payload.onlineMeeting,
      attendees: attendees || [],
      title: data.payload.title,
      description: data.payload.candidateDescription,
      location: data.payload.location,
      date: {
        start: new Date(data.payload.start),
        end: new Date(data.payload.end)
      },
      confirmUrl: data.payload.confirmUrl
    }
    super(afterConfirmParam)
    this.messageToOrganizer = data.payload.confirmInfo?.formData?.message
    this.eventTitle = 'eventTitle' in data.payload ? data.payload.eventTitle : undefined
    this.publicTitle = 'eventTitle' in data.payload ? data.payload.publicTitle : undefined
  }
}
