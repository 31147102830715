import router from '@/router'
import store from '@/store'
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'
import EditPoll from './editPoll'
import EditSchedule from './editSchedule'
import UserModule from './user'

export enum CreateType {
  SCHEDULE = 'schedule',
  POLL = 'poll'
}

export const currentModule = () => {
  switch (router.app.$route.name) {
    case 'CreateSchedule':
    case 'EditSchedule':
    case 'ConfirmSchedule':
    case 'SuggestAlternativeDate':
      return EditSchedule
    case 'CreatePoll':
    case 'EditPoll':
    case 'VotePoll':
      return EditPoll
  }
  return null
}

const MODULE_NAME = 'PollAndSchedule'
/**
 * 日程調整と日程投票の共通パートのため、選択状態と、Getterなどをもつ。
 */
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class PollAndSchedule extends VuexModule {
  get isValid() {
    // @ts-expect-error TS2531
    return currentModule() ? currentModule().isValid : true
  }
  get editingForm() {
    // @ts-expect-error TS2531
    return currentModule() ? currentModule().editingForm : null
  }
  // @ts-expect-error TS2366
  get isReadOnly(): boolean {
    const module = currentModule()
    if (module === EditSchedule) {
      return module.isExist && module.editingSchedule.status === 'expired'
    }
  }
  get getEditingEventByCalendarFormat() {
    // @ts-expect-error TS2531
    return currentModule() ? currentModule().getEditingEventByCalendarFormat : []
  }
  get isMine() {
    return this.editingForm && this.editingForm.author?.id === UserModule.currentUser?.uid
  }
  get isSyncing() {
    // @ts-expect-error TS2531
    return currentModule().isSyncing
  }
  get isSchedule() {
    return currentModule() === EditSchedule
  }
  @Action
  addOrEditCandidate(payload: { id?: string; allDay: boolean; start: Date; end: Date }) {
    const module = currentModule()
    // @ts-expect-error TS2531
    module.updateOrAddEventStartAndEnd(payload)
  }
  @Action
  setEditingObject(payload: { id: string; hideLoading: boolean }) {
    const module = currentModule()
    const { id, hideLoading } = payload
    if (module === EditSchedule) {
      return module.setScheduleAsEditingSchedule({ scheduleId: id, hideLoading })
    }
    if (module === EditPoll) {
      return module.setPollAsEditingPoll({ pollId: id, hideLoading })
    }
  }
  @Action
  removeCandidate(candidateId) {
    const module = currentModule()
    // @ts-expect-error TS2531
    return module.removeCandidate(candidateId)
  }
}

export default getModule(PollAndSchedule)
