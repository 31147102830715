import Router, { RouteNames } from '@/router'
import * as Sentry from '@sentry/vue'
import axios, { AxiosError, AxiosHeaders } from 'axios'
import { getAuth } from 'firebase/auth'
import { APIError } from './sdk/error'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})
axiosInstance.defaults.timeout = 90000 // 90秒
axiosInstance.interceptors.request.use(
  async config => {
    const firebaseAuth = getAuth()
    const user = firebaseAuth.currentUser
    if (user === null) {
      return config
    }
    const idToken = await user.getIdToken()
    const headers = new AxiosHeaders(config.headers)
    headers.set('X-Spir-User-Id', user.uid)
    headers.set('Authorization', `Bearer ${idToken}`)
    config.headers = headers
    return config
  },
  (error: AxiosError) => {
    sendAxiosErrorToSentry(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(null, async error => {
  sendAxiosErrorToSentry(error)
  if (error.response && error.response.status === 403) {
    const signOutRouteName: RouteNames = 'SignOut'
    if (Router.app.$route.name !== signOutRouteName) {
      Router.push({ name: signOutRouteName })
    }
  }
  // if there is data, make it to API Error
  if (error.response && error.response.data) {
    return Promise.reject(new APIError(error.response.status, error.response.data, error.response))
  }
  return Promise.reject(error)
})

/**
 * Because some errors are not subclasses of `Error` but a plain objects, Sentry can't handle it like https://sentry.io/organizations/spir-inc/issues/3584938645/?project=1844005&referrer=slack
 * According to the Sentry log, these plain objects have `message` field.
 * This function pass to captureException
 *  - error itself if error inherits Error
 *  - `message` field if error doesn't inherits Error
 *  - 'Unknown http error ocurred' otherwise (unexpected pattern)
 *
 * See also [Sentry's source code](https://github.com/getsentry/sentry-javascript/blob/7954cf31d0f55bc1c16dd1c0dfe8229343c5f298/packages/browser/src/eventbuilder.ts#L55-L63)
 */
const sendAxiosErrorToSentry = (error: AxiosError | Record<string, unknown>) => {
  if (error instanceof Error) {
    const traceId: string | undefined = error.response?.headers?.['x-cloud-trace-context']?.split(';')?.[0]
    if (traceId !== undefined) {
      Sentry.setContext('tracing', { traceId })
    }
    Sentry.captureException(error)
    return
  }
  if ('message' in error) {
    Sentry.captureException(error.message)
    return
  }
  Sentry.captureException('Unknown http error ocurred')
}

export default axiosInstance
