import { render, staticRenderFns } from "./AuthFormFrame.vue?vue&type=template&id=13089dfd&scoped=true&"
import script from "./AuthFormFrame.vue?vue&type=script&lang=ts&"
export * from "./AuthFormFrame.vue?vue&type=script&lang=ts&"
import style0 from "./AuthFormFrame.vue?vue&type=style&index=0&id=13089dfd&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css_fghn5se6rw76wmnxy7pxwdglgi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13089dfd",
  null
  
)

export default component.exports