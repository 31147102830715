import {
  confirmAsGuest,
  confirmAsSpirUser,
  getCandidatesById,
  getConfirmedInfo,
  getOverview
} from '@/infrastructures/apis/teamAvailabilitySharing'
import { create as createTeamAvailability, getAvailability, getList } from '@/lib/api/availabilityTeam'
import { APIError } from '@/lib/sdk/error'
import { isGuest } from '@/lib/utils'
import { fromAvailabilityModel } from '@/lib/utils/converters/fromAvailabilityModel'
import { findOrganizerAndAttendees } from '@/lib/utils/converters/fromPriorityGroups'
import { getAvailabilitySharingConfirmErrorMessage } from '@/lib/utils/getErrorMessage'
import { Failure, Result } from '@/lib/utils/result'
import { isTeamSubscriptionCanceledError } from '@/lib/utils/teamPlan'
import {
  ConfirmationFormData,
  PostConfirmTeamAvailabilitySharing,
  PreConfirmTeamAvailabilitySharingOverview,
  TeamAvailabilitySharingCandidate,
  TeamId
} from '@/models'
import { AvailabilitySharingConfirmer, AvailabilitySharingConfirmerAttendee } from '@/models/availabilitySharing'
import { AvailabilityModelForTeam } from '@/models/data'
import { EventModel } from '@/models/data/event'
import type { QuestionnaireAnswer } from '@/models/questionnaireAnswer'
import { UtmParameters } from '@/models/utmParameters'
import { TeamAvailabilitySharingListResponseItem } from '@spirinc/contracts'
import { ERROR_CODE } from '@spirinc/message-catalog'

type NotFoundError = {
  type: 'not_found'
  teamId: string
  availabilitySharingId: string
}

type InternalServerError = {
  type: 'internal_server'
  teamId: string
  availabilitySharingId: string
}

type SubscriptionCanceledError = {
  type: 'subscription_canceled'
  teamId: string
  availabilitySharingId: string
}

type ConfirmError = {
  type: 'confirm_error'
  message: string
}

interface AccountTokenIsInvalidError {
  type: 'account_token_is_invalid'
  teamId: string
  availabilitySharingId: string
}

export type TeamAvailabilitySharingEffectsError =
  | NotFoundError
  | InternalServerError
  | SubscriptionCanceledError
  | AccountTokenIsInvalidError
function toFailure(
  type: 'not_found' | 'internal_server' | 'subscription_canceled' | 'account_token_is_invalid',
  data: { teamId: string; availabilitySharingId: string }
): Failure<TeamAvailabilitySharingEffectsError> {
  return {
    status: 'failure',
    error: {
      type,
      ...data
    }
  }
}

function handle400Error(data: { code?: ERROR_CODE; teamId: string; availabilitySharingId: string }) {
  if (data.code === ERROR_CODE.SUBSCRIPTION_CANCELED) {
    return toFailure('subscription_canceled', {
      teamId: data.teamId,
      availabilitySharingId: data.availabilitySharingId
    })
  } else if (data.code === ERROR_CODE.ACCOUNT_TOKEN_IS_INVALID) {
    return toFailure('account_token_is_invalid', {
      teamId: data.teamId,
      availabilitySharingId: data.availabilitySharingId
    })
  } else {
    return toFailure('internal_server', {
      teamId: data.teamId,
      availabilitySharingId: data.availabilitySharingId
    })
  }
}

function handleError(data: {
  e: unknown
  teamId: string
  availabilitySharingId: string
}): Failure<TeamAvailabilitySharingEffectsError> {
  if (data.e instanceof APIError) {
    if (data.e.response?.status === 404) {
      return toFailure('not_found', {
        teamId: data.teamId,
        availabilitySharingId: data.availabilitySharingId
      })
    } else if (data.e.response?.status === 400) {
      return handle400Error({
        code: data.e.code,
        teamId: data.teamId,
        availabilitySharingId: data.availabilitySharingId
      })
    } else {
      return toFailure('internal_server', {
        teamId: data.teamId,
        availabilitySharingId: data.availabilitySharingId
      })
    }
  }
  throw data.e
}

export async function fetchPreConfirmTeamAvailabilitySharingOverview(data: {
  teamId: string
  availabilitySharingId: string
}): Promise<Result<PreConfirmTeamAvailabilitySharingOverview, TeamAvailabilitySharingEffectsError>> {
  try {
    const result = await getOverview(data.teamId, data.availabilitySharingId)
    const { organizer, attendees } = findOrganizerAndAttendees({
      priorityGroups: result.priorityGroups,
      potentialAttendingMembers: result.potentialAttendingMembers
    })

    const preOverview = {
      title: result.title,
      duration: result.duration,
      location: result.location,
      description: result.description,
      start: new Date(result.start),
      end: new Date(result.end),
      organizer: organizer,
      attendees: attendees,
      candidateDescription: result.candidateDescription,
      timezone: { key: result.timeZone },
      prioritizedOnlineMeetings: result.prioritizedOnlineMeetings,
      confirmationForm: result.confirmationForm,
      questionnaireId: result.formId
    }
    return { status: 'success', value: preOverview }
  } catch (e) {
    return handleError({ e, ...data })
  }
}

export async function fetchTeamAvailabilitySharingCandidates(data: {
  teamId: string
  availabilitySharingId: string
}): Promise<Result<TeamAvailabilitySharingCandidate[], TeamAvailabilitySharingEffectsError>> {
  try {
    const result = await getCandidatesById(data.teamId, data.availabilitySharingId)
    return {
      status: 'success',
      value: result.candidates.map(c => ({ start: new Date(c.start), end: new Date(c.end) }))
    }
  } catch (e) {
    return handleError({ e, ...data })
  }
}

export async function confirmTeamAvailabilitySharing(data: {
  teamId: string
  availabilitySharingId: string
  candidate: TeamAvailabilitySharingCandidate
  confirmer: AvailabilitySharingConfirmer
  confirmerAttendees?: AvailabilitySharingConfirmerAttendee[]
  confirmationFormData?: ConfirmationFormData
  questionnaireAnswer?: QuestionnaireAnswer
  utmParameters?: UtmParameters
}): Promise<Result<PostConfirmTeamAvailabilitySharing, ConfirmError | SubscriptionCanceledError>> {
  try {
    const { confirm, name, timezone, language } = isGuest(data.confirmer)
      ? { confirm: confirmAsGuest, ...data.confirmer }
      : {
          confirm: confirmAsSpirUser,
          ...data.confirmer.user,
          name: data.confirmer.user.fullName
        }
    const payload = {
      start: data.candidate.start.toISOString(),
      end: data.candidate.end.toISOString(),
      email: data.confirmer.email,
      name,
      timeZone: timezone.key,
      timeZoneDisplayName: timezone.displayName,
      language,
      attendees: data.confirmerAttendees,
      formData: data.confirmationFormData,
      formAnswer: data.questionnaireAnswer
        ? {
            formId: data.questionnaireAnswer.questionnaireId,
            questionAnswers: data.questionnaireAnswer.questionAnswers
          }
        : undefined,
      utmParameters: data.utmParameters
    }

    // @ts-expect-error TS2345
    const { confirmInfo, confirmationPage } = await confirm(data.teamId, data.availabilitySharingId, payload)
    return {
      status: 'success',
      value: {
        teamId: data.teamId,
        availabilitySharingId: data.availabilitySharingId,
        // @ts-expect-error TS2532
        confirmationId: confirmInfo.id,
        confirmationPage
      }
    }
  } catch (e) {
    if (isTeamSubscriptionCanceledError(e)) {
      return {
        status: 'failure',
        error: {
          type: 'subscription_canceled',
          teamId: data.teamId,
          availabilitySharingId: data.availabilitySharingId
        }
      }
    } else {
      const message = getAvailabilitySharingConfirmErrorMessage(e)
      return {
        status: 'failure',
        error: {
          type: 'confirm_error',
          message
        } as ConfirmError
      }
    }
  }
}

type BadRequestError = {
  type: 'not_member_of_team'
}

export type CreateTeamAvailabilitySharingEffectsError = InternalServerError | BadRequestError

// Create時にも同じエラーになる。CreateのEffectを作る時には関数名を変えて一緒に使うようにする
function handleDuplicationError(data: {
  e: unknown
  teamId: string
  availabilitySharingId: string
}): CreateTeamAvailabilitySharingEffectsError {
  if (!(data.e instanceof APIError)) throw data.e
  switch (data.e.code) {
    case ERROR_CODE.NOT_MEMBER_OF_TEAM:
      return { type: 'not_member_of_team' }
    default:
      return {
        type: 'internal_server',
        availabilitySharingId: data.availabilitySharingId,
        teamId: data.teamId
      }
  }
}

export async function duplicateTeamAvailabilitySharing(
  teamId: string,
  availabilitySharingId: string
): Promise<Result<AvailabilityModelForTeam, CreateTeamAvailabilitySharingEffectsError>> {
  try {
    const target = await getAvailability(teamId, availabilitySharingId).then(
      res => new AvailabilityModelForTeam(teamId, res)
    )
    const response = await createTeamAvailability(
      teamId,
      fromAvailabilityModel.convertToDuplicationPayload<'team'>(target)
    )
    return {
      status: 'success',
      value: new AvailabilityModelForTeam(teamId, response)
    }
  } catch (e: unknown) {
    const errorResult = handleDuplicationError({ e, teamId, availabilitySharingId: availabilitySharingId })
    return { status: 'failure', error: errorResult }
  }
}

export async function fetchConfirmedTeamAvailabilitySharingEvent(data: {
  teamId: string
  availabilitySharingId: string
  availabilitySharingConfirmId: string
}): Promise<Result<EventModel, TeamAvailabilitySharingEffectsError>> {
  try {
    await getConfirmedInfo(data)
    return {
      status: 'success',
      value: new EventModel() // 確定後画面用のAPIではEventを返さなくなったので、ここはクリックに対応したら再度実装を検討
    }
  } catch (e) {
    return handleError({ e, ...data })
  }
}

export async function getAllList({
  teamId,
  limit = 100,
  onListAdd
}: {
  teamId: TeamId
  limit?: number
  onListAdd: ({ teamId, list }: { teamId: string; list: TeamAvailabilitySharingListResponseItem[] }) => void
}) {
  let nextToken = ''
  do {
    const result = await getList(teamId, `nextToken=${nextToken}&limit=${limit}`)
    onListAdd({ teamId, list: result.availabilitySharings })
    // @ts-expect-error TS2322
    nextToken = result.nextToken
  } while (nextToken)
}
