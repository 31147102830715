export const AttendeeKind = {
  UNDECIDED: 'undecided',
  ORGANIZER: 'organizer'
} as const
export type AttendeeKind = typeof AttendeeKind[keyof typeof AttendeeKind]

export type AttendeeGroup = {
  id: string
  memberIds: string[]
}
export type AssignPriorityGroups = AttendeeGroup[][]
