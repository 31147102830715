import store from '@/store'
import { ZoomSettings } from '@/types'
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'
import * as SettingApi from '../../lib/api/settings'

@Module({
  dynamic: true,
  name: 'Settings',
  namespaced: true,
  store
})
class Settings extends VuexModule {
  @Action
  async updateZoomSettings(payload: ZoomSettings) {
    await SettingApi.updateSettings(SettingApi.SettingType.ZOOM, payload)
  }
}

export default getModule(Settings)
