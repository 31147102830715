import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { onBeforeUnmount, onMounted, Ref, ref } from '@vue/composition-api'

type Props = {
  timeZoneKey: Ref<string>
}
export const useTimeZoneIntervalLabel = ({ timeZoneKey }: Props): { timeZoneIntervalLabel: Ref<string> } => {
  const timeZoneIntervalLabel = ref('')
  const interval = ref(null)

  function updateTimezoneTime() {
    //'HH:mm'
    timeZoneIntervalLabel.value = spirDateFormat(new Date(), spirDateFormatTypes.hourMin, {
      timeZone: timeZoneKey.value
    })
  }
  function makeInterval() {
    // @ts-expect-error TS2322
    interval.value = setInterval(() => {
      updateTimezoneTime()
    }, 60 * 1000)
  }
  onMounted(() => {
    updateTimezoneTime()
    makeInterval()
  })
  onBeforeUnmount(() => {
    if (interval.value) {
      clearInterval(interval.value)
    }
  })
  return {
    timeZoneIntervalLabel
  }
}
