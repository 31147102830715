import { SignalType } from '@/lib/analytics'
import { hubspotService } from '@/lib/externalServices/hubspot'
import { isDevelopmentLevelEnv, isProductionLevelEnv } from '@/lib/utils/environment'
import { guardGroupPoll } from '@/router/groupPollGuard'
import AppModule from '@/store/modules/app'
import Vue from 'vue'
import Component from 'vue-class-component'
import Router from 'vue-router'
import { PageAccessSignalType } from '../lib/analytics/LogEntry'
import AuthGuard from './auth-guard'
import AuthRedirect from './authRedirect'
import { redirectToFirstWorkspace } from './firstWorkspaceRedirect'
import { GuardsInRouter } from './guardConfirm'
import type { RouteNames } from './route-names'
import { AllRouteNames } from './route-names'
import { teamUsableGuard } from './team-usable-guard'
import {
  redirectFromConfirmedList,
  redirectFromListAvailability,
  redirectFromListForms,
  redirectFromUnavailable,
  redirectFromUnconfirmedList
} from './workspace-page-redirects'
// Register the router hooks with their names
Component.registerHooks(['beforeRouteUpdate'])

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/404',
      name: AllRouteNames.NotFound,
      component: () => import('../views/NotFound.vue')
    },
    {
      path: '/external-404',
      name: AllRouteNames.ExternalNotFound,
      component: () => import('../views/ExternalNotFound.vue')
    },
    {
      path: '/t/:id/s/:scheduleId/c',
      redirect: { name: AllRouteNames.TeamScheduleConfirm }
    },
    {
      path: '/auth',
      name: 'AuthRoot',
      component: {
        render: c => c('router-view')
      },
      children: [
        {
          path: 'signin-password',
          name: AllRouteNames.SignInWithPassword,
          beforeEnter: AuthRedirect,
          component: () => import('../views/auth/SignInWithPassword.vue')
        },
        {
          path: 'send-password-reset-mail',
          name: AllRouteNames.SendPasswordResetEmail,
          beforeEnter: AuthRedirect,
          component: () => import('../views/auth/SendPasswordResetEmail.vue')
        },
        {
          path: 'signout',
          name: AllRouteNames.SignOut,
          component: () => import('../views/auth/SignOut.vue')
        },
        {
          path: 'signin',
          name: AllRouteNames.SignIn,
          beforeEnter: AuthRedirect,
          component: () => import('@/views/auth/SignIn.vue')
        },
        {
          path: 'signup',
          name: AllRouteNames.SignUp,
          beforeEnter: AuthRedirect,
          component: () => import('@/views/auth/SignUpWithStep.vue')
        },
        {
          path: 'signup-with-step',
          name: AllRouteNames.SignUpWithStep,
          beforeEnter: AuthRedirect,
          component: () => import('@/views/auth/SignUpWithStep.vue')
        },
        {
          path: 'add-calendar',
          name: AllRouteNames.AddCalendar,
          beforeEnter: AuthGuard,
          component: () => import('@/views/auth/AddCalendar.vue')
        },
        {
          path: 'teams/create',
          name: AllRouteNames.AuthTeamsCreate,
          component: () => import('@/views/auth/teams/Create.vue')
        },
        {
          path: 'teams/:teamId/start',
          name: AllRouteNames.AuthTeamIdStart,
          beforeEnter: AuthGuard,
          component: () => import('@/views/auth/teams/teamId/Start.vue')
        },
        {
          path: 'teams/create/free',
          name: AllRouteNames.AuthTeamsCreateFree,
          component: () => import('@/views/auth/teams/CreateFree.vue')
        },
        {
          path: 'teams/:teamId/start/free',
          name: AllRouteNames.AuthTeamIdStartFree,
          component: () => import('@/views/auth/teams/teamId/StartFree.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('../views/Home.vue'),
      beforeEnter: AuthGuard,
      children: [
        {
          path: '/',
          name: AllRouteNames.Main,
          beforeEnter: (to, _, next) => redirectToFirstWorkspace(next)
        },
        {
          path: '/service-unavailable',
          name: AllRouteNames.ServiceUnavailable,
          component: () => import('../views/ServiceUnavailable.vue')
        },
        {
          path: '/calendar',
          name: 'CalendarRoot',
          component: {
            render: c => c('router-view')
          },
          children: [
            {
              path: 'arrangements',
              name: AllRouteNames.ArrangementsOnCalendar,
              beforeEnter: (to, _, next) => redirectToFirstWorkspace(next)
            }
          ]
        },
        {
          path: '/list/availability',
          name: AllRouteNames.AvailabilityList,
          beforeEnter: redirectFromListAvailability
        },
        {
          path: '/list/forms',
          name: AllRouteNames.Questionnaires,
          beforeEnter: redirectFromListForms
        },
        {
          path: '/list/teams/:teamId/forms/:questionnaireId',
          name: AllRouteNames.TeamQuestionnaireDetail,
          component: () => import('@/views/list/teams/teamId/questionnaires/QuestionnaireId.vue')
        },
        {
          path: '/list',
          component: {
            render: c => c('router-view')
          },
          children: [
            {
              path: '',
              redirect: { name: AllRouteNames.UnconfirmedList }
            },
            {
              path: 'confirmed',
              name: AllRouteNames.ConfirmedList,
              beforeEnter: redirectFromConfirmedList
            },
            {
              path: 'unconfirmed',
              name: AllRouteNames.UnconfirmedList,
              beforeEnter: redirectFromUnconfirmedList
            }
          ]
        },
        {
          path: '/arrangements',
          name: 'ArrangementsRoot',
          component: {
            render: c => c('router-view')
          },
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreateArrangement,
              beforeEnter: (to, from, next) => redirectToFirstWorkspace(next)
            }
          ]
        },
        {
          path: '/polls',
          name: 'PollsRoot',
          component: {
            render: c => c('router-view')
          },
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreatePoll,
              redirect: { name: AllRouteNames.CreateArrangement }
            },
            {
              path: ':id',
              component: {
                render: c => c('router-view')
              },
              beforeEnter: async (to, _, next) => {
                await guardGroupPoll(to, _, next)
              },
              children: [
                {
                  path: 'edit',
                  name: AllRouteNames.EditPoll,
                  component: () => import('@/views/personal/poll/id/Edit.vue')
                },
                {
                  path: 'vote',
                  name: AllRouteNames.VotePoll,
                  beforeEnter: GuardsInRouter.getPersonalGroupPollGuard({
                    isActive: true
                  }),
                  component: () => import('@/views/calendar/schedule/poll/PrivateVote.vue')
                },
                {
                  path: 'vote-guest',
                  name: AllRouteNames.VotePollGuest,
                  beforeEnter: GuardsInRouter.getPersonalGroupPollGuard({
                    isActive: true
                  }),
                  component: () => import('@/views/polls/id/VoteGuest.vue')
                }
              ]
            }
          ]
        },
        {
          path: '/schedules',
          component: {
            render: c => c('router-view')
          },
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreateSchedule,
              redirect: { name: AllRouteNames.CreateArrangement }
            },
            {
              path: ':id',
              component: () => import('@/views/calendar/schedule/private/id/Root.vue'),
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.ConfirmSchedule }
                },
                {
                  path: 'edit',
                  name: AllRouteNames.EditSchedule,
                  component: () => import('@/views/personal/schedule/scheduleId/Edit.vue')
                },
                {
                  path: 'confirm',
                  name: AllRouteNames.ConfirmSchedule,
                  beforeEnter: GuardsInRouter.getScheduleGuard({ isActive: true }),
                  component: () => import('../views/calendar/schedule/private/id/PrivateScheduleConfirm.vue')
                },
                {
                  path: 'confirm-guest',
                  name: AllRouteNames.ConfirmScheduleGuest,
                  beforeEnter: GuardsInRouter.getScheduleGuard({ isActive: true }),
                  component: () => import('@/views/schedules/schedulesId/ConfirmGuest.vue')
                },
                {
                  path: 'suggest',
                  name: AllRouteNames.SuggestAlternativeDate,
                  component: () => import('../views/calendar/schedule/private/Suggest.vue')
                },
                {
                  path: 'change-requested',
                  name: AllRouteNames.ChangeRequestedSchedule,
                  component: () => import('@/views/schedules/schedulesId/ChangeRequested.vue')
                }
              ]
            }
          ]
        },
        {
          path: '/events',
          component: {
            render: c => c('router-view')
          },
          beforeEnter: (to, from, next) => redirectToFirstWorkspace(next),
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreateEvent
            },
            {
              path: ':eventId/edit',
              name: AllRouteNames.EditEvent
            }
          ]
        },
        {
          path: '/t/:teamId/as/:id/confirm',
          name: AllRouteNames.TeamAvailabilitySharingConfirm,
          beforeEnter: GuardsInRouter.getTeamAvailabilitySharingGuard({ isActive: true }),
          component: () => import('@/views/calendar/patterns/availability/team/Confirm.vue')
        },
        {
          path: '/t/:teamId/as/:availabilitySharingId/confirm-guest',
          name: AllRouteNames.TeamAvailabilitySharingConfirmGuest,
          beforeEnter: GuardsInRouter.getTeamAvailabilitySharingGuard({ isActive: true }),
          component: () => import('@/views/t/teamId/as/teamAvailabilitySharingId/ConfirmGuest.vue')
        },
        {
          path: '/after-confirm',
          name: AllRouteNames.AfterConfirm,
          component: () => import('@/views/AfterConfirm.vue')
        },
        {
          path: '/patterns/availability-sharing/:id/confirm',
          name: AllRouteNames.AvailabilityConfirm,
          beforeEnter: GuardsInRouter.guardConfirm,
          component: () => import('@/views/calendar/patterns/availability/private/Confirm.vue')
        },
        {
          path: '/patterns/availability-sharing/:availabilitySharingId/confirm-guest',
          name: AllRouteNames.AvailabilityConfirmGuest,
          beforeEnter: GuardsInRouter.guardConfirm,
          component: () => import('@/views/patterns/availabilitySharing/availabilitySharingId/ConfirmGuest.vue')
        },
        {
          path: '/callback-from-zoom',
          name: AllRouteNames.CallbackFromZoom,
          component: () => import('../views/callbacks/Zoom.vue')
        },
        {
          path: '/account-link',
          name: AllRouteNames.AccountLink,
          component: isProductionLevelEnv()
            ? () => import('@/views/NotFound.vue')
            : () => import('@/views/accountLink/AccountLink.vue')
        },
        {
          path: '/personal',
          component: {
            render: c => c('router-view')
          },
          children: [
            {
              path: '',
              component: () => import('@/components/pages/PersonalLayout/PersonalLayout.vue'),
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.PersonalHome }
                },
                {
                  path: 'availability-sharings',
                  name: AllRouteNames.PersonalHome,
                  component: () => import('@/components/pages/PersonalHome/PersonalHome.vue')
                },
                {
                  path: 'confirmed-list',
                  name: AllRouteNames.PersonalConfirmedList,
                  component: () => import('@/components/pages/PersonalConfirmedList/PersonalConfirmedList.vue')
                },
                {
                  path: 'unconfirmed-list',
                  name: AllRouteNames.PersonalUnconfirmedList,
                  component: () => import('@/components/pages/PersonalUnconfirmedList/PersonalUnconfirmedList.vue')
                },
                {
                  path: 'calendar',
                  name: AllRouteNames.PersonalCalendar,
                  component: () => import('@/components/pages/PersonalCalendar/PersonalCalendar.vue')
                }
              ]
            },
            {
              path: 'event',
              component: {
                render: c => c('router-view')
              },
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.PersonalEventCreate }
                },
                {
                  path: 'create',
                  name: AllRouteNames.PersonalEventCreate,
                  component: () => import('@/components/pages/PersonalEventCreate/PersonalEventCreate.vue')
                },
                {
                  path: ':eventId/edit',
                  name: AllRouteNames.PersonalEventEdit,
                  component: () => import('@/components/pages/PersonalEventEdit/PersonalEventEdit.vue')
                }
              ]
            },
            {
              path: 'arrangement',
              component: {
                render: c => c('router-view')
              },
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.PersonalArrangementCreate }
                },
                {
                  path: 'create',
                  name: AllRouteNames.PersonalArrangementCreate,
                  component: () => import('@/components/pages/PersonalArrangementCreate/PersonalArrangementCreate.vue')
                }
              ]
            },
            {
              path: 'availability-sharing',
              component: {
                render: c => c('router-view')
              },
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.PersonalAvailabilitySharingCreate }
                },
                {
                  path: 'create',
                  name: AllRouteNames.PersonalAvailabilitySharingCreate,
                  component: () =>
                    import('@/components/pages/PersonalAvailabilitySharingCreate/PersonalAvailabilitySharingCreate.vue')
                },
                {
                  path: ':availabilitySharingId/edit',
                  name: AllRouteNames.PersonalAvailabilitySharingEdit,
                  component: () => import('@/views/personal/availabilitySharing/availabilitySharingId/Edit.vue')
                }
              ]
            }
          ]
        },
        {
          path: '/teams/:id',
          component: () => import('@/views/teams/id/Root.vue'),
          children: [
            {
              path: '',
              component: () => import('@/components/pages/TeamLayout/TeamLayout.vue'),
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.TeamHome }
                },
                {
                  path: 'availability-sharings',
                  name: AllRouteNames.TeamHome,
                  component: () => import('@/components/pages/TeamHome/TeamHome.vue')
                },
                {
                  path: 'confirmed-list',
                  name: AllRouteNames.TeamConfirmedList,
                  beforeEnter: teamUsableGuard,
                  component: () => import('@/components/pages/TeamConfirmedList/TeamConfirmedList.vue')
                },
                {
                  path: 'unconfirmed-list',
                  name: AllRouteNames.TeamUnconfirmedList,
                  beforeEnter: teamUsableGuard,
                  component: () => import('@/components/pages/TeamUnconfirmedList/TeamUnconfirmedList.vue')
                },
                {
                  path: 'forms',
                  name: AllRouteNames.TeamForms,
                  beforeEnter: teamUsableGuard,
                  component: () => import('@/components/pages/TeamForms/TeamForms.vue')
                },
                {
                  path: 'calendar',
                  name: AllRouteNames.TeamCalendar,
                  beforeEnter: teamUsableGuard,
                  component: () => import('@/components/pages/TeamCalendar/TeamCalendar.vue')
                },
                {
                  path: 'unavailable',
                  name: AllRouteNames.TeamUnavailable,
                  beforeEnter: redirectFromUnavailable,
                  component: {
                    render: c => c('router-view')
                  }
                },
                {
                  path: 'settings',
                  component: () => import('@/views/teams/id/settings/TeamSettingsRoot.vue'),
                  redirect: { name: AllRouteNames.TeamSettingMember },
                  children: [
                    {
                      path: '',
                      name: AllRouteNames.TeamSettingRoot,
                      redirect: { name: AllRouteNames.TeamSettingMember }
                    },
                    {
                      path: 'member',
                      name: AllRouteNames.TeamSettingMember,
                      component: () => import('@/views/teams/id/settings/TeamMember.vue')
                    },
                    {
                      path: 'my-calendar',
                      name: AllRouteNames.TeamSettingMyCalendar,
                      component: () => import('@/views/teams/id/settings/TeamMyCalendar.vue')
                    },
                    {
                      path: 'team-info',
                      name: AllRouteNames.TeamInfoSetting,
                      component: () => import('@/views/teams/id/settings/TeamDetails.vue')
                    },
                    {
                      path: 'plan',
                      name: AllRouteNames.TeamPlan,
                      component: () => import('@/views/teams/id/settings/TeamPlan.vue')
                    },
                    {
                      path: 'payment',
                      name: AllRouteNames.TeamPlanPaymentInfo,
                      component: () => import('@/views/teams/id/settings/TeamPlanPaymentInfo.vue')
                    },
                    {
                      path: 'order',
                      name: AllRouteNames.TeamPlanOrder,
                      component: () => import('@/views/teams/id/settings/TeamPlanOrder.vue')
                    },
                    {
                      path: 'webhooks',
                      name: AllRouteNames.TeamWebhookSettings,
                      component: () => import('@/views/teams/id/settings/TeamWebhooks.vue')
                    },
                    {
                      path: 'resource',
                      name: AllRouteNames.TeamResourceSettings,
                      component: () => import('@/components/pages/TeamResourceSettings/TeamResourceSettings.vue')
                    }
                  ]
                }
              ]
            },
            {
              path: 'event',
              component: {
                render: c => c('router-view')
              },
              beforeEnter: teamUsableGuard,
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.TeamEventCreate }
                },
                {
                  path: 'create',
                  name: AllRouteNames.TeamEventCreate,
                  component: () => import('@/components/pages/TeamEventCreate/TeamEventCreate.vue')
                },
                {
                  path: ':eventId/edit',
                  name: AllRouteNames.TeamEventEdit,
                  component: () => import('@/components/pages/TeamEventEdit/TeamEventEdit.vue')
                }
              ]
            },
            {
              path: 'arrangement',
              component: () => import('@/components/pages/TeamArrangementRoot/TeamArrangementRoot.vue'),
              beforeEnter: teamUsableGuard,
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.TeamArrangementCreate }
                },
                {
                  path: 'create',
                  name: AllRouteNames.TeamArrangementCreate,
                  component: () => import('@/components/pages/TeamArrangementCreate/TeamArrangementCreate.vue')
                }
              ]
            },
            {
              path: 'schedules',
              component: {
                render: c => c('router-view')
              },
              children: [
                {
                  path: ':scheduleId',
                  component: () => import('@/views/teams/id/schedules/scheduleId/Root.vue'),
                  children: [
                    {
                      path: 'confirm',
                      name: AllRouteNames.TeamScheduleConfirm,
                      beforeEnter: GuardsInRouter.getTeamScheduleGuard({ isActive: true }),
                      component: () => import('@/views/teams/id/schedules/scheduleId/Confirm.vue')
                    },
                    {
                      path: 'confirm-guest',
                      name: AllRouteNames.TeamScheduleConfirmGuest,
                      beforeEnter: GuardsInRouter.getTeamScheduleGuard({ isActive: true }),
                      component: () => import('@/views/teams/id/schedules/scheduleId/ConfirmGuest.vue')
                    },
                    {
                      path: 'edit',
                      name: AllRouteNames.TeamScheduleEdit,
                      beforeEnter: teamUsableGuard,
                      component: () => import('@/views/teams/id/schedule/scheduleId/Edit.vue')
                    },
                    {
                      path: 'change-requested',
                      name: AllRouteNames.ChangeRequestedTeamSchedule,
                      component: () => import('@/views/teams/id/schedules/scheduleId/ChangeRequested.vue')
                    }
                  ]
                }
              ]
            },
            {
              path: 'availability-sharing',
              component: {
                render: c => c('router-view')
              },
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.TeamAvailabilitySharingCreate }
                },
                {
                  path: 'create',
                  name: AllRouteNames.TeamAvailabilitySharingCreate,
                  component: () =>
                    import('@/components/pages/TeamAvailabilitySharingCreate/TeamAvailabilitySharingCreate.vue')
                },
                {
                  path: ':availabilitySharingId/edit',
                  name: AllRouteNames.TeamAvailabilitySharingEdit,
                  component: () => import('@/views/teams/id/availabilitySharing/availabilitySharingId/Edit.vue')
                },
                {
                  path: ':availabilitySharingId/confirmed-events/:confirmedEventId/members/:memberId/accounts/:accountId/calendars/:calendarId/events/:eventId',
                  name: AllRouteNames.TeamAvailabilitySharingConfirmedEventEditByMember,
                  component: () =>
                    import(
                      '@/components/pages/TeamAvailabilitySharingConfirmedEventEditByMember/TeamAvailabilitySharingConfirmedEventEditByMember.vue'
                    )
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/teams/invitations/invited-token-expired',
      name: AllRouteNames.TeamMemberTokenExpired,
      component: () => import('@/views/teams/id/invitations/TeamMemberInvitedTokenExpired.vue')
    },
    {
      path: '/teams/:id/invitations/:invitationId',
      name: AllRouteNames.TeamMemberInvited,
      component: () => import('@/views/teams/id/invitations/TeamMemberInvited.vue')
    },
    {
      path: '/profile',
      name: AllRouteNames.ProfileView,
      component: () => import('@/components/pages/ProfileView/ProfileView.vue')
    },
    {
      path: '/profile/:id',
      name: AllRouteNames.ProfileDetails,
      component: () => import('@/components/pages/ProfileShareView/ProfileShareView.vue')
    },
    {
      path: '/profile-edit',
      name: AllRouteNames.ProfileEdit,
      component: () => import('@/components/pages/ProfileEdit/ProfileEdit.vue')
    },
    {
      path: '/callback-from-ms',
      name: AllRouteNames.CallbackFromMS,
      redirect: { name: AllRouteNames.CallbackMicrosoftIdentity }
    },
    {
      path: '/callback-from-google',
      name: AllRouteNames.CallbackFromGoogle,
      redirect: { name: AllRouteNames.CallbackGoogleIdentity }
    },
    {
      path: '/callbacks',
      component: { render: c => c('router-view') },
      children: [
        {
          path: 'microsoft-identity',
          name: AllRouteNames.CallbackMicrosoftIdentity,
          component: () => import('@/views/callbacks/MicrosoftIdentity.vue')
        },
        {
          path: 'google-identity',
          name: AllRouteNames.CallbackGoogleIdentity,
          component: () => import('@/views/callbacks/GoogleIdentity.vue')
        },
        {
          path: 'slack',
          name: AllRouteNames.CallbackSlack,
          component: () => import('@/views/callbacks/Slack.vue')
        },
        {
          path: 'microsoft/provider-resource-grant',
          name: AllRouteNames.CallbackMicrosoftProviderResourceGrant,
          component: () =>
            import(
              '@/components/pages/CallbackMicrosoftProviderResourceGrant/CallbackMicrosoftProviderResourceGrant.vue'
            )
        }
      ]
    },
    {
      path: '/settings',
      name: AllRouteNames.Settings,
      component: () => import('@/components/pages/Settings/Settings.vue')
    },
    {
      path: '/external',
      component: () => import('@/views/external/ExternalRoot.vue'),
      children: [
        {
          path: 'teams/:teamId/availability-sharings/:availabilitySharingId',
          component: {
            render: c => c('router-view')
          },
          children: [
            {
              path: 'confirm',
              name: AllRouteNames.ExternalTeamAvailabilitySharingConfirm,
              component: () =>
                import('@/views/external/teams/teamId/availabilitySharings/availabilitySharingId/Confirm.vue')
            }
          ]
        },
        {
          path: 'after-confirm',
          name: AllRouteNames.ExternalAfterConfirm,
          component: () => import('@/views/external/AfterConfirm.vue')
        }
      ]
    },
    // temp. Removed soon
    {
      path: '/react-pages-demo',
      beforeEnter: (_to, _from, next) => {
        if (isDevelopmentLevelEnv()) {
          next()
          return
        }
        next({ name: AllRouteNames.Main })
      },
      component: () => import('@/components/pages/ReactPagesDemo.vue')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

const pageToSendSignal: { [key in RouteNames]?: PageAccessSignalType } = {
  [AllRouteNames.CreateArrangement]: 'ACCESS_ARRANGEMENT_CREATE',
  [AllRouteNames.EditSchedule]: 'ACCESS_SCHEDULE_EDIT',
  [AllRouteNames.TeamScheduleEdit]: 'ACCESS_SCHEDULE_EDIT',
  [AllRouteNames.ConfirmSchedule]: 'ACCESS_SCHEDULE_CONFIRM',
  [AllRouteNames.EditPoll]: 'ACCESS_GROUPPOLL_EDIT',
  [AllRouteNames.VotePoll]: 'ACCESS_GROUPPOLL_VOTE',
  [AllRouteNames.AvailabilitySharingCreate]: 'ACCESS_PUBLICURL_CREATE',
  [AllRouteNames.PersonalAvailabilitySharingEdit]: 'ACCESS_PUBLICURL_EDIT',
  [AllRouteNames.TeamAvailabilitySharingEdit]: 'ACCESS_PUBLICURL_EDIT',
  [AllRouteNames.AvailabilityConfirm]: 'ACCESS_PUBLICURL_CONFIRM',
  [AllRouteNames.AvailabilityConfirmGuest]: 'ACCESS_PUBLICURL_CONFIRM_GUEST',
  [AllRouteNames.CreateEvent]: 'ACCESS_EVENT_CREATE',
  [AllRouteNames.EditEvent]: 'ACCESS_EVENT_EDIT',
  [AllRouteNames.ProfileView]: 'ACCESS_MYPROFILE',
  [AllRouteNames.SignUp]: 'ACCESS_SIGNUP',
  [AllRouteNames.SignIn]: 'ACCESS_SIGNIN',
  [AllRouteNames.TeamPlan]: 'ACCESS_TEAM_PLAN',
  [AllRouteNames.TeamPlanOrder]: 'ACCESS_TEAM_ORDER',
  [AllRouteNames.TeamPlanPaymentInfo]: 'ACCESS_TEAM_PAYMENT',
  [AllRouteNames.AuthTeamsCreate]: 'ACCESS_AUTH_TEAMS_CREATE',
  [AllRouteNames.AuthTeamsCreateFree]: 'ACCESS_AUTH_TEAMS_CREATE_FREE',
  [AllRouteNames.AuthTeamIdStartFree]: 'ACCESS_AUTH_TEAM_ID_START_FREE'
}
router.beforeEach((to, from, next) => {
  AppModule.SET_PAGE_LOADING(true)
  next()
})
router.afterEach(async to => {
  AppModule.SET_PAGE_LOADING(false)
  const mySignal = pageToSendSignal[to.name as RouteNames]
  // 特定のページへのアクセスを別Eventとして発火するための仕組み
  if (mySignal) {
    await Vue.prototype.$analytics.send(mySignal, { ...to.params })
  }
  await Vue.prototype.$analytics.send(SignalType.PAGE_ACCESS, { name: to.name, params: to.params, query: to.query })
  hubspotService.refresh()
})
export default router

export { uiRevampRouter } from './ui-revamp-router'
export { AllRouteNames }
export type { RouteNames }
