import i18n from '@/i18n'
import * as calendarAPI from '@/lib/api/calendar'
import { getMemberEvent } from '@/lib/api/team'
import { addDays } from '@/lib/utils/timezone'
import store from '@/store'
import CalendarModule from '@/store/modules/calendars'
import TeamCalendarModule, { encodeTeamCalendarKey } from '@/store/modules/teamCalendar'
import { FullCalendarEvent, GoogleEvent, ScheduleSource, SpirAttendeeStatus, VISIBILITY } from '@/types'
import { isAfter, isBefore, parseISO } from 'date-fns'
import { flatten } from 'lodash'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import CalendarControlModule from './calendarControl'
import CalendarsModule, { decodeCalendarKey, encodeCalendarKeyByAccountIdAndCalendarId } from './calendars'
import EditEventModule from './editEvent'
import { decodeTeamCalendarKey } from './teamCalendar'
import UserModule from './user'
import { TeamEvent } from '@spirinc/contracts'

export const CACHE_PERIOD = 8

const MODULE_NAME = 'Events'
const TEMP_DEFAULT_BACKGROUND_COLOR = '#9fe1e7'
const TEMP_DEFAULT_FOREGROUND_COLOR = '#000'

interface EventCache {
  key: string
  type: 'private' | 'team'
  events: Array<GoogleEvent>
  start?: string // can't save Date to Local storage
  end?: string
  isLoading: boolean
}

export const decodeFullCalendarEventToGoogleEvent = (fullCalendarEvent: FullCalendarEvent): GoogleEvent => {
  // @ts-expect-error TS2532
  const teamInfo = fullCalendarEvent.extendedProps.teamInfo
  return {
    id: fullCalendarEvent.id,
    description: fullCalendarEvent.description,
    // @ts-expect-error TS2532
    accountId: fullCalendarEvent.extendedProps.accountId,
    // @ts-expect-error TS2532
    calendarId: fullCalendarEvent.extendedProps.calendarId,
    start: fullCalendarEvent.start.toISOString(),
    end: fullCalendarEvent.end.toISOString(),
    // @ts-expect-error TS2322
    attendeeFlag: fullCalendarEvent.extendedProps.attendeeFlag,
    summary: fullCalendarEvent.title,
    // @ts-expect-error TS2322
    visibility: fullCalendarEvent.extendedProps.visibility,
    allDay: fullCalendarEvent.allDay || false,
    organizer: fullCalendarEvent.extendedProps?.organizer,
    // @ts-expect-error TS2532
    attendees: fullCalendarEvent.extendedProps.attendees,
    resources: fullCalendarEvent.extendedProps?.resources?.map(r => ({
      fullName: r.fullName,
      resource: true,
      responseStatus: r.responseStatus,
      email: r.email
    })),
    teamInfo,
    // @ts-expect-error TS2532
    onlineMeeting: fullCalendarEvent.extendedProps.onlineMeeting,
    memberEditBasis: fullCalendarEvent.extendedProps?.memberEditBasis
  }
}
const encodeGoogleEventToFullCalendarEvent = (googleEvent: GoogleEvent, teamKey?: string): FullCalendarEvent => {
  const { description, accountId, calendarId, start, end, attendeeFlag, summary, id, allDay, visibility, attendees } =
    googleEvent
  let borderColor = 'white'
  let backgroundColor = TEMP_DEFAULT_BACKGROUND_COLOR
  let foregroundColor = TEMP_DEFAULT_FOREGROUND_COLOR
  let teamInfo
  if (teamKey) {
    const targetMember = TeamCalendarModule.allTeamMembers[teamKey]
    const { teamId, memberId } = decodeTeamCalendarKey(teamKey)
    if (targetMember) {
      backgroundColor = targetMember.backgroundColor
      foregroundColor = targetMember.foregroundColor
      teamInfo = {
        teamId: teamId,
        memberId: memberId,
        fullName: targetMember.fullName,
        photoUrl: targetMember.photoUrl
      }
    }
  } else {
    // @ts-expect-error TS2345
    const idByAccountAndCalendar = encodeCalendarKeyByAccountIdAndCalendarId(accountId, calendarId)
    const targetCalendar = CalendarsModule.flattenCalendars[idByAccountAndCalendar]
    if (targetCalendar) {
      backgroundColor = targetCalendar.backgroundColor
      foregroundColor = targetCalendar.foregroundColor
    }
  }

  const title =
    summary ||
    (visibility === VISIBILITY.PRIVATE ? i18n.t('message.havePlan') : `(${i18n.t('message.noTitle')})`).toString()
  let source: ScheduleSource = 'calendarEvent'
  if (attendeeFlag === SpirAttendeeStatus.DECLINED) {
    foregroundColor = backgroundColor
    borderColor = backgroundColor
    backgroundColor = '#FFF'
    source = 'declined'
  } else if (attendeeFlag === SpirAttendeeStatus.NEEDS_ACTION) {
    foregroundColor = backgroundColor
    borderColor = backgroundColor
    backgroundColor = '#FFF'
  } else if (attendeeFlag === SpirAttendeeStatus.TENTATIVE) {
    source = 'tentative'
  }
  return {
    // @ts-expect-error TS2322
    id,
    title,
    start: parseISO(start),
    end: parseISO(end),
    allDay: allDay || false,
    startEditable: false,
    durationEditable: false,
    extendedProps: {
      source: source,
      eventId: id,
      calendarId,
      accountId,
      teamInfo: teamKey ? teamInfo : undefined,
      visibility,
      attendeeFlag,
      organizer: googleEvent.organizer,
      attendees,
      resources: googleEvent.resources,
      onlineMeeting: googleEvent.onlineMeeting,
      memberEditBasis: googleEvent.memberEditBasis
    },
    textColor: foregroundColor,
    borderColor,
    backgroundColor: backgroundColor,
    // @ts-expect-error TS2322
    description
  }
}

const convertTeamMemberEventToGoogleEvent = (e: TeamEvent): GoogleEvent => {
  return {
    ...e,
    summary: e.summary ?? null,
    description: e.description ?? null,
    location: e.location ?? null,
    attendeeFlag: e.attendeeFlag as SpirAttendeeStatus,
    visibility: e.visibility as VISIBILITY,
    organizer: e.organizer,
    attendees: e.attendees?.map(a => ({
      id: a.id,
      photoURL: a.photoURL,
      fullName: a.displayName,
      email: a.email,
      resource: a.resource === true,
      responseStatus: a.responseStatus as SpirAttendeeStatus
    })),
    resources: e.resources?.map(r => ({
      fullName: r.displayName,
      email: r.email,
      resource: r.resource === true,
      responseStatus: r.responseStatus as SpirAttendeeStatus
    })),
    memberEditBasis: e.eventSource
  }
}

export interface IModuleEvents {
  cachedEvents: EventCache[]
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Events extends VuexModule {
  cachedEvents: EventCache[] = []
  get allPrivateEvents(): GoogleEvent[] {
    return flatten(
      this.cachedEvents.filter(calendar => calendar.type === 'private' && !calendar.isLoading).map(c => c.events)
    )
  }
  get filteredPrivateEvents(): GoogleEvent[] {
    const editingEventId = EditEventModule.editingEvent?.id
    return this.allPrivateEvents.filter(({ start, end, id, accountId, calendarId }) => {
      // @ts-expect-error TS2345
      const key = encodeCalendarKeyByAccountIdAndCalendarId(accountId, calendarId)
      return (
        id !== editingEventId &&
        !!start &&
        !!end &&
        CalendarsModule.visibleCalendarsKeys.some(c => c === key) &&
        // @ts-expect-error TS2531
        isAfter(new Date(end), CalendarControlModule.getEventsDate.start) &&
        // @ts-expect-error TS2531
        isBefore(new Date(start), CalendarControlModule.getEventsDate.end)
      )
    })
  }
  get getEventById() {
    // @ts-expect-error TS2322
    return (eventId: string, accountId: string = null, calendarId: string = null): GoogleEvent => {
      // @ts-expect-error TS2322
      return this.filteredPrivateEvents.find(
        (e: GoogleEvent) =>
          e.id === eventId &&
          (accountId ? e.accountId === accountId : true) &&
          (calendarId ? e.calendarId === calendarId : true)
      )
    }
  }
  get teamEvents(): FullCalendarEvent[] {
    const visibleTeamCalendars = TeamCalendarModule.visibleTeamMemberKeys
    const allTeamEvents = flatten(
      this.cachedEvents
        .filter(calendar => {
          return calendar.type === 'team' && visibleTeamCalendars.indexOf(calendar.key) >= 0
        })
        .map(c => c.events.map(te => encodeGoogleEventToFullCalendarEvent(te, c.key)))
      // @ts-expect-error TS2769
    ).filter((event: GoogleEvent) => {
      return (
        // @ts-expect-error TS2531
        isAfter(new Date(event.end), CalendarControlModule.getEventsDate.start) &&
        // @ts-expect-error TS2531
        isBefore(new Date(event.start), CalendarControlModule.getEventsDate.end)
      )
    })
    return allTeamEvents
  }
  get privateEvents(): FullCalendarEvent[] {
    return this.filteredPrivateEvents.map(googleEvent => {
      return encodeGoogleEventToFullCalendarEvent(googleEvent)
    })
  }
  @Action
  async fetchAllVisibleTeamCalendars(payload?: { option?: { hideLoader?: boolean; sequential?: boolean } }) {
    if (payload?.option?.sequential) {
      await TeamCalendarModule.visibleTeamMemberKeys.reduce(async (previousPromise, nextID) => {
        await previousPromise
        return this.fetchEventsByTeamCalendarKey({ teamCalendarKey: nextID, option: payload.option })
      }, Promise.resolve())
    } else {
      return Promise.all(
        TeamCalendarModule.visibleTeamMemberKeys.map(tKey => {
          // @ts-expect-error TS2532
          this.fetchEventsByTeamCalendarKey({ teamCalendarKey: tKey, option: payload.option })
        })
      )
    }
  }
  @Action
  async fetchEventsByTeamCalendarKey(payload: {
    teamCalendarKey: string
    option?: { hideLoader?: boolean; dateRange?: { start: Date; end: Date } }
  }) {
    if (!UserModule.isSignIn || !CalendarControlModule.getEventsDate?.start) {
      return
    }
    const { teamCalendarKey } = payload
    const hideLoader = !!payload.option?.hideLoader
    const { teamId, memberId } = decodeTeamCalendarKey(teamCalendarKey)
    try {
      const start = payload.option?.dateRange?.start ?? CalendarControlModule.getEventsDate.start
      const startDate = start
      const endDate = payload.option?.dateRange?.end ?? CalendarControlModule.nextPeriodDate
      if (!hideLoader) {
        TeamCalendarModule.SET_MEMBER_CALENDAR_LOADING({ teamId, memberId, isLoading: true })
      }
      this.SET_FETCHED_CALENDARS_LOADING({
        calendarKey: teamCalendarKey,
        loadingStatus: true
      })
      const events = await getMemberEvent(teamId, memberId, startDate.toISOString(), endDate.toISOString())
      TeamCalendarModule.SET_MEMBER_CALENDAR_LOADING({ teamId, memberId, isLoading: false })
      this.SET_FETCHED_CALENDARS({
        type: 'team',
        key: teamCalendarKey,
        events: events.map(e => convertTeamMemberEventToGoogleEvent(e)),
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        isLoading: false
      })
    } catch (e) {
      this.SET_FETCHED_CALENDARS({
        type: 'team',
        key: teamCalendarKey,
        events: [],
        isLoading: false
      })
      TeamCalendarModule.SET_MEMBER_CALENDAR_LOADING({ teamId, memberId, isLoading: false })
    }
  }
  @Action
  async fetchEventsByCalendarKey(calendarKey) {
    const { accountId, calendarId } = decodeCalendarKey(calendarKey)
    this.fetchEvents({ targetCalendars: [{ accountId, calendarId }] })
  }
  @Action
  async fetchEvents(payload?: {
    targetCalendars?: { accountId?: string; calendarId?: string }[]
    option?: { hideLoader?: boolean; sequential?: boolean }
  }) {
    if (!UserModule.isSignIn || !CalendarControlModule.getEventsDate?.start) {
      return
    }
    try {
      const start = CalendarControlModule.getEventsDate.start
      const startDate = addDays(start, -CACHE_PERIOD).toISOString()
      const endDate = addDays(start, CACHE_PERIOD).toISOString()
      let visibleCalendars = []
      // @ts-expect-error TS2532
      if (payload?.targetCalendars?.length > 0) {
        // @ts-expect-error TS2322
        visibleCalendars = payload.targetCalendars.map(c =>
          // @ts-expect-error TS2345
          encodeCalendarKeyByAccountIdAndCalendarId(c.accountId, c.calendarId)
        )
      } else {
        // @ts-expect-error TS2322
        visibleCalendars = CalendarsModule.visibleCalendarsKeys
      }
      const fetchEvent = async (calendarKey: string) => {
        const targetCalendar = CalendarsModule.flattenCalendars[calendarKey]
        try {
          if (!payload?.option?.hideLoader) {
            CalendarModule.ON_CALENDAR_VISIBLE({
              accountId: targetCalendar.accountId,
              calendarId: targetCalendar.calendarId,
              isLoading: true
            })
          }
          const events = await calendarAPI.getCalendarEvents(
            targetCalendar.accountId,
            targetCalendar.calendarId,
            startDate,
            endDate
          )
          CalendarModule.ON_CALENDAR_VISIBLE({
            accountId: targetCalendar.accountId,
            calendarId: targetCalendar.calendarId,
            isLoading: false
          })
          this.SET_FETCHED_CALENDARS({
            type: 'private',
            key: calendarKey,
            events,
            start: startDate,
            end: endDate,
            isLoading: false
          })
        } catch (e: unknown) {
          this.SET_FETCHED_CALENDARS({
            type: 'private',
            key: calendarKey,
            events: [],
            start: startDate,
            end: endDate,
            isLoading: false
          })
        }
      }
      if (payload?.option?.sequential) {
        await visibleCalendars.reduce(async (previousPromise, nextID) => {
          await previousPromise
          return fetchEvent(nextID)
        }, Promise.resolve())
      } else {
        await Promise.all(visibleCalendars.map(key => fetchEvent(key)))
      }
    } catch (e) {
      this.SET_FETCHED_CALENDARS()
    }
  }
  @Action
  async deleteTeamMemberEvent(args: {
    teamId: string
    memberId: string
    accountId: string
    calendarId: string
    eventId: string
  }) {
    const teamMemberCalendarKey = encodeTeamCalendarKey(args.teamId, args.memberId)
    const cachedEvent = this.cachedEvents.find(calendar => calendar.key === teamMemberCalendarKey)
    if (cachedEvent === undefined) return
    cachedEvent.events = cachedEvent.events.filter(
      event =>
        !(event.id === args.eventId && event.accountId === args.accountId && event.calendarId === args.calendarId)
    )
    this.SET_FETCHED_CALENDARS(cachedEvent)
  }
  @Mutation
  SET_FETCHED_CALENDARS_LOADING({ calendarKey, loadingStatus }) {
    const targetElementIndex = this.cachedEvents.findIndex(calendar => calendar.key === calendarKey)
    if (targetElementIndex >= 0) {
      this.cachedEvents[targetElementIndex] = {
        ...this.cachedEvents[targetElementIndex],
        isLoading: loadingStatus
      }
    }
  }
  @Mutation
  SET_FETCHED_CALENDARS(cachedEvent?: EventCache) {
    if (!cachedEvent) {
      this.cachedEvents = []
      return
    }
    cachedEvent.events = cachedEvent.events.map(event =>
      !event.visibility ? { ...event, visibility: VISIBILITY.DEFAULT } : event
    )
    const targetElementIndex = this.cachedEvents.findIndex(calendar => calendar.key === cachedEvent.key)
    if (targetElementIndex >= 0) {
      // this.cachedEvents[targetElementIndex] = cachedEvent
      this.cachedEvents.splice(targetElementIndex, 1, cachedEvent)
    } else {
      this.cachedEvents.push(cachedEvent)
    }
  }
  @Mutation
  RESET_STATE() {
    this.cachedEvents = []
  }
}

export default getModule(Events)
