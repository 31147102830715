import { deleteCandidate, deleteSchedule, getList } from '@/lib/api/scheduleTeam'
import { ScheduleModelTeam } from '@/models/data/schedule'
import store from '@/store'
import ProfileModule from '@/store/modules/profile'
import UserModule from '@/store/modules/user'
import { flatten } from 'lodash'
import { nanoid } from 'nanoid'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Candidate, FullCalendarEvent, ListType, ScheduleStatus } from '../../types'
import CalendarsModule from './calendars'
import { getScheduleTitleByStatus } from './candidates'
import EditScheduleTeamModule from './editScheduleTeam'
import { TeamModule } from './team'
import TeamCalendarModule, { encodeTeamCalendarKey } from './teamCalendar'
import TeamRecord from './teamRecord'
import { TeamPlanStateModule } from './teamSubscriptionState'

const MODULE_NAME = 'TeamScheduleList'

type ScheduleList = {
  [key: string]: {
    isLoading: boolean
    models: ScheduleModelTeam[]
  }
}
export interface IModuleTeamScheduleList {
  scheduleList: ScheduleList
  confirmedScheduleList: ScheduleList
}

const encodeToFullCalendarEvent = (
  scheduleModel: ScheduleModelTeam,
  candidate: Candidate,
  memberId: string
): FullCalendarEvent => {
  const backgroundColor =
    TeamCalendarModule.memberCalendarByTeamAndMemberId(scheduleModel.teamId, memberId)?.backgroundColor || '#1e366a'
  return {
    id: nanoid(),
    title: getScheduleTitleByStatus(scheduleModel.title, 'open', ListType.SCHEDULE),
    start: new Date(candidate.start),
    end: new Date(candidate.end),
    startEditable: false,
    durationEditable: false,
    backgroundColor: backgroundColor,
    borderColor: 'white',
    extendedProps: {
      source: 'schedule',
      teamInfo: {
        teamId: scheduleModel.teamId,
        memberId: memberId
      },
      scheduleId: scheduleModel.id,
      candidateId: candidate.id,
      underDuration: candidate.underDuration
    }
  }
}
@Module({ dynamic: true, name: MODULE_NAME, namespaced: true, store })
class TeamScheduleList extends VuexModule {
  scheduleList: ScheduleList = {}
  confirmedScheduleList: ScheduleList = {}

  get scheduleListByTeamId() {
    return (teamId: string) => {
      return this.scheduleList[teamId]
    }
  }
  get scheduleModelByTeamIdScheduleId() {
    return (teamId: string, scheduleId: string) => {
      const team = this.scheduleListByTeamId(teamId)
      return team?.models.find(m => m.id === scheduleId)
    }
  }
  get confirmedScheduleListByTeamId() {
    return (teamId: string) => {
      return this.confirmedScheduleList[teamId]
    }
  }
  get confirmedScheduleByTeamIdAndScheduleId() {
    return (teamId: string, scheduleId: string) => {
      const team = this.confirmedScheduleListByTeamId(teamId)
      return team?.models.find(m => m.id === scheduleId)
    }
  }
  get allScheduleModels(): ScheduleModelTeam[] {
    const teamModels = Object.keys(this.scheduleList)
      .filter(teamId => TeamPlanStateModule.usable(teamId))
      .map(teamId => this.scheduleList[teamId].models)
    return flatten(teamModels)
  }
  get allCandidates(): FullCalendarEvent[] {
    const editingScheduleId = EditScheduleTeamModule.editingSchedule?.id
    return this.allScheduleModels
      .filter(schedule => {
        // 編集中の日程調整の候補は除外
        if (schedule.id === editingScheduleId) {
          return false
        }
        if (!schedule.canDisplayDate) {
          return false
        }
        return true
      })
      .map(schedule => {
        // @ts-expect-error TS2322
        const targetMemberIds: string[] = [
          schedule.organizerMemberId,
          ...schedule.attendingMembers.map(am => am.memberId)
        ]
          .map(memberId => {
            const memberInfo = TeamRecord.memberInfo(schedule.teamId, memberId)
            if (memberInfo?.userId === ProfileModule.myProfile.id) {
              const myInfo = TeamRecord.myInfoByTeamId(schedule.teamId)
              const primaryCalendar = myInfo?.calendars.find(c => c.primary)
              if (
                primaryCalendar &&
                CalendarsModule.isVisibleByAccountAndCalendarId(
                  primaryCalendar.calendar.accountId,
                  primaryCalendar.calendar.id
                )
              ) {
                return memberId
              }
              const key = encodeTeamCalendarKey(schedule.teamId, memberId)
              if (TeamCalendarModule.visibleTeamMemberKeys.some(vkey => vkey === key)) {
                return memberId
              }
              return null
            } else {
              const key = encodeTeamCalendarKey(schedule.teamId, memberId)
              if (TeamCalendarModule.visibleTeamMemberKeys.some(vkey => vkey === key)) {
                return memberId
              }
              return null
            }
          })
          .filter(mId => !!mId)
        // すべてVislbleがFalseの場合
        if (!targetMemberIds) {
          return []
        }
        return schedule.candidates
          .map(mc => {
            return targetMemberIds.map(memberId => {
              return encodeToFullCalendarEvent(schedule, mc, memberId)
            })
          })
          .flat()
      })
      .flat()
  }
  @Action
  async fetchAllList() {
    await Promise.all(
      TeamModule.myLoadedUsableTeams.map(async nextTeam => {
        try {
          return await this.fetchAllTypeSchedules({ teamId: nextTeam.id, hideLoader: true })
        } catch (e: unknown) {
          return Promise.resolve()
        }
      })
    )
  }
  @Action
  async fetchAllTypeSchedules(payload: { teamId: string; hideLoader?: boolean }) {
    await Promise.all([this.fetchConfirmedTeamSchedule(payload), this.fetchUnConfirmedTeamSchedule(payload)])
    return Promise.resolve(null)
  }
  @Action
  async fetchUnConfirmedTeamSchedule(payload: { teamId: string; hideLoader?: boolean }) {
    if (!UserModule.isSignIn) {
      return
    }
    const { teamId } = payload
    if (!payload.hideLoader) {
      this.SET_SCHEDULE_LOADING({ teamId, isLoading: true })
    }
    try {
      const status: ScheduleStatus[] = [
        'suggestedByOrganizer',
        'requestedByConfirmer',
        'requestedByOrganizer',
        'suggestedByConfirmer',
        'expired'
      ]
      const response = await this.fetchTeamSchedule({ teamId, status })
      const scheduleList = response.schedules.map(s => new ScheduleModelTeam(teamId, s))
      this.SET_SCHEDULE({ teamId, models: scheduleList })
    } catch (e: unknown) {
      this.SET_SCHEDULE({ teamId, models: [] })
      throw e
    } finally {
      this.SET_SCHEDULE_LOADING({ teamId, isLoading: false })
    }
  }
  @Action
  async fetchConfirmedTeamSchedule(payload: { teamId: string; hideLoader?: boolean }) {
    if (!UserModule.isSignIn) {
      return
    }
    const { teamId } = payload
    if (!payload.hideLoader) {
      this.SET_CONFIRMED_SCHEDULE_LOADING({ teamId, isLoading: true })
    }
    try {
      const status: ScheduleStatus[] = ['confirmed']
      const response = await this.fetchTeamSchedule({ teamId, status })
      const scheduleList = response.schedules.map(s => new ScheduleModelTeam(teamId, s))
      this.SET_CONFIRMED_SCHEDULE({ teamId, models: scheduleList })
    } catch (e) {
      this.SET_CONFIRMED_SCHEDULE({ teamId, models: [] })
      throw e
    } finally {
      this.SET_CONFIRMED_SCHEDULE_LOADING({ teamId, isLoading: false })
    }
  }
  @Action
  async fetchTeamSchedule(payload: { teamId: string; status: ScheduleStatus[]; organizerMemberId?: string }) {
    const { teamId, status, organizerMemberId } = payload
    const response = await getList(teamId, { status, organizerMemberId })
    return response
  }
  @Action
  async deleteSchedule(payload: { teamId: string; scheduleId: string }) {
    const { teamId, scheduleId } = payload
    this.SET_SCHEDULE_LOADING({ teamId, isLoading: true })
    try {
      await deleteSchedule(teamId, scheduleId)
      this.DELETE_SCHEDULE({ teamId, scheduleId })
    } catch (e) {
      this.SET_SCHEDULE_LOADING({ teamId, isLoading: false })
    }
  }
  @Action
  async deleteCandidate(payload: { teamId: string; scheduleId: string; candidateId: string }) {
    const { teamId, scheduleId, candidateId } = payload
    this.SET_SCHEDULE_LOADING({ teamId, isLoading: true })
    try {
      await deleteCandidate(teamId, scheduleId, candidateId)
      const scheduleModel = this.scheduleModelByTeamIdScheduleId(teamId, scheduleId)
      // @ts-expect-error TS2532
      const candidateIndex = scheduleModel.candidates.findIndex(c => c.id === candidateId)
      if (candidateIndex >= 0) {
        // @ts-expect-error TS2532
        scheduleModel.candidates.splice(candidateIndex, 1)
      }
      // @ts-expect-error TS2322
      this.UPDATE_SCHEDULE_MODEL({ teamId, model: scheduleModel })
    } catch (e) {
      this.SET_SCHEDULE_LOADING({ teamId, isLoading: false })
    }
  }
  @Mutation
  SET_SCHEDULE_LOADING(payload: { teamId: string; isLoading: boolean }) {
    const { teamId, isLoading } = payload
    const newScheduleList = {
      isLoading,
      models: this.scheduleList[teamId]?.models || []
    }
    this.scheduleList = {
      ...this.scheduleList,
      [payload.teamId]: newScheduleList
    }
  }
  @Mutation
  SET_CONFIRMED_SCHEDULE_LOADING(payload: { teamId: string; isLoading: boolean }) {
    const { teamId, isLoading } = payload
    const newScheduleList = {
      isLoading,
      models: this.confirmedScheduleList[teamId]?.models || []
    }
    this.confirmedScheduleList = {
      ...this.confirmedScheduleList,
      [payload.teamId]: newScheduleList
    }
  }
  @Mutation
  SET_SCHEDULE(payload: { teamId: string; models: ScheduleModelTeam[] }) {
    this.scheduleList = {
      ...this.scheduleList,
      [payload.teamId]: {
        ...this.scheduleList[payload.teamId],
        models: payload.models
      }
    }
  }
  @Mutation
  SET_CONFIRMED_SCHEDULE(payload: { teamId: string; models: ScheduleModelTeam[] }) {
    this.confirmedScheduleList = {
      ...this.confirmedScheduleList,
      [payload.teamId]: {
        ...this.confirmedScheduleList[payload.teamId],
        models: payload.models
      }
    }
  }
  @Mutation
  DELETE_SCHEDULE(payload: { teamId: string; scheduleId: string }) {
    const { teamId, scheduleId } = payload
    const deletedModelIndex = this.scheduleList[teamId]?.models.findIndex(m => m.id === scheduleId)
    if (deletedModelIndex < 0) {
      return
    }
    const newScheduleList = {
      ...this.scheduleList[teamId],
      isLoading: false
    }
    newScheduleList.models.splice(deletedModelIndex, 1)
    this.scheduleList = {
      ...this.scheduleList,
      [teamId]: newScheduleList
    }
  }
  @Mutation
  UPDATE_SCHEDULE_MODEL(payload: { teamId: string; model: ScheduleModelTeam }) {
    const { teamId, model } = payload
    if (!this.scheduleList[teamId]) {
      this.scheduleList[teamId] = {
        isLoading: false,
        models: [model]
      }
    } else {
      const modelIndex = this.scheduleList[teamId].models.findIndex(m => m.id === model.id)
      const currentModels = [...this.scheduleList[teamId].models]
      if (modelIndex >= 0) {
        currentModels[modelIndex] = model
      } else {
        currentModels.push(model)
      }
      this.scheduleList = {
        ...this.scheduleList,
        [teamId]: {
          isLoading: false,
          models: currentModels
        }
      }
    }
  }
}

export default getModule(TeamScheduleList)
