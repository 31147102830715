import { connectZoom, disconnectZoom, fetchIntegrations } from '@/lib/api/integrations'
import { getProfile } from '@/lib/api/profile'
import { createUser } from '@/lib/api/user'
import { sendUserInfoToExternalServices } from '@/lib/externalServices/sendUserInfoToExternalServices'
import { getFirebaseUser } from '@/lib/firebaseAuth'
import { sendAuthExceptionToSentry } from '@/lib/sentry'
import { selectAppInitMode } from '@/lib/utils/authRedirect'
import { isProduction } from '@/lib/utils/environment'
import { UserAuthProviders } from '@/models/user'
import store from '@/store'
import EventModule from '@/store/modules/event'
import ProfileModule from '@/store/modules/profile'
import ScheduleListModule from '@/store/modules/scheduleList'
import { captureException } from '@sentry/vue'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { QueryClient } from '@tanstack/vue-query'
import { User as FirebaseUser, getAdditionalUserInfo, getAuth, getRedirectResult, signOut } from 'firebase/auth'
import userflow from 'userflow.js'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import { SignalType } from '../../lib/analytics/LogEntry'
import { deleteAccount, getAccounts } from '../../lib/api/account'
import { IAccount, IIntegrations } from '../../types'
import { SessionStorage } from '../lib/sessionStorage'
import AppModule from './app'
import CalendarControlModule from './calendarControl'
import CalendarsModule from './calendars'
import './dailyView'
import EventsModule from './events'
import FeatureTogglerModule from './featureToggler'
import './settings'
import TimezonesModule from './timezones'

@Module({
  dynamic: true,
  name: 'User',
  namespaced: true,
  store
})
export class User extends VuexModule {
  _currentUser: FirebaseUser | null = null // todo
  _initFB = false
  _accounts: IAccount[] = []
  _isAccountLoading = false
  _isRedirectedAfterSignIn: 'ok' | 'error' | 'noUser' | 'invalidInvitationCode' | 'userExists' | null = null
  _integrations: IIntegrations = {}
  // @ts-expect-error TS2322
  _authProvider: UserAuthProviders = null // 初回連携したProviderを保存

  get myId() {
    return this.currentUser?.uid
  }
  get currentUser(): FirebaseUser | null {
    return this._currentUser
  }
  get isSignIn() {
    return this._currentUser && !!this._currentUser.uid
  }
  get accounts() {
    return this._accounts
  }
  get isRedirectedAfterSignIn() {
    return this._isRedirectedAfterSignIn
  }
  get integrations() {
    return this._integrations
  }
  get isConnectedZoom(): boolean {
    const zoomInfo = this._integrations.zoomInfo
    return zoomInfo?.accountId != null && zoomInfo?.email != null
  }
  get authProvider(): UserAuthProviders {
    return this._authProvider
  }
  @Mutation
  RESET_STATE() {
    this._currentUser = null
    this._accounts = []
    this._isAccountLoading = false
    this._isRedirectedAfterSignIn = null
  }
  @Mutation
  ON_AUTH_CHANGED(user: FirebaseUser | null) {
    this._currentUser = user
    if (user && user.providerData?.length > 0) {
      switch (user.providerData[0].providerId) {
        case 'google.com':
          this._authProvider = 'google'
          break
        case 'microsoft.com':
          this._authProvider = 'microsoft'
          break
        default:
          this._authProvider = 'email'
      }
    }
  }
  @Mutation
  ON_ACCOUNT_LOADING_CHANGED(loadingStatus: boolean) {
    this._isAccountLoading = loadingStatus
  }
  @Mutation
  ON_ACCOUNTS_CHANGED(accounts) {
    this._accounts = accounts
  }
  @Mutation
  ON_CHANGED_STATUS_REDIRECTED(result) {
    this._isRedirectedAfterSignIn = result
  }
  @Mutation
  SET_INTEGRATIONS(integrations: IIntegrations) {
    this._integrations = integrations
  }
  @Mutation
  ON_INIT_FB() {
    this._initFB = true
  }
  @Action
  async initializeAppData() {
    try {
      const user = await getFirebaseUser()
      const currentUser = user && user.uid ? user : null

      if (user) {
        // userflow上でprodとdevを区別している為、出し分けを行います。
        // 設定に関しては、https://userflow.com/app/spir-inc@dev/settings/environments 参照
        // tokenがコード上に露出してますが、ユーザにも露出するので問題ないと判断しています。
        userflow.init(process.env.VUE_APP_USER_FLOW_KEY as string)
        const attributes = {
          name: user.displayName,
          email: user.email,
          language: ProfileModule.getLanguage
        }

        if (user.metadata?.creationTime) {
          attributes['signed_up_at'] = new Date(user.metadata.creationTime).toISOString()
        }

        userflow.identify(user.uid, attributes)

        // HubspotのContact（ユーザ）とSpirのユーザを紐付けるために、emailを送信して連携する
        // https://developers.hubspot.jp/docs/api/events/tracking-code
        if (isProduction()) {
          const _hsq = (window._hsq = window._hsq || [])
          _hsq.push([
            'identify',
            {
              email: user.email
            }
          ])
        }
      }

      this.ON_AUTH_CHANGED(currentUser)
      if (currentUser) {
        Vue.prototype.$analytics.setUserId(currentUser.uid)
        Vue.prototype.$analytics.send(SignalType.ACTIVE)
        // Google Signupの場合、SignUp処理中にInit処理するためここではやらない。
        if (this._initFB) return
        // FIXME: このアクション(firebaseInit)をmain.tsのVue appをmountする前に呼び出すのをやめると
        // Routingがvue-routerの名前で取れるので、以下のようなlocation.pathnameをしなくてよくなる
        const initMode = selectAppInitMode(location.pathname)
        await AppModule.initApp({ mode: initMode })
      } else if (this.isRedirectedAfterSignIn === null) {
        this.RESET_STATE()
        Vue.prototype.$analytics.setUserId(null)
        ProfileModule.setLanguage()
        CalendarsModule.RESET_STATE()
        EventsModule.RESET_STATE()
        EventModule.RESET_STATE()
        ProfileModule.RESET_STATE()
        ScheduleListModule.RESET_STATE()
        sendUserInfoToExternalServices.unSignedInUser()
      }
    } catch (e: unknown) {
      // noop
    } finally {
      this.ON_INIT_FB()
    }
  }
  @Action
  async firebaseInit() {
    try {
      await FeatureTogglerModule.initialize()
      await this.initializeAppData()
      await CalendarControlModule.setDefaultCalendarView()
    } catch (e: unknown) {
      captureException(e)
    }
  }
  // MS signin, signup, Google Signinの時、FirebaseAuthのRedirectを処理
  // Authcodeを取得する時は、firebase.auth().getRedirectResult()がないため何もおきない。
  @Action
  async checkRedirectFromSignIn() {
    try {
      const firebaseAuth = getAuth()
      const result = await getRedirectResult(firebaseAuth)
      if (result?.user) {
        const additionalUserInfo = getAdditionalUserInfo(result)
        const authAction = SessionStorage.getAuthAction()
        if (authAction) {
          switch (authAction.action) {
            case 'SignIn':
              // firebaseに新規作成されたユーザーの場合 -> firebaseのユーザーを削除
              if (additionalUserInfo?.isNewUser) {
                this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'noUser')
                await result.user.delete()
                await signOut(firebaseAuth)
                break
              }
              // Backendで作成済のユーザーを確認
              try {
                await getProfile()
                this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'ok')
                await Vue.prototype.$analytics.send(SignalType.SIGNIN_SUCCESS, {
                  from: authAction.from,
                  id: authAction.id,
                  type: authAction.type,
                  from_screen_name: authAction.fromScreenName,
                  redirect_from: authAction.redirectFrom
                })
              } catch (e) {
                sendAuthExceptionToSentry(e, 'error in login')
                this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
              }
              break
            case 'SignInOrSignUp':
            case 'SignUp':
              // 新規ユーザーの場合->ユーザー作成APIを呼び出してトップへ
              if (additionalUserInfo?.isNewUser) {
                try {
                  await createUser({
                    timeZone: TimezonesModule.localTimezoneInfo.key,
                    language: ProfileModule.getLanguage
                  })
                } catch (e: any) {
                  if (e.code === ERROR_CODE.ACCOUNT_DUPLICATED) {
                    this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'userExists')
                    sendAuthExceptionToSentry(e, 'remove user due to duplicated user.')
                  } else {
                    this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
                    sendAuthExceptionToSentry(e, 'remove user due to error in creating user.')
                  }
                  return await result.user.delete()
                }
                this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'ok')
                await Vue.prototype.$analytics.send(SignalType.SIGNUP_SUCCESS, {
                  from: authAction.from,
                  id: authAction.id,
                  type: authAction.type,
                  from_screen_name: authAction.fromScreenName,
                  redirect_from: authAction.redirectFrom
                })
              } else {
                try {
                  await getProfile()
                  this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'ok')
                  await Vue.prototype.$analytics.send(SignalType.SIGNIN_SUCCESS, {
                    from: authAction.from,
                    id: authAction.id,
                    type: authAction.type,
                    from_screen_name: authAction.fromScreenName,
                    redirect_from: authAction.redirectFrom
                  })
                } catch (e) {
                  sendAuthExceptionToSentry(e, 'error in login.')
                  this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
                }
              }
              break
          }
        }
      }
    } catch (e: any) {
      sendAuthExceptionToSentry(e, 'error in calling firebase.auth().getRedirectResult()')
      if (e.code && e.code === 'auth/account-exists-with-different-credential') {
        const authAction = SessionStorage.getAuthAction()
        SessionStorage.setAuthAction(null)
        if (authAction?.action === 'SignIn') {
          this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'noUser')
        } else {
          this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'userExists')
        }
      } else {
        this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
      }
    }
  }
  @Action
  async fetchAccount() {
    this.context.commit('ON_ACCOUNT_LOADING_CHANGED', true)
    try {
      const accounts = await getAccounts()
      this.context.commit('ON_ACCOUNTS_CHANGED', accounts)
    } finally {
      this.context.commit('ON_ACCOUNT_LOADING_CHANGED', false)
    }
  }
  @Action
  async deleteAccount(accountId) {
    await deleteAccount(accountId)
    return CalendarsModule.fetchCalendars()
  }
  @Action
  async signOut() {
    try {
      sendUserInfoToExternalServices.signedOut()
      const firebaseAuth = getAuth()
      await signOut(firebaseAuth)
    } finally {
      AppModule.resetAllModules()
    }
  }
  @Action
  async connectZoom(authCode: string) {
    await connectZoom(authCode)
    await this.context.dispatch('fetchIntegrations')
  }
  @Action
  async fetchIntegrations(refreshingZoom = false) {
    const integrations = await fetchIntegrations(refreshingZoom)
    this.context.commit('SET_INTEGRATIONS', integrations)
  }
  @Action
  async disconnectZoom() {
    await disconnectZoom()
    await this.context.dispatch('fetchIntegrations')
  }
}

export default getModule(User)
