const RemoteConfigKeys = {
  accountLinkEnabled: 'accountLinkEnabled',
  notificationEnabled: 'notificationEnabled',
  emailChangeEnabled: 'emailChangeEnabled',
  teamEnabled: 'teamEnabled',
  teamPhotoUpdateEnabled: 'teamPhotoUpdateEnabled',
  abTestingShowNewConfirmPageEnabled: 'abTestingShowNewConfirmPageEnabled',
  questionnaireEnabled: 'questionnaireEnabled',
  formCheckboxEnabled: 'formCheckboxEnabled'
}
type RemoteConfigKeys = typeof RemoteConfigKeys[keyof typeof RemoteConfigKeys]

export default RemoteConfigKeys
