export interface User {
  id: string
  name: string
  photoURL: string
}

export enum ACCOUNT_TYPE {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft'
}
export interface IAccount {
  accountId: string
  name: string
  photo: string
  accountType: ACCOUNT_TYPE
}

export interface IIntegrations {
  zoomInfo?: IZoomInfo
}

export interface IZoomInfo {
  accountId: string
  email: string
  settings: ZoomSettings
}

export interface ZoomSettings {
  passwordSetting: ZoomPasswordSetting
}

export const ZoomPasswordSettings = {
  auto: 'auto',
  conformity: 'conformity'
} as const

export type ZoomPasswordSetting = typeof ZoomPasswordSettings[keyof typeof ZoomPasswordSettings]
export const UserCalendarStatus = {
  VALID: 'valid', //正常
  NO_CALENDAR_ACCOUNT: 'noAccount', //カレンダーが登録されていない
  ALL_CALENDAR_ACCOUNTS_ARE_INVALID: 'allCalendarAccountsAreInvalid' //すべてのカレンダーがInvalidの状態
}
export type UserCalendarStatus = typeof UserCalendarStatus[keyof typeof UserCalendarStatus]
